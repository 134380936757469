import {Component, OnDestroy, OnInit} from '@angular/core';
import {BUTTON_COLORS} from '../../../modules/common-components/button/button.component';
import {Subscription} from 'rxjs/internal/Subscription';
import {AuthService} from '@replica-frontend/auth';
import {ActivatedRoute, Params} from '@angular/router';
import {DeviceDetectorService} from '@replica-frontend/sdk';

@Component({
  selector: 'app-confirm-email-successful',
  templateUrl: './confirm-email-successful.component.html',
  styleUrls: ['./confirm-email-successful.component.scss']
})
export class ConfirmEmailSuccessfulComponent implements OnInit, OnDestroy {
  ButtonColors = BUTTON_COLORS;
  loading = true;
  subscription: Subscription;
  error = '';
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    if (this.deviceDetectorService.isServer()) {
      return;
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.authService.confirmEmail(params.token).subscribe(
        () => {
          this.loading = false;
        },
        (err) => {
          this.error = 'Invalid token';
        }
      );
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
