<div
    fxLayout="column"
    class="container"
>
    <div
        *ngIf="!!error"
        class="error-wrapper"
    >
        <span class="error-message">{{error}}</span>
    </div>
    <div
        class="input-wrapper"
        fxLayout="row"
        fxFlex="100"
    >
        <input
        [ngStyle]="{'text-align': centerLabel ? 'center' : 'inherit' }"
            (change)="onChange($event)"
            [ngClass]="{'error': !!error, 'pad-input': !centerLabel}"
            [type]="type"
            fxLayout="row"
            [placeholder]="placeholder"
            fxLayoutAlign="center center"
            fxFlex="100"
            class="input-container"
            [(ngModel)]="value"
            (ngModelChange)="updateChanges()"
        >
    </div>

</div>
