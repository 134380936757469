import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BUTTON_COLORS } from '../../../modules/common-components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@replica-frontend/auth';

@Component({
  templateUrl: './invite-create-password.component.html',
  styleUrls: ['./invite-create-password.component.scss']
})
export class InviteCreatePasswordComponent implements OnInit {
  submitError = '';
  ButtonColors = BUTTON_COLORS;
  invitePasswordForm = this.formBuilder.group({
    password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
        )
      ]
    ],
    passwordRetype: ['', Validators.required],
    acceptRules: [false, Validators.required],
    newsletter: [false]
  });
  token = '';
  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.token = params.token;
    });
  }

  submit(): void {
    // check form errors
    this.authService
      .acceptInvitation({
        password: this.invitePasswordForm.get('password').value,
        token: this.token
      })
      .subscribe(
        () => {
          this.snackBar.open(
            `Account created, now you can log in with your email`,
            'Dismiss',
            {
              duration: 3000
            }
          );
          this.router.navigate(['/', 'sign-in']);
        },
        () => {
          this.snackBar.open(`Something went wrong`, 'Dismiss', {
            duration: 3000
          });
        }
      );
  }
}
