import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HierarchicalOption } from 'src/app/modules/common-components/interfaces/hierarchical-option.interface';
import {
  MapboxFeature,
  MapboxResponse,
  MapboxService,
} from 'src/app/modules/sp-services/mapbox.service';

type Option = { label: string; value: number };
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  placeholder = 'Wpisz adres';
  secondPlaceholder = 'promień wyszukiw...';
  optionsSubject: BehaviorSubject<HierarchicalOption<MapboxFeature>[]> =
    new BehaviorSubject<HierarchicalOption<MapboxFeature>[]>([]);
  options$ = this.optionsSubject.asObservable();
  loadSub: Subscription;
  required: boolean;
  options = [];
  leftIcon = false;
  value: any | null = null;
  secondValue: Option | null = null;
  onChange: (_: any) => void;
  onTouched: () => void;
  opened = false;
  mileages = [
    {
      label: '10 Mil',
      value: 10,
    },
    {
      label: '100 Mil',
      value: 100,
    },
  ];

  disabled = false;
  clickedInside = false;

  error: string = null;

  constructor(private mapboxService: MapboxService, private router: Router) {}

  ngOnInit(): void {
    this.options$.subscribe((options) => {
      this.options = options;
    });
    this.writeSecondValue(this.mileages[0]);
  }

  writeValue(value: any) {
    this.value = value;
  }

  searchCities($event): void {
    const value = $event.target.value;

    if (this.loadSub) {
      this.loadSub.unsubscribe();
    }

    if (value.length > 0) {
      this.opened = true;
    } else {
      this.opened = false;
    }

    this.mapboxService.searchPlaces(value,[
      'country',
      'region',
      'place',
      'district',
      'postcode',
      'neighborhood',
      'address'
    ]).subscribe((response) => {
      console.log(response);
      this.optionsSubject.next(
        response.features.map((feature) => ({
          activeNode: false,
          label: feature.place_name,
          children: [],
          lazyChildren: false,
          value: feature,
        }))
      );
    });
  }

  writeSecondValue(option: Option) {
    this.secondValue = option;
  }

  submit(): void {
    if (this.value && this.secondValue) {
      this.router.navigate(['search'], {
        queryParams: {
          mapboxPlace: encodeURIComponent(JSON.stringify(this.value.value)),
          radius: this.secondValue.value,
        },
      });
    }
  }
}
