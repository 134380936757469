import {DeviceDetectorService} from 'src/app/modules/common-services';
import {ExceptionTelemeteryService} from './exception-telemetery.service';
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthException} from '@replica-frontend/auth';
import {ValidationException} from '@replica-frontend/sdk';

@Injectable({
  providedIn: 'root'
})
export class ExceptionTelemeteryErrorHandlerService implements ErrorHandler {

  constructor(
    private exceptionTelemeteryService: ExceptionTelemeteryService,
    private deviceDetectorService: DeviceDetectorService
  ) {
  }

  handleError(error: any): void {
    if (this.deviceDetectorService.isServer()) {
      return;
    }
    if (!(error instanceof HttpErrorResponse ||
      error instanceof ValidationException || error instanceof AuthException) && !(error.message.includes('form validation'))) {
      const message = {
        message: JSON.stringify(error.message),
        exceptionTrace: JSON.stringify(error.stack) || error.stack,
        additionalTechData: JSON.stringify({
          isMobile: !this.deviceDetectorService.isDesktop(),
          location: document.URL,
          userAgent: navigator.userAgent
        }),
        loggedUserTechData: ''
      };
      this.exceptionTelemeteryService.reportException(message).subscribe((isSent) => {
        if (isSent) {
          // tslint:disable-next-line:no-console
          console.info('ExceptionTelemetery', 'sent report to backend', message);
        } else {
          console.error('ExceptionTelemetery', 'Exception was sent but was not saved', message);
        }
      });
    }
    console.error(error);
  }
}
