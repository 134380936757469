import { Component, OnInit, Input } from '@angular/core';
import { AuthService, UserData } from '@replica-frontend/auth';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @Input()
  userList: string[];
  users: UserData[];
  loading = true;
  constructor(private authService: AuthService) { }
  ngOnInit(): void {
    this.authService.getUsersByIds(this.userList).subscribe((users: UserData[]) => {
      this.users = users;
      this.loading = false;
    });
  }

}
