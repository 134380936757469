import { Component, HostListener, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from '../../common-services';

@Component({
  selector: 'responsive-switcher',
  templateUrl: './responsive-switcher.component.html',
  styleUrls: ['./responsive-switcher.component.scss']
})
export class ResponsiveSwitcherComponent implements OnInit {
  mobile = false;

  constructor(private deviceDetectorService: DeviceDetectorService) {
  }
  ngOnInit() {
    if (this.deviceDetectorService.isServer() && this.deviceDetectorService.isDesktop())  {
      this.mobile = false;
    } else if (this.deviceDetectorService.isServer() && !this.deviceDetectorService.isDesktop()) {
      this.mobile = true;
    } else if (!this.deviceDetectorService.isServer() && this.deviceDetectorService.isDesktop()) {
      this.mobile = false;
    } else if (!this.deviceDetectorService.isServer() && !this.deviceDetectorService.isDesktop()) {
      this.mobile = true;
    }

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const mobile = event.target.innerWidth < this.deviceDetectorService.THRESHOLD;
    if (this.mobile !== mobile) {
      location.reload();
    }
  }

}
