import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@replica-frontend/auth';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import {ServerSideValidationService, ValidationErrorMessage, ValidationHandler} from '@replica-frontend/sdk';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy, ValidationHandler {
  ButtonColors = BUTTON_COLORS;
  loginForm = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
  badPassword = false;
  badEmail = false;
  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private location: Location,
    private validationService: ServerSideValidationService) {

  }

  ngOnInit(): void {
    this.validationService.registerValidationHandler('sign-in', this);
  }

  submit(): void {
    this.authService.login(this.loginForm.value).subscribe(() => {
      this.badPassword = false;
      this.badEmail = false;
      this.router.navigate(['/app', 'offers-for-employee']);
    }, (err) => {
      this.badPassword = true;
      // this.badEmail = true;
    });
  }

  onFieldErrors(errors: ValidationErrorMessage): void {
    errors.message.forEach(error => {
      this.loginForm.controls[error.field].setErrors({ message: error.message });
    });
  }

  back(): void {
    this.location.back();
  }

  loginSocial($event, platform: string): void {
    this.authService.loginSocial($event, platform)
    .subscribe(() => {
      this.router.navigate(['/app', 'offers-for-employee']);
    });
  }

  ngOnDestroy(): void {
    this.validationService.removeValidationHandler('sign-in');
  }
}
