import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/modules/common-services/environment.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DefaultFilters } from '.';
import { Option } from '../common-components/index';
export interface City {
  id: number;
  name: string;
  province: number | Province;
}

export interface Province {
  id: number;
  name: string;
  cities: City[];
}

@Injectable()
export class CommonHttpService {
  getProvinces$ = this.httpClient
    .get<Array<Province>>(`${this.environmentService.getEnv('apiUrl')}/geoselect/province`).pipe(shareReplay(1));


  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
  ) { }

  public getCities(filters: DefaultFilters<City>): Observable<Option[]> {
    return this.httpClient.post<Array<City>>(`${this.environmentService.getEnv('apiUrl')}/geoselect`, filters).pipe(
      map((values: City[]) => {
        return values.map((value) => {
          return {
            label: value.name,
            value: value.id
          };
        });
      })
    );
  }

  public singleCity(id: number): Observable<Option> {
    return this.httpClient.get<City>(`${this.environmentService.getEnv('apiUrl')}/geoselect/${id}`)
      .pipe(map(x => ({
        label: x.name,
        value: x.id
      })));
  }

  public getImagePath(path: string): string {
    return `${this.environmentService.getEnv('apiUrl')}/${path}`;
  }
}
