import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-view',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.scss']
})
export class LoadingViewComponent implements OnInit {
  @Input()
  loading: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}

