import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { OffersResponseDTO, OffersService, Other } from '@src/app/modules/sp-services/offers.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Option } from '../../modules/common-components';
import {ServerSideValidationService, ValidationError, ValidationErrorMessage} from '@replica-frontend/sdk';

enum TopbarFilterState {
  None, Search, Filter
}
@Component({
  selector: 'app-topbar-filter',
  templateUrl: './topbar-filter.component.html',
  styleUrls: ['./topbar-filter.component.scss'],
})
export class TopbarFilterComponent implements OnInit, OnDestroy {

  @Input()
  label: string;
  placeholder: string;
  opened = false;
  selected = 'title';
  filterButtonColor = '#F4F6FB';
  search = '';
  searchButtonColor = '#F4F6FB';
  city: number;
  filters = new FormControl();
  filtersList: Observable<any> = of([{ label: 'Data dodania', value: 'created' },
  { label: 'Nazwa', value: 'title' },
  { label: 'Miejscowość', value: 'city' }]);
  filterForm = this.formBuilder.group({
    mapboxPlace: [null, []],
    category: ['', []],
    type: ['', []],
    description: ['', []],
    rateType: ['', []],
    salary: ['', []],
    workTime: ['', []],
    days: ['', []],
    skills: [[], this.formBuilder.array([])],
    thumbnail: [null]
  });

  @Output()
  filtersOpened = new EventEmitter<boolean>();
  @Output()
  filtersChanged = new EventEmitter<Partial<OffersResponseDTO>>();
  @Output()
  sortChanged = new EventEmitter<{ field: string, sort: string }>();
  @ViewChild('form') form;


  thumbnail: File = null;
  TopbarFilterState = TopbarFilterState;
  topbarFilterState: TopbarFilterState;

  types$ = this.offersHttpService.getOfferTypes$.pipe(
    map((offersTypes) => {
      return offersTypes.map(offersType => ({
        label: offersType.name,
        value: offersType.id
      }));
    }),
  );

  categories$ = this.offersHttpService.getCategories$.pipe(
    map((categories) => {
      return categories.map(category => ({
        label: category.category,
        value: category.id
      }));
    }),
  );

  rateTypes$ = this.offersHttpService.getRateTypes$.pipe(
    map((rateTypes) => {
      return rateTypes.map(rateType => ({
        label: rateType.name,
        value: rateType.id
      }));
    }),
  );

  workTimes$ = of([{
    label: 'Pełny',
    value: 'full'
  }, {
    label: 'Niepełny',
    value: 'partial'
  }]);

  days$ = this.offersHttpService.getOffersDays$.pipe(
    map((days) => {
      return days.map(day => ({
        label: day.name,
        value: day.id
      }));
    }),
  );

  constructor(private formBuilder: FormBuilder,
              private offersHttpService: OffersService,
              private validationService: ServerSideValidationService,
              private location: Location) {

  }

  ngOnInit(): void {
    this.filterForm.valueChanges.subscribe((valueChange) => {
      this.cleanForm(valueChange);
      this.filtersChanged.emit(valueChange);
    });
    this.validationService.registerValidationHandler('create-offer', this);
  }

  cleanForm(formValues: any): void {
    const formKeys = Object.keys(formValues);
    if (formKeys.length) {
      for (let i = 0; i < formKeys.length; i++) {
        if (!formValues[formKeys[i]]) {
          delete formValues[formKeys[i]];
        }
      }
    }
    return formValues;
  }

  openFilters($event): void {
    this.opened = !this.opened;
    if (this.topbarFilterState !== TopbarFilterState.Filter) {
      this.topbarFilterState = TopbarFilterState.Filter;
    } else {
      this.topbarFilterState = TopbarFilterState.None;
    }
    this.filtersOpened.emit(this.opened);
  }

  openSearch($event): void {
    if (this.topbarFilterState !== TopbarFilterState.Search) {
      this.topbarFilterState = TopbarFilterState.Search;
    } else {
      this.topbarFilterState = TopbarFilterState.None;
    }
  }

  submitFilters(): void {
    this.filtersChanged.next(this.filterForm.value as OffersResponseDTO);
  }

  runSearch(): void {
    this.filtersChanged.emit({title: this.search});
  }

  skillsFinder(name: string): Observable<Option[]> {
    return this.offersHttpService.getSkillsByName(name).pipe(
      switchMap((values: Other[]) => {
        return of(values.map((value) => {
          return {
            label: value.name,
            value: value.id.toString(10)
          };
        }));
      })
    );
  }

  changed($event): void {
    this.sortChanged.emit($event);
  }

  onFieldErrors(errors: ValidationErrorMessage): void {
    errors.message.forEach(error => {
      this.filterForm.controls[error.field].setErrors({ message: error.message });
    });
  }

  back(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.validationService.removeValidationHandler('create-offer');
  }
}
