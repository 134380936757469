import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DefaultFilters } from '../../modules/common-services/default-filters.interface';
import {
  OffersResponseDTO,
  OffersService,
} from '../../modules/sp-services/offers.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DeviceDetectorService } from '../..//modules/common-services';
import { SearchService } from '../../modules/sp-services/search.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@replica-frontend/auth';
import { LngLat, LngLatBounds, Map } from 'mapbox-gl';
import { OffersRequestDTO } from 'src/app/modules/sp-services';
import { MapboxService } from 'src/app/modules/sp-services/mapbox.service';

export interface MenuObject {
  name: string;
  link: string;
  icon: string;
}
// TODO: unused, remove
@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss'],
})
export class OfferListComponent implements OnInit, OnDestroy {
  @Input()
  public queryMethod: string;
  /** DEPRECATED */
  @Input()
  public filterLabel: string;
  @Input()
  public titleLabel: string;
  @Input()
  public limit: number;
  @Input()
  public offset: number;
  /** DEPRECATED */
  @Input()
  public label: string;
  @Input()
  public noOffersLabel: string;
  filtersOpened = false;

  public map: Map;
  mapZoomed = false;
  @Input()
  filters: DefaultFilters<OffersResponseDTO> = {
    limit: 16,
    offset: 0,
    sortBy: { field: 'title', sort: 'ASC' },
    filterBy: {}
  };

  filter: DefaultFilters<OffersRequestDTO> = { limit: 10, offset: 0, filterBy: {} };
  buffer: OffersResponseDTO[] = [];
  loading: boolean;
  loadSub: Subscription;
  isServer = this.deviceDetectorService.isServer();
  mapLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mapLoaded$ = this.mapLoadedSubject.asObservable();
  public heightVal: number;
  isIos = false;
  bounds: LngLatBounds;
  constructor(
    public authService: AuthService,
    public offerService: OffersService,
    private searchService: SearchService,
    private snackBar: MatSnackBar,
    private router: Router,
    private deviceDetectorService: DeviceDetectorService
  ) {}
  ngOnInit(): void {
    this.isIos = this.deviceDetectorService.iOS();
    this.loading = true;
    if (!this.queryMethod || !this.offerService[this.queryMethod]) {
      throw new Error(
        'Query method not found or not defined. Use method name from offers.service'
      );
    }
    if (this.filterLabel) {
      this.titleLabel = this.filterLabel;
    } else if (this.label) {
      this.titleLabel = this.label;
    }
    this.load();
    this.searchService.search$.subscribe((searchQuery: string) => {
      this.filtersChanged({ title: searchQuery });
    });
    this.onResize();
  }

  onResize(): void {
    this.heightVal = window.innerHeight - 260;
  }

  fetchMore(event): void {


  }

  load(): void {
    // this.loading = true;
    if (this.loadSub) {
      this.loadSub.unsubscribe();
    }
    this.loadSub = (this.offerService[this.queryMethod](
      this.filters
    ) as Observable<Array<OffersResponseDTO>>).subscribe(
      (offers: OffersResponseDTO[]) => {
        this.loading = false;
        offers.forEach((offer) => {
          if (this.buffer.indexOf(offer) === -1) {
            this.buffer.push(offer);
          }
        });
      }, (err) => {
        console.error(err);
        const snackBarRef = this.snackBar.open(
          'Błąd podczas pobierania ogłoszeń',
          'Cofnij',
          {
            duration: 3000
          }
        );
        snackBarRef.onAction().subscribe(() => this.router.navigate(['/app', 'offers-for-employee']));
        this.loading = false;
      }
    );
  }

  onMapLoaded($event): void {
    this.map = $event;
    this.mapLoadedSubject.next(true);
    if (!this.mapZoomed) {
      this.zoomMap();
      this.addOffersToMap();
    }

  }

  getCurrentGeoJson() {
    const geojson = {
      type: 'FeatureCollection',
      features:  this.buffer.filter(offer => offer.mapboxPlace).map((offer) => {
        return {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [offer.mapboxPlace.centerLat, offer.mapboxPlace.centerLng, 0 ] },
        }

      })
    }
    
    return geojson;
}

  addOffersToMap(): void {
    console.log('planned offers to map');
    this.mapLoaded$.subscribe((result) => {
      if (result) {
        this.map.addSource('offers', {
          type: 'geojson',
          // Point to GeoJSON data. This example visualizes all M1.0+ offers
          // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
          data: this.getCurrentGeoJson() as any,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });
  
        this.map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'offers',
          filter: ['has', 'point_count'],
          paint: MapboxService.getClusterPaintConfig()
        });
  
        this.map.addLayer(MapboxService.getSymbolConfig() as any);
  
        this.map.addLayer(MapboxService.getUnclusteredPointConfig() as any);
        this.map.addLayer(MapboxService.getUnclusteredSymbolConfig() as any);

  
        // inspect a cluster on click
        this.map.on('click', 'clusters', (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          // const clusterId = features[0].properties.cluster_id;
        });

        this.map.on('click', 'unclustered-point', (e) => {
          const coordinates = (<any>e.features[0].geometry).coordinates.slice();
          const mag = e.features[0].properties.mag;
          const tsunami = e.features[0].properties.tsunami === 1 ? 'yes' : 'no';
    
          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
    
          this.map.on('mouseenter', 'clusters', () => {
            this.map.getCanvas().style.cursor = 'pointer';
          });
          this.map.on('mouseleave', 'clusters', () => {
            this.map.getCanvas().style.cursor = '';
          });
        });
      }
      // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.

    });

    
  }


  zoomMap(): void {
    this.bounds = new LngLatBounds();
    this.buffer.forEach((offer: OffersResponseDTO) => {
      if (offer.mapboxPlace) {
        this.bounds.extend(new LngLat(offer.mapboxPlace.centerLat, offer.mapboxPlace.centerLng));
      }
    });
    this.map.fitBounds(this.bounds, { zoom: 1 });
  }


  filtersChanged($event): void {
    this.filters = {
      ...this.filters,
      offset: 0,
    };
    this.filters.filterBy = $event;
    console.log($event);
    this.buffer = [];
    this.load();
  }

  sortChanged($event): void {
    this.filters = {
      ...this.filters,
      offset: 0,
    };
    this.filters.sortBy = $event;
    this.buffer = [];
    this.load();
  }

  trackFunction(index: number, item: OffersResponseDTO): string {
    return item.id;
  }

  setOpenedFilter($event): void {
    this.filtersOpened = !this.filtersOpened;
  }

  ngOnDestroy(): void {
    if (this.loadSub) {
      this.loadSub.unsubscribe();
    }
  }
}
