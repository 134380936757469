<div *ngIf="!!error" class="error-wrapper">
  <span class="error-message">{{ error }}</span>
</div>
<div
  class="container-select"
  [ngClass]="{
    'input-small': small,
    'input-big': !small
  }"
>
  <div
    [ngClass]="{
      'border-closed':!opened || (options$ | async).length === 0, 'border-opened': opened && (options$ | async).length > 0,
      error: !!error
    }"
    (click)="click()"
    fxLayout="row"
    class="input-container"
  >
    <div
      *ngIf="leftIcon"
      fxLayout="row"
      fxFlex="10"
      fxLayoutAlign="center center"
      class="icon-location-wrapper"
    >

      <a *ngIf="!loading" class="icon-location"></a>
    </div>
    <mat-progress-spinner *ngIf="loading"></mat-progress-spinner>
    <div
      [ngClass]="{
        'label-input-container-icon': leftIcon,
        'label-input-container': !leftIcon
      }"
      fxLayoutAlign="start center"
    >
      <span class="span-icon-label label-color-value" *ngIf="value && value !== -1">
        {{ value.label }}
      </span>
      <input
        class="span-label label-color-placeholder"
        *ngIf="!value || value === -1"
        [placeholder]="placeholder"
        (input)="searchChanged($event)"
        [(ngModel)]="searchString"
      />
      <button
        class="close-button"
        *ngIf="value && value !== -1"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clear()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <div class="arrow-container">
        <a class="arrow"></a>
      </div>
    </div>
  </div>
  <div *ngIf="opened" class="select-list">
    <common-select-options-list
      [small]="small"
      (onSelected)="writeValue($event)"
      (onBack)="onBack()"
      (scrolledDown)="scrolledDown()"
      [options$]="options$"
      [displayBack]="displayBack"
    ></common-select-options-list>
  </div>

</div>
