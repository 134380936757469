<div *ngIf="!mine" class="second-bottom-buttons">
  <app-button
    cdkFocusInitial
    *ngIf="!apply"
    (click)="newApply($event)"
    matTooltip="Aplikuj"
    matTooltipClass="tooltip"
  >
    <span class="button-span">Aplikuj</span>
  </app-button>
  <app-select-button
    matTooltip="Aplikowałeś na zlecenie"
    matTooltipClass="tooltip"
    cdkFocusInitial
    *ngIf="apply?.status === OfferApplyStatus.AwaitingAprooval"
    staticLabel="Aplikowano"
    (changed)="employeeAwaitingAproovalClicked($event)"
    [options]="employeeAwaitingAproovalOptions"
  >
  </app-select-button>
  <app-select-button
    matTooltip="Jesteś w trakcie wykonywania zlecenia"
    matTooltipClass="tooltip"
    cdkFocusInitial
    *ngIf="apply?.status === OfferApplyStatus.InProgress"
    staticLabel="W trakcie"
    (changed)="employeeInProgressClicked($event)"
    [options]="employeeInProgressOptions"
  >
  </app-select-button>
  <app-select-button
    matTooltip="Oczekuje na finalizacje"
    matTooltipClass="tooltip"
    cdkFocusInitial
    *ngIf="apply?.status === OfferApplyStatus.EmployeeFinalized"
    staticLabel="Oczekiwanie na finalizacje"
  >
  </app-select-button>
  <app-button
    matTooltip="Zapłacono za zlecenie"
    matTooltipClass="tooltip"
    cdkFocusInitial
    *ngIf="apply?.status === OfferApplyStatus.Finalized"
    (click)="aproovePayment($event)"
  >
    Zapłacono
  </app-button>
  <app-select-button
    matTooltip="Zakończono zlecenie"
    matTooltipClass="tooltip"
    cdkFocusInitial
    *ngIf="apply?.status === OfferApplyStatus.Payed"
    staticLabel="Zakończona"
  >
  </app-select-button>
</div>
<div
  fxLayoutAlign="end center"
  fxLayout="row"
  class="select-button-wrapper"
  *ngIf="mine"
>
  <app-select-button
    matTooltip="Oferta aktywna"
    matTooltipClass="tooltip"
    staticLabel="Aktywna"
    [options]="mineOfferOptions"
    (changed)="mineOfferOptionsClicked($event)"
  ></app-select-button>
</div>
