<div>
  <div class="padding" *ngIf="!logged" fxLayout="column">
    <app-guest-topbar></app-guest-topbar>
  </div>
  <div fxLayout="row">
    <div fxLayout="column" *ngIf="logged" [fxFlex]="18">
      <app-menu-logged></app-menu-logged>
    </div>
    <div fxFlex fxLayout="column" [fxFlex]="logged ? 82 : 100">
      <app-topbar-logged-desktop *ngIf="logged"></app-topbar-logged-desktop>

      <div
        [ngClass]="{
          'content-guest': !logged,
          'content-logged': logged
        }"
      >
        <router-outlet></router-outlet>
      </div>
      <app-footer-desktop *ngIf="logged"></app-footer-desktop>
    </div>
  </div>
  <div class="padding footer" *ngIf="!logged">
    <app-footer-panel></app-footer-panel>
  </div>
</div>
