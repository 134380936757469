import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '@replica-frontend/auth';
import {
  ServerSideValidationService,
  ValidationError,
  ValidationErrorMessage,
  ValidationHandler
} from '@replica-frontend/sdk';


@Component({
  selector: 'app-create-employee-account',
  templateUrl: './create-employee-account.component.html',
  styleUrls: ['./create-employee-account.component.scss']
})
export class CreateEmployeeAccountComponent implements OnInit, OnDestroy, ValidationHandler {
  ButtonColors = BUTTON_COLORS;
  title = 'Szybkapraca';
  captchaToken = '';
  captchaError = false;
  submitError = '';
  registerForm = this.formBuilder.group({
    firstname: ['', [Validators.required, Validators.maxLength(80)]],
    lastname: ['', [Validators.required, Validators.maxLength(80)]],
    email: ['', [Validators.required, Validators.email, Validators.maxLength(80)]],
    password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
    passwordRetype: ['', Validators.required],
    acceptRules: [false, Validators.required],
    newsletter: [false],
  });
  constructor(private formBuilder: FormBuilder,
              public authService: AuthService,
              private router: Router,
              private location: Location,
              private validationService: ServerSideValidationService
              ) {

  }
  ngOnInit(): void {
    this.validationService.registerValidationHandler('register', this);
  }
  ngOnDestroy(): void {
    this.validationService.removeValidationHandler('register');
  }
  onFieldErrors(errors: ValidationErrorMessage): void {
    errors.message.forEach(error => {
      this.registerForm.controls[error.field].setErrors({ message: error.message });
    });
  }
  submit(): void {
    // TODO: check password retype
    if (!this.captchaToken) {
      this.captchaError = true;
      return;
    }
    this.authService.register({ ...this.registerForm.value, ...{captchaToken: this.captchaToken }})
      .subscribe(() => {
        this.router.navigate(['create-account-successful']);
      }, (err) => this.handleError(err));
  }


  handleError(err): void {
    if (err && err.message === 'form validation failed') {
      return;
    }
    this.submitError = err.message ? err.message : 'Coś poszło nie tak';
  }

  loginSocial($event, platform: string): void {
    this.authService.loginSocial($event, platform)
      .subscribe(() => {
        this.router.navigate(['/app', 'offers-for-employee']);
      });
  }

  back($event): void {
    this.location.back();
    $event.stopPropagation();
  }

  resolved(captchaResponse: string): void {
    this.captchaError = false;
    this.captchaToken = captchaResponse;
  }

}
