<div class="container" fxLayout="column">
  <div class="button-wrapper">
    <app-button [color]="ButtonColors.WHITE" (click)="toggleOpened($event)">
      <span class="span-label label-color-placeholder">
        {{ staticLabel ? staticLabel : selectedOption?.label }}
      </span>
      <span class="material-icons" *ngIf="options?.length > 0"> keyboard_arrow_down </span>
    </app-button>
  </div>

  <div *ngIf="opened" class="options-wrapper">
    <mat-list role="list" *ngFor="let option of options" class="options">
      <div
        mat-list-item
        class="option"
        (click)="writeValue($event, option.value)"
        role="listitem"
        [ngClass]="{ 'active-item': option.value === selectedOption.value }"
      >
        {{ option.label }}
      </div>
    </mat-list>
  </div>
</div>
