<responsive-switcher>
  <rs-desktop>
    <div class="container-main">
        <div class="label-wrapper-container">
          <div class="label-inner-wrapper-container">
            <div class="label-wrapper">
              <button
                fxLayoutAlign="start center"
                mat-icon-button
                fxFlex
                (click)="back()"
                matTooltip="Cofnij"
                matTooltipClass="tooltip"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
              <span
                fxLayoutAlign="center center"
                class="create-label"
                fxFlex="100"
              >
                Podaj adres email</span
              >
            </div>

            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="grey-container"
            >
              <form
                fxLayout="column"
                fxLayoutAlign="space-between center"
                [formGroup]="resetPasswordForm"
                (ngSubmit)="submit()"
                class="forgot-pass-forms"
              >
                <div fxLayout="column" class="inputs-container">
                  <p *ngIf="badEmail" class="error-password">
                    Niepoprawny email
                  </p>
                  <app-input
                    class="first-input"
                    formControlName="email"
                    ngDefaultControl
                    placeholder="Adres e-mail"
                  ></app-input>
                </div>
                <div class="button-signin">
                  <app-button
                    [color]="ButtonColors.PRIMARY"
                    buttonType="submit"
                    tooltip="Zresetuj hasło"
                  >
                    <span> Zresetuj hasło </span>
                  </app-button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  </rs-desktop>

  <rs-mobile>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center" class="label-wrapper">
          <button mat-icon-button (click)="back()" class="back-button">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span class="create-label">Podaj adres email</span>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="grey-container"
        >
          <form
            fxLayout="column"
            fxLayoutAlign="space-between center"
            [formGroup]="resetPasswordForm"
            (ngSubmit)="submit()"
          >
            <div fxLayout="column" class="inputs-container">
              <p *ngIf="badEmail" class="error-mobile">
                Niepoprawny email lub hasło
              </p>
              <app-input
                class="first-input"
                formControlName="email"
                ngDefaultControl
                placeholder="Adres e-mail"
              ></app-input>
            </div>

            <div class="button-zaloguj">
              <app-button
                [color]="ButtonColors.PRIMARY"
                buttonType="submit"
                [routerLink]="['/', 'sign-in']"
              >
                <span> Zaloguj się </span>
              </app-button>
            </div>
          </form>
        </div>
      </div>
  </rs-mobile>
</responsive-switcher>
