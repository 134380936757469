
<responsive-switcher>
  <rs-desktop>
    <mat-slide-toggle
    class="example-margin"
    (change)="changed($event)"
    [checked]="checked">
  </mat-slide-toggle>
  </rs-desktop>
  <rs-mobile>
    <mat-slide-toggle
    class="example-margin"
    (change)="changed($event)"
    [checked]="checked">
  </mat-slide-toggle>
  </rs-mobile>
</responsive-switcher>
