import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {BUTTON_COLORS} from '../../../modules/common-components/button/button.component';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent {
  ButtonColors = BUTTON_COLORS;
}
