import { ActivatedRoute } from '@angular/router';
import { DefaultFilters } from './../../../modules/common-services/default-filters.interface';
import { SearchService } from '../../../modules/sp-services/search.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OffersResponseDTO, OffersService } from '../../../modules/sp-services/offers.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  offers: Array<OffersResponseDTO> = [];
  method: string;
  constructor(
    public offersService: OffersService,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute) {}
  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      if (data.side === 'employee') {
        this.method = 'getOffers';
      } else {
        this.method = 'mine';
      }
    });
  }

  filters: DefaultFilters<OffersResponseDTO> = {
    limit: 16,
    offset: 0,
    sortBy: { field: 'title', sort: 'ASC' },
    filterBy: {}
  };

  searchSub = this.searchService.search$.subscribe((search) => {
    this.filters = { ...this.filters, filterBy: { title: search }};
  })

  ngOnDestroy(): void {
    this.searchSub.unsubscribe();
  }

}
