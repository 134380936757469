<div class="sorting-container">
  <span  class="select-label">Sortuj:</span>
  <select
      [(ngModel)]="selected"
      (change)="changed()"
  >
      <option
          *ngFor="let filter of filtersList | async"
          [value]="filter.value"
      >
          {{ filter.label }}
      </option>
  </select>

    <a (click)="reverseSort($event)" [ngClass]="
    {'arrow-up': !sortReverse, 'arrow-down': sortReverse}">

    </a>



</div>
