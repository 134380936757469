import { Router } from '@angular/router';
import { MaintenanceService } from '../../common-services/maintenance.service';
import { Component, OnInit } from '@angular/core';
import {BUTTON_COLORS} from '../../common-components/button/button.component';

@Component({
  selector: 'app-guest-topbar',
  templateUrl: './guest-topbar.component.html',
  styleUrls: ['./guest-topbar.component.scss']
})
export class GuestTopbarComponent implements OnInit {
  ButtonColors = BUTTON_COLORS;
  opened: boolean;
  title = 'Szybkapraca';
  maintenance = false;
  constructor(private maintenanceService: MaintenanceService, private router: Router) {
  }
  ngOnInit(): void {
    this.maintenance = this.maintenanceService.maintenance;
  }

  signIn(): void {
    this.router.navigate(['/','sign-in']);
  }
}
