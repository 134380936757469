import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class DeviceDetectorService {
  userAgent: string;
  THRESHOLD = 1000;
  constructor(
    @Inject(PLATFORM_ID)
    private platformId,
    @Optional()
    @Inject(REQUEST)
    protected request: Request,
  ) {
    if (!isPlatformBrowser(platformId) && request) {
      this.userAgent = request.headers['user-agent'];
    }
  }

  isDesktop(): boolean {
    if (this.userAgent) {
      return !this.userAgent.toLowerCase().includes('android') &&
      !this.userAgent.toLowerCase().includes('ios');
    } else if (window) {
      if (window.innerWidth > this.THRESHOLD) {
        return true;
      } else {
        return false;
      }
    }
  }

  isServer(): boolean {
    return !!this.userAgent;
  }

  iOS() {
    return !navigator || [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

}
