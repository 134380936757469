<responsive-switcher>
  <rs-desktop>
    <div class="container-main">
      <app-desktop-guest-wrapper>
        <div class="label-wrapper-container">
          <div class="label-inner-wrapper-container">
            <div class="label-wrapper">
              <span
                fxLayoutAlign="center center"
                class="create-label"
                fxFlex="100"
              >
                Ustaw nowe hasło</span
              >
            </div>

            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="grey-container"
            >
              <form
                fxLayout="column"
                fxLayoutAlign="space-between center"
                [formGroup]="newPasswordForm"
                (ngSubmit)="submit()"
                class="forgot-pass-forms"
              >
                <div fxLayout="column" class="inputs-container">
                  <app-input
                    type="password"
                    class="first-input"
                    formControlName="password"
                    ngDefaultControl
                    placeholder="Nowe hasło"
                  ></app-input>
                </div>
                <div fxLayout="column" class="inputs-container">
                  <app-input
                    type="password"
                    class="first-input"
                    formControlName="retypePassword"
                    ngDefaultControl
                    placeholder="Powtórz nowe hasło"
                  ></app-input>
                </div>
                <div class="button-signin">
                  <app-button
                    [color]="ButtonColors.PRIMARY"
                    buttonType="submit"
                    tooltip="Zresetuj hasło"
                  >
                    <span> Zresetuj hasło </span>
                  </app-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </app-desktop-guest-wrapper>
    </div>
  </rs-desktop>

  <rs-mobile>
    <app-mobile-guest-wrapper>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="grey-container"
        >
          <form
            fxLayout="column"
            fxLayoutAlign="space-between center"
            [formGroup]="newPasswordForm"
            (ngSubmit)="submit()"
          >
            <div fxLayout="column" class="inputs-container">
              <app-input
                class="first-input"
                formControlName="password"
                ngDefaultControl
                type="password"
                placeholder="Nowe hasło"
              ></app-input>
            </div>
            <div fxLayout="column" class="inputs-container">
              <app-input
                class="first-input"
                formControlName="retypePassword"
                ngDefaultControl
                type="password"
                placeholder="Powtórz nowe hasło"
              ></app-input>
            </div>

            <div class="button-zaloguj">
              <app-button
                [color]="ButtonColors.PRIMARY"
                buttonType="submit"
                [routerLink]="['/', 'sign-in']"
              >
                <span> Ustaw nowe hasło </span>
              </app-button>
            </div>
          </form>
        </div>
      </div>
    </app-mobile-guest-wrapper>
  </rs-mobile>
</responsive-switcher>
