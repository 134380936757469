<responsive-switcher>
  <rs-desktop>
    <div class="main-container">
      <div class="faq-desktop-wrapper">
        <div class="faq-desktop-inner-wrapper">
          <span class="subtitle"> 1. INTRODUCTION </span>
          <span class="content">
            The Site Terms of Use describe when and the conditions under which
            you are allowed to access or use the Site (defined in Section 5
            below). Please read these Site Terms of Use carefully before
            visiting our Site. Some visitors to our Site simply visit the
            publicly available portions of our Site or use the Public Site
            Services (defined in Section 5 below) (“you” or “Site Visitors”).
            If you do not agree to these Site Terms of Use, you may not visit,
            use, or access the Site or Site Services as a Site Visitor, User
            or otherwise and may not click to accept the Terms of Service when
            prompted on the Site.
          </span>
          <span class="subtitle-3">
            2. LICENSES AND THIRD-PARTY CONTENT
          </span>

          <span class="subtitle-2">
            2.1 PROVISION OF THE SITE AND LIMITED SITE LICENSE
          </span>

          <span class="content">
            Section 2.1 discusses the rights we give to you to allow you to
            use the Site, as detailed below: As we describe in this Section
            2.1, We grants you a limited license to access the Site and Site
            Services. This license is subject to and conditioned on compliance
            with the Site Terms of Use and, to the extent applicable, the rest
            of the Terms of Service. We try to keep our Site and the Site
            Services safe, secure, and functioning properly, but we cannot
            guarantee the continuous operation of or access to our Services.
            In fact, we might even stop providing the Site or Site Services
            completely or stop providing certain features without notice.
          </span>
          <span class="subtitle-2">
            2.2 TERMINATION OF THE LIMITED SITE LICENSE
          </span>

          <span class="content">
            Section 2.2 explains that we can stop allowing you to use the
            Site, as detailed below: We may terminate any license it has
            granted to any Site Visitor or User to access the Site and Site
            Services by providing notice, and the termination of such license
            shall be effective immediately upon providing such notice.
          </span>
          <span class="subtitle-2"> 2.3 INTELLECTUAL PROPERTY </span>
          <span class="content">
            Section 2.3 explains that even though we let you use the Site, we
            still retain all our rights, as detailed below: We and our
            licensors retain all right, title, and interest in and to all
            Intellectual Property Rights related in and to the Site and the
            Site Services. The logos and names are trademarks and are
            registered in certain jurisdictions. All other product names,
            company names, marks, logos, and symbols on the Site or Site
            Services may be the trademarks of their respective owners. Nothing
            in the Terms of Service grants you a right to use any Marks.
          </span>
        </div>
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div class="container">
      <div class="inner-container">
        <span class="subtitle-2"> FAQ </span>
        <span class="subtitle">
          Dlaczego nie widzę swojego profilu w bazie wykonawców?
        </span>
        <span class="content">
          The Site Terms of Use describe when and the conditions under which
          you are allowed to access or use the Site (defined in Section 5
          below). Please read these Site Terms of Use carefully before
          visiting our Site. Some visitors to our Site simply visit the
          publicly available portions of our Site or use the Public Site
          Services (defined in Section 5 below) (“you” or “Site Visitors”). If
          you do not agree to these Site Terms of Use, you may not visit, use,
          or access the Site or Site Services as a Site Visitor, User or
          otherwise and may not click to accept the Terms of Service when
          prompted on the Site.
        </span>

        <span class="subtitle">
          Dlaczego nie widzę swojego profilu w bazie wykonawców?
        </span>

        <span class="content">
          The Site Terms of Use describe when and the conditions under which
          you are allowed to access or use the Site (defined in Section 5
          below). Please read these Site Terms of Use carefully before
          visiting our Site. Some visitors to our Site simply visit the
          publicly available portions of our Site or use the Public Site
          Services (defined in Section 5 below) (“you” or “Site Visitors”). If
          you do not agree to these Site Terms of Use, you may not visit, use,
          or access the Site or Site Services as a Site Visitor, User or
          otherwise and may not click to accept the Terms of Service when
          prompted on the Site.
        </span>
        <span class="subtitle">
          Dlaczego nie widzę swojego profilu w bazie wykonawców?
        </span>

        <span class="content">
          The Site Terms of Use describe when and the conditions under which
          you are allowed to access or use the Site (defined in Section 5
          below). Please read these Site Terms of Use carefully before
          visiting our Site. Some visitors to our Site simply visit the
          publicly available portions of our Site or use the Public Site
          Services (defined in Section 5 below) (“you” or “Site Visitors”). If
          you do not agree to these Site Terms of Use, you may not visit, use,
          or access the Site or Site Services as a Site Visitor, User or
          otherwise and may not click to accept the Terms of Service when
          prompted on the Site.
        </span>
        <span class="subtitle">
          Dlaczego nie widzę swojego profilu w bazie wykonawców?
        </span>
        <span class="content">
          The Site Terms of Use describe when and the conditions under which
          you are allowed to access or use the Site (defined in Section 5
          below). Please read these Site Terms of Use carefully before
          visiting our Site. Some visitors to our Site simply visit the
          publicly available portions of our Site or use the Public Site
          Services (defined in Section 5 below) (“you” or “Site Visitors”). If
          you do not agree to these Site Terms of Use, you may not visit, use,
          or access the Site or Site Services as a Site Visitor, User or
          otherwise and may not click to accept the Terms of Service when
          prompted on the Site.
        </span>
      </div>
    </div>
  </rs-mobile>
</responsive-switcher>
