<responsive-switcher>
  <rs-desktop>
    <mat-toolbar fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="row" class="padding-wrapper" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="70">
          <span class="label-filter">{{ label }}</span>
          <button
            matTooltip="Filtry"
            matTooltipClass="tooltip"
            mat-fab
            color="primary"
            class="filter-button"
            fxLayoutAlign="center center"
            (click)="openFilters($event)"
            *ngIf="topbarFilterState === TopbarFilterState.Filter"
            [style.background-color]="
              topbarFilterState === TopbarFilterState.Filter
                ? '#0F0E1B'
                : '#F4F6FB'
            "
          >
            <a class="filter-icon-active" fxLayoutAlign="center center"></a>
          </button>
          <button
            matTooltip="Filtry"
            matTooltipClass="tooltip"
            mat-fab
            color="primary"
            class="filter-button"
            fxLayoutAlign="center center"
            (click)="openFilters($event)"
            *ngIf="topbarFilterState !== TopbarFilterState.Filter"
            [style.background-color]="
              topbarFilterState === TopbarFilterState.Filter
                ? '#0F0E1B'
                : '#F4F6FB'
            "
          >
            <a class="filter-icon" fxLayoutAlign="center center"></a>
          </button>
        </div>

        <div fxLayout="row" fxFlex="30" fxLayoutAlign="end center">
          <app-sorting-select
            matTooltip="Sortuj"
            matTooltipClass="tooltip"
            (sortChanged)="changed($event)"
          ></app-sorting-select>
        </div>
      </div>
    </mat-toolbar>
    <form
      fxLayout="column"
      [formGroup]="filterForm"
      (ngSubmit)="submitFilters()"
    >
      <div
        *ngIf="topbarFilterState === TopbarFilterState.Filter"
        fxLayout="column"
      >
        <div fxLayout="row" class="first-row">
          <common-geo-select
            [small]="true"
            formControlName="mapboxPlace"
          ></common-geo-select>
          <!--
                  <common-geo-select

                  ></common-geo-select> -->
          <app-select-mobile
            class="category-select"
            placeholder="Kategoria"
            [options]="categories$ | async"
            formControlName="category"
            ngDefaultControl
          ></app-select-mobile>
          <app-select-mobile
            class="type-select"
            placeholder="Rodzaj"
            [options]="types$ | async"
            formControlName="type"
            ngDefaultControl
          ></app-select-mobile>
        </div>
        <div fxLayout="row" class="chips">
          <app-chips
            [small]="true"
            [findFunction]="skillsFinder"
            placeholder="Umiejętności"
            formControlName="skills"
            name="skills"
          ></app-chips>
        </div>
      </div>
    </form>
  </rs-desktop>
  <rs-mobile>
    <mat-toolbar fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="70">
        <app-sorting-select
          (sortChanged)="changed($event)"
        ></app-sorting-select>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="30">
        <button
          (click)="openSearch($event)"
          mat-mini-fab
          *ngIf="topbarFilterState !== TopbarFilterState.Search"
          [style.background-color]="
            topbarFilterState === TopbarFilterState.Search
              ? '#0F0E1B'
              : '#F4F6FB'
          "
        >
          <a class="search-icon"></a>
        </button>

        <button
          (click)="openSearch($event)"
          mat-mini-fab
          *ngIf="topbarFilterState === TopbarFilterState.Search"
          [style.background-color]="
            topbarFilterState === TopbarFilterState.Search
              ? '#0F0E1B'
              : '#F4F6FB'
          "
        >
          <a class="search-icon-active"></a>
        </button>
        <button
          mat-mini-fab
          class="filter-button"
          (click)="openFilters($event)"
          *ngIf="topbarFilterState === TopbarFilterState.Filter"
          [style.background-color]="
            topbarFilterState === TopbarFilterState.Filter
              ? '#0F0E1B'
              : '#F4F6FB'
          "
        >
          <a class="filter-icon-active"></a>
        </button>
        <button
          mat-mini-fab
          class="filter-button"
          (click)="openFilters($event)"
          *ngIf="topbarFilterState !== TopbarFilterState.Filter"
          [style.background-color]="
            topbarFilterState === TopbarFilterState.Filter
              ? '#0F0E1B'
              : '#F4F6FB'
          "
        >
          <a class="filter-icon"></a>
        </button>
      </div>
    </mat-toolbar>
    <form fxLayout="column" #form [formGroup]="filterForm">
      <div
        *ngIf="topbarFilterState === TopbarFilterState.Filter"
        fxLayout="column"
      >
        <common-geo-select
        class="geoselect"
          [small]="true"
          formControlName="mapboxPlace"
        ></common-geo-select>
        <app-select
          class="first-select"
          placeholder="Kategoria"
          [options]="categories$ | async"
          formControlName="category"
          ngDefaultControl
        ></app-select>
        <app-select
          class="first-select"
          placeholder="Rodzaj"
          [options]="types$ | async"
          formControlName="type"
          ngDefaultControl
        ></app-select>
        <div fxLayout="row" class="chips">
          <app-chips
            [small]="true"
            [findFunction]="skillsFinder"
            placeholder="Umiejętności"
            formControlName="skills"
          ></app-chips>
        </div>
      </div>
      <div
        *ngIf="topbarFilterState === TopbarFilterState.Search"
        fxLayout="column"
      >
        <app-input
          leftIcon="../../../../assets/icon-location.svg"
          class="first-select"
          placeholder="Wyszukaj"
          [(ngModel)]="search"
          [ngModelOptions]="{ standalone: true }"
          [small]="true"
          (keyup.enter)="runSearch()"
        ></app-input>
      </div>
    </form>
  </rs-mobile>
</responsive-switcher>
