<responsive-switcher>
  <rs-desktop>
    <section>
      <div
        *ngIf="!!error"
        class="error-wrapper"
      >
        <span class="error-message">{{error}}</span>
      </div>
      <mat-checkbox [ngClass]="{'error': !!error}" value={{checked}}  (click)="changeValue(checked)">

        <span class="label">{{label}}</span>
      </mat-checkbox>
    </section>
  </rs-desktop>
  <rs-mobile>

    <section>
      <div
        *ngIf="!!error"
        class="error-wrapper"
      >
        <span class="error-message">{{error}}</span>
      </div>
      <mat-checkbox [ngClass]="{'error': !!error}" value={{checked}} (click)="changeValue(checked)">

        <span class="label">{{label}}</span>
      </mat-checkbox>
    </section>
  </rs-mobile>
</responsive-switcher>
