import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, forwardRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, SelectMultipleControlValueAccessor } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CommonHttpService } from '@src/app/modules/common-services/common-http.service';
import { OffersService } from '@src/app/modules/sp-services';
import { interval, Observable, BehaviorSubject } from 'rxjs';
import { filter, startWith, switchMap, tap, throttle } from 'rxjs/operators';
import { Option } from '../../modules/common-components';
@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true
    }
  ]

})
export class ChipsComponent implements ControlValueAccessor, OnInit {
  visible = true;
  selectable = true;
  @Input()
  small = true;
  @Input()
  disabled = false;
  @Input()
  placeholder: string;
  @Input()
  selectedOptions: Array<Option> = [];
  onChange: (_: any) => void;
  @Input()
  findFunction: (keyword: string) => Observable<Array<Option>>;
  @Input()
  removable = true;
  @Input()
  error: string = null;
  autocompleteOpened = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('inputAutocomplete', { static: false }) inputAutocomplete: ElementRef<HTMLInputElement>;
  filteredOptions$: Observable<Array<Option>>;
  keywordsInputSubject = new BehaviorSubject<string>('');
  keywordInput$ = this.keywordsInputSubject.asObservable();
  onTouched: () => void;
  options = [];
  value: any;
  constructor(private commonHttpService: CommonHttpService, private offersHttpService: OffersService) {
  }


  ngOnInit(): void {
    if (!this.findFunction) {
      throw new Error('[findFunction] not found. Bind it to Observable<Array<Option>>');
    }
    this.filteredOptions$ = this.keywordInput$.pipe(
      startWith(null),
      filter(x => !!x),
      throttle(() => interval(1000)),
      switchMap((keyword) => this.findFunction(keyword)),
      tap(options => {
        this.options = options;
      })
    )
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }


  optionSelected(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedOptions.find(x => x.value === event.option.value.value)) {
      // already exists
      return;
    }

    this.selectedOptions.push(this.options.find(x => x.value === event.option.value.value));

    this.writeValue(this.selectedOptions.map(x => x.value));
    this.inputAutocomplete.nativeElement.value = '';

  }

  remove(index: number): void {
    this.selectedOptions.splice(index);
    this.writeValue(this.selectedOptions.map(x => x.value));
  }

  onKeywordsInput($event) {
    const newVal = $event.target.value;
    this.keywordsInputSubject.next(newVal);
  }

  writeValue(value: any): void {
    this.value = value;
    this.error = null;
  if (this.onChange) {
      this.onChange(value);
    }
  }

}
