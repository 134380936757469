import { CustomMessageRegistry, CustomMessageType } from './custom-message-registry.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessengerService } from '@src/app/modules/messenger-services/messenger.service';


export class MessengerServicesConfig {
  customMessageTypes: CustomMessageType[];
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [CustomMessageRegistry]
})
export class MessengerServicesModule {
  static forRoot(config: MessengerServicesConfig): ModuleWithProviders<MessengerServicesModule> {
    return {
      ngModule: MessengerServicesModule,
      providers: [
        {
          provide: MessengerServicesConfig,
          useValue: config
        }
      ]
    };

  }
}
