import { EnvironmentService } from './../common-services/environment.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { City } from '../common-services/common-http.service';
import { OfferCategory, Skills } from './offers.service';
import { AuthService, UserData } from '@replica-frontend/auth';
import {ServerSideValidationService, ValidationException} from '@replica-frontend/sdk';

export interface Files {
  id: string;
  type: string;
  encoding: string;
  mimetype: string;
  userid: string;
  path: string;
}

export interface Profile {
  id: string;
  email: string;
  phoneNumber: string;
  welcomeTitle: string;
  welcomeDescription: string;
  description: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  twitter: string;
  profileMedia: Files;
  city: City;
  skills: Skills[];
  education: string;
  website: string;
  offersCategory: OfferCategory;
  contratorTitle: string;
  contractorDescription: string;
  endedOffers: number;
  createdOffers: number;
  earned: number;
  averageSalary: number;
  sold: number;
  averagePaycheck: number;
  contractorProfileMedia: Files;
}

@Injectable()
export class ProfileService {
  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
    private authService: AuthService,
    private validationService: ServerSideValidationService
  ) { }

  public getProfileByUserId(id: string): Observable<Profile> {
    return this.httpClient
      .get<Profile>(`${this.environmentService
        .getEnv('apiUrl')}/profile/byUser/${id}`);
  }

  public editProfile(profile: Profile): Observable<UserData> {
    return this.httpClient.put<Profile>(`${this.environmentService.getEnv('apiUrl')}/profile`, profile).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 422) {
          this.validationService.handleErrorResponse('profile', error);
        }
        console.log(error);
        return throwError(new ValidationException('form validation error'));
      }),
      switchMap(() => {
        return this.authService.getUserData();
      })
    );
  }

}
