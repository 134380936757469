import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@replica-frontend/auth';
import { BUTTON_COLORS } from '../../common-components/button/button.component';
import { MenuObject, MenuService } from '../../sp-services';
import { IconModes } from '../../sp-services/menu.service';

@Component({
  selector: 'app-menu-logged',
  templateUrl: './menu-logged.component.html',
  styleUrls: ['./menu-logged.component.scss']
})
export class MenuLoggedComponent {
  options = [];
  public options$ = this.menuService.menu$;
  public iconMode$ = this.menuService.iconMode$;
  IconModes = IconModes;
  menus: MenuObject[];
  public title: string;
  ButtonColors = BUTTON_COLORS;
  @Output()
  itemClicked = new EventEmitter<void>()
  constructor(
    private router: Router,
    public authService: AuthService,
    private menuService: MenuService
  ) {
  }

  register(): void {
    this.itemClicked.next();
    this.router.navigate(['/','create-account']);
  }

  login(): void {
    this.itemClicked.next();
    this.router.navigate(['/','sign-in']);
  }

  logout(): void {
    this.itemClicked.next();
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

}
