import { ExceptionTelemeteryService } from './../common-services/exception-telemetery/exception-telemetery.service';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subscription, Subject, BehaviorSubject, of } from 'rxjs';
import { filter, last, map, tap, withLatestFrom } from 'rxjs/operators';
import { SocketService } from '../common-services/socket.service';
import { AuthService, AuthEvents } from '@replica-frontend/auth';

export interface Buddy {
  id?: string;
  initiatorblocked?: boolean;
  friendblocked?: boolean;
  initiator: {
    id: string
    nick: string
  };
  friend?: {
    id: string
    nick: string
  };
  wasRead?: boolean;
  message?: string;
  lastMessageData?: Date;
  createDate?: Date;
}
export interface MessageDTO {
  receiver: string;
  content: string;
  metatags?: string;
}
export interface MessageFromDb {
  id: string;
  messageNumber: number;
  content: string;
  wasRead: false;
  createAt: Date;
  isInitiator: boolean;
  friendship: string;
  metatags?: string; // stringified
}
export interface MessageMeta {
  name: string;
  data: any;
}
export interface FriendshipMap {
  getMessageListener: Observable<MessageFromDb[]>;
  friendshipId: string;
}

@Injectable({
  providedIn: 'root'
})
export class MessengerService {
  NAMESPACE = 'messenger';
  buddies: Buddy[] = [];
  buddiesLoading = true;
  buddiesSubject: ReplaySubject<Buddy[]> = new ReplaySubject<Buddy[]>(1);
  buddies$: Observable<Buddy[]> = this.buddiesSubject.asObservable();
  buddiesWS;
  messageSubject = new Subject<MessageFromDb>();
  messages$: Observable<MessageFromDb> = this.messageSubject.asObservable();
  online = false;
  authSub: Subscription;
  messageSocketSub: Subscription;

  constructor(
    private socket: SocketService,
    private exceptionTelemeteryService: ExceptionTelemeteryService,
    private authService: AuthService) {
    this.authSub = this.authService.authEvents$.subscribe((event) => {
      if (event === AuthEvents.onLogout) {
        this.destroy();
      }
      // service often don't want to catch onLogged event, that's why initialization is done manually
      // in future it should be done always like that
    });
    if (this.authService.hasMe) {
      this.init();
    }
  }

  init(): void {
    console.log('Messenger init');
    if (!this.online) {
      this.online = true;

      this.buddies = [];
      this.buddiesLoading = true;
      this.buddiesWS = this.socket.listen<Buddy[]>(this.NAMESPACE, 'buddies').pipe(map(x => x.filter(x => !!x)))
        .subscribe((buddies: Buddy[]) => {
          this.addOrUpdateBuddies(buddies);
        }, err => console.error(err));
      this.messageSocketSub = this.socket.listen<MessageFromDb>(this.NAMESPACE, 'message').subscribe((message) => {
        this.messageSubject.next(message);
      });

    }
  }

  private addOrUpdateBuddies(buddies: Buddy[]): void {
    this.buddiesLoading = false;
    if (this.buddies.length === 0) {
      this.buddies = buddies;
    } else {
      buddies.forEach((value, index) => {
        const idxToUpdate = this.buddies.findIndex(x => x.id === value.id);
        if (idxToUpdate > -1) {
          this.buddies[idxToUpdate] = value;
        } else {
          this.buddies.push(value);
        }
      });
    }
    this.buddiesSubject.next(this.buddies);
  }

  sendMessage(message: MessageDTO, buddyId?: string): void {
    if (buddyId) {
      const buddyIndex = this.buddies.findIndex((buddy) => buddy.id === buddyId);
      if (!this.buddies[buddyIndex]) {
        console.error('Cannot find buddy to write message data');
        this.exceptionTelemeteryService.reportException({
          message: 'Cannot find buddy to write message data',
          exceptionTrace: (<any>new Error().stack),
          additionalTechData: JSON.stringify({
            location: document.URL,
            userAgent: navigator.userAgent
          }),
          loggedUserTechData: ''
        })

      } else {
        this.buddies[buddyIndex].message = message.content;
        this.buddies[buddyIndex].lastMessageData = new Date();

      }

    }
    message.metatags = JSON.stringify({ name: 'message' });
    this.socket.emit(this.NAMESPACE, 'priv', message);
  }

  sendFirstMessage(message: MessageDTO): Observable<{ buddy: Buddy, message: MessageFromDb }> {
    const subject = new Subject<{ buddy: Buddy, message: MessageFromDb }>();
    combineLatest([this.messages$, this.buddies$.pipe(filter(x => x.length !== 0))]).subscribe((values) => {
      subject.next({
        message: values[0],
        buddy: values[1][0]
      });
    }, err => console.error(err));
    message.metatags = JSON.stringify({ name: 'message' });
    this.socket.emit(this.NAMESPACE, 'priv', message);
    return subject.asObservable();
  }

  streamMessages(friendshipId: string): Observable<MessageFromDb[]> {

    const messageSubject = new Subject<MessageFromDb[]>();
    const message$ = messageSubject.asObservable();
    this.socket.listen<MessageFromDb[]>(this.NAMESPACE, 'getMessages')
      .pipe(
        map(x => x.sort((a, b) => {
          return a.messageNumber - b.messageNumber;
        })),
        withLatestFrom(this.buddies$))
      .subscribe((values) => {
        messageSubject.next(values[0]);
      });
    this.socket.emit(this.NAMESPACE, 'getMessages', friendshipId);
    this.messages$.pipe(
      filter((messages: MessageFromDb) => {
        return messages.friendship === friendshipId;
      }),
      withLatestFrom(message$),
    ).subscribe((values) => {
      values[1].push(values[0]);
      messageSubject.next(values[1]);
    });
    return message$;
  }

  getBuddyByUserId(userId): Promise<Buddy> {
    return new Promise((resolve, reject) => {
      this.buddies$.subscribe((buddies) => {
        if (!this.buddiesLoading) {
          const buddy = this.buddies.find(x => {
            const result = x.friend.id === userId;
            if (!result) {
              return x.initiator.id === userId;
            }
            return result;
          });
          resolve(buddy);
        }
      });
    });
  }

  getBuddyByBuddyId(buddyId): Observable<Buddy> {
    return this.buddies$.pipe(
      map(x => x.find(x => x.id === buddyId) || this.buddies.find(x => x.id === buddyId))
    );
  }

  destroy(): void {
    if (this.buddiesWS) {
          this.buddiesWS.unsubscribe();
    }

    this.buddiesSubject.next([]);
    this.online = false;
    if (this.messageSocketSub) {
          this.messageSocketSub.unsubscribe();

    }
  }
}
