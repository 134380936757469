<responsive-switcher>
  <rs-desktop>
    <loading-view [loading]="loading">
      <div fxLayout="row" class="main-container">
        <form fxLayout="row" [formGroup]="profileForm" (ngSubmit)="submit()">
          <div fxLayout="column" class="first-container">
            <div fxLayout="row">
              <div fxFlex="50" fxLayoutAlign="start center">
                <span class="title-label">Mój profil</span>
              </div>
            </div>
            <mat-tab-group mat-align-tabs="start" disablePagination="true">
              <mat-tab
                isActive="true"
                label="Profil wykonawcy"
                class="contractor-span"
              >
                <div fxLayout="column" class="main-wrapper">
                  <div fxLayout="column" class="grey-container">
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      class="welcome-wrapper"
                    >
                      <span class="welcome-span">Przywitanie</span>
                      <!-- <app-slide-toggle
                        (change)="welcomeEnabled = !welcomeEnabled"
                      ></app-slide-toggle> -->
                    </div>
                    <div fxLayout="row" *ngIf="welcomeEnabled">
                      <div fxFlex="50" class="desktop-add-pic-wrapper">
                        <app-button-add-picture
                          height="292px"
                          class="desktop-add-pic"
                          [imagePath]="getImageURL(profile?.profileMedia?.path)"
                          (loaded)="welcomeImageLoaded($event)"
                          *ngIf="profile"
                        ></app-button-add-picture>
                      </div>
                      <div fxFlex="50" fxLayoutAlign="center center">
                        <div fxLayout="column" class="description-desktop">
                          <app-input
                            placeholder="Dodaj tytuł"
                            formControlName="welcomeTitle"
                            ngDefaultControl
                            name="welcomeTitle"
                          ></app-input>
                          <app-textarea
                            minHeight="217px"
                            maxHeight="217px"
                            placeholder="Dodaj opis"
                            class="textarea-desktop"
                            formControlName="welcomeDescription"
                            ngDefaultControl
                          ></app-textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" class="grey-container">
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      class="welcome-wrapper"
                    >
                      <span class="welcome-span">Statystyki</span>
                      <!-- <app-slide-toggle
                        (change)="statsEnabled = !statsEnabled"
                      ></app-slide-toggle> -->
                    </div>
                    <div fxLayout="column" *ngIf="statsEnabled">
                      <div fxLayout="row">
                        <div fxLayout="column" class="mini-card-desktop">
                          <span class="mini-card-number">{{
                            profile?.endedOffers
                          }}</span>
                          <span class="mini-card-description"
                            >Wykonane zlecenia</span
                          >
                        </div>
                        <div fxLayout="column" class="mini-card-desktop">
                          <span class="mini-card-number"
                            >{{ profile?.earned }} PLN</span
                          >
                          <span class="mini-card-description"
                            >Zarobionych ze zleceń</span
                          >
                        </div>
                        <div fxLayout="column" class="mini-card-desktop">
                          <span class="mini-card-number"
                            >{{ profile?.averageSalary }} PLN</span
                          >
                          <span class="mini-card-description"
                            >Średnia kwota zlecenia</span
                          >
                        </div>
                        <!-- <div fxLayout="column" class="mini-card-desktop">
                          <span class="mini-card-number"> 50 PLN / h </span>
                          <span class="mini-card-description"
                            >Stawka godzinowa</span
                          >
                        </div> -->
                      </div>
                      <!-- <div fxLayout="row" class="statistics-second-row">

                        <div
                          fxLayout="column"
                          class="mini-card-desktop-button"
                          fxLayoutAlign="center start"
                        ></div>
                      </div> -->
                    </div>
                  </div>

                  <!-- <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    class="welcome-wrapper-2"
                  >
                    <div
                      fxLayout="row"
                      fxFlex="60"
                      fxLayoutAlign="start center"
                    >
                      <span class="welcome-span">Zakończone zlecenia</span>
                      <app-slide-toggle
                        (change)="finishedEnabled = !finishedEnabled"
                      ></app-slide-toggle>
                    </div>

                    <div
                      fxFlex="40"
                      fxLayout="row"
                      fxLayoutAlign="end center"
                      class="paginator"
                    >
                      <mat-paginator [length]="length" [pageSize]="pageSize">
                      </mat-paginator>
                    </div>
                  </div> -->
                  <!-- <div
                    fxLayout="row"
                    class="offers-list-container"
                    *ngIf="finishedEnabled"
                  >
                    <app-offer-list-horizontal></app-offer-list-horizontal>
                  </div> -->
                  <!-- <div fxLayout="row" class="paginator-wrapper">
                    <div fxFlex="60" fxLayoutAlign="start center">
                      <span class="finished-offers welcome-span">
                        Rekomendacje
                      </span>
                      <app-slide-toggle
                        (change)="
                          recommendationsEnabled = !recommendationsEnabled
                        "
                      ></app-slide-toggle>
                    </div>
                    <div
                      fxFlex="40"
                      fxLayout="row"
                      fxLayoutAlign="end center"
                      class="filter"
                    >
                      <span class="select-label">Sortuj:</span>
                      <select>
                        <option
                          *ngFor="let filter of filtersList | async"
                          [value]="filter.value"
                        >
                          {{ filter.label }}
                        </option>
                      </select>
                    </div>
                  </div> -->
                  <!-- <div fxLayout="column" *ngIf="recommendationsEnabled">
                    <div fxLayout="column">
                      <div fxLayout="row">
                        <span class="event-title">
                          Pomoc przy przygotowaniu eventu Samsunga
                        </span>
                      </div>
                      <div fxLayout="row" class="event-name-wrapper">
                        <div fxLayoutAlign="start center">
                          <app-avatar
                            class="mini-avatar"
                            (loaded)="onAvatarLoaded($event)"
                          ></app-avatar>
                          <span class="event-name">Max Bruce</span>
                        </div>
                        <div
                          fxLayoutAlign="start center"
                          fxLayout="row"
                          class="middle-event-title"
                        >
                          <a class="location-icon"></a>
                          <span class="event-name">Kraków</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <a class="clock-icon"></a>
                          <span class="date-span">24 kwiecień 2020</span>
                        </div>
                      </div>
                      <div fxLayout="row" class="recommendation-content">
                        Jimmy jest bardzo kompetentnym i szybko działającym
                        pracownikiem. Podczas naszej owocnej współpracy nie
                        pojawiły się żadne problemy czy to komunikacyjne czy
                        organizacyjne. Widać profesjonalne podejście do
                        wykonawanej pracy oraz sumienność w realizacji zadań.
                        Mogę jedynie polecić go, jako bardzo rzetelnego
                        pracownika.
                        <br /><br />
                        Mam nadzieję, że to nie ostatnia takie zlecenie!
                      </div>
                    </div>
                    <div fxLayout="column">
                      <div fxLayout="row">
                        <span class="event-title">
                          Pomoc przy przygotowaniu eventu Samsunga
                        </span>
                      </div>
                      <div fxLayout="row" class="event-name-wrapper">
                        <div fxLayoutAlign="start center">
                          <app-avatar
                            (loaded)="onAvatarLoaded($event)"
                            class="mini-avatar"
                          ></app-avatar>
                          <span class="event-name">Max Bruce</span>
                        </div>
                        <div
                          fxLayoutAlign="start center"
                          fxLayout="row"
                          class="middle-event-title"
                        >
                          <a class="location-icon"></a>
                          <span class="event-name">Kraków</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <a class="clock-icon"></a>
                          <span class="date-span">24 kwiecień 2020</span>
                        </div>
                      </div>
                      <div
                        fxLayout="row"
                        class="recommendation-content recommendation-content-2"
                      >
                        Jimmy jest bardzo kompetentnym i szybko działającym
                        pracownikiem. Podczas naszej owocnej współpracy nie
                        pojawiły się żadne problemy czy to komunikacyjne czy
                        organizacyjne. Widać profesjonalne podejście do
                        wykonawanej pracy oraz sumienność w realizacji zadań.
                        Mogę jedynie polecić go, jako bardzo rzetelnego
                        pracownika.
                        <br /><br />
                        Mam nadzieję, że to nie ostatnia takie zlecenie!
                      </div>
                    </div>
                  </div> -->
                </div>
              </mat-tab>
              <mat-tab label="Profil zleceniodawcy" class="contractor-span">
                <div fxLayout="column" class="main-wrapper">
                  <div fxLayout="column" class="grey-container">
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      class="welcome-wrapper"
                    >
                      <span class="welcome-span">Przywitanie</span>
                      <!-- <app-slide-toggle
                        (change)="welcomeEnabled = !welcomeEnabled"
                      ></app-slide-toggle> -->
                    </div>
                    <div fxLayout="row" *ngIf="welcomeEnabled">
                      <div fxFlex="50" class="desktop-add-pic-wrapper">
                        <app-button-add-picture
                          height="292px"
                          class="desktop-add-pic"
                          [imagePath]="getImageURL(profile?.contractorProfileMedia?.path)"
                          (loaded)="welcomeContractorImageLoaded($event)"
                        ></app-button-add-picture>
                      </div>
                      <div fxFlex="50" fxLayoutAlign="center center">
                        <div fxLayout="column" class="description-desktop">
                          <app-input
                            placeholder="Dodaj tytuł"
                            formControlName="contratorTitle"
                            ngDefaultControl
                            name="contratorTitle"
                          ></app-input>
                          <app-textarea
                            minHeight="217px"
                            maxHeight="217px"
                            placeholder="Dodaj opis"
                            class="textarea-desktop"
                            formControlName="contractorDescription"
                            ngDefaultControl
                          ></app-textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" class="grey-container">
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      class="welcome-wrapper"
                    >
                      <span class="welcome-span">Statystyki</span>
                    </div>
                    <div fxLayout="column" *ngIf="statsEnabled">
                      <div fxLayout="row">
                        <div fxLayout="column" class="mini-card-desktop">
                          <span class="mini-card-number">0</span>
                          <span class="mini-card-description"
                            >Wystawione zlecenia</span
                          >
                        </div>
                        <div fxLayout="column" class="mini-card-desktop">
                          <span class="mini-card-number">0 PLN</span>
                          <span class="mini-card-description"
                            >Łącznie zapłacono</span
                          >
                        </div>
                        <div fxLayout="column" class="mini-card-desktop">
                          <span class="mini-card-number">0 PLN</span>
                          <span class="mini-card-description"
                            >Średnia kwota zlecenia</span
                          >
                        </div>
                        <!-- <div fxLayout="column" class="mini-card-desktop">
                          <span class="mini-card-number"> 50 PLN / h </span>
                          <span class="mini-card-description"
                            >Stawka godzinowa</span
                          >
                        </div> -->
                      </div>
                      <!-- <div fxLayout="row" class="statistics-second-row">

                        <div
                          fxLayout="column"
                          class="mini-card-desktop-button"
                          fxLayoutAlign="center start"
                        ></div>
                      </div> -->
                    </div>
                  </div>
                  <!-- <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    class="welcome-wrapper-2"
                  >
                    <div
                      fxLayout="row"
                      fxFlex="60"
                      fxLayoutAlign="start center"
                    >
                      <span class="welcome-span">Zrealizowane zlecenia</span>
                    </div>

                    <div
                      fxFlex="40"
                      fxLayout="row"
                      fxLayoutAlign="end center"
                      class="paginator"
                    >
                      <mat-paginator [length]="length" [pageSize]="pageSize">
                      </mat-paginator>
                    </div>
                  </div> -->
                  <!-- <div
                    fxLayout="row"
                    class="offers-list-container"
                    *ngIf="finishedEnabled"
                  >
                    <div
                      fxLayout="row"
                      fxLayoutGap="15px"
                      fxLayoutAlign="start center"
                    >

                    </div>
                  </div> -->
                  <!-- <div fxLayout="row" class="paginator-wrapper">
                    <div fxFlex="60" fxLayoutAlign="start center">
                      <span class="finished-offers welcome-span">
                        Rekomendacje
                      </span>
                      <app-slide-toggle
                        (change)="
                          recommendationsEnabled = !recommendationsEnabled
                        "
                      ></app-slide-toggle>
                    </div>
                    <div
                      fxFlex="40"
                      fxLayout="row"
                      fxLayoutAlign="end center"
                      class="filter"
                    >
                      <span class="select-label">Sortuj:</span>
                      <select>
                        <option
                          *ngFor="let filter of filtersList | async"
                          [value]="filter.value"
                        >
                          {{ filter.label }}
                        </option>
                      </select>
                    </div>
                  </div> -->
                  <!-- <div fxLayout="column" *ngIf="recommendationsEnabled">
                    <div fxLayout="column">
                      <div fxLayout="row">
                        <span class="event-title">
                          Pomoc przy przygotowaniu eventu Samsunga
                        </span>
                      </div>
                      <div fxLayout="row" class="event-name-wrapper">
                        <div fxLayoutAlign="start center">
                          <app-avatar
                            class="mini-avatar"
                            (loaded)="onAvatarLoaded($event)"
                          ></app-avatar>
                          <span class="event-name">Max Bruce</span>
                        </div>
                        <div
                          fxLayoutAlign="start center"
                          fxLayout="row"
                          class="middle-event-title"
                        >
                          <a class="location-icon"></a>
                          <span class="event-name">Kraków</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <a class="clock-icon"></a>
                          <span class="date-span">24 kwiecień 2020</span>
                        </div>
                      </div>
                      <div fxLayout="row" class="recommendation-content">
                        Jimmy jest bardzo kompetentnym i szybko działającym
                        pracownikiem. Podczas naszej owocnej współpracy nie
                        pojawiły się żadne problemy czy to komunikacyjne czy
                        organizacyjne. Widać profesjonalne podejście do
                        wykonawanej pracy oraz sumienność w realizacji zadań.
                        Mogę jedynie polecić go, jako bardzo rzetelnego
                        pracownika.
                        <br /><br />
                        Mam nadzieję, że to nie ostatnia takie zlecenie!
                      </div>
                    </div>
                    <div fxLayout="column">
                      <div fxLayout="row">
                        <span class="event-title">
                          Pomoc przy przygotowaniu eventu Samsunga
                        </span>
                      </div>
                      <div fxLayout="row" class="event-name-wrapper">
                        <div fxLayoutAlign="start center">
                          <app-avatar
                            (loaded)="onAvatarLoaded($event)"
                            class="mini-avatar"
                          ></app-avatar>
                          <span class="event-name">Max Bruce</span>
                        </div>
                        <div
                          fxLayoutAlign="start center"
                          fxLayout="row"
                          class="middle-event-title"
                        >
                          <a class="location-icon"></a>
                          <span class="event-name">Kraków</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <a class="clock-icon"></a>
                          <span class="date-span">24 kwiecień 2020</span>
                        </div>
                      </div>
                      <div
                        fxLayout="row"
                        class="recommendation-content recommendation-content-2"
                      >
                        Jimmy jest bardzo kompetentnym i szybko działającym
                        pracownikiem. Podczas naszej owocnej współpracy nie
                        pojawiły się żadne problemy czy to komunikacyjne czy
                        organizacyjne. Widać profesjonalne podejście do
                        wykonawanej pracy oraz sumienność w realizacji zadań.
                        Mogę jedynie polecić go, jako bardzo rzetelnego
                        pracownika.
                        <br /><br />
                        Mam nadzieję, że to nie ostatnia takie zlecenie!
                      </div>
                    </div>
                  </div> -->
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div fxLayout="column" class="second-container">
            <div fxLayout="column">
              <div class="container" fxLayout="column">
                <form
                  fxLayout="column"
                  (keydown.enter)="onEnter($event)"
                  [formGroup]="profileForm"
                  (ngSubmit)="submit()"
                >
                  <div
                    fxLayout="column"
                    *ngIf="wasChanged"
                    fxLayoutAlign="center center"
                    class="top-buttons"
                  >
                    <app-button
                      [color]="ButtonColors.PRIMARY"
                      buttonType="submit"
                      class="yellow-button"
                      tooltip="Zapisz zmiany"
                    >
                      <span class="yellow-button-span">Zapisz zmiany</span>
                    </app-button>
                  </div>

                  <div fxLayout="column">
                    <div fxLayout="row" class="avatar-panel">
                      <div class="avatar">
                        <app-avatar
                          (loaded)="onAvatarLoaded($event)"
                          [editable]="true"
                          [userId]="user?.id"
                        ></app-avatar>
                      </div>

                      <div fxLayout="column" class="name-container">
                        <span class="name">
                          {{ profileForm.get("firstname").value }}
                          {{ profileForm.get("lastname").value }}
                        </span>
                        <!-- <div fxLayout="row" class="location">
                          <a class="location-icon"></a>
                          <span class="location-span">+ Dodaj lokalizację</span>
                        </div> -->
                      </div>
                    </div>
                    <div class="buttons-container-wrapper">
                      <div fxLayout="column" class="buttons-container">
                        <app-button
                          tooltip="Edytuj adres email"
                          *ngIf="!activeField.includes('email')"
                          [color]="ButtonColors.WHITE"
                          class="grey-button"
                          (click)="$event.preventDefault()"
                        >
                          <!-- TODO: rozciagnac wg maila, albo skrocic mail ... -->
                          <mat-icon>alternate_email</mat-icon>
                          <span class="white-button-span">
                            {{
                              profileForm.get("email").value ||
                                "+ Dodaj adres mailowy"
                            }}
                          </span>
                        </app-button>
                        <app-input-mobile
                          [centerLabel]="true"
                          placeholder="Podaj email kontaktowy"
                          formControlName="email"
                          [error]="profileForm.get('email').errors?.message"
                          ngDefaultControl
                          class="email-input"
                          *ngIf="activeField.includes('email')"
                        ></app-input-mobile>
                        <div class="phone-number-for-desktop">
                        <app-button
                          tooltip="Edytuj numer telefonu"
                          (click)="setActiveField('phoneNumber')"
                          *ngIf="!activeField.includes('phoneNumber')"
                          [color]="ButtonColors.WHITE"
                          class="grey-button grey-button-2"
                        >
                          <a class="icon-phone" fxLayoutAlign="center center"></a>
                          <span class="white-button-span">
                            {{
                              profileForm.get("phoneNumber").value ||
                                "+ Dodaj numer telefonu"
                            }}
                          </span>
                        </app-button>
                       
                          <app-input-mobile
                          [centerLabel]="true"
                          placeholder="Podaj numer kontaktowy"
                          formControlName="phoneNumber"
                          [error]="profileForm.get('phoneNumber').errors?.message"
                          ngDefaultControl
                          class="phone-input"
                          *ngIf="activeField.includes('phoneNumber')"
                        ></app-input-mobile>
                        </div>
                      
                      </div>
                    </div>
              

                    <div fxLayout="column" class="expansion-panel-wrapper">
                      <span class="profile-details-main-label"
                        >Szczegóły profilu</span
                      >

                      <div class="profile-details-wrapper" fxLayout="column">
                        <span class="profile-details-label"
                          >Kategorie zleceń</span
                        >
                        <span
                          matTooltip="Dodaj kategorię"
                          matTooltipClass="tooltip"
                          class="profile-details-description"
                          (click)="activeField = ['category']"
                          *ngIf="
                            !activeField.includes('category') &&
                            !profileForm.get('offersCategory').value
                          "
                        >
                          + Dodaj preferowaną kategorię
                        </span>
                        <span
                          matTooltip="Edytuj kategorię"
                          matTooltipClass="tooltip"
                          class="profile-details-description"
                          (click)="activeField = ['category']"
                          *ngIf="
                            !activeField.includes('category') &&
                            profileForm.get('offersCategory').value
                          "
                        >
                          +
                          {{
                            findCategoryById(
                              profileForm.get("offersCategory").value
                            )
                          }}
                        </span>
                        <!-- <app-select-mobile
                                    class="category-input"
                                    placeholder="Brak kategorii"
                                    [options]="categories$ | async"
                                    formControlName="offersCategory"
                                    ngDefaultControl
                                    *ngIf="user.profile.offersCategory?.category"
                                ></app-select-mobile> -->
                        <app-select-mobile
                          matTooltip="Wybierz kategorię"
                          matTooltipClass="tooltip"
                          class="category-input"
                          placeholder="Kategoria"
                          [options]="categories$ | async"
                          formControlName="offersCategory"
                          ngDefaultControl
                          *ngIf="activeField.includes('category')"
                        ></app-select-mobile>
                        <span class="profile-details-label">Edukacja</span>
                        <span
                          matTooltip="Dodaj edukację"
                          matTooltipClass="tooltip"
                          class="profile-details-description"
                          *ngIf="
                            !activeField.includes('educationSchoolName') &&
                            !(
                              profileForm.get('educationSchoolName').value ||
                              profileForm.get('educationYears').value ||
                              profileForm.get('educationSpecialization').value
                            )
                          "
                          (click)="activeField = ['educationSchoolName']"
                        >
                          + Dodaj edukację
                        </span>
                        <span
                          matTooltip="Edytuj edukację"
                          matTooltipClass="tooltip"
                          class="profile-details-description"
                          *ngIf="
                            !activeField.includes('educationSchoolName') &&
                            profileForm.get('educationSchoolName').value
                          "
                          (click)="activeField = ['educationSchoolName']"
                        >
                          <p>
                            {{ profileForm.get("educationYears").value }}
                          </p>
                          <p>
                            {{ profileForm.get("educationSchoolName").value }}
                          </p>

                          <p>
                            {{
                              profileForm.get("educationSpecialization").value
                            }}
                          </p>
                        </span>
                        <div
                          fxLayout="column"
                          *ngIf="activeField.includes('educationSchoolName')"
                        >
                          <app-input-mobile
                            placeholder="Podaj lata (YYYY-YYYY)"
                            ngDefaultControl
                            formControlName="educationYears"
                            class="category-input"
                          ></app-input-mobile>
                          <app-input-mobile
                            placeholder="Podaj nazwę szkoły"
                            ngDefaultControl
                            class="category-input"
                            formControlName="educationSchoolName"
                          ></app-input-mobile>

                          <app-input-mobile
                            placeholder="Podaj kierunek"
                            ngDefaultControl
                            formControlName="educationSpecialization"
                            class="category-input"
                          ></app-input-mobile>
                        </div>
                        <span class="profile-details-label">Social media</span>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-facebook"></a>
                          <span
                            matTooltip="Edytuj profil Facebooka"
                            matTooltipClass="tooltip"
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('facebook')"
                            (click)="activeField = ['facebook']"
                          >
                            {{
                              profileForm.get("facebook").value ||
                                "+ Dodaj swojego Facebooka"
                            }}
                          </span>
                          <app-input-mobile
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="facebook"
                            [error]="
                              profileForm.get('facebook').errors?.message
                            "
                            ngDefaultControl
                            *ngIf="activeField.includes('facebook')"
                            class="surname-input"
                          ></app-input-mobile>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-instagram"></a>
                          <span
                            matTooltip="Edytuj profil Instagram"
                            matTooltipClass="tooltip"
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('instagram')"
                            (click)="activeField = ['instagram']"
                          >
                            {{
                              profileForm.get("instagram").value ||
                                "+ Dodaj swojego Instagrama"
                            }}
                          </span>
                          <app-input-mobile
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="instagram"
                            [error]="
                              profileForm.get('instagram').errors?.message
                            "
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('instagram')"
                          ></app-input-mobile>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-twitter"></a>
                          <span
                            matTooltip="Edytuj profil Twitter"
                            matTooltipClass="tooltip"
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('twitter')"
                            (click)="activeField = ['twitter']"
                          >
                            {{
                              profileForm.get("twitter").value ||
                                "+ Dodaj swojego Twittera"
                            }}
                          </span>
                          <app-input-mobile
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="twitter"
                            [error]="profileForm.get('twitter').errors?.message"
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('twitter')"
                          ></app-input-mobile>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-linkedin"></a>
                          <span
                            matTooltip="Edytuj profil Linkedin"
                            matTooltipClass="tooltip"
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('linkedin')"
                            (click)="activeField = ['linkedin']"
                          >
                            {{
                              profileForm.get("linkedin").value ||
                                "+ Dodaj swojego LinkedIna"
                            }}
                          </span>
                          <app-input-mobile
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="linkedin"
                            [error]="
                              profileForm.get('linkedin').errors?.message
                            "
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('linkedin')"
                          ></app-input-mobile>
                        </div>

                        <span class="profile-details-label"
                          >Strony internetowe</span
                        >
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <span class="material-icons website-icon"
                            >public</span
                          >
                          <span
                            matTooltip="Edytuj swoją stronę internetową"
                            matTooltipClass="tooltip"
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('website')"
                            (click)="activeField = ['website']"
                          >
                            {{
                              profileForm.get("website").value ||
                                "+ Dodaj swoją stronę internetową"
                            }}
                          </span>
                          <app-input-mobile
                            placeholder="Podaj pełny link do swojej strony"
                            formControlName="website"
                            [error]="profileForm.get('website').errors?.message"
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('website')"
                          ></app-input-mobile>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </form>
      </div>
    </loading-view>
  </rs-desktop>
  <rs-mobile>
    <div fxLayout="column">
      <!-- <app-topbar-logged-mobile></app-topbar-logged-mobile> -->
      <div class="container" fxLayout="column">
        <form
          class="form-container"
          fxLayout="column"
          [formGroup]="profileForm"
          (ngSubmit)="submit()"
        >
          <div
            fxLayout="column"
            *ngIf="wasChanged"
            fxLayoutAlign="center center"
            class="top-buttons"
          >
            <app-button
              [color]="ButtonColors.PRIMARY"
              buttonType="submit"
              class="yellow-button"
            >
              <span class="yellow-button-span">Zapisz zmiany</span>
            </app-button>
          </div>
          <div fxLayout="row" class="profiles">
            <mat-tab-group mat-align-tabs="start" disablePagination="true">
              <mat-tab class="contractor-span" label="Profil wykonawcy">
                <div fxLayout="row" class="avatar-panel">
                  <div fxFlex="30" fxLayoutAlign="start center">
                    <div class="avatar">
                      <app-avatar
                        (loaded)="onAvatarLoaded($event)"
                        [userId]="user?.id"
                        [editable]="true"
                      ></app-avatar>
                    </div>
                  </div>

                  <div fxLayout="column" class="name-container">
                    <span class="name">
                      {{ profileForm.get("firstname").value }}
                      {{ profileForm.get("lastname").value }}
                    </span>
                    <!-- <div fxLayout="row" *ngIf="!profile?.city">
                        <span class="location-span">+ Dodaj lokalizację</span>
                      </div> -->
                    <div fxLayout="row" *ngIf="profile?.city">
                      <a class="location-icon"></a>
                      <span class="location-span">
                        {{ profileForm.get("city").value }}
                      </span>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" class="buttons-container">
                  <app-button
                    [color]="ButtonColors.WHITE"
                    class="grey-button"
                    (click)="$event.preventDefault()"
                    [error]="profileForm.get('email').errors?.message"
                  >
                    <!-- TODO: rozciagnac wg maila, albo skrocic mail ... -->
                    <mat-icon>alternate_email</mat-icon>
                    <span class="white-button-span">
                      {{
                        profileForm.get("email").value ||
                          "+ Dodaj adres mailowy"
                      }}
                    </span>
                  </app-button>
                  <app-input
                    [centerLabel]="true"
                    placeholder="Podaj email kontaktowy"
                    formControlName="email"
                    [error]="profileForm.get('email').errors?.message"
                    ngDefaultControl
                    class="email-input-mobile"
                    *ngIf="activeField.includes('email')"
                  ></app-input>
                  <app-button
                    (click)="setActiveField('phoneNumber')"
                    *ngIf="!activeField.includes('phoneNumber')"
                    [color]="ButtonColors.WHITE"
                    class="grey-button grey-button-2"
                    [error]="profileForm.get('phoneNumber').errors?.message"
                  >
                    <a class="icon-phone" fxLayoutAlign="center center"></a>
                    <span class="white-button-span">
                      {{
                        profileForm.get("phoneNumber").value ||
                          "+ Dodaj numer telefonu"
                      }}
                    </span>
                  </app-button>
                  <app-input
                    [centerLabel]="true"
                    placeholder="Podaj numer kontaktowy"
                    formControlName="phoneNumber"
                    [error]="profileForm.get('phoneNumber').errors?.message"
                    ngDefaultControl
                    class="phone-input-mobile"
                    *ngIf="activeField.includes('phoneNumber')"
                  ></app-input>
                </div>

                <div fxLayout="row" class="welcome">
                  <div fxFlex="70" fxLayoutAlign="start center">
                    <span class="welcome-span">Przywitanie</span>
                  </div>
                  <div fxLayoutAlign="end center" fxFlex="30">
                    <app-slide-toggle
                      (change)="welcomeEnabled = !welcomeEnabled"
                    ></app-slide-toggle>
                  </div>
                </div>
                <div
                  class="add-picture-wrapper"
                  *ngIf="welcomeEnabled"
                  fxLayout="column"
                >
                  <app-button-add-picture
                    *ngIf="profile"
                    [imagePath]="getImageURL(profile?.profileMedia?.path)"
                    (loaded)="welcomeImageLoaded($event)"
                  ></app-button-add-picture>
                  <div fxLayoutAlign="center center" class="textarea-wrapper">
                    <app-textarea
                      name="welcomeTitle"
                      placeholder="Dodaj tytuł"
                      [cdkAutosizeMinRows]="3"
                      [cdkAutosizeMaxRows]="3"
                      formControlName="welcomeTitle"
                      ngDefaultControl
                    >
                    </app-textarea>
                  </div>
                  <div fxLayoutAlign="center center">
                    <app-textarea
                      [placeholder]="'Dodaj opis'"
                      [cdkAutosizeMinRows]="5"
                      [cdkAutosizeMaxRows]="5"
                      formControlName="welcomeDescription"
                      ngDefaultControl
                    >
                    </app-textarea>
                  </div>
                </div>
                <div fxLayout="column" class="expansion-panel-wrapper">
                  <mat-accordion>
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                      class="first-panel"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>Szczegóły profilu</mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="profile-details-wrapper" fxLayout="column">
                        <span
                          class="profile-details-description"
                          (click)="activeField = 'category'"
                          *ngIf="
                            !activeField.includes('category') &&
                            !profileForm.get('offersCategory').value
                          "
                        >
                          + Dodaj preferowaną kategorię
                        </span>
                        <span
                          class="profile-details-description"
                          *ngIf="
                            !activeField.includes('category') &&
                            profileForm.get('offersCategory').value
                          "
                          (click)="activeField = 'category'"
                        >
                          -
                          {{
                            findCategoryById(
                              profileForm.get("offersCategory").value
                            )
                          }}
                        </span>

                        <app-select
                          class="category-input"
                          placeholder="Kategoria"
                          [options]="categories$ | async"
                          formControlName="offersCategory"
                          ngDefaultControl
                          *ngIf="activeField.includes('category')"
                        ></app-select>
                        <span class="profile-details-label">Edukacja</span>
                        <span
                          class="profile-details-description"
                          *ngIf="
                            !activeField.includes('educationSchoolName') &&
                            !profileForm.get('educationSchoolName').value &&
                            !profileForm.get('educationYears').value &&
                            !profileForm.get('educationSpecialization').value
                          "
                          (click)="setActiveField('educationSchoolName')"
                        >
                          + Dodaj edukację
                        </span>
                        <span
                          class="profile-details-description"
                          *ngIf="!activeField.includes('educationSchoolName')"
                          (click)="activeField = 'educationSchoolName'"
                        >
                          <p>
                            {{ profileForm.get("educationSchoolName").value }}
                          </p>
                          <p>
                            {{ profileForm.get("educationYears").value }}
                          </p>
                          <p>
                            {{
                              profileForm.get("educationSpecialization").value
                            }}
                          </p>
                        </span>
                        <div
                          fxLayout="column"
                          *ngIf="activeField.includes('educationSchoolName')"
                        >
                          <app-input
                            placeholder="Podaj nazwę szkoły"
                            ngDefaultControl
                            class="category-input"
                            formControlName="educationSchoolName"
                          ></app-input>
                          <app-input
                            placeholder="Podaj lata (YYYY-YYYY)"
                            ngDefaultControl
                            class="category-input"
                            formControlName="educationYears"
                          ></app-input>
                          <app-input
                            placeholder="Podaj kierunek"
                            ngDefaultControl
                            formControlName="educationSpecialization"
                            class="category-input"
                          ></app-input>
                        </div>
                        <span class="profile-details-label">Social media</span>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-facebook"></a>
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('facebook')"
                            (click)="setActiveField('facebook')"
                          >
                            {{
                              profileForm.get("facebook").value ||
                                "+ Dodaj swojego Facebooka"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="facebook"
                            [error]="
                              profileForm.get('facebook').errors?.message
                            "
                            ngDefaultControl
                            *ngIf="activeField.includes('facebook')"
                            class="surname-input"
                          ></app-input>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-instagram"></a>
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('instagram')"
                            (click)="setActiveField('instagram')"
                          >
                            {{
                              profileForm.get("instagram").value ||
                                "+ Dodaj swojego Instagrama"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="instagram"
                            [error]="
                              profileForm.get('instagram').errors?.message
                            "
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('instagram')"
                          ></app-input>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-twitter"></a>
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('twitter')"
                            (click)="setActiveField('twitter')"
                          >
                            {{
                              profileForm.get("twitter").value ||
                                "+ Dodaj swojego Twittera"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="twitter"
                            [error]="profileForm.get('twitter').errors?.message"
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('twitter')"
                          ></app-input>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-linkedin"></a>
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('linkedin')"
                            (click)="setActiveField('linkedin')"
                          >
                            {{
                              profileForm.get("linkedin").value ||
                                "+ Dodaj swojego LinkedIna"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="linkedin"
                            [error]="
                              profileForm.get('linkedin').errors?.message
                            "
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('linkedin')"
                          ></app-input>
                        </div>

                        <span class="profile-details-label"
                          >Strony internetowe</span
                        >
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <span class="material-icons website-icon"
                            >public</span
                          >
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('website')"
                            (click)="setActiveField('website')"
                          >
                            {{
                              profileForm.get("website").value ||
                                "+ Dodaj swoją stronę internetową"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojej strony"
                            formControlName="website"
                            [error]="profileForm.get('website').errors?.message"
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('website')"
                          ></app-input>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>Działalność</mat-panel-title>
                      </mat-expansion-panel-header>
                      <div fxLayout="row" class="welcome">
                        <div fxFlex="70" fxLayoutAlign="start center">
                          <span class="welcome-span">Statystyki</span>
                        </div>
                        <div fxLayoutAlign="end center" fxFlex="30">
                          <app-slide-toggle
                            (change)="statsEnabled = !statsEnabled"
                          ></app-slide-toggle>
                        </div>
                      </div>
                      <div
                        fxLayout="row"
                        class="stats-cards"
                        *ngIf="statsEnabled"
                      >
                        <div fxLayout="row" class="mini-cards">
                          <div class="mini-card-container" fxLayout="column">
                            <span class="mini-card-number">
                              {{ profile?.endedOffers }}
                            </span>
                            <span class="mini-card-description"
                              >Wykonane zlecenia</span
                            >
                          </div>
                          <div class="mini-card-container" fxLayout="column">
                            <span class="mini-card-number"
                              >{{ profile?.earned }} PLN</span
                            >
                            <span class="mini-card-description"
                              >Zarobionych ze zleceń</span
                            >
                          </div>
                          <div class="mini-card-container" fxLayout="column">
                            <span class="mini-card-number"
                              >{{ profile?.averageSalary }} PLN</span
                            >
                            <span class="mini-card-description"
                              >Średnia kwota zlecenia</span
                            >
                          </div>
                        </div>
                      </div>
                      <!-- <div fxLayout="row" class="welcome">
                          <div fxFlex="70" fxLayoutAlign="start center">
                            <span class="welcome-span"
                              >Zakończone zlecenia</span
                            >
                          </div>
                          <div fxLayoutAlign="end center" fxFlex="30">
                            <app-slide-toggle
                              (change)="finishedEnabled = !finishedEnabled"
                            ></app-slide-toggle>
                          </div>
                        </div>

                        <app-offer-list-horizontal></app-offer-list-horizontal> -->
                      <!-- <div fxLayout="row" class="welcome">
                          <div fxFlex="70" fxLayoutAlign="start center">
                            <span class="welcome-span">Rekomendacje</span>
                          </div>
                          <div fxLayoutAlign="end center" fxFlex="30">
                            <app-slide-toggle
                              (change)="
                                recommendationsEnabled = !recommendationsEnabled
                              "
                            ></app-slide-toggle>
                          </div>
                        </div> -->
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-tab>
              <mat-tab class="contractor-span" label="Profil zleceniodawcy">
                <div fxLayout="row" class="avatar-panel">
                  <div fxFlex="30" fxLayoutAlign="start center">
                    <div class="avatar">
                      <app-avatar
                        (loaded)="onAvatarLoaded($event)"
                        [userId]="user.id"
                        [editable]="true"
                      ></app-avatar>
                    </div>
                  </div>

                  <div fxLayout="column" class="name-container">
                    <span class="name">
                      {{ profileForm.get("firstname").value }}
                      {{ profileForm.get("lastname").value }}
                    </span>
                    <!-- <div fxLayout="row" *ngIf="!profile?.city">
                        <span class="location-span">+ Dodaj lokalizację</span>
                      </div> -->
                    <div fxLayout="row" *ngIf="profile?.city">
                      <a class="location-icon"></a>
                      <span class="location-span">
                        {{ profileForm.get("city").value }}
                      </span>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" class="buttons-container">
                  <app-button
                    *ngIf="!activeField.includes('email')"
                    [color]="ButtonColors.WHITE"
                    class="grey-button"
                    (click)="setActiveField('email')"
                  >
                    <!-- TODO: rozciagnac wg maila, albo skrocic mail ... -->
                    <mat-icon>alternate_email</mat-icon>
                    <span class="white-button-span">
                      {{
                        profileForm.get("email").value ||
                          "+ Dodaj adres mailowy"
                      }}
                    </span>
                  </app-button>
                  <app-input
                    [centerLabel]="true"
                    placeholder="Podaj email kontaktowy"
                    formControlName="email"
                    [error]="profileForm.get('email').errors?.message"
                    ngDefaultControl
                    class="email-input-mobile"
                    *ngIf="activeField.includes('email')"
                  ></app-input>
                  <app-button
                    (click)="setActiveField('phoneNumber')"
                    *ngIf="!activeField.includes('phoneNumber')"
                    [color]="ButtonColors.WHITE"
                    class="grey-button grey-button-2"
                    [error]=""
                  >
                    <a class="icon-phone" fxLayoutAlign="center center"></a>
                    <span class="white-button-span">
                      {{
                        profileForm.get("phoneNumber").value ||
                          "+ Dodaj numer telefonu"
                      }}
                    </span>
                  </app-button>
                  <app-input
                    [centerLabel]="true"
                    placeholder="Podaj numer kontaktowy"
                    formControlName="phoneNumber"
                    [error]="profileForm.get('phoneNumber').errors?.message"
                    ngDefaultControl
                    class="phone-input-mobile"
                    *ngIf="activeField.includes('phoneNumber')"
                  ></app-input>
                </div>

                <div fxLayout="row" class="welcome">
                  <div fxFlex="70" fxLayoutAlign="start center">
                    <span class="welcome-span">Przywitanie</span>
                  </div>
                  <div fxLayoutAlign="end center" fxFlex="30">
                    <app-slide-toggle
                      (change)="welcomeEnabled = !welcomeEnabled"
                    ></app-slide-toggle>
                  </div>
                </div>
                <div
                  class="add-picture-wrapper"
                  *ngIf="welcomeEnabled"
                  fxLayout="column"
                >
                  <app-button-add-picture
                  *ngIf="profile"
                    [imagePath]="getImageURL(profile?.contractorProfileMedia?.path)"
                    (loaded)="welcomeContractorImageLoaded($event)"
                  ></app-button-add-picture>
                  <div fxLayoutAlign="center center" class="textarea-wrapper">
                    <app-textarea
                      placeholder="Dodaj tytuł"
                      formControlName="contratorTitle"
                      ngDefaultControl
                      name="contratorTitle"
                      [cdkAutosizeMinRows]="3"
                      [cdkAutosizeMaxRows]="3"
                    >
                    </app-textarea>
                  </div>
                  <div fxLayoutAlign="center center">
                    <app-textarea
                      placeholder="Dodaj opis"
                      class="textarea-desktop"
                      formControlName="contractorDescription"
                      ngDefaultControl
                      [cdkAutosizeMinRows]="5"
                      [cdkAutosizeMaxRows]="5"
                    >
                    </app-textarea>
                  </div>
                </div>
                <div fxLayout="column" class="expansion-panel-wrapper">
                  <mat-accordion>
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                      class="first-panel"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>Szczegóły profilu</mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="profile-details-wrapper" fxLayout="column">
                        <span
                          class="profile-details-description"
                          (click)="activeField = 'category'"
                          *ngIf="
                            !activeField.includes('category') &&
                            !profileForm.get('offersCategory').value
                          "
                        >
                          + Dodaj preferowaną kategorię
                        </span>
                        <span
                          class="profile-details-description"
                          *ngIf="
                            !activeField.includes('category') &&
                            profileForm.get('offersCategory').value
                          "
                          (click)="activeField = 'category'"
                        >
                          -
                          {{
                            findCategoryById(
                              profileForm.get("offersCategory").value
                            )
                          }}
                        </span>

                        <app-select
                          class="category-input"
                          placeholder="Kategoria"
                          [options]="categories$ | async"
                          formControlName="offersCategory"
                          ngDefaultControl
                          *ngIf="activeField.includes('category')"
                        ></app-select>
                        <span class="profile-details-label">Edukacja</span>
                        <span
                          class="profile-details-description"
                          *ngIf="
                            !activeField.includes('educationSchoolName') &&
                            !profileForm.get('educationSchoolName').value &&
                            !profileForm.get('educationYears').value &&
                            !profileForm.get('educationSpecialization').value
                          "
                          (click)="setActiveField('educationSchoolName')"
                        >
                          + Dodaj edukację
                        </span>
                        <span
                          class="profile-details-description"
                          *ngIf="!activeField.includes('educationSchoolName')"
                          (click)="activeField = 'educationSchoolName'"
                        >
                          <p>
                            {{ profileForm.get("educationSchoolName").value }}
                          </p>
                          <p>
                            {{ profileForm.get("educationYears").value }}
                          </p>
                          <p>
                            {{
                              profileForm.get("educationSpecialization").value
                            }}
                          </p>
                        </span>
                        <div
                          fxLayout="column"
                          *ngIf="activeField.includes('educationSchoolName')"
                        >
                          <app-input
                            placeholder="Podaj nazwę szkoły"
                            ngDefaultControl
                            class="category-input"
                          ></app-input>
                          <app-input
                            placeholder="Podaj lata (YYYY-YYYY)"
                            ngDefaultControl
                            class="category-input"
                          ></app-input>
                          <app-input
                            placeholder="Podaj kierunek"
                            ngDefaultControl
                            class="category-input"
                          ></app-input>
                        </div>
                        <span class="profile-details-label">Social media</span>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-facebook"></a>
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('facebook')"
                            (click)="setActiveField('facebook')"
                          >
                            {{
                              profileForm.get("facebook").value ||
                                "+ Dodaj swojego Facebooka"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="facebook"
                            [error]="
                              profileForm.get('facebook').errors?.message
                            "
                            ngDefaultControl
                            *ngIf="activeField.includes('facebook')"
                            class="surname-input"
                          ></app-input>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-instagram"></a>
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('instagram')"
                            (click)="setActiveField('instagram')"
                          >
                            {{
                              profileForm.get("instagram").value ||
                                "+ Dodaj swojego Instagrama"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="instagram"
                            [error]="
                              profileForm.get('instagram').errors?.message
                            "
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('instagram')"
                          ></app-input>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-twitter"></a>
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('twitter')"
                            (click)="setActiveField('twitter')"
                          >
                            {{
                              profileForm.get("twitter").value ||
                                "+ Dodaj swojego Twittera"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="twitter"
                            [error]="profileForm.get('twitter').errors?.message"
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('twitter')"
                          ></app-input>
                        </div>
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <a class="icon-linkedin"></a>
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('linkedin')"
                            (click)="setActiveField('linkedin')"
                          >
                            {{
                              profileForm.get("linkedin").value ||
                                "+ Dodaj swojego LinkedIna"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojego profilu"
                            formControlName="linkedin"
                            [error]="
                              profileForm.get('linkedin').errors?.message
                            "
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('linkedin')"
                          ></app-input>
                        </div>

                        <span class="profile-details-label"
                          >Strony internetowe</span
                        >
                        <div
                          fxLayout="row"
                          class="profile-details-description-wrapper"
                        >
                          <span class="material-icons website-icon"
                            >public</span
                          >
                          <span
                            class="profile-details-description-socialmedia"
                            *ngIf="!activeField.includes('website')"
                            (click)="setActiveField('website')"
                          >
                            {{
                              profileForm.get("website").value ||
                                "+ Dodaj swoją stronę internetową"
                            }}
                          </span>
                          <app-input
                            placeholder="Podaj pełny link do swojej strony"
                            formControlName="website"
                            [error]="profileForm.get('website').errors?.message"
                            ngDefaultControl
                            class="surname-input"
                            *ngIf="activeField.includes('website')"
                          ></app-input>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel
                      (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>Działalność</mat-panel-title>
                      </mat-expansion-panel-header>
                      <div fxLayout="row" class="welcome">
                        <div fxFlex="70" fxLayoutAlign="start center">
                          <span class="welcome-span">Statystyki</span>
                        </div>
                        <div fxLayoutAlign="end center" fxFlex="30">
                          <app-slide-toggle
                            (change)="statsEnabled = !statsEnabled"
                          ></app-slide-toggle>
                        </div>
                      </div>
                      <div
                        fxLayout="row"
                        class="stats-cards"
                        *ngIf="statsEnabled"
                      >
                        <div fxLayout="row" class="mini-cards">
                          <div class="mini-card-container" fxLayout="column">
                            <span class="mini-card-number">0</span>
                            <span class="mini-card-description"
                              >Wystawione zlecenia</span
                            >
                          </div>
                          <div class="mini-card-container" fxLayout="column">
                            <span class="mini-card-number">0 PLN</span>
                            <span class="mini-card-description"
                              >Łącznie zapłacono</span
                            >
                          </div>
                          <div class="mini-card-container" fxLayout="column">
                            <span class="mini-card-number">0 PLN</span>
                            <span class="mini-card-description"
                              >Średnia kwota zlecenia</span
                            >
                          </div>
                        </div>
                      </div>
                      <!-- <div fxLayout="row" class="welcome">
                          <div fxFlex="70" fxLayoutAlign="start center">
                            <span class="welcome-span"
                              >Zrealizowane zlecenia</span
                            >
                          </div>
                          <div fxLayoutAlign="end center" fxFlex="30">
                            <app-slide-toggle
                              (change)="finishedEnabled = !finishedEnabled"
                            ></app-slide-toggle>
                          </div>
                        </div> -->

                      <!-- <app-offer-list-horizontal></app-offer-list-horizontal> -->
                      <!-- <div fxLayout="row" class="welcome">
                          <div fxFlex="70" fxLayoutAlign="start center">
                            <span class="welcome-span">Rekomendacje</span>
                          </div>
                          <div fxLayoutAlign="end center" fxFlex="30">
                            <app-slide-toggle
                              (change)="
                                recommendationsEnabled = !recommendationsEnabled
                              "
                            ></app-slide-toggle>
                          </div>
                        </div> -->
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </form>
      </div>
    </div>
  </rs-mobile>
</responsive-switcher>
