import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { OffersResponseDTO } from '../../sp-services';
enum TopbarFilterState {
  None,
  Search,
  Filter,
}
@Component({
  selector: 'app-sorting-select',
  templateUrl: './sorting-select.component.html',
  styleUrls: ['./sorting-select.component.scss'],
})
export class SortingSelectComponent implements OnInit {

  @Output()
  sortChanged = new EventEmitter<{ field: string; sort: string }>();
  selected = 'title';
  filters = new FormControl();
  filtersList: Observable<any> = of([
    { label: 'Data dodania', value: 'created' },
    { label: 'Nazwa', value: 'title' },
    { label: 'Miejscowość', value: 'city' },
  ]);

  loading = true;
  sortReverse = false;
  constructor() {}

  ngOnInit(): void {}

  changed(): void {
    this.sortChanged.emit({ field: this.selected,
      sort: this.sortReverse ? 'ASC' : 'DESC' });
  }

  reverseSort($event) {
    this.sortReverse = !this.sortReverse;
    this.changed();
  }
}
