import { Injectable } from '@angular/core';
import { DefaultFilters } from './default-filters.interface';
import { OffersResponseDTO } from '../sp-services/offers.service';
import {DeviceDetectorService} from 'src/app/modules/common-services/device-detector.service';

@Injectable()
export class UtilsService {
  constructor(private deviceDetector: DeviceDetectorService) {
  }

  getWindow(): Window {
    if (!this.deviceDetector.isServer()) {
      return window;
    } else {
      return null;
    }
  }

  getDefaultFiltersQueryString(filters: DefaultFilters<OffersResponseDTO>): string {
    let queryString = '?';
    if (!filters) {
      queryString = '';
      return queryString;
    } else {
      if (filters.offset) {
        queryString += `?offset=${filters.offset}`;
      }
      if (filters.limit) {
        queryString += `&limit=${filters.limit}`;
      }
    }
    return queryString;
  }

  queryString(obj: Object) {
    return Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
  }
}
