<responsive-switcher>
  <rs-desktop>
    <app-desktop-guest-wrapper>
      <div fxLayout="column" fxLayoutAlign=" center" class="container">
        <div fxLayout="row">
           <span class="span1" *ngIf="!loading && error.length === 0">
           Konto zostało potwierdzone!
          </span>
          <span class="span1" *ngIf="error.length > 0">
           Niepoprawny token!
          </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <app-button [color]="ButtonColors.PRIMARY" class="button-go">
            <span class="button-span" [routerLink]="['/', 'sign-in']">Przejdź do serwisu</span>
          </app-button>
        </div>
      </div>

    </app-desktop-guest-wrapper>
  </rs-desktop>

  <rs-mobile>
    <app-mobile-guest-wrapper>
      <div fxLayout="column" fxLayoutAlign=" center" class="container">
        <div fxLayout="row">
           <span class="span1">
           Konto zostało potwierdzone!
          </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <app-button [color]="ButtonColors.PRIMARY" class="button-go">
            <span class="button-span" [routerLink]="['/', 'sign-in']">Przejdź do serwisu</span>
          </app-button>
        </div>
      </div>
    </app-mobile-guest-wrapper>
  </rs-mobile>
</responsive-switcher>
