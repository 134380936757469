import {Component} from '@angular/core';
import {BUTTON_COLORS} from '../../../modules/common-components/button/button.component';
import {FormBuilder, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import {AuthService} from '@replica-frontend/auth';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ValidationErrorMessage} from '@replica-frontend/sdk';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  ButtonColors = BUTTON_COLORS;
  resetPasswordForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });
  badEmail = false;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
  }
  back(): void {
    this.location.back();
  }
  onFieldErrors(errors: ValidationErrorMessage): void {
    errors.message.forEach(error => {
      this.resetPasswordForm.controls[error.field].setErrors({ message: error.message });
    });
  }
  submit(): void {
    this.authService
      .forgotPassword(this.resetPasswordForm.value.email)
      .subscribe(() => {
        this.router.navigate(['/']).then(() => {
          this.snackBar.open('Verification email sent', 'Dismiss', {
            duration: 3000
          });
        });
      });
  }}
