<mat-toolbar>
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="80">
    <button mat-button routerLink="/" class="form-logo">
      <a class="logo"></a>
      <span>szybkapraca.pl</span>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
    <button *ngIf="!maintenance" mat-button (click)="sidenav.toggle()">
      <a class="burger-icon"></a>
    </button>
  </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="push" [(opened)]="opened" class="content-2">
    <mat-toolbar
      class="mobile-topbar-wrapper"
      fxLayout="row"
      fxLayoutAlign="center"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="80">
        <button mat-button routerLink="/" class="form-logo">
          <a class="logo"></a>
          <span>szybkapraca.pl</span>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
        <button mat-button (click)="sidenav.toggle()">
          <a class="burger-icon"></a>
        </button>
      </div>
    </mat-toolbar>

    <app-menu-logged
      (itemClicked)="sidenav.close()"
      [options]="options"
    ></app-menu-logged>
  </mat-sidenav>
  <div
    fxLayout="column"
    fxLayoutAlign="center"
    fxFlex="100"
    class="wrapper-mobile"
  >
    <ng-content></ng-content>
    <app-footer-panel class="footer"></app-footer-panel>
  </div>
</mat-sidenav-container>
