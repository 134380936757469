<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxFlex="100"
  class="second-content"
>
  <div fxLayout="row" fxLayoutAlign="center" class="second-content">
    <button
      mat-button
      class="label-menu"
      routerLink="/app/offers-for-employee"
      routerLinkActive="active-link"
    >
      Wykonawca
    </button>
    <button
      mat-button
      class="label-menu"
      routerLink="/app/offers-for-contractor"
      routerLinkActive="active-link"
    >
      Zleceniodawca
    </button>
    <button
    [roles]="['admin']"
    mat-button
    class="label-menu"
    routerLink="/app/admin"
    routerLinkActive="active-link"
  >
    Admin
  </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" class="fourth-content">
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="80">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        class="input-container"
        matTooltip="Szukaj"
        matTooltipClass="tooltip"
      >
        <input
          class="input-search"
          [(ngModel)]="searchQuery"
          (change)="changed()"
        />
        <div class="chat-button-container">
          <a class="icon-search"></a>
        </div>
      </div>

      <app-button
        [color]="ButtonColors.PRIMARY"
        class="yellow-button"
        [routerLink]="['/app', 'create-offer']"
        tooltip="Utwórz zlecenie"
      >
        <span class="material-icons">add</span>
        Utwórz zlecenie
      </app-button>
    </div>

    <div class="third-content" fxLayout="row">
      <button
        routerLinkActive="messages-button-opened"
        class="messages-button chat-button"
        mat-fab
        color="primary"
        fxLayoutAlign="center center"
        routerLink="/ext/messenger"
        matTooltip="Wiadomości"
        matTooltipClass="tooltip"
      >
        <a class="icon-mail" fxLayoutAlign="center center"></a>
      </button>
      <div class="avatar-wrapper">
        <replica-avatar
          [debug]="true"
          matTooltip="Profil"
          matTooltipClass="tooltip"
          [routerLink]="['/app', 'contractor-profile']"
          class="avatar"
        >
        </replica-avatar>
      </div>
    </div>
  </div>
</div>
