<responsive-switcher>
  <rs-desktop>
    <app-desktop-guest-wrapper>
      <div fxLayout="column" fxLayoutAlign=" center" class="container">
        <div fxLayout="row">
           <span  class="span1">
  Udało się, założyłeś konto!
</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
           <span fxLayout="row" class="span2"  fxFlex="80">
          Sprawdź swoją skrzynkę pocztową i aktywuj konto za pomocą linku, żeby w pełni korzystać z możliwości serwisu!
        </span>
        </div>


      </div>

    </app-desktop-guest-wrapper>
  </rs-desktop>

  <rs-mobile>
    <app-mobile-guest-wrapper>
      <div fxLayout="column" fxLayoutAlign=" center" class="container">
        <div fxLayout="row">
           <span  class="span1">
  Udało się, założyłeś konto!
</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
           <span fxLayout="row" class="span2">
          Sprawdź swoją skrzynkę pocztową i aktywuj konto za pomocą linku, żeby w pełni korzystać z możliwości serwisu!
        </span>
        </div>


      </div>
    </app-mobile-guest-wrapper>
  </rs-mobile>
</responsive-switcher>
