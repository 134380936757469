import { EnvironmentService } from './../environment.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ReportFrontendExceptionDTO {
  message: string;
  loggedUserTechData: string;
  additionalTechData: string;
  exceptionTrace: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExceptionTelemeteryService {

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) { }

  reportException(reportFrontendExceptionDTO: ReportFrontendExceptionDTO): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${this.environmentService.getEnv('apiUrl')}/report-exception`,
      reportFrontendExceptionDTO
    );
  }
}
