import { UserData } from '@replica-frontend/auth';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DefaultFilters } from '../common-services';
import { EnvironmentService } from '../common-services/environment.service';
import { OffersResponseDTO } from './offers.service';

export const OfferApplyStatus = {
  AwaitingAprooval: 'AWAITING_APROOVAL',
  InProgress: 'IN_PROGRESS',
  EmployerResigned: 'EMPLOYER_RESIGNED',
  EmployeeFinalized: 'FINALIZED_BY_EMPLOYEE',
  Finalized: 'FINALIZED',
  Payed: 'PAYED'
}

export interface OffersApplies {
  id: number;
  offerid: OffersResponseDTO;
  userid: UserData;
  status: string;
}

@Injectable()
export class AppliesService {
  watchMap: Map<number, Subject<OffersApplies>> = new Map<number, Subject<OffersApplies>>();
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
  }

  apply(offer: OffersResponseDTO): Observable<OffersApplies> {
    return this.http.put<OffersApplies>(`${this.environmentService.getEnv('apiUrl')}/applies/apply`, { id: offer.id });
  }

  aproove(applyid: number): Observable<OffersApplies> {
    return this.http.put<OffersApplies>(`${this.environmentService.getEnv('apiUrl')}/applies/aproove`, { id: applyid });
  }

  resignAsEmployee(apply: OffersApplies): Observable<OffersApplies> {
    return this.http.put<OffersApplies>(`${this.environmentService.getEnv('apiUrl')}/applies/resign-as-employee`, { id: apply.id });
  }

  finalizeAsEmployee(offerApplyId: number): Observable<OffersApplies> {
    return this.http.put<OffersApplies>(`${this.environmentService.getEnv('apiUrl')}/applies/finalizeAsEmployee`, { id: offerApplyId });
  }

  finalizeAsEmployer(offerApplyId: number): Observable<OffersApplies> {
    return this.http.put<OffersApplies>(`${this.environmentService.getEnv('apiUrl')}/applies/finalizeAsEmployer`, { id: offerApplyId })
  }

  aproovePayment(offerApplyId: number): Observable<OffersApplies> {
    return this.http.put<OffersApplies>(`${this.environmentService.getEnv('apiUrl')}/applies/aproovePayment`, { id: offerApplyId });
  }

  getApplyByOfferId(offerId: number): Observable<OffersApplies> {
    return this.http.get<OffersApplies>(`${this.environmentService.getEnv('apiUrl')}/offers/appliedByMe/${offerId}`);
  }

  appliedByMe(filters: DefaultFilters<OffersResponseDTO>): Observable<OffersApplies[]> {
    return this.http.post<OffersApplies[]>(`${this.environmentService.getEnv('apiUrl')}/offers/appliedByMe`, filters);
  }

  employeesApplied(filters: DefaultFilters<OffersResponseDTO>): Observable<OffersApplies[]> {
    return this.http.post<OffersApplies[]>(`${this.environmentService.getEnv('apiUrl')}/offers/employeesApplied`, filters);
  }

  singleApply(id: string): Observable<OffersApplies> {
    return this.http.get<OffersApplies>(`${this.environmentService.getEnv('apiUrl')}/applies/single/${id}`);
  }

}
