<responsive-switcher>
  <rs-desktop>
      <app-offer-list
      titleLabel="Obserwowane"
        queryMethod="getFavorites"
      ></app-offer-list>
      <!-- <mat-tab-group mat-align-tabs="start" disablePagination="true">
        <mat-tab label="Aktywne" class="span-tab-active">
          <div fxLayout="row" class="offers-list">


          </div>
        </mat-tab>
        <mat-tab
          label="Podobają mi się"
          class="margin-span-tabs span-tab-inactive"
        >
          <div fxLayout="column" class="list-container">

          </div>
        </mat-tab>
        <mat-tab
          label="Nie podobają mi się"
          class="span-tab-inactive margin-span-tabs"
        >
          <div fxLayout="column" class="list-container"></div>
        </mat-tab>
        <mat-tab label="Archiwum" class="margin-span-tabs span-tab-inactive">
          <div fxLayout="column" class="list-container">

          </div>
        </mat-tab>
      </mat-tab-group> -->
  </rs-desktop>
  <rs-mobile>
      <app-offer-list
      filterLabel="Obserwowane"
        queryMethod="getFavorites"
      ></app-offer-list>
  </rs-mobile>
</responsive-switcher>
