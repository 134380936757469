import {Component} from '@angular/core';

@Component({
  selector: 'app-create-account-successful',
  templateUrl: './create-account-successful.component.html',
  styleUrls: ['./create-account-successful.component.scss']
})
export class CreateAccountSuccessfulComponent {

}
