<mat-form-field
  class="wrapper-chip-list"
  [ngClass]="{ 'small-chips': small, 'big-chips': !small }"
>
  <mat-chip-list #chipList class="chip-list" fxLayout="column">
    <input
      class="input-container"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      #inputAutocomplete
      (input)="onKeywordsInput($event)"
    />
    <mat-chip
      *ngFor="let options of selectedOptions; let i = index"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(i)"
    >
      {{ options.label }}
      <mat-icon matChipRemove *ngIf="removable"> close </mat-icon>
    </mat-chip>
  </mat-chip-list>
  <mat-autocomplete
    #auto="matAutocomplete"
    (opened)="(autocompleteOpened)"
    (optionSelected)="optionSelected($event)"
  >
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      [value]="option"
    >
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
