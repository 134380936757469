<responsive-switcher>
  <rs-desktop>
    <div fxLayout="column"></div>
      <div *ngIf="!!error" class="error-wrapper">
        <span class="error-message">{{ error }}</span>
      </div>
      <div class="file-drop">
        <ngx-file-drop
          accept=".png, .jpg, .jpeg"
          dropZoneLabel="Drop files here"
          (onFileDrop)="dropped($event)"
          (onFileOver)="fileOver($event)"
          (onFileLeave)="fileLeave($event)"
          dropZoneClassName="drop-zone"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <button
              [style.height]="height"
              mat-button
              [ngClass]="{ error: !!error }"
              fxLayout="column"
              fxLayoutAlign="center center"
              [style.background-image]="
                imagePath ? 'url(' + imagePath + ')' : 'none'
              "
              (click)="invokeSelector($event, openFileSelector)"
            >
              <div
                (click)="invokeSelector($event, openFileSelector)"
                mat-button
              >
                <!-- TODO: add preview -->

                <div fxLayout="row" fxLayoutAlign="center center">
                  <a class="add-pic-icon"></a>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <span class="first-span">
                    Kliknij lub upuść obrazek, aby dodać miniaturkę
                  </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <span class="span-2">Minimalny rozmiar: 400 x 600 px</span>
                  <span class="span-3">Maksymalna waga: 3 MB</span>
                </div>
              </div>
            </button>
          </ng-template>
        </ngx-file-drop>
      </div>

  </rs-desktop>
  <rs-mobile>
    <div *ngIf="!!error" class="error-wrapper">
      <span class="error-message">{{ error }}</span>
    </div>
    <div class="file-drop">
      <ngx-file-drop
        accept=".png, .jpg, .jpeg"
        dropZoneLabel="Drop files here"
        dropZoneClassName="drop-zone"
        (onFileDrop)="dropped($event)"
        (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <button
            mat-button
            fxLayout="column"
            [ngClass]="{ error: !!error }"
            fxLayoutAlign="center center"
            [style.background-image]="
              imagePath ? 'url(' + imagePath + ')' : 'none'
            "
            (click)="invokeSelector($event, openFileSelector)"
          >
            <!--          <div class="img-container">-->
            <!--            <img *ngIf="imagePath" [src]="imagePath"/>-->
            <!--          </div>-->
            <div fxLayout="row" fxLayoutAlign="center center">
              <a class="add-pic-icon"></a>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <span class="first-span">Kliknij, aby dodać miniaturkę</span>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="span-2">Minimalny rozmiar: 400 x 600 px</span>
              <span class="span-3">Maksymalna waga: 3 MB</span>
            </div>
          </button>
        </ng-template>
      </ngx-file-drop>
    </div>
  </rs-mobile>
</responsive-switcher>
