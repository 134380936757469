import { AuthService } from '@replica-frontend/auth';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedAuthGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (!this.authService.storage) {
    //   this.authService.redirectToRoute = this.router.url;
    //   console.error('403: Forbidden for guests');
    //   this.router.navigate(['sign-in']);
    //   return false;
    // }
    return true;
  }
}
