import { Component, OnDestroy, OnInit } from '@angular/core';
import { DefaultFilters } from '../../../modules/common-services';
import {
  Categories,
  OffersRequestDTO,
  OffersResponseDTO,
  OffersService
} from '@src/app/modules/sp-services/offers.service';
import { Subscription } from 'rxjs';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { CommonHttpService } from '../../../modules/common-services/common-http.service';
import { ExceptionTelemeteryService } from './../../../modules/common-services/exception-telemetery/exception-telemetery.service';
@Component({
  selector: 'app-guest-dashboard',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  events: [];
  ButtonColors = BUTTON_COLORS;
  city: number;
  offers: OffersResponseDTO[] = [];
  loadSub: Subscription;
  filter: DefaultFilters<OffersRequestDTO> = { limit: 10, offset: 0, filterBy: {} };
  cannotBeMore = false;
  activeTab = 0;
  categories: Categories[] = [];
  constructor(
    public offersService: OffersService,
    public commonHttpService: CommonHttpService,
    public telemeteryService: ExceptionTelemeteryService
  ) {
    // Use the component constructor to inject providers.
  }

  ngOnInit(): void {
    this.loadOffers();
    this.loadTags();
  }

  loadTags(): void {
    this.offersService.getCategories$.subscribe((categories) => {
      this.categories = categories.splice(0,5);
    })
  }

  submit($event): void {
    this.filter = { limit: 10, offset: 0, filterBy: {} };
    if ($event !== -1) {
      this.filter.filterBy = { mapboxPlace: $event };
    }
    this.cannotBeMore = false;

    this.loadOffers();
  }

  loadOffers(): void {
    if (this.loadSub) {
      this.loadSub.unsubscribe();
    }
    this.loadSub = this.offersService.getOffers(this.filter)
      .subscribe((offers: OffersResponseDTO[]) => {
        this.offers = offers;
        if (offers.length < 10) {
          this.cannotBeMore = true;
        }
      }, (error) => {
        console.error(error);
      });
  }
  getMore(): void {
    this.filter.offset += this.filter.limit;
    this.loadSub = this.offersService.getOffers(this.filter).subscribe((offers: OffersResponseDTO[]) => {
      this.offers = this.offers.concat(offers);
      if (offers.length < 10) {
        this.cannotBeMore = true;
      }
    });
  }


  ngOnDestroy(): void {
    if (this.loadSub) {
      this.loadSub.unsubscribe();
    }
  }
}
