<responsive-switcher>
  <rs-desktop>
    <div *ngIf="!!error" class="error-wrapper">
      <span class="error-message">{{error}}</span>
    </div>
    <div class="container-select" fxLayout="column">
      <div [ngClass]="{'border-closed':!opened || !options || options?.length === 0, 'border-opened': opened && options && options?.length > 0, 'error': !!error}" fxLayout="row"
        class="input-container">
        <div *ngIf="leftIcon" fxLayout="row" fxFlex="10" fxLayoutAlign="center center" class="icon-location-wrapper">
          <a class="icon-location"></a>
        </div>
        <div *ngIf="!leftIcon" fxLayout="row">
        </div>
        <div fxLayout="row" fxFlex="100" *ngIf="!leftIcon" fxLayoutAlign="start center">
          <span class="span-label label-color-value" *ngIf="value">
            {{getCurrentLabel()}}
          </span>
          <span class="span-label label-color-placeholder" *ngIf="!value">
            {{placeholder}}
          </span>
          <button class="close-button" *ngIf="value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="writeValue(null)">
          <mat-icon class="close-icon">close</mat-icon>
        </button>
        <div class="arrow-container">
          <a class="arrow"></a>
        </div>
        </div>
        <div fxLayout="row" fxFlex="90" *ngIf="leftIcon" fxLayoutAlign="start center">
          <span class="span-icon-label label-color-value" *ngIf="value">
            {{getCurrentLabel()}}
          </span>
          <span class="span-icon-label label-color-placeholder" *ngIf="!value">
            {{placeholder}}
          </span>
          <button class="close-button" *ngIf="value" mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="writeValue(null)">
          <mat-icon>close</mat-icon>
        </button>
        <div class="arrow-container">
          <a class="arrow"></a>
        </div>
        </div>

      </div>

      <div class="options" *ngIf="opened">
        <div *ngFor="let option of options" class="option" (click)="writeValue(option.value)">
          {{option.label}}
        </div>
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div *ngIf="!!error" class="error-wrapper">
      <span class="error-message">{{error}}</span>
    </div>
    <div fxLayout="column">
      <div class="select-container"
        [ngClass]="{'border-closed':(!opened || !options || options?.length === 0), 'border-opened': (options && options?.length > 0 && opened), 'error': !!error}">
        <mat-form-field fxFlex="100" fxLayoutAlign="start center">
          <div *ngIf="leftIcon" fxLayout="row" fxFlex="10" fxLayoutAlign="center center">
            <a class="icon-location"></a>
          </div>
          <div *ngIf="!leftIcon" fxLayout="row">
          </div>
          <div fxLayout="row" fxFlex="90">
            <select [ngClass]="{'label-color-placeholder': !(!!value)}" [placeholder]="placeholder" fxFlex="100" matNativeControl [compareWith]="compareFn"
              [(ngModel)]="value">
              <option value="" selected >{{placeholder}}</option>
              <option *ngFor="let option of options" [value]="option.value" class="option">
                {{option.label}}
              </option>
            </select>
          </div>
        </mat-form-field>
      </div>
    </div>

  </rs-mobile>
</responsive-switcher>
