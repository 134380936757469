<div *ngIf="!loading">
<responsive-switcher>
  <rs-desktop>
      <form
        fxLayout="column"
        [formGroup]="createOrderForm"
        (ngSubmit)="submit()"
      >
        <div fxLayout="row" fxFlex="100" class="wrapper-logged">
          <div fxLayout="column" class="content-logged">
            <span class="main-label">{{ mode === 'add' ? 'Utwórz' : 'Edytuj'}} zlecenie</span>
            <div fxLayout="row" class="selectors">
              <common-geo-select
                leftIcon="../../../../assets/icon-location.svg"
                class="first-select work-time"
                placeholder="Wybierz miasto"
                formControlName="mapboxPlace"
                [small]="false"
                [error]="createOrderForm.get('mapboxPlace').errors?.message"
                ngDefaultControl
              ></common-geo-select>

              <app-select
                class="second-select work-time"
                placeholder="Kategoria"
                [options]="categories"
                [error]="createOrderForm.get('category').errors?.message"
                formControlName="category"
                ngDefaultControl
              >
              </app-select>
              <app-select
                class="second-select"
                placeholder="Rodzaj"
                [error]="createOrderForm.get('type').errors?.message"
                [options]="types"
                formControlName="type"
                ngDefaultControl
              ></app-select>
            </div>

            <div fxLayout="row" class="input-first">
              <app-input
                placeholder="Tytuł"
                [error]="createOrderForm.get('title').errors?.message"
                formControlName="title"
                ngDefaultControl
              ></app-input>
            </div>

            <div fxLayout="row" class="textarea-wrapper">
              <app-textarea
                class="textarea"
                placeholder="Dodaj opis"
                [error]="createOrderForm.get('description').errors?.message"
                formControlName="description"
                ngDefaultControl
                minHeight="190px"
                maxHeight="200px"
              ></app-textarea>
            </div>

            <div fxLayout="row" class="selectors-2" fxLayoutAlign="center end">
              <app-select
                fxFlex="30"
                class="third-select"
                [options]="rateTypes"
                [error]="createOrderForm.get('rateType').errors?.message"
                placeholder="Rodzaj stawki"
                formControlName="rateType"
                ngDefaultControl
              ></app-select>
              <app-input
                fxFlex="30"
                class="input-second work-time"
                placeholder="Kwota"
                formControlName="salary"
                type="number"
                [error]="createOrderForm.get('salary').errors?.message"
                ngDefaultControl
              ></app-input>
              <app-input
                fxFlex="30"
                class="input-second work-time"
                placeholder="Czas pracy"
                formControlName="workTime"
                [error]="createOrderForm.get('workTime').errors?.message"
                ngDefaultControl
              ></app-input>
              <app-select
                class="input-days work-time"
                formControlName="days"
                [error]="createOrderForm.get('days').errors?.message"
                ngDefaultControl
                [options]="days"
                placeholder="Dni"
              ></app-select>
            </div>

            <div fxLayout="row" class="chips-container">
              <app-chips
                formControlName="skills"
                [findFunction]="skillsFinder"
                placeholder="Dodaj umiejętność"
                [small]="false"
              ></app-chips>
            </div>

            <div fxLayout="row" class="add-pic-margin">
              <app-button-add-picture
                height="190px"
                [imagePath]="commonHttpService.getImagePath(thumbnail?.path)"
                (loaded)="imageLoaded($event)"
                [error]="createOrderForm.get('thumbnail').errors?.message"
              ></app-button-add-picture>
            </div>

            <div
              fxLayout="row"
              class="buttons-container"
              fxLayoutAlign="center center"
            >
              <app-button
                buttonType="submit"
                [color]="ButtonColors.PRIMARY"
                class="button-yellow"
                tooltip="{{mode === 'add' ? 'Utwórz' : 'Zapisz'}}"
              >
              <span class="button-span">{{mode === 'add' ? 'Utwórz' : 'Zapisz'}}</span>
            </app-button>
            </div>
          </div>
        </div>
      </form>
  </rs-desktop>

  <rs-mobile>
      <div class="form-wrapper">
        <form
          class="form-mobile"
          [formGroup]="createOrderForm"
          (ngSubmit)="submit()"
        >
          <div fxLayoutAlign="start center" fxLayout="row" class="first-span">
            <button mat-mini-fab class="back-button" (click)="back($event)">
              <a class="arrow-back"></a>
            </button>

            <span class="main-label">Utwórz zlecenie</span>
          </div>
          <div fxLayout="column" class="selectors">
            <common-geo-select
                leftIcon="../../../../assets/icon-location.svg"
                class="first-select work-time"
                placeholder="Wybierz miasto"
                formControlName="mapboxPlace"
                [small]="true"
                [error]="createOrderForm.get('mapboxPlace').errors?.message"
                ngDefaultControl
              ></common-geo-select>
            <app-select
              class="second-select"
              placeholder="Kategoria"
              [options]="categories"
              [error]="createOrderForm.get('category').errors?.message"
              formControlName="category"
              ngDefaultControl
            >
            </app-select>
            <app-select
              class="second-select"
              placeholder="Rodzaj"
              [error]="createOrderForm.get('type').errors?.message"
              [options]="types"
              formControlName="type"
              ngDefaultControl
            ></app-select>
            <div fxLayout="column" class="textarea-wrapper">
              <app-textarea
                cdkAutosizeMaxRows="4"
                cdkAutosizeMinRows="3"
                class="textarea"
                [error]="createOrderForm.get('title').errors?.message"
                placeholder="Dodaj tytuł"
                formControlName="title"
                ngDefaultControl
              ></app-textarea>
            </div>
            <div fxLayout="column" class="textarea-wrapper">
              <app-textarea
                cdkAutosizeMaxRows="5"
                cdkAutosizeMinRows="5"
                class="textarea-2"
                [error]="createOrderForm.get('description').errors?.message"
                placeholder="Dodaj opis"
                formControlName="description"
                ngDefaultControl
              ></app-textarea>
            </div>

            <app-select
              class="second-select"
              [options]="rateTypes"
              [error]="createOrderForm.get('rateType').errors?.message"
              placeholder="Rodzaj stawki"
              formControlName="rateType"
              ngDefaultControl
            ></app-select>
            <app-input
              class="input-second"
              placeholder="Kwota"
              formControlName="salary"
              type="number"
              [error]="createOrderForm.get('salary').errors?.message"
              ngDefaultControl
            ></app-input>
            <app-input
              class="input-second"
              placeholder="Czas pracy"
              formControlName="workTime"
              type="number"
              [error]="createOrderForm.get('workTime').errors?.message"
              ngDefaultControl
            ></app-input>
            <app-select
              class="input-third"
              formControlName="days"
              [error]="createOrderForm.get('days').errors?.message"
              ngDefaultControl
              [options]="days"
              placeholder="Dni"
            ></app-select>
            <!-- <app-input
                        class="input-second margin-input"
                        placeholder="Pożądane umiejętności"
                        formControlName=""
                        ngDefaultControl
                    ></app-input> -->

            <div fxLayout="row" class="chips-container">
              <app-chips
                formControlName="skills"
                [findFunction]="skillsFinder"
                placeholder="Dodaj umiejętność"
                [small]="true"
              ></app-chips>
            </div>
            <div fxLayout="row" class="chips-container-2">
              <app-button-add-picture
                [imagePath]="commonHttpService.getImagePath(thumbnail?.path)"
                (loaded)="imageLoaded($event)"
                [error]="createOrderForm.get('thumbnail').errors?.message"
              ></app-button-add-picture>
            </div>
            <div
              fxLayout="column"
              class="buttons-container"
              fxLayoutAlign="center center"
            >
              <!-- <app-button [color]="ButtonColors.GREY" class="button-grey">
                <span class="button-span">Podgląd</span>
              </app-button> -->
              <app-button
                buttonType="submit"
                [color]="ButtonColors.PRIMARY"
                class="button-yellow"
              >
                <span class="button-span">{{mode === 'add' ? 'Utwórz' : 'Zapisz'}}</span>
              </app-button>
            </div>
          </div>
        </form>
      </div>
  </rs-mobile>
</responsive-switcher>
</div>
<div *ngIf="loading">
  <mat-progress-spinner></mat-progress-spinner>
</div>
