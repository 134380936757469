import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MessengerService} from '@src/app/modules/messenger-services/messenger.service';
import {OffersResponseDTO, OffersService} from '@src/app/modules/sp-services/offers.service';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import {AuthService} from '@replica-frontend/auth';
@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})

export class FavoritesComponent implements OnInit {
  @Input()
  public color: BUTTON_COLORS = BUTTON_COLORS.PRIMARY;
  filters = new FormControl();
  filtersList: string[] = ['Data dodania', 'Nazwa', 'Miejscowość'];
  @Input()
  buttonType: string;
  favorite = true;
  public ButtonColors = BUTTON_COLORS;
  id: number;

  favorites: OffersResponseDTO[] = [];
  likes: OffersResponseDTO[] = [];
  dislikes: OffersResponseDTO[] = [];

  // loadSub: Subscription;
  constructor(public messengerService: MessengerService, public authService: AuthService, public offersService: OffersService) {

  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    Promise.all([
      this.offersService.getFavorites({ limit: 10, offset: 0, filterBy: {} }).toPromise(),
      this.offersService.getLike({ limit: 10, offset: 0, filterBy: {} }).toPromise(),
      this.offersService.getDislike({ limit: 10, offset: 0, filterBy: {} }).toPromise()])
      .then(([favorites, likes, dislikes]) => {
        this.favorites = favorites;
        this.likes = likes;
        this.dislikes = dislikes;
      });
  }

  changed(): void {
    this.load();
  }
}
