import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Option } from '../../modules/common-components';

@Component({
  selector: 'app-topbar-logged-mobile',
  templateUrl: './topbar-logged-mobile.component.html',
  styleUrls: ['./topbar-logged-mobile.component.scss']
})
export class TopbarLoggedMobileComponent implements OnInit, OnDestroy {
  onTouched: () => void;
  opened = false;
  options = [
    {
      label: 'Wykonawca',
      value: '',
    },
    {
      label: 'Zleceniodawca',
      value: '',
    }
  ];
  selected: Option = this.options[0];
  routeSub: Subscription;
  label: string;
  optionsVisible = false;
  constructor(private route: ActivatedRoute, private router: Router) { }
  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.setRouteValues(this.route.snapshot.firstChild.data);
    this.routeSub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      ).subscribe((data) => {
        this.setRouteValues(data.data);
      });
  }

  setRouteValues(data): void {
    this.label = data.title;
    if (data.side) {
      this.optionsVisible = true;
    } else {
      this.optionsVisible = false;
    }
  }

  toggleOpen($event): void {
    if (this.optionsVisible) {
      this.opened = !this.opened;
      $event.stopPropagation();
    }
  }
  changeLabel(option) {
    this.selected = option;
  }
}
