<responsive-switcher>
  <rs-desktop>
    <div class="container-main">
        <div class="label-wrapper-container">
          <div class="label-inner-wrapper-container">
            <div class="label-wrapper">
              <button
                fxLayoutAlign="start center"
                mat-icon-button
                fxFlex
                (click)="back()"
                matTooltip="Cofnij"
                matTooltipClass="tooltip"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
              <span
                fxLayoutAlign="center center"
                class="create-label"
                fxFlex="100"
              >
                Zaloguj się</span
              >
            </div>

            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="grey-container"
            >
              <div
                fxFlex="60"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="buttons"
              >
                <app-button-socialmedia
                  [color]="ButtonColors.WHITE"
                  class="facebook-button"
                  fxFlex="50"
                  (click)="loginSocial($event, 'facebook')"
                  matTooltip="Połącz przez Facebooka"
                  matTooltipClass="tooltip"
                >
                  <div fxLayout="row">
                    <a class="facebook-icon-2"></a>
                    <span class="facebook-span">Połącz przez Facebooka</span>
                  </div>
                </app-button-socialmedia>

                <app-button-socialmedia
                  [color]="ButtonColors.WHITE"
                  class="google-button"
                  fxFlex="50"
                  (click)="loginSocial($event, 'google')"
                  matTooltip="Połącz przez Google"
                  matTooltipClass="tooltip"
                >
                  <div fxLayout="row">
                    <a class="google-icon-2"></a>
                    <span class="google-span">Połącz przez Google</span>
                  </div>
                </app-button-socialmedia>
              </div>

              <span class="label-in-container"
                >lub zaloguj za pomocą adresu email</span
              >

              <form
                fxLayout="column"
                fxLayoutAlign="space-between center"
                [formGroup]="loginForm"
                (ngSubmit)="submit()"
                class="login-forms"
              >
                <div fxLayout="column" class="inputs-container">
                  <p *ngIf="badEmail || badPassword" class="error-password">
                    Niepoprawny email lub hasło
                  </p>
                  <app-input
                    class="first-input"
                    formControlName="email"
                    ngDefaultControl
                    placeholder="Adres e-mail"
                  ></app-input>

                  <app-input
                    formControlName="password"
                    type="password"
                    ngDefaultControl
                    placeholder="Hasło"
                    class="password-input"
                  ></app-input>
                </div>
                <div class="button-signin">
                  <app-button
                    [color]="ButtonColors.PRIMARY"
                    buttonType="submit"
                    [routerLink]="['/', 'sign-in']"
                    tooltip="Zaloguj się"
                  >
                    <span> Zaloguj się </span>
                  </app-button>
                </div>
                <div class="forgot-password">
                  <span routerLink="/forgot-password"> Zapomniałem hasła </span>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  </rs-desktop>

  <rs-mobile>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center" class="label-wrapper">
          <button mat-icon-button (click)="back()" class="back-button">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span class="create-label"> Zaloguj się</span>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="grey-container"
        >
          <app-button-socialmedia
            [color]="ButtonColors.WHITE"
            class="facebook-button"
            fxFlex="30"
            (click)="loginSocial($event, 'facebook')"
          >
            <div fxLayout="row">
              <a class="facebook-icon-2"></a>
              <span class="facebook-span">Połącz przez Facebooka</span>
            </div>
          </app-button-socialmedia>
          <app-button-socialmedia
            [color]="ButtonColors.WHITE"
            class="google-button"
            fxFlex="30"
            (click)="loginSocial($event, 'google')"
          >
            <div fxLayout="row">
              <a class="google-icon-2"></a>
              <span class="google-span">Połącz przez Google</span>
            </div>
          </app-button-socialmedia>
          <span class="label-in-container"
            >lub dołącz za pośrednictwem adresu e-mail</span
          >

          <form
            fxLayout="column"
            fxLayoutAlign="space-between center"
            [formGroup]="loginForm"
            (ngSubmit)="submit()"
          >
            <div fxLayout="column" class="inputs-container">
              <p *ngIf="badEmail || badPassword" class="error-mobile">
                Niepoprawny email lub hasło
              </p>
              <app-input
                class="first-input"
                formControlName="email"
                ngDefaultControl
                placeholder="Adres e-mail"
              ></app-input>
              <app-input
                class="second-input"
                formControlName="password"
                ngDefaultControl
                type="password"
                placeholder="Hasło"
              ></app-input>
            </div>
            <span class="forgot-password"> </span>
            <div class="button-zaloguj">
              <app-button
                [color]="ButtonColors.PRIMARY"
                buttonType="submit"
                [routerLink]="['/', 'sign-in']"
              >
                <span> Zaloguj się </span>
              </app-button>
            </div>
            <div class="forgot-password">
              <span routerLink="/forgot-password"> Zapomniałem hasła </span>
            </div>
          </form>
        </div>
      </div>
  </rs-mobile>
</responsive-switcher>
