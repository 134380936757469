<responsive-switcher>
  <rs-desktop>
    <div class="container">
      <div fxLayout="column" class="container">
        <div *ngIf="!!error" class="error-wrapper">
          <span class="error-message">{{ error }}</span>
        </div>
        <div class="input-wrapper" fxLayout="row" fxFlex="100">
          <textarea
            matInput
            [ngClass]="{ error: !!error }"
            [placeholder]="placeholder"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            [style.min-height]="minHeight"
            [style.max-height]="maxHeight"
            [cdkAutosizeMinRows]="cdkAutosizeMinRows"
            [cdkAutosizeMaxRows]="cdkAutosizeMaxRows"
            [(ngModel)]="value"
            (ngModelChange)="updateChanges()"
          >
          </textarea>
        </div>
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div
      fxLayout="column"
      [ngClass]="{ 'container-error': !!error, container: !!!error }"
    >
      <div *ngIf="!!error" class="error-wrapper">
        <span *ngIf="!!error" class="error-message">{{ error }}</span>
      </div>
      <textarea
        matInput
        [placeholder]="placeholder"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        [style.min-height]="minHeight"
        [style.max-height]="maxHeight"
        [cdkAutosizeMinRows]="cdkAutosizeMinRows"
        [cdkAutosizeMaxRows]="cdkAutosizeMaxRows"
        [(ngModel)]="value"
        (ngModelChange)="updateChanges()"
        [ngClass]="{'error': !!error}"
      ></textarea>
    </div>
  </rs-mobile>
</responsive-switcher>
