import {Component} from '@angular/core';
import {BUTTON_COLORS} from '../../../modules/common-components/button/button.component';

@Component({
  selector: 'app-regulations',
  templateUrl: './regulations.component.html',
  styleUrls: ['./regulations.component.scss']
})
export class RegulationsComponent {
  ButtonColors = BUTTON_COLORS;
  title = 'Szybkapraca';
  options = [{
    name: 'Regulamin',
    link: ''
  }, {
    name: 'Polityka prywatności',
    link: ''
  }];
}
