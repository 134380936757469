import { Location } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, UserData } from '@replica-frontend/auth';
import { CommonHttpService } from '@src/app/modules/common-services';
import { DefaultFilters } from '@src/app/modules/common-services/default-filters.interface';
import { Profile, ProfileService } from '@src/app/modules/sp-services/profile.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { OffersRequestDTO, OffersResponseDTO, OffersService } from '../../../modules/sp-services/offers.service';
@Component({
  selector: 'app-contractor-profile',
  templateUrl: './contractor-profile.component.html',
  styleUrls: ['./contractor-profile.component.scss']
})
export class ContractorProfileComponent implements OnInit, OnDestroy {
  ButtonColors = BUTTON_COLORS;
  isWelcome = true;
  editMode = false;
  length = 32;
  pageSize = 2;
  pageSizeOptions: number[] = [2, 4, 6, 8];
  id: string;
  selected = null;
  panelOpenState = false;
  getEndedOffers = this.offersService.getEndedOffers;
  mine = false;
  friend = false;
  profile: Profile = null;
  public userData: UserData;
  offers: Array<OffersResponseDTO> = [];
  offers$: Observable<Array<OffersResponseDTO>> = this.offersService.mine({
    limit: 10,
    offset: 0,
    filterBy: {}
  });
  education;
  @Output()
  sortChanged = new EventEmitter<{ field: string, sort: string }>();
  authSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public authService: AuthService,
    public offersService: OffersService,
    private profileService: ProfileService,
    public commonHttpService: CommonHttpService,
    
  ) {

  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id || this.authService.me.id;
    forkJoin([
      this.authService.getUsersByIds([this.id]),
      this.profileService.getProfileByUserId(this.id || this.authService.me.id)])
        .subscribe(([user, profile]) => {
          this.userData = user[0];
          this.profile = profile;
          if (profile) {
            this.education = JSON.parse(profile.education);
          }
          this.mine = this.authService.me && (!this.id || this.id === this.authService.me.id);
        });
  }

  load(filters: DefaultFilters<OffersRequestDTO>): void {
    this.offersService.getOffers(filters).subscribe((offers) => {
      this.offers = offers;
    });
  }
  changed(): void {
    this.sortChanged.emit({ field: this.selected, sort: 'ASC' });

  }
  back(): void {
    this.location.back();
  }

  getPhone(phone: string): string {
    return `tel:${phone}`;
  }

  offersLoaded(offers) {
    this.offers = offers;
  }

  ngOnDestroy(): void {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
