<responsive-switcher>
  <rs-desktop>
    <mat-toolbar fxLayout="row" fxLayoutAlign="center" fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="start center" class="logo-wrapper">
        <button
          mat-button
          [routerLink]="['/']"
          class="form-logo"
          matTooltip="Strona główna"
          matTooltipClass="tooltip"
        >
          <a class="logo"> </a>
          <span>szybkapraca.pl</span>
        </button>
      </div>
      <div fxLayout="row" fxFlex="30" fxLayoutAlign="start center">
        <button
          *ngIf="!maintenance"
          mat-button
          class="label-menu"
          [routerLink]="['/', 'static', 'how-it-works']"
          matTooltip="Jak to działa?"
          matTooltipClass="tooltip"
        >
          Jak to działa?
        </button>
      </div>

      <div fxLayout="row" fxFlex="50" fxLayoutAlign="end center">
        <button
          *ngIf="!maintenance"
          mat-button
          class="button-left"
          routerLink="/sign-in"
          matTooltip="Zaloguj się"
          matTooltipClass="tooltip"
        >
          <a class="person-icon"></a>
          <span class="zaloguj-sie">Zaloguj się</span>
        </button>
        <app-button
          tooltip="Załóż konto"
          *ngIf="!maintenance"
          [color]="ButtonColors.PRIMARY"
          class="button-right"
          [routerLink]="['/', 'create-account']"
        >
          <span>Załóż konto</span>
        </app-button>
      </div>
    </mat-toolbar>
  </rs-desktop>

  <rs-mobile>
    <mat-toolbar
      class="mobile-topbar-wrapper"
      fxLayout="row"
      fxLayoutAlign="center"
      fxFlex="100"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="80">
        <button mat-button routerLink="/" class="form-logo">
          <a class="logo"> </a>
          <span>szybkapraca.pl</span>
        </button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
        <button mat-button>
          <a class="burger-icon"></a>
        </button>
      </div>
    </mat-toolbar>
  </rs-mobile>
</responsive-switcher>
