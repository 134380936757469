import { DeviceDetectorService } from './device-detector.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import * as io from 'socket.io-client';
import {EnvironmentService} from './environment.service';
import { AuthService, AuthEvents } from '@replica-frontend/auth';

export enum CONNECTION_STATES {
  CONNECTING, ONLINE, OFFLINE, RECONNECTING, ERROR
}

export class SocketException extends Error {
  constructor(public message: string) {
    super(message);
    this.name = 'SocketException';
    this.stack = (<any> new Error()).stack;
  }
}

@Injectable()
export class SocketService {
  connection$ = new BehaviorSubject<CONNECTION_STATES>(CONNECTION_STATES.CONNECTING);
  private topicSockets: Map<string, { socket: any, events: Map<string, Subject<any>> }> =
    new Map<string, { socket: any, events: Map<string, Subject<any>> }>();
  errors = new Subject<any>();
  errors$ = this.errors.asObservable();
  logOutSub: Subscription;

  constructor(
    private authService: AuthService,
    private environmentService: EnvironmentService,
    private deviceDetectorService: DeviceDetectorService) {
    if (deviceDetectorService.isServer()) {
      return;
    }
  }

  listen<T>(namespace: string, event: string): Observable<T> {
    if (this.topicSockets.size === 0) {
      this.logOutSub = this.authService.authEvents$.subscribe((authEvents: AuthEvents) => {
        if (authEvents === AuthEvents.onLogout) {
          this.disconnect();
        }
      });
    }
    const options = {
      query: null,
      transports: [
        'websocket'
      ]
    };
    if (this.authService.storage) {
      options.query = `token=${this.authService.storage.access_token}`;
    }
    if (!this.topicSockets.get(namespace)) {
      this.topicSockets.set(namespace, {
        socket: io(`${this.environmentService.getEnv('wsUrl')}:${this.environmentService.getEnv('wsPort')}/${namespace}`, options),
        events: new Map<string, Subject<any>>([
            [event, new Subject<T>()]
        ])
      });
    } else {
      this.topicSockets.get(namespace).events.set(event,new Subject<T>());
    }
    const rSubject = this.topicSockets.get(namespace).events.get(event);
    this.topicSockets.get(namespace).socket.on(event, (data) => {
      this.topicSockets.get(namespace).events.get(event).next(data);
    });
    this.topicSockets.get(namespace).socket.on('connect', (error) => {
      this.connection$.next(CONNECTION_STATES.ONLINE);
    });
    this.topicSockets.get(namespace).socket.on('error', (error) => {
      this.topicSockets.get(namespace).events.get(event).error(new SocketException(error));
      console.error(namespace + ': ' + error);
    });
    this.topicSockets.get(namespace).socket.on('reconnect_attempt', (error) => {
      this.topicSockets.get(namespace).events.get(event);
      console.error(namespace + ': ' + error);
      this.connection$.next(CONNECTION_STATES.RECONNECTING);
    });
    return rSubject.asObservable();
  }

  emit(namespace: string, event: string, data: any): void {
    // check connection and manage queue
    if (!this.topicSockets.get(namespace)) {
      throw new Error(`no subscriptions for ${this.topicSockets}`);
    }
    this.topicSockets.get(namespace).socket.emit(event, data);
  }

  disconnect(): void {
    for (const  [key, value] of this.topicSockets) {
      value.socket.disconnect();
      // value.events.forEach((event) => {
      //   event.unsubscribe();
      // })
    }
    if (this.logOutSub) {
      this.logOutSub.unsubscribe();
    }
    this.topicSockets = new Map<string, { socket: any, events: Map<string, Subject<any>> }>();
    this.connection$.next(CONNECTION_STATES.OFFLINE);
  }
}
