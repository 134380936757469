import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar
} from '@angular/material/snack-bar';
import { AuthEvents, AuthService } from '@replica-frontend/auth';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from '../../modules/common-services';
import {
  CONNECTION_STATES, SocketService
} from '../../modules/common-services/socket.service';
import { MessengerService } from '../../modules/messenger-services/messenger.service';
import { AppliesSyncService } from '../../modules/sp-services';

@Component({
  selector: 'app-security-desktop-wrapper',
  templateUrl: './security-wrapper-desktop.component.html',
  styleUrls: ['./security-wrapper-desktop.component.scss'],
})
export class SecurityWrapperDesktopComponent implements OnInit, OnDestroy {
  snackBarRef: MatSnackBarRef<TextOnlySnackBar>;
  authServiceSub: Subscription;
  logged = false;
  constructor(
    private appliesSyncService: AppliesSyncService,
    private authService: AuthService,
    private deviceDetectorService: DeviceDetectorService,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private messengerService: MessengerService,
  ) {}
  ngOnInit(): void {
    if (this.deviceDetectorService.isServer()) {
      return;
    }
    if (this.authService.me) {
      this.logged = true;
      this.initServices();
    } else {
      this.authServiceSub = this.authService.authEvents$.subscribe((authEvent: AuthEvents) => {
        if (authEvent === AuthEvents.loading) {

        }
        if (authEvent === AuthEvents.onLogged) {
          this.logged = true;
          this.initServices();
        }
        if (authEvent === AuthEvents.onLogout) {
            //TODO: investigate why no refresh 
            this.logged = false;
        }
      });
    }

    // if (this.authService.hasMe === false) {
    //   this.logout();
    // }
    this.socketService.connection$.subscribe((connected: CONNECTION_STATES) => {
      if (connected === CONNECTION_STATES.RECONNECTING) {
        if (!this.snackBarRef) {
          this.snackBarRef = this.snackBar.open(
            'Utracono połączenie. Ponawianie...'
          );
        }
      } else if (connected === CONNECTION_STATES.ONLINE) {
        if (this.snackBarRef) {
          this.snackBarRef.dismiss();
        }
      } else if (connected === CONNECTION_STATES.ERROR) {
        this.snackBarRef = this.snackBar.open(
          'Wystąpił błąd połączenia',
          'Odśwież'
        );
        this.snackBarRef.onAction().subscribe(() => location.reload());
      }
    });
  }

  initServices(): void {
    this.appliesSyncService.init();
    this.messengerService.init();
  }

  ngOnDestroy(): void {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }
}
