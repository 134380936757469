<responsive-switcher>
  <rs-desktop>
    <footer fxLayout="column">
      <div fxLayout="row">
        <div fxLayout="row" fxLayoutAlign="start" fxFlex>
          <div fxLayout="column">
            <div fxLayout="row" [routerLink]="['/']">
              <a href="/" class="logo"> </a>
              <span class="logo-span">szybkapraca.pl</span>
            </div>

            <div fxLayout="row" class="icons">
              <a
                class="facebook-icon"
                matTooltip="Facebook"
                matTooltipClass="tooltip"
              ></a>
              <a
                class="instagram-icon"
                matTooltip="Instagram"
                matTooltipClass="tooltip"
              ></a>
              <a
                class="twitter-icon"
                matTooltip="Twitter"
                matTooltipClass="tooltip"
              ></a>
            </div>
          </div>
        </div>

        <div fxLayout="column" class="columns" *ngIf="!maintenance">
          <div class="labels" [routerLink]="['/', 'sign-in']">Działanie</div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">
            Ogłoszenia
          </div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">
            Dodaj ogłoszenie
          </div>
          <div
            class="label-list"
            [routerLink]="['/', 'static', 'how-it-works']"
          >
            Jak to działa?
          </div>
        </div>
        <div fxLayout="column" class="columns" *ngIf="!maintenance">
          <div class="labels" [routerLink]="['/', 'sign-in']">Ustawienia</div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">
            Moje konto
          </div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">Ulubione</div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">
            Moje ogłoszenias
          </div>
        </div>
        <div fxLayout="column" class="columns" *ngIf="!maintenance">
          <div class="labels">Szybkapraca.pl</div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">Kontakt</div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">O nas</div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">Pomoc</div>
        </div>
        <div fxLayout="column" class="columns" *ngIf="!maintenance">
          <div class="labels">Zasady</div>
          <div class="label-list" [routerLink]="['/', 'static', 'faq']">
            FAQ
          </div>
          <div
            class="label-list"
            routerLink="/regulations"
            [routerLink]="['/', 'static', 'regulations']"
          >
            Regulamin
          </div>
          <div class="label-list" [routerLink]="['/', 'sign-in']">
            Polityka prywatności
          </div>
        </div>
        <div fxLayout="column" class="columns">
          <div class="labels">Pobierz aplikacje</div>
          <a
            class="play-store"
            matTooltip="Android"
            matTooltipClass="tooltip"
          ></a>
          <a class="app-store" matTooltip="Ios" matTooltipClass="tooltip"></a>
        </div>
        <div></div>
      </div>

      <hr class="divider" />

      <span class="klauzula">
        © 2020 szybkapraca.pl | Wszelkie prawa zastrzeżone
      </span>
    </footer>
  </rs-desktop>

  <rs-mobile>
    <footer fxLayout="column" class="footer-container">
      <div fxLayout="row">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxFlex="100"
          class="first-label"
        >
          <div fxLayout="row" fxFlex="60" fxLayoutAlign="start center">
            <a [routerLink]="['/']" class="logo"> </a>
            <span fxLayoutAlign="center center" class="logo-span"
              >szybkapraca.pl</span
            >
          </div>

          <div
            fxFlex="40"
            fxLayout="row"
            class="icons"
            fxLayoutAlign="end center"
          >
            <a class="facebook-icon"></a>
            <a class="instagram-icon"></a>
            <a class="twitter-icon"></a>
          </div>
        </div>
      </div>
      <!-- <div
        class="footer-content"
        fxLayout="row"
        fxFlex="100"
        fxLayoutAlign="center start"
        fxLayoutGap="30px"
      >
        <div fxLayout="column" fxFlex="40" fxLayoutAlign="center start">
          <div class="labels" [routerLink]="['/']">Zasady</div>
          <div class="label-list" [routerLink]="['/', 'static', 'faq']">FAQ</div>
          <div class="label-list" [routerLink]="['/', 'static', 'regulations']">Regulamin</div>
          <div class="label-list" [routerLink]="['/']">Polityka prywatności</div>
        </div>
        <div fxLayout="column" fxFlex="40" fxLayoutAlign="center start">
          <div class="labels" [routerLink]="['/']">Szybkapraca.pl</div>
          <div class="label-list" [routerLink]="['/']">Kontakt</div>
          <div class="label-list" [routerLink]="['/']">O nas</div>
          <div class="label-list" [routerLink]="['/']">Pomoc</div>
        </div>
      </div> -->
      <div
        fxLayout="row"
        fxFlex="100"
        fxLayoutAlign="center center"
        class="bottom-content"
      >
        <div class="labels" fxFlex="90">Pobierz aplikacje</div>
      </div>
      <div
        fxLayout="row"
        fxFlex="100"
        fxLayoutAlign="center"
        class="bottom-content-2"
      >
        <a fxFlex="50" fxLayoutAlign="center center" class="play-store"></a>
        <a fxFlex="50" fxLayoutAlign="center center" class="app-store"></a>
      </div>
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
        <span class="klauzula">
          © 2020 szybkapraca.pl | Wszelkie prawa zastrzeżone
        </span>
      </div>
    </footer>
  </rs-mobile>
</responsive-switcher>
