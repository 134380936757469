import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-mobile',
  templateUrl: './input-mobile.component.html',
  styleUrls: ['./input-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputMobileComponent),
    multi: true
  }]
})
export class InputMobileComponent implements ControlValueAccessor, OnInit {
  @Input()
  placeholder = '';
  @Input()
  type = '';
  @Input()
  error: string = null;
  @Input()
  numberOnly = false;
  @Input()
  centerLabel = false;
  @Input()
  disabled: boolean;
  @Input()
  value: any;

  ngOnInit(): void {
    if (this.error) {
    console.log(this.error); 
    }
  }

  onTouched = () => {};
  updateChanges(): void {
    this.onChange(this.value);
  }
  writeValue(value: any): void {
    this.value = value;
    if (value !== null) {
      this.updateChanges();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange($event: any): void {
    // input mask
    const searchValue = $event.target.value;
    if (this.type === 'number') {
      this.value = searchValue
        .replace(',', '.');
    }
    // console.log(searchValue);
  }
}
