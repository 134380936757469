import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {MenuService, IconModes} from '../../modules/sp-services';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './app-footer-mobile.component.html',
  styleUrls: ['./app-footer-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFooterMobileComponent implements OnInit {
  public options$ = this.menuService.menu$;
  public iconMode$ = this.menuService.iconMode$;
  IconModes = IconModes;
  active = false;
  constructor(private menuService: MenuService) { }

  ngOnInit(): void {
  }

}
