<div fxLayout="row" class="offers-list-container">
  <div *ngIf="offers.length !== 0" class="horizontal-list-wrapper">
    <app-offer-small-card-logged
      *ngFor="let offer of offers"
      [offer]="offer"
      class="offer-item"
    ></app-offer-small-card-logged>
  </div>
</div>
<div *ngIf="offers.length === 0" fxLayout="row" class="no-offers-list-container">
  <span class="no-offers">Brak ofert</span>
</div>
