<responsive-switcher>
  <rs-desktop>
      <app-offer-list
      titleLabel="Twój katalog"
        queryMethod="mine"
      ></app-offer-list>
      <!-- <mat-tab-group mat-align-tabs="start" disablePagination="true">
        <mat-tab label="Wszystkie" class="span-tab-active">
          <div fxLayout="row"  class="list-container">



            </div>
        </mat-tab>
        <mat-tab label="Oczekujące" class="margin-span-tabs span-tab-inactive">
          <div fxLayout="column" class="list-container"></div>
        </mat-tab>
        <mat-tab label="Aktywne" class="span-tab-inactive margin-span-tabs">
          <div fxLayout="column" class="list-container"></div>
        </mat-tab>
        <mat-tab label="Archiwum" class="margin-span-tabs span-tab-inactive">
          <div fxLayout="column" class="list-container"></div>
        </mat-tab>
      </mat-tab-group> -->
  </rs-desktop>
  <rs-mobile>
      <app-offer-list
      filterLabel="Twój katalog"
        queryMethod="mine"
      ></app-offer-list>
  </rs-mobile>
</responsive-switcher>
