import { NgModule } from '@angular/core';
import { Route, Router, RouterModule } from '@angular/router';
import { AdminWrapperComponent } from './admin/wrapper/admin-wrapper.component';
import { DeviceDetectorService } from '@src/app/modules/common-services';
import { CatalogComponent } from '@src/app/pages/desktop/catalog/catalog.component';
import { ContractorProfileComponent } from '@src/app/pages/desktop/contractor-profile/contractor-profile.component';
import { CreateEmployeeAccountComponent } from '@src/app/pages/desktop/create-employee-account/create-employee-account.component';
import { EditContractorProfileComponent } from '@src/app/pages/desktop/edit-contractor-profile/edit-contractor-profile.component';
import { FaqComponent } from '@src/app/pages/desktop/faq/faq.component';
import { FavoritesComponent } from '@src/app/pages/desktop/favorites/favorites.component';
import { LoggedOptionsComponent } from '@src/app/pages/desktop/logged-options/logged-options.component';
import { DesktopExtWrapperComponent } from '@src/app/wrappers/desktop-ext-wrapper/desktop-ext-wrapper.component';
import { LoggedAuthGuard } from './modules/common-services/logged-auth.guard';
import { MaintenanceGuard } from './modules/common-services/maintenance.guard';
import { MaintenanceComponent } from './modules/sp-components/pages/maintenance/maintenance.component';
import { SpVersionComponent } from './modules/sp-components/sp-version/sp-version.component';
import { ConfirmEmailSuccessfulComponent } from './pages/desktop/confirm-email-successful/confirm-email-successful.component';
import { CreateAccountSuccessfulComponent } from './pages/desktop/create-account-successful/create-account-successful.component';
import { CreateAccountComponent } from './pages/desktop/create-account/create-account.component';
import { CreateContractorAccountComponent } from './pages/desktop/create-contractor-account/create-contractor-account.component';
import { CreateOfferComponent } from './pages/desktop/create-offer/create-offer.component';
import { DashboardComponent } from './pages/desktop/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './pages/desktop/forgot-password/forgot-password.component';
import { HomeSearchResultsComponent } from './pages/desktop/home-search-results/home-search-results.component';
import { HomeComponent } from './pages/desktop/home/home.component';
import { HowItWorksComponent } from './pages/desktop/how-it-works/how-it-works.component';
import { InviteCreatePasswordComponent } from './pages/desktop/invite-create-password/invite-create-password.component';
import { NewPasswordFormComponent } from './pages/desktop/new-password-form/new-password-form.component';
import { RegulationsComponent } from './pages/desktop/regulations/regulations.component';
import { SignInComponent } from './pages/desktop/sign-in/sign-in.component';
import { SingleOfferComponent } from './pages/desktop/single-offer/single-offer.component';
import { MobileExtWrapperComponent } from './wrappers/mobile-ext-wrapper/mobile-ext-wrapper.component';
import { SecurityWrapperDesktopComponent } from './wrappers/security-desktop-wrapper/security-wrapper-desktop.component';
import { SecurityWrapperMobileComponent } from './wrappers/security-mobile-wrapper/security-wrapper-mobile.component';

const desktopRoutes: Route = {
  path: '',
  component: SecurityWrapperDesktopComponent,
  children: [
    {
      path: '',
      component: HomeComponent,
      data: { title: 'Strona główna' },
      pathMatch: 'full',
    },
    {
      path: 'maintenance',
      component: MaintenanceComponent,
    },
    {
      path: 'search',
      data: { title: 'Wyniki wyszukiwania' },
      component: HomeSearchResultsComponent,
      pathMatch: 'full',
    },
    {
      path: 'search',
      data: { title: 'Wyniki wyszukiwania' },
      component: HomeSearchResultsComponent,
      pathMatch: 'full',
    },
    {
      path: 'offer/:slug',
      component: SingleOfferComponent,
      data: { title: 'Oferta' },
      canActivate: [MaintenanceGuard],
    },
    {
      component: CreateContractorAccountComponent,
      path: 'create-contractor-account',
      canActivate: [MaintenanceGuard],
    },
    {
      component: CreateEmployeeAccountComponent,
      path: 'create-employee-account',
      canActivate: [MaintenanceGuard],
    },
    {
      component: CreateAccountSuccessfulComponent,
      path: 'create-account-successful',
      canActivate: [MaintenanceGuard],
    },
    {
      component: InviteCreatePasswordComponent,
      path: 'invite-create-password/:token',
      canActivate: [MaintenanceGuard],
    },
    {
      component: ConfirmEmailSuccessfulComponent,
      path: 'confirm-email/:token',
      canActivate: [MaintenanceGuard],
    },
    {
      component: CreateAccountComponent,
      path: 'create-account',
      canActivate: [MaintenanceGuard],
    },
    {
      component: SignInComponent,
      path: 'sign-in',
      canActivate: [MaintenanceGuard],
    },
    {
      path: 'forgot-password',
      component: ForgotPasswordComponent,
      canActivate: [MaintenanceGuard],
    },
    {
      path: 'new-password/:token',
      component: NewPasswordFormComponent,
      canActivate: [MaintenanceGuard],
    },
    {
      component: RegulationsComponent,
      path: 'static/regulations',
      canActivate: [MaintenanceGuard],
    },
    {
      component: FaqComponent,
      path: 'static/faq',
      canActivate: [MaintenanceGuard],
    },
    {
      component: ContractorProfileComponent,
      path: 'contractor-profile/:id',
      data: { title: 'Profil' },
    },
    {
      component: FaqComponent,
      path: 'static/privacy-policy',
      canActivate: [MaintenanceGuard],
    },
    {
      component: HowItWorksComponent,
      path: 'static/how-it-works',
      canActivate: [MaintenanceGuard],
    },
    {
      path: 'app',
      canActivate: [LoggedAuthGuard, MaintenanceGuard],
      children: [
        { path: '', redirectTo: 'offers-for-employee', pathMatch: 'full' },
        {
          path: 'admin',
          component: AdminWrapperComponent,
          children: [
            {
              path: '',
              loadChildren: () =>
                import('@replica-frontend/admin').then((m) => m.AdminModule),
            },
          ],
        },
        {
          path: 'offers-for-employee',
          component: DashboardComponent,
          data: { side: 'employee', title: 'Wykonawca' },
        },
        {
          path: 'offers-for-contractor',
          component: DashboardComponent,
          data: { side: 'contractor', title: 'Zleceniodawca' },
        },
        {
          path: 'offer/:slug',
          component: SingleOfferComponent,
          data: { title: 'Oferta' },
        },
        {
          component: CreateOfferComponent,
          path: 'create-offer',
          data: { title: 'Utwórz ofertę' },
        },
        {
          component: CreateOfferComponent,
          path: 'edit-offer/:slug',
          data: { title: 'Edytuj ofertę' },
        },
        {
          component: ContractorProfileComponent,
          path: 'contractor-profile',
          data: { title: 'Profil' },
        },
        {
          component: EditContractorProfileComponent,
          path: 'edit-contractor-profile',
          data: { title: 'Edycja profilu' },
        },
        {
          component: CatalogComponent,
          path: 'catalog',
          data: { title: 'Katalog' },
        },
        {
          component: FavoritesComponent,
          path: 'favorites',
          data: { title: 'Ulubione' },
        },
        {
          component: HowItWorksComponent,
          path: 'static/how-it-works',
          data: { title: 'Jak to działa' },
        },
        {
          component: RegulationsComponent,
          path: 'static/regulations',
          data: { title: 'Regulacje' },
        },
        {
          component: FaqComponent,
          path: 'static/faq',
          data: { title: 'Faq' },
        },
        {
          component: LoggedOptionsComponent,
          path: 'logged-options',
          data: { title: 'Profil' },
        },
      ],
    },
    {
      component: SpVersionComponent,
      path: 'version',
    },
  ],
};

const mobileRoutes: Route = {
  path: '',
  component: SecurityWrapperMobileComponent,
  children: [
    {
      path: '',
      data: { title: 'Strona główna' },
      component: HomeComponent,
      pathMatch: 'full',
    },
    {
      path: 'search',
      data: { title: 'Wyniki wyszukiwania' },
      component: HomeSearchResultsComponent,
      pathMatch: 'full',
    },
    {
      path: 'maintenance',
      data: { title: 'Maintenance' },
      component: MaintenanceComponent,
    },
    {
      path: 'offer/:slug',
      component: SingleOfferComponent,
      data: { title: 'Oferta' },
      canActivate: [MaintenanceGuard],
    },
    {
      component: ContractorProfileComponent,
      path: 'contractor-profile/:id',
      data: { title: 'Profil' },
    },
    {
      component: CreateContractorAccountComponent,
      path: 'create-contractor-account',
      canActivate: [MaintenanceGuard],
    },
    {
      component: CreateEmployeeAccountComponent,
      path: 'create-employee-account',
      canActivate: [MaintenanceGuard],
    },
    {
      component: CreateAccountSuccessfulComponent,
      path: 'create-account-successful',
      canActivate: [MaintenanceGuard],
    },
    {
      component: InviteCreatePasswordComponent,
      path: 'invite-create-password/:token',
      canActivate: [MaintenanceGuard],
    },
    {
      component: ConfirmEmailSuccessfulComponent,
      path: 'confirm-email/:token',
      canActivate: [MaintenanceGuard],
    },
    {
      component: CreateAccountComponent,
      path: 'create-account',
      canActivate: [MaintenanceGuard],
    },
    {
      component: SignInComponent,
      path: 'sign-in',
      canActivate: [MaintenanceGuard],
    },
    {
      path: 'forgot-password',
      component: ForgotPasswordComponent,
      canActivate: [MaintenanceGuard],
    },
    {
      path: 'new-password/:token',
      component: NewPasswordFormComponent,
      canActivate: [MaintenanceGuard],
    },
    {
      component: RegulationsComponent,
      path: 'static/regulations',
      canActivate: [MaintenanceGuard],
    },
    {
      component: FaqComponent,
      path: 'static/faq',
      canActivate: [MaintenanceGuard],
    },
    {
      component: FaqComponent,
      path: 'static/privacy-policy',
      canActivate: [MaintenanceGuard],
    },
    {
      component: HowItWorksComponent,
      path: 'static/how-it-works',
      canActivate: [MaintenanceGuard],
    },
    {
      component: SpVersionComponent,
      path: 'version',
    },
    {
      path: 'app',
      canActivate: [LoggedAuthGuard, MaintenanceGuard],
      children: [
        { path: '', redirectTo: 'offers-for-employee', pathMatch: 'full' },
        {
          path: 'offers-for-employee',
          component: DashboardComponent,
          data: { side: 'employee', title: 'Wykonawca' },
        },
        {
          path: 'offers-for-contractor',
          component: DashboardComponent,
          data: { side: 'contractor', title: 'Zleceniodawca' },
        },
        {
          component: CreateOfferComponent,
          path: 'create-offer',
          data: { title: 'Zleceniodawca' },
        },
        {
          component: CreateOfferComponent,
          path: 'edit-offer/:slug',
          data: { title: 'Zleceniodawca' },
        },
        {
          component: ContractorProfileComponent,
          path: 'contractor-profile',
          data: { title: 'Profil' },
        },

        {
          component: EditContractorProfileComponent,
          path: 'edit-contractor-profile',
          data: { title: 'Edycja profilu' },
        },
        {
          component: CatalogComponent,
          path: 'catalog',
          data: { title: 'Katalog' },
        },
        {
          component: FavoritesComponent,
          path: 'favorites',
          data: { title: 'Ulubione' },
        },
        {
          component: HowItWorksComponent,
          path: 'static/how-it-works',
          data: { title: 'Jak to działa' },
        },
        {
          component: RegulationsComponent,
          path: 'static/regulations',
          data: { title: 'Regulacje' },
        },
        {
          component: FaqComponent,
          path: 'static/faq',
          data: { title: 'Faq' },
        },
        {
          component: LoggedOptionsComponent,
          path: 'logged-options',
          data: { title: 'Profil' },
        },
        {
          path: 'forgot-password',
          component: ForgotPasswordComponent,
          data: { title: 'Forgot password ' },
        },
        {
          path: 'new-password/:token',
          component: NewPasswordFormComponent,
          data: { title: 'Forgot password ' },
        },
        {
          path: 'offer/:slug',
          component: SingleOfferComponent,
          data: { title: 'Oferta' },
        },
        {
          component: MobileExtWrapperComponent,
          path: 'ext',
          children: [
            {
              loadChildren: () =>
                import(
                  './modules/messenger-mobile-view/messenger-mobile-view.module'
                ).then((m) => m.MessengerMobileViewModule),
              path: 'messenger',
            },
          ],
        },
        {
          path: 'admin',
          component: AdminWrapperComponent,
          children: [
            {
              path: '',
              loadChildren: () =>
                import('@replica-frontend/admin').then((m) => m.AdminModule),
            },
          ],
        },
      ],
    }
  ],
};

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      relativeLinkResolution: 'corrected',
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // TODO: Add conditional lazy routes
  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private router: Router
  ) {
    // if (deviceDetectorService.isServer()) {
    //   return;
    // }
    if (deviceDetectorService.isDesktop()) {
      console.log('desktop detected');
      this.pushDesktopRoutes();
      // add desktop ViewModules
    } else {
      this.pushMobileRoutes();
    }
  }

  pushDesktopRoutes() {
    const config = this.router.config;
    config.push(desktopRoutes);


    config[config.length - 1].children.push({
      component: DesktopExtWrapperComponent,
      path: 'ext',
      children: [
        {
          loadChildren: () =>
            import(
              './modules/messenger-desktop-view/messenger-desktop-view.module'
            ).then((m) => m.MessengerDesktopViewModule),
          path: 'messenger',
          pathMatch: 'full',
        },
      ],
    });
    this.router.resetConfig(config);
  }

  pushMobileRoutes() {
    const config = this.router.config;
    // add mobile ViewModules
    config.push(mobileRoutes);
    this.router.resetConfig(config);
  }
}
