<responsive-switcher>
  <rs-desktop>
    <div fxLayout="column" class="container">
      <div *ngIf="!!error" class="error-wrapper">
        <span class="error-message">{{ error }}</span>
      </div>
      <div class="input-wrapper" fxLayout="row" fxFlex="100">
        <input
          (change)="onChange($event.target.value)"
          [ngClass]="{ error: !!error }"
          [type]="type"
          fxLayout="row"
          [placeholder]="placeholder"
          fxLayoutAlign="center center"
          fxFlex="100"
          class="input-container"
          [(ngModel)]="value"
          (ngModelChange)="updateChanges()"
        />
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div
      fxLayout="column"
      [ngClass]="{ 'container-error': !!error, container: !!!error }"
    >
      <div class="error-wrapper">
        <span *ngIf="!!error" class="error-message">{{ error }}</span>
      </div>

      <input
        [ngClass]="{ error: !!error }"
        [type]="type"
        fxLayout="row"
        [placeholder]="placeholder"
        fxLayoutAlign="center center"
        fxFlex="100"
        class="input-container"
        [(ngModel)]="value"
        (ngModelChange)="updateChanges()"
      />
    </div>
  </rs-mobile>
</responsive-switcher>
