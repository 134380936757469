import {Component} from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  options = [{
    name: 'Regulamin',
    link: ''
  }, {
    name: 'Polityka prywatności',
    link: ''
  }];
}
