import {Component} from '@angular/core';

@Component({
  templateUrl: './desktop-ext-wrapper.component.html',
  styleUrls: ['./desktop-ext-wrapper.component.scss']
})
export class DesktopExtWrapperComponent {
  constructor() {}

}
