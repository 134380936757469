import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import '@angular/common/locales/global/pl'
import { LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatRippleModule } from '@angular/material/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthModule } from '@replica-frontend/auth'
import { MediaModule } from '@replica-frontend/media'
import { SdkModule } from '@replica-frontend/sdk'
import { AppRoutingModule } from '@src/app/app-routing.module'
import { AppComponent } from '@src/app/app.component'
import { ButtonAddAvatarComponent } from '@src/app/components/button-add-avatar/button-add-avatar.component'
import { ButtonAddPictureComponent } from '@src/app/components/button-add-picture/button-add-picture.component'
import { ButtonSocialmediaComponent } from '@src/app/components/button-socialmedia/button-socialmedia.component'
import { ButtonSquareComponent } from '@src/app/components/button-square/button-square.component'
import { CheckboxComponent } from '@src/app/components/checkbox/checkbox.component'
import { ChipsComponent } from '@src/app/components/chips/chips.component'
import { FacebookLikeComponent } from '@src/app/components/facebook-like/facebook-like.component'
import { InputComponent } from '@src/app/components/input/input.component'
import { MenuComponent } from '@src/app/components/menu/menu.component'
import { NewsletterPanelEmptyComponent } from '@src/app/components/newsletter-panel-empty/newsletter-panel-empty.component'
import { NewsletterPanelComponent } from '@src/app/components/newsletter-panel/newsletter-panel.component'
import { OfferListComponent } from '@src/app/components/offer-list/offer-list.component'
import { SearchBarComponent } from '@src/app/components/search-bar/search-bar.component'
import { SelectComponent } from '@src/app/components/select/select.component'
import { SlideToggleComponent } from '@src/app/components/slide-toggle/slide-toggle.component'
import { StatsComponent } from '@src/app/components/stats/stats.component'
import { TextareaComponent } from '@src/app/components/textarea/textarea.component'
import { TopbarFilterComponent } from '@src/app/components/topbar-filter/topbar-filter.component'
import { TopbarMobileMenuComponent } from '@src/app/components/topbar-mobile-menu/topbar-mobile-menu.component'
import { YellowButtonComponent } from '@src/app/components/yellow-button/yellow-button.component'
import { CommonComponentsModule } from '@src/app/modules/common-components/common-components.module'
import { SpComponentsModule } from '@src/app/modules/sp-components/sp-components.module'
import { CatalogComponent } from '@src/app/pages/desktop/catalog/catalog.component'
import { ConfirmEmailSuccessfulComponent } from '@src/app/pages/desktop/confirm-email-successful/confirm-email-successful.component'
import { ContractorProfileComponent } from '@src/app/pages/desktop/contractor-profile/contractor-profile.component'
import { CreateAccountSuccessfulComponent } from '@src/app/pages/desktop/create-account-successful/create-account-successful.component'
import { CreateAccountComponent } from '@src/app/pages/desktop/create-account/create-account.component'
import { CreateContractorAccountComponent } from '@src/app/pages/desktop/create-contractor-account/create-contractor-account.component'
import { CreateEmployeeAccountComponent } from '@src/app/pages/desktop/create-employee-account/create-employee-account.component'
import { CreateOfferComponent } from '@src/app/pages/desktop/create-offer/create-offer.component'
import { DashboardComponent } from '@src/app/pages/desktop/dashboard/dashboard.component'
import { EditContractorProfileComponent } from '@src/app/pages/desktop/edit-contractor-profile/edit-contractor-profile.component'
import { FaqComponent } from '@src/app/pages/desktop/faq/faq.component'
import { FavoritesComponent } from '@src/app/pages/desktop/favorites/favorites.component'
import { ForgotPasswordComponent } from '@src/app/pages/desktop/forgot-password/forgot-password.component'
import { GuestStaticPageComponent } from '@src/app/pages/desktop/guest-static-page/guest-static-page.component'
import { HomeComponent } from '@src/app/pages/desktop/home/home.component'
import { HowItWorksComponent } from '@src/app/pages/desktop/how-it-works/how-it-works.component'
import { LoggedStaticPageComponent } from '@src/app/pages/desktop/logged-static-page/logged-static-page.component'
import { NewPasswordFormComponent } from '@src/app/pages/desktop/new-password-form/new-password-form.component'
import { RegulationsComponent } from '@src/app/pages/desktop/regulations/regulations.component'
import { SignInComponent } from '@src/app/pages/desktop/sign-in/sign-in.component'
import { DesktopExtWrapperComponent } from '@src/app/wrappers/desktop-ext-wrapper/desktop-ext-wrapper.component'
import { LoggedWrapperMobileComponent } from '@src/app/wrappers/logged-wrapper-mobile/logged-wrapper-mobile.component'
import { MobileExtWrapperComponent } from '@src/app/wrappers/mobile-ext-wrapper/mobile-ext-wrapper.component'
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_SETTINGS
} from 'ng-recaptcha';
import { NgxFileDropModule } from 'ngx-file-drop';
import { environment } from './../environments/environment';
import { AdminOfferListComponent } from './admin/offer-list/admin-offer-list.component';
import { ConfirmDialogComponent } from './admin/offer-list/confirm-dialog/confirm-dialog.component';
import { AdminWrapperComponent } from './admin/wrapper/admin-wrapper.component';
import { AppFooterDesktopComponent } from './components/app-footer-desktop/app-footer-desktop.component';
import { AppFooterMobileComponent } from './components/app-footer-mobile/app-footer-mobile.component';
import { OfferMessageComponent } from './components/custom-messages/offer-message/offer-message.component';
import { OfferSmallCardComponent } from './components/offer-small-card/offer-small-card.component';
import { SelectMobileComponent } from './components/select-mobile/select-mobile.component';
import { TopbarLoggedDesktopComponent } from './components/topbar-logged-desktop/topbar-logged-desktop.component';
import { TopbarLoggedMobileComponent } from './components/topbar-logged-mobile/topbar-logged-mobile.component';
import { MessengerServicesModule } from './modules/messenger-services/messenger-services.module';
import { SpServicesModule } from './modules/sp-services/sp-services.module';
import { InviteCreatePasswordComponent } from './pages/desktop/invite-create-password/invite-create-password.component';
import { LoggedOptionsComponent } from './pages/desktop/logged-options/logged-options.component';
import { SingleOfferComponent } from './pages/desktop/single-offer/single-offer.component';
import { LoggedWrapperDesktopComponent } from './wrappers/logged-wrapper-desktop/logged-wrapper-desktop.component';
import { SecurityWrapperDesktopComponent } from './wrappers/security-desktop-wrapper/security-wrapper-desktop.component';
import { SecurityWrapperMobileComponent } from './wrappers/security-mobile-wrapper/security-wrapper-mobile.component';
import { HomeSearchResultsComponent } from './pages/desktop/home-search-results/home-search-results.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { HomeSelectComponent } from './components/home-select/home-select.component'


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    YellowButtonComponent,
    SearchBarComponent,
    SelectComponent,
    ButtonSquareComponent,
    NewsletterPanelComponent,
    CreateAccountComponent,
    SignInComponent,
    InputComponent,
    CreateContractorAccountComponent,
    CheckboxComponent,
    RegulationsComponent,
    MenuComponent,
    HowItWorksComponent,
    OfferListComponent,
    TopbarFilterComponent,
    SecurityWrapperMobileComponent,
    SecurityWrapperDesktopComponent,
    ButtonSocialmediaComponent,
    DashboardComponent,
    CreateAccountSuccessfulComponent,
    ConfirmEmailSuccessfulComponent,
    CreateOfferComponent,
    TextareaComponent,
    ChipsComponent,
    ButtonAddPictureComponent,
    NewsletterPanelEmptyComponent,
    TopbarMobileMenuComponent,
    LoggedWrapperMobileComponent,
    TopbarLoggedMobileComponent,
    TopbarLoggedDesktopComponent,
    LoggedWrapperDesktopComponent,
    AppFooterMobileComponent,
    AppFooterDesktopComponent,
    ContractorProfileComponent,
    EditContractorProfileComponent,
    ButtonAddAvatarComponent,
    SlideToggleComponent,
    StatsComponent,
    FacebookLikeComponent,
    CreateEmployeeAccountComponent,
    CatalogComponent,
    FavoritesComponent,
    LoggedOptionsComponent,
    GuestStaticPageComponent,
    LoggedStaticPageComponent,
    FaqComponent,
    DesktopExtWrapperComponent,
    MobileExtWrapperComponent,
    SelectMobileComponent,
    OfferMessageComponent,
    OfferSmallCardComponent,
    AdminOfferListComponent,
    ConfirmDialogComponent,
    InviteCreatePasswordComponent,
    AdminWrapperComponent,
    ForgotPasswordComponent,
    NewPasswordFormComponent,
    SingleOfferComponent,
    HomeSearchResultsComponent,
    HomeSelectComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SdkModule.forRoot({
      appName: 'Szybkapraca',
      environment,
      modulesConfig: {
        admin: {
          showMenu: false,
          externalModules: [
            {
              entryPath: 'offer-list',
              component: AdminOfferListComponent,
              label: 'Offer list',
              icon: 'local_offer',
            },
          ],
        },
      },
    }),
    AuthModule.forRoot({
      defaultAvatarPath: 'assets/icon-person.svg',
    }),
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1IjoidGhlY3liZXJkM20wbiIsImEiOiJja2FpcnNyeXowM21tMndwamxyZjU5ajJpIn0.TjWqQxlCdOZmoVFLFJBRsA', // Optional, can also be set per map (accessToken input of mgl-map)
    }),
    MediaModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    MatCheckboxModule,
    MatListModule,
    MatTooltipModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxFileDropModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatTabsModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatDialogModule,
    SpServicesModule,
    MatSnackBarModule,
    SpComponentsModule,
    MessengerServicesModule.forRoot({
      customMessageTypes: [
        {
          metaType: 'offer',
          sticky: true,
          component: OfferMessageComponent,
        },
      ],
    }),
    RecaptchaModule, // this is the recaptcha main module
    RecaptchaFormsModule, // this is the module for form incase form validation,
    CommonComponentsModule,
    CommonModule,
    MatTableModule,
    MatRippleModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaV2WebsiteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
