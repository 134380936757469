import { Component, OnDestroy, OnInit } from '@angular/core';
import { DefaultFilters } from '../../../modules/common-services';
import {
  OffersRequestDTO,
  OffersResponseDTO,
  OffersService,
} from '../../../modules/sp-services/offers.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { CommonHttpService } from '../../../modules/common-services/common-http.service';
import { ExceptionTelemeteryService } from '../../../modules/common-services/exception-telemetery/exception-telemetery.service';
import { Map } from 'mapbox-gl';
import { ActivatedRoute } from '@angular/router';
import {
  MapboxFeature,
  MapboxResponse,
  MapboxService,
} from '../../../modules/sp-services/mapbox.service';
import { DeviceDetectorService } from '@replica-frontend/sdk';
@Component({
  templateUrl: './home-search-results.component.html',
  styleUrls: ['./home-search-results.component.scss'],
})
export class HomeSearchResultsComponent implements OnInit, OnDestroy {
  filtersOpened: boolean;
  constructor(
    public offersService: OffersService,
    public commonHttpService: CommonHttpService,
    public telemeteryService: ExceptionTelemeteryService,
    private activatedRoute: ActivatedRoute,
    private mapboxService: MapboxService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    // Use the component constructor to inject providers.
  }
  mapboxPlace: MapboxFeature;
  radius: number;
  events: [];
  searchResultLabel = '';
  titleLabel = 'Wyniki wyszukiwania: ';
  ButtonColors = BUTTON_COLORS;
  city: number;
  mapZoomed = false;
  offers: OffersResponseDTO[] = [];
  loadSub: Subscription;
  filter: DefaultFilters<OffersRequestDTO> = { limit: 10, offset: 0, filterBy: {} };
  cannotBeMore = false;
  citiesQueryFunction = this.commonHttpService.getCities;
  public map: Map;
  mapLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mapLoaded$ = this.mapLoadedSubject.asObservable();
  isServer = this.deviceDetectorService.isServer();
  ngOnInit(): void {
    // read route state
    this.activatedRoute.queryParams.subscribe((params) => {
      this.mapboxPlace = JSON.parse(decodeURIComponent(params.mapboxPlace)) as MapboxFeature;
      this.radius = params.radius;
      this.filter.filterBy.mapboxPlace = this.mapboxPlace;
      this.searchResultLabel = this.mapboxPlace.text;
      this.titleLabel += this.searchResultLabel;
      this.load();
      // // search for mapboxPlace
      // this.
      // this.filter.filterBy.mapboxPlace as Partial<MapboxFeature> = { id: this.mapboxPlace };
    });
  }

  filtersChanged($event): void {
    this.filter = {
      ...this.filter,
      offset: 0,
    };
    this.filter.filterBy = $event;
    console.log($event);
    this.offers = [];
    this.load();
  }

  sortChanged($event): void {
    this.filter = {
      ...this.filter,
      offset: 0,
    };
    this.filter.sortBy = $event;
    this.offers = [];
    this.load();
  }


  setOpenedFilter($event): void {
    this.filtersOpened = !this.filtersOpened;
  }
  
  trackFunction(index: number, item: OffersResponseDTO): string {
    return item.id;
  }

  submit($event): void {
    this.filter = { limit: 10, offset: 0, filterBy: {} };
    if ($event !== -1) {
      this.filter.filterBy = { mapboxPlace: $event };
    }
    this.cannotBeMore = false;

    this.load();
  }
  load(): void {
    if (this.loadSub) {
      this.loadSub.unsubscribe();
    }
    this.loadSub = this.offersService.getOffers(this.filter).subscribe(
      (offers: OffersResponseDTO[]) => {
        this.offers = offers;
        if (offers.length < 10) {
          this.cannotBeMore = true;
        }
        this.addOffersToMap();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onMapLoaded($event): void {
    this.map = $event;
    this.mapLoadedSubject.next(true);
    if (!this.mapZoomed) {
      this.zoomMap();
    }

  }

  addOffersToMap(): void {
    this.mapLoaded$.subscribe((result) => {
      if (result) {
        this.map.addSource('offers', {
          type: 'geojson',
          // Point to GeoJSON data. This example visualizes all M1.0+ offers
          // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
          data: this.getCurrentGeoJson() as any,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });
  
        this.map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'offers',
          filter: ['has', 'point_count'],
          paint: MapboxService.getClusterPaintConfig(),
        });
  
        this.map.addLayer(MapboxService.getSymbolConfig() as any);

  
        this.map.addLayer(MapboxService.getUnclusteredPointConfig() as any);
        this.map.addLayer(MapboxService.getUnclusteredSymbolConfig() as any);

        // inspect a cluster on click
        this.map.on('click', 'clusters', (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          // const clusterId = features[0].properties.cluster_id;
        });

        this.map.on('click', 'unclustered-point', (e) => {
          const coordinates = (<any>e.features[0].geometry).coordinates.slice();

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
    
          this.map.on('mouseenter', 'clusters', () => {
            this.map.getCanvas().style.cursor = 'pointer';
          });
          this.map.on('mouseleave', 'clusters', () => {
            this.map.getCanvas().style.cursor = '';
          });
        });
      }
      // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.

    });

    
  }

  getCurrentGeoJson() {
      const geojson = {
        type: 'FeatureCollection',
        features:  this.offers.map((offer) => {
          return {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [offer.mapboxPlace.centerLat, offer.mapboxPlace.centerLng, 0 ] },
          }

        })
      }
      
      return geojson;
  }

  zoomMap(): void {
    const place = this.filter.filterBy.mapboxPlace;
    if (place.bbox) {
      this.map.fitBounds([
        [place.bbox[0], place.bbox[1]],
        [place.bbox[2], place.bbox[3]],
      ]);
    } else {
      this.map.flyTo({
        center: place.center,
        zoom: 5
      })
    }

  }

  getMore(): void {
    this.filter.offset += this.filter.limit;
    this.loadSub = this.offersService
      .getOffers(this.filter)
      .subscribe((offers: OffersResponseDTO[]) => {
        this.offers = this.offers.concat(offers);
        if (offers.length < 5) {
          this.cannotBeMore = true;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.loadSub) {
      this.loadSub.unsubscribe();
    }
  }
}
