<mat-nav-list>
  <a mat-list-item href="..." *ngFor="let user of users">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="column">
        <app-avatar class="avatar-item" [userId]="user.id"></app-avatar>
      </div>
      <div fxLayout="column" fxFill>
        <span>{{user.firstname}} {{user.lastname}}</span>
      </div>
    </div>
  </a>
</mat-nav-list>
