<div>
  <ng-container *ngIf="logged; then authenticated; else guest"></ng-container>
</div>

<ng-template #authenticated>
  <app-logged-wrapper-mobile>
    <router-outlet></router-outlet>
  </app-logged-wrapper-mobile>
</ng-template>
<ng-template #guest>
  <app-mobile-guest-wrapper>
    <router-outlet></router-outlet>
  </app-mobile-guest-wrapper>
</ng-template>
