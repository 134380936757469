import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@replica-frontend/auth';
import { DeviceDetectorService } from '../../../modules/common-services';
import { UploadService } from '../../../modules/sp-services';
import {
  CreateOfferDto,
  OffersService,
} from '../../../modules/sp-services/offers.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Option } from '../../../modules/common-components';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { CommonHttpService } from '../../../modules/common-services/common-http.service';
import {
  ServerSideValidationService,
  ValidationErrorMessage,
  ValidationHandler,
} from '@replica-frontend/sdk';
import { Files } from '../../../modules/sp-services/profile.service';
import {
  OffersResponseDTO,
  Other,
} from './../../../modules/sp-services/offers.service';

@Component({
  selector: 'app-create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss'],
})
export class CreateOfferComponent
  implements OnInit, OnDestroy, ValidationHandler
{
  ButtonColors = BUTTON_COLORS;
  placeholder: string;
  value: string;
  events: [];
  createOrderForm = this.formBuilder.group({
    mapboxPlace: ['', [Validators.required, Validators.maxLength(80)]],
    category: ['', [Validators.required, Validators.maxLength(80)]],
    type: ['', [Validators.required, Validators.maxLength(80)]],
    title: ['', [Validators.required, Validators.maxLength(80)]],
    description: ['', [Validators.required, Validators.maxLength(80)]],
    rateType: ['', [Validators.required, Validators.maxLength(80)]],
    salary: ['', [Validators.required, Validators.maxLength(6)]],
    workTime: ['', [Validators.required, Validators.maxLength(6)]],
    days: ['', [Validators.required, Validators.maxLength(80)]],
    thumbnail: [null, [Validators.required]],
    skills: [this.formBuilder.array([]), [Validators.required]],
  });
  thumbnail: Files = null;
  newThumbnail: File;
  loading = true;
  // cities$ = this.commonHttpService.getCities$.pipe(
  //   map((cities) => {
  //     return cities.map(city => ({
  //       label: city.name,
  //       value: city.id
  //     }));
  //   }),
  // );
  types$ = this.offersHttpService.getOfferTypes$.pipe(
    map((offersTypes) => {
      return offersTypes.map((offersType) => ({
        label: offersType.name,
        value: offersType.id,
      }));
    })
  );

  categories$ = this.offersHttpService.getCategories$.pipe(
    map((categories) => {
      return categories.map((category) => ({
        label: category.category,
        value: category.id,
      }));
    })
  );

  rateTypes$ = this.offersHttpService.getRateTypes$.pipe(
    map((rateTypes) => {
      return rateTypes.map((rateType) => ({
        label: rateType.name,
        value: rateType.id,
      }));
    })
  );

  workTimes$ = of([
    {
      label: 'Pełny',
      value: 'full',
    },
    {
      label: 'Niepełny',
      value: 'partial',
    },
  ]);
  days$ = this.offersHttpService.getOffersDays$.pipe(
    map((days) => {
      return days.map((day) => ({
        label: day.name,
        value: day.id,
      }));
    })
  );
  categories = [];
  days = [];
  rateTypes = [];
  types = [];
  mode = 'add';
  editSlug: string;
  editId: string;
  mine;

  constructor(
    private formBuilder: FormBuilder,
    private offersHttpService: OffersService,
    public commonHttpService: CommonHttpService,
    private validationService: ServerSideValidationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private uploadService: UploadService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  ngOnInit(): void {

    this.validationService.registerValidationHandler('create-offer', this);
    this.editSlug = this.activatedRoute.snapshot.paramMap.get('slug');
    const depDataObsArray: Observable<any>[] = [
      this.categories$,
      this.days$,
      this.rateTypes$,
      this.types$,
    ];
    if (this.editSlug) {
      depDataObsArray.push(this.offersHttpService.singleOffer(this.editSlug));
    }
    combineLatest(depDataObsArray).subscribe((data) => {
      this.categories = data[0];
      this.days = data[1];
      this.rateTypes = data[2];
      this.types = data[3];

      this.loading = false;
      if (data[4]) {
        this.authService.me$.subscribe((me) => {
          console.log('me', me);
          if (me) {
            const mine = me && data[4].userid.id === me.id;
            if (!mine) {
              const snackBarRef = this.snackBar.open('Brak uprawnień', null, {
                duration: 3000,
              });
              this.location.back();
              return;
            }
            this.bindOfferToForm(data[4]);
            this.editId = data[4].id;
            this.mode = 'edit';
            this.loading = false;
          } else {
          }
        });
      }
    });
  }

  bindOfferToForm(offer: OffersResponseDTO): void {
    const formOffer = { ...offer } as any;
    console.log(formOffer);
    delete formOffer.userid;
    delete formOffer.id;
    delete formOffer.slug;
    delete formOffer.scrapperRecruiterImage;
    delete formOffer.archived;
    delete formOffer.view;
    delete formOffer.created;
    delete formOffer.expireAt;
    delete formOffer.likesCount;
    delete formOffer.offerApplies;
    delete formOffer.dislikesCount;
    delete formOffer.isScrapped;
    delete formOffer.favorite;
    delete formOffer.LikedStatus;
    delete formOffer.originalPosted;
    delete formOffer.scrapperAddress;
    delete formOffer.scrapperRecruiterName;
    delete formOffer.scrappedFrom;
    formOffer.category = formOffer.category.id;
    formOffer.type = formOffer.type.id;
    formOffer.rateType = formOffer.rateType.id;
    formOffer.days = formOffer.days.id;
    delete formOffer.city;
    this.createOrderForm.setValue(formOffer);
    this.thumbnail = formOffer.thumbnail;
    this.mode = 'edit';
  }

  getValues(): CreateOfferDto {
    const values = this.createOrderForm.value;
    if (values.workTime) {
      values.workTime = parseFloat(this.createOrderForm.get('workTime').value);
    } else {
      values.workTime = null;
    }
    values.salary = parseFloat(values.salary as any);
    return values;
  }

  submit(): void {
    if (this.mode === 'add') {
      this.createOffer();
    } else {
      this.editOffer();
    }
  }

  createOffer(): void {
    const values = this.createOrderForm.value || null;
    values.salary = parseFloat(values.salary);
    if (values.skills.length) {
      values.skills = values.skills.map((skill) => ({
        name: skill.label,
        id: skill.value,
      }));
    }

    if (this.newThumbnail) {
      this.uploadService.uploadOfferImage(this.newThumbnail).subscribe(
        (imageData) => {
          this.createOrderForm.get('thumbnail').setValue(imageData.id);
          this.offersHttpService
            .createOffer(this.getValues())
            .subscribe((value) => this.router.navigate(['app']));
        },
        (err) => {
          this.onFieldErrors({
            message: [
              {
                field: 'thumbnail',
                message: 'Nie można wczytać obrazka',
              },
            ],
          }); // TODO: read from 'err'
        }
      );
    } else {
      this.offersHttpService
        .createOffer(this.getValues())
        .subscribe((value) => {
          this.router.navigate(['app', 'catalog']);
        });
    }
  }

  editOffer(): void {
    const values = this.createOrderForm.value || null;
    values.salary = parseFloat(values.salary);
    if (values.skills.length) {
      values.skills = values.skills.map((skill) => ({
        name: skill.label,
        id: skill.value,
      }));
    }

    if (this.newThumbnail) {
      this.uploadService.uploadOfferImage(this.newThumbnail).subscribe(
        (imageData) => {
          this.createOrderForm.get('thumbnail').setValue(imageData.id);
          this.offersHttpService
            .updateOffer(this.editId, this.getValues())
            .subscribe((value) => this.router.navigate(['app']));
        },
        (err) => {
          this.onFieldErrors({
            message: [
              {
                field: 'thumbnail',
                message: 'Nie można wczytać obrazka',
              },
            ],
          });
        }
      );
    } else {
      this.offersHttpService
        .updateOffer(this.editId, this.getValues())
        .subscribe((value) => this.router.navigate(['app']));
    }
  }

  skillsFinder(name: string): Observable<Option[]> {
    return this.offersHttpService.getSkillsByName(name).pipe(
      switchMap((values: Other[]) => {
        return of(
          values.map((value) => {
            return {
              label: value.name,
              value: value.id.toString(10),
            };
          })
        );
      })
    );
  }

  onFieldErrors(errors: ValidationErrorMessage): void {
    this.changeDetectorRef.detectChanges();
    errors.message.forEach((error) => {
      this.createOrderForm.controls[error.field].setErrors({
        message: error.message,
      });
    });
    this.formSubmitFunction();
  }

  formSubmitFunction(): void {
    this.changeDetectorRef.detectChanges();
    const mobileSelector = 'form .ng-invalid';
    const desktopSelector = 'form .ng-invalid'; // second form
    let firstElementWithError = null;
    if (this.deviceDetectorService.isDesktop()) {
      firstElementWithError = document.querySelector(desktopSelector);
    } else {
      firstElementWithError = document.querySelector(mobileSelector);
    }

    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }

  back($event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.location.back();
  }

  imageLoaded($event: File): void {
    this.newThumbnail = $event;
  }

  ngOnDestroy(): void {
    this.validationService.removeValidationHandler('create-offer');
  }
}
