<responsive-switcher>
  <rs-desktop>
    <app-desktop-guest-wrapper>
      <div class="label-wrapper">
        <span class="welcome-label welcome-label-first">Przerwa techniczna,</span>
        <span class="welcome-label">zapraszamy wkrótce </span>
      </div>
    </app-desktop-guest-wrapper>
  </rs-desktop>
  <rs-mobile>
    <app-mobile-guest-wrapper>
      <div class="label-wrapper">
        <span class="welcome-label welcome-label-first">Przerwa techniczna,</span>
        <span class="welcome-label">zapraszamy wkrótce </span>
      </div>
    </app-mobile-guest-wrapper>
     </rs-mobile>
</responsive-switcher>
