<responsive-switcher>
  <rs-desktop>
    <div class="home-container">
      <div
        fxLayout="column"
        class="container-grey"
        fxLayoutAlign="center center"
      >
        <div fxLayout="row" class="pic-and-btns">
          <div class="first-pic" fxFlex="20" fxLayoutAlign="start end">
            <a class="dash-pic-1"></a>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
            <div class="container-span-wrapper">
              <span
                class="container-span span-active"
                (click)="activeTab = 0"
                [ngClass]="{
                  'span-inactive': activeTab === 1,
                  'span-active': activeTab === 0
                }"
              >
                Szukam zleceń
              </span>
              <span
                class="container-span second-container-span"
                (click)="activeTab = 1"
                [ngClass]="{
                  'span-inactive': activeTab === 0,
                  'span-active': activeTab === 1
                }"
              >
                Szukam pracownikow
              </span>
            </div>

            <div
              class="container-buttons"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <app-search-bar></app-search-bar>
            </div>
            <div class="container-span span-active popular-jobs">
              Popular Jobs
            </div>
            <div class="tags-container">
              <!-- TODO: router-link to category -->
              <sp-tag *ngFor="let category of categories" [label]="category.category"></sp-tag>

            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
              <span>Masz konkretne wymagania?</span>
              <a class="advenced-search">Użyj wyszukiwania zaawansowanego</a>
            </div>
          </div>
          <div class="second-pic" fxFlex="20" fxLayoutAlign="end end">
            <a class="dash-pic-2"></a>
          </div>
        </div>
      </div>
      <div fxLayout="row" class="event-list-title-container">
        <span class="event-list-title" fxLayoutAlign="start center">
          Wyróżnione mikrozlecenia w Twojej okolicy
        </span>

      </div>
      <div class="list-container" *ngIf="offers.length !== 0">
        <div
          fxLayout="row"
          fxLayoutAlign="start"
          fxLayoutGap="30px"
          class="cards-wrapper"
        >
          <app-offer-small-card
            [routerLink]="['offer', offer.slug]"
            class="offer-small-card"
            [offer]="offer"
            *ngFor="let offer of offers"
          ></app-offer-small-card>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="offers.length === 0" class="no-offers">Brak ofert</span>
        <app-button
          *ngIf="offers.length > 0"
          tooltip="Zobacz więcej"
          [color]="ButtonColors.PRIMARY"
          class="button-more"
          (click)="getMore()"
          [style.display]="cannotBeMore ? 'none' : 'flex'"
        >
          <span class="button-span">Zobacz więcej</span>
        </app-button>
      </div>
      <app-newsletter-panel></app-newsletter-panel>
    </div>
  </rs-desktop>

  <rs-mobile>
    <div fxLayout="column">
      <div class="container-grey-wrapper" fxLayoutAlign="center center">
        <div
          fxLayout="column"
          class="container-grey"
          fxLayoutAlign="center center"
        >
          <span
            class="container-span"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            Szukam zleceń
          </span>
          <div
            class="container-buttons"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
           
          <app-search-bar></app-search-bar>

          </div>
          <div fxLayout="row"
          fxLayoutAlign="center center"
          class="popular-jobs-label">
            <span>
              Popular jobs
            </span>
          </div>
          <div class="tags-container">
            <!-- TODO: router-link to category -->
            <sp-tag *ngFor="let category of categories" [label]="category.category"></sp-tag>

          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <span>Masz konkretne wymagania?</span>
            <a class="advenced-search">Użyj wyszukiwania zaawansowanego</a>
          </div>
          <div fxLayout="row" class="dash-pics">
            <div class="first-pic" fxFlex="40" fxLayoutAlign="start end">
              <a class="dash-pic-1"></a>
            </div>
            <div class="second-pic" fxFlex="40" fxLayoutAlign="end end">
              <a class="dash-pic-2"></a>
            </div>
          </div>
          
        </div>
      
      </div>

      <div fxLayoutAlign="center center" fxFlex="100" fxLayout="row">
        <span class="event-list-title" fxLayoutAlign="center center">
          Wyróżnione mikrozlecenia w Twojej okolicy
        </span>
      </div>
      <span *ngIf="offers.length === 0" class="no-offers">Brak ofert</span>
      <div
        fxLayout="row"
        [fxLayoutAlign]="
          offers.length === 1 ? 'center center' : 'space-between center'
        "
        fxLayoutGap="10px"
        fxFlex="100"
        [ngClass]="{
          'event-list-container': offers.length === 1,
          'events-list-container': offers.length > 1
        }"
        class="event-list-container"
      >
        <app-offer-small-card
          [offer]="offer"
          *ngFor="let offer of offers"
          [routerLink]="['/', 'offer', offer.slug]"
        ></app-offer-small-card>
      </div>
      <div fxLayout="row" fxLayoutAlign="center">
        <app-button
          [color]="ButtonColors.SECONDARY"
          class="button-more"
          (click)="getMore()"
          [style.display]="cannotBeMore ? 'none' : 'flex'"
        >
          <span class="button-span">Pokaż więcej zleceń</span>
        </app-button>
      </div>
    </div>
    <app-newsletter-panel class="newsletter-mobile"></app-newsletter-panel>
  </rs-mobile>
</responsive-switcher>
