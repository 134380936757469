export const environment = {
  production: true,
  apiUrl: 'api',
  wsUrl: '', //TODO: change to prod URL and gitignore
  wsPort: 443,
  debug: false,
  appVersion: require('../../package.json').version,
  recaptchaV2WebsiteKey: '6Le1X1IaAAAAAJFFLAOWhYqVlclqJgdsTKhcFeEk',
  domain: 'http://localhost:4200',
  mapboxGlToken: 'sk.eyJ1IjoidGhlY3liZXJkM20wbiIsImEiOiJjbDR3d2R1YXYwMGwwM2Jwbmx1ejF4YnAyIn0.n2TYOXPXUUM5WAS2Ij7oig'
};
