<div *ngIf="loading" fxLayoutAlign="center center">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="error" fxLayoutAlign="center center">
  Błąd podczas pobierania wiadomości
</div>

<div
  fxLayoutAlign="center center"
  [ngSwitch]="meta.data.status"
  *ngIf="!loading"
>
  <div *ngSwitchCase="OfferApplyStatus.AwaitingAprooval" class="wrapper">
    <div *ngIf="debug">
      offerStatus: {{ offerApply?.status }}, messageStatus:
      {{ meta.data.status }}, employee: {{ employee }}
    </div>
    <div
      *ngIf="
        employee && offerApply.status === OfferApplyStatus.AwaitingAprooval
      "
    >
      <span class="user-apply">
        Aplikowałeś na ofertę
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
    <div
      *ngIf="
        !employee && offerApply.status === OfferApplyStatus.AwaitingAprooval
      "
      class="container"

    >
      <span class="user-apply">
        Wykonawca aplikował na ofertę
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>

      <div
        fxLayout="row"
        class="buttons-wrapper"
        *ngIf="offerApply.status === OfferApplyStatus.AwaitingAprooval"
      >
        <div
          class="buttons"
          fxLayout="row"
          *ngIf="offerApply?.status === OfferApplyStatus.AwaitingAprooval"
        >
          <div class="button-first">
            <app-button (click)="approove()" [color]="ButtonColors.PRIMARY">
              Akceptuj
            </app-button>
          </div>
          <div class="button-second">
            <app-button [color]="ButtonColors.GREY">Odrzuć</app-button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        employee && offerApply.status !== OfferApplyStatus.AwaitingAprooval
      "
      class="container"
      fxLayout="row"
    >
      <span class="user-apply">
        Aplikowałeś na ofertę
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
    <div
      *ngIf="
        !employee && offerApply.status !== OfferApplyStatus.AwaitingAprooval
      "
      class="container"
      fxLayout="row"
    >
      <span class="user-apply">
        Wykonawca aplikował na ofertę
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
  </div>
  <div *ngSwitchCase="OfferApplyStatus.InProgress" class="wrapper">
    <div *ngIf="debug">
      offerStatus: {{ offerApply?.status }}, messageStatus:
      {{ meta.data.status }}, employee: {{ employee }}
    </div>
    <div *ngIf="employee && offerApply.status !== OfferApplyStatus.InProgress">
      <span class="user-apply">
        Zleceniodawca zaakceptował Twoje zgłoszenie na ofertę
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
    <div *ngIf="!employee && offerApply.status !== OfferApplyStatus.InProgress">
      <span class="user-apply">
        Zaakceptowałeś zgłoszenie na ofertę
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
    <div *ngIf="employee && offerApply?.status === OfferApplyStatus.InProgress">
      <span class="user-apply">
        Zleceniodawca zaakceptował Twoje zgłoszenie na ofertę
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
      <div fxLayout="row" class="single-button">
        <div
          class="buttons-wrapper"
          fxLayout="row"
          *ngIf="offerApply?.status === OfferApplyStatus.InProgress"
        >
          <div class="button-first">
            <app-button (click)="finalize()" [color]="ButtonColors.PRIMARY">
              Wykonałem zadanie
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!employee && offerApply?.status === OfferApplyStatus.InProgress"
    >
      <span class="user-apply">
        Zaakceptowałeś aplikacje wykonawcy na oferte
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
      <div fxLayout="row" *ngIf="offerApply?.status === OfferApplyStatus.InProgress" class="single-button">
        <div class="buttons-wrapper" fxLayout="row">
          <div class="button-first">
            <app-button
              (click)="resignAsEmployer()"
              [color]="ButtonColors.SECONDARY"
            >
              Zwolnij
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="OfferApplyStatus.EmployeeFinalized" class="wrapper">
    <div *ngIf="debug">
      offerStatus: {{ offerApply?.status }}, messageStatus:
      {{ meta.data.status }}, employee: {{ employee }}
    </div>
    <div
      *ngIf="
        !employee && offerApply.status === OfferApplyStatus.EmployeeFinalized
      "
    >
      <span class="user-apply">
        Wykonawca zakończył wykonywanie oferty
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
      <span class="user-apply">Potwierdź wykonanie zadania</span>
      <div
        fxLayout="row"
        class="single-button"
        *ngIf="offerApply?.status === OfferApplyStatus.EmployeeFinalized"
      >
        <div class="buttons-wrapper" fxLayout="row">
          <div class="button-first">
            <app-button (click)="finalize()" [color]="ButtonColors.PRIMARY">
              Akceptuj
            </app-button>
          </div>
          <div class="button-second">
            <app-button (click)="unfinalize()" [color]="ButtonColors.GREY">
              Odrzuć
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        employee && offerApply?.status === OfferApplyStatus.EmployeeFinalized
      "
    >
      <span class="user-apply">
        Zakończyłeś wykonywanie oferty
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
      <span class="user-apply">Oczekiwanie na akceptacje zleceniodawcy</span>
    </div>
    <div
      *ngIf="
        !employee && offerApply.status !== OfferApplyStatus.EmployeeFinalized
      "
    >
      <span class="user-apply">
        Wykonawca zakończył wykonywanie oferty
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
    <div
      *ngIf="
        employee && offerApply?.status !== OfferApplyStatus.EmployeeFinalized
      "
    >
      <span class="user-apply">
        Zakończyłeś wykonywanie oferty
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
  </div>
  <div *ngSwitchCase="OfferApplyStatus.Finalized" class="wrapper">
    <div *ngIf="debug">
      offerStatus: {{ offerApply?.status }}, messageStatus:
      {{ meta.data.status }}, employee: {{ employee }}
    </div>
    <div *ngIf="!employee && offerApply.status === OfferApplyStatus.Finalized">
      <span class="user-apply">
        Zaakceptowałeś wykonania zadania
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
      <span class="user-apply"
        >Wykonawca oczekuje na zapłate {{ meta.data.offer.salary }} PLN</span
      >
      <div
        fxLayout="row"
        class="buttons"
        *ngIf="offerApply.status === OfferApplyStatus.EmployeeFinalized"
      >
        <div style="width: 100%" fxLayout="row">
          <div class="button-first">
            <app-button (click)="finalize()" [color]="ButtonColors.PRIMARY">
              Akceptuj
            </app-button>
          </div>
          <div class="button-second">
            <app-button (click)="unfinalize()" [color]="ButtonColors.GREY">
              Odrzuć
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="employee && offerApply?.status === OfferApplyStatus.Finalized">
      <span class="user-apply">
        Zleceniodawca zaakceptował Twoje wykonanie oferty
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
      <span class="user-apply"
        >i zapłaci Ci {{ meta.data.offer.salary }} PLN</span
      >
      <div class="buttons-wrapper" fxLayout="row">
        <div class="button-first">
          <app-button (click)="aproovePayment()" [color]="ButtonColors.PRIMARY">
            Potwierdź płatność
          </app-button>
        </div>
      </div>
    </div>
    <div *ngIf="!employee && offerApply.status !== OfferApplyStatus.Finalized">
      <span class="user-apply">
        Wykonawca zakończył wykonywanie oferty
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
    <div *ngIf="employee && offerApply?.status !== OfferApplyStatus.Finalized">
      <span class="user-apply">
        Zakończyłeś wykonywanie oferty
        <strong class="offer-name">{{ meta.data.offer.title }}</strong>
      </span>
    </div>
  </div>
  <div *ngSwitchCase="OfferApplyStatus.Payed" class="wrapper">
    <div *ngIf="debug">
      offerStatus: {{ offerApply?.status }}, messageStatus:
      {{ meta.data.status }}, employee: {{ employee }}
    </div>
    <span class="paid-status-label">
      Płatność za ofertę
      <strong class="offer-name">{{ meta.data.offer.title }}</strong>
    </span>
    <span class="paid-status">została potwierdzona.</span>
  </div>
</div>
