<responsive-switcher>
  <rs-desktop>

  </rs-desktop>
  <rs-mobile>
      <div [ngClass]="{ 'wrapper': !isIos, 'wrapper-ios': isIos }">
        <div class="more-icon-wrapper">
          <!-- <a class="more-icon"></a> -->
        </div>
        <div class="avatar">
          <div *ngIf="(authService.me$ | async)" class="avatar-wrapper">
            <app-avatar [userId]="(authService.me$ | async)?.id" ></app-avatar>
          </div>
        </div>

        <div class="name-wrapper">
          <span class="name">{{(authService.me$ | async)?.firstname}} {{(authService.me$ | async)?.lastname}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="view-profile-wrapper">
          <span class="view-profile" [routerLink]="['/app', 'contractor-profile']">Zobacz swój profil</span>
        </div>
        <div fxLayout="column" class="list-container">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="list-label">Zasady</span>
          </div>
          <mat-list role="list">
            <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list" fxLayoutAlign=" center"[routerLink]="['/app', 'static', 'faq']" >
              <span fxFlex="90" fxLayoutAlign="start center">
                  FAQ
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item>
            <mat-list-item role="listitem">
              <button mat-button fxLayout="row"  class="button-list-last" fxLayoutAlign="center">
              <span fxFlex="90" [routerLink]="['/app', 'static', 'regulations']" fxLayoutAlign="start center">
                  Regulamin
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item>
            <!-- <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list-last" fxLayoutAlign=" center">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Polityka prywatności
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item> -->
          </mat-list>
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
            <span class="list-label">Szybkapraca.pl</span>
          </div>
          <mat-list role="list">
            <!-- <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list" fxLayoutAlign=" center">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Oceń aplikację
                 <a class="external-link-icon"></a>
              </span>

                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item> -->
            <!-- <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list" fxLayoutAlign=" center">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Nasz Facebook
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item> -->
            <!-- <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list" fxLayoutAlign=" center">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Nasz Twitter
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item>
            <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list" fxLayoutAlign=" center">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Nasz Instagram
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item> -->
            <mat-list-item role="listitem">
              <button mat-button fxLayout="row"  class="button-list-last" fxLayoutAlign=" center" [routerLink]="['/app', 'static', 'how-it-works']">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Jak to działa?
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item>
            <!-- <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list" fxLayoutAlign=" center">
              <span fxFlex="90" fxLayoutAlign="start center">
                  O nas
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item> -->
            <!-- <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list-last" fxLayoutAlign=" center">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Pomoc
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item> -->
          </mat-list>
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
            <span class="list-label">Konto</span>
          </div>
          <mat-list role="list">
            <mat-list-item role="listitem">
              <button mat-button fxLayout="row" class="button-list" fxLayoutAlign=" center" [routerLink]="['/app', 'edit-contractor-profile']">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Ustawienia konta
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item>
            <mat-list-item [roles]="['admin']" role="listitem">
              <button mat-button fxLayout="row" class="button-list" fxLayoutAlign=" center" [routerLink]="['/app', 'admin']">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Ustawienia administracyjne
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item>
            <mat-list-item role="listitem" (click)="logout()">
              <button mat-button fxLayout="row" class="button-list-last" fxLayoutAlign=" center">
              <span fxFlex="90" fxLayoutAlign="start center">
                  Wyloguj się
              </span>
                <span class="material-icons" fxFlex="90" fxLayoutAlign="end center">
                  keyboard_arrow_right
                </span>
              </button>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
  </rs-mobile>
</responsive-switcher>
