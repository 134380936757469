import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from '../../../common-services';
@Component({
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  constructor(
    private maintenanceService: MaintenanceService,
    private router: Router) { }

  ngOnInit(): void {
    if (!this.maintenanceService.maintenance) {
      this.router.navigateByUrl('/');
    }
  }

}
