import { Component } from '@angular/core';
import { AuthService } from '@replica-frontend/auth';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent {

  constructor( public authService: AuthService){}

}
