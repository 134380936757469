import { BehaviorSubject, from, fromEvent, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { map, tap, switchMap, last, filter } from 'rxjs/operators';
const CACHE_SIZE = 50; // store up to 50 images
const CACHE_TIMEOUT = 600;

export interface CachedImage {
  src: string;
  base64Url: string;
  created: Date;
}

@Injectable()
export class AvatarCacheService {
  imageMapSubject: Subject<Map<string, CachedImage>> = new Subject<Map<string, CachedImage>>(); // just map url to blob
  imageMap$ = this.imageMapSubject.asObservable();
  imageMapSubscriptions: Map<string, Subscription> = new Map<string, Subscription>();
  imageMap = new Map<string, CachedImage>();
  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) { }

  getImage(src: string): Observable<CachedImage> {
    // returns observable with image, while saving it.
    if (!src) {
      throw new Error('ImageCacheService: storeImage(src) is undefined.');
    }
    // if already cached
    if (this.imageMap.get(src)) {
      return of(this.imageMap.get(src));
      // if already being downloaded
    } else {
      // download the image
      if (!this.imageMapSubscriptions.get(src)) {
        console.log('downloading', src)
        this.imageMapSubscriptions.set(src, this.storeImage(src));
      }
      return this.imageMap$.pipe(
        map((cachedImages) => cachedImages.get(src)),
        filter(x => !!x)
      )
    }
  }

  // if (this.imageMap.get(src)) {
  //   console.log('got cached image');
  //   return of(this.imageMap.get(src));
  // } else if () {
  //   console.log('lets download new image')
  //   return this.storeImage(src);
  // }
  // check if src exists in map, if yes - share it as src string, if not - download, cache and share it


  // download and save image
  private storeImage(src: string): Subscription {
    return this.httpClient.get(
      `${this.environmentService.getEnv('apiUrl')}/${src}`)
      .pipe(
        switchMap((link: { path: string}) => {
          return this.httpClient.get(`${this.environmentService.getEnv('apiUrl')}/${link.path}`, { responseType: 'blob' });
        }),
        switchMap((blob) => {
          return from(new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = (event) => {
              resolve(reader.result);
            };
          }));
        }),
      ).subscribe((base64: string) => {
        const cachedImage = {
          src,
          created: new Date(),
          base64Url: base64
        };
        this.imageMap.set(src, cachedImage);
        this.imageMapSubject.next(this.imageMap);
      });
  }
}

