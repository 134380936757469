<responsive-switcher>
  <rs-desktop>
    <div fxLayout="row" class="main-container">
      <div fxLayout="column" class="first-container">
        <div fxLayout="row" class="top-left" fxLayoutAlign=" center">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxFlex="50"
            class="arrow-back-wrapper label-profile"
          >
            {{
              mine
                ? "Mój profil"
                : "Profil użytkownika " +
                  userData?.firstname +
                  " " +
                  userData?.lastname
            }}
          </div>
        </div>
        <mat-tab-group mat-align-tabs="start" disablePagination="true">
          <mat-tab
            isActive="true"
            label="Profil wykonawcy"
            class="contractor-span"
          >
            <div class="welcome-and-image-wrapper">
              <div class="welcome-and-image">
                <div fxLayout="column" class="welcome-container">
                  <div fxLayout="row">
                    <span
                      class="welcome-span"
                      *ngIf="profile?.welcomeTitle"
                      >{{ profile?.welcomeTitle }}</span
                    >
                    <span
                      class="welcome-span"
                      *ngIf="!profile?.welcomeTitle"
                      >Opis</span
                    >
                  </div>
                  <div fxLayout="row">
                    <span
                      class="bio-content"
                      *ngIf="profile?.welcomeDescription"
                    >
                      {{ profile?.welcomeDescription }}
                    </span>
                    <a
                      matTooltip="Dodaj opis"
                      matTooltipClass="tooltip"
                      [routerLink]="['/app', 'edit-contractor-profile']"
                      class="bio-content-none"
                      *ngIf="mine && !profile?.welcomeDescription"
                    >
                      Brak opisu
                    </a>
                    <span class="edit-icon-small" *ngIf="mine"></span>
                    <a
                      class="bio-content-none-friend"
                      *ngIf="!mine && !profile?.welcomeDescription"
                    >
                      Brak opisu
                    </a>
                  </div>
                </div>
                <div
                  fxLayout="column"
                  class="image-container-desktop"
                  *ngIf="profile?.profileMedia?.path"
                >
                  <!-- <a class="image"
      [ngStyle]="{'background-image': 'url('+     this.offersService.getImagePath(profile?.profileMedia.path)  +')'}"></a> -->
                  <a
                    *ngIf="profile?.profileMedia.path"
                    class="example-image"
                    [ngStyle]="{
                      'background-image':
                        'url(' +
                        commonHttpService.getImagePath(
                          profile?.profileMedia?.path
                        ) +
                        ')'
                    }"
                  ></a>
                </div>
              </div>
              <div class="statistics">
                <div fxLayout="row">
                  <div fxLayout="column" class="mini-card-desktop">
                    <span class="mini-card-number"
                      >{{ profile?.endedOffers }}
                    </span>
                    <span class="mini-card-description">
                      Wykonane zlecenia
                    </span>
                  </div>
                  <div fxLayout="column" class="mini-card-desktop">
                    <span class="mini-card-number">
                      {{ profile?.earned }} PLN</span
                    >
                    <span class="mini-card-description"
                      >Zarobionych ze zleceń</span
                    >
                  </div>
                  <div fxLayout="column" class="mini-card-desktop">
                    <span class="mini-card-number">
                      {{ profile?.averageSalary }} PLN
                    </span>
                    <span class="mini-card-description"
                      >Średnia kwota zlecenia</span
                    >
                  </div>
                  <!-- <div fxLayout="column" class="mini-card-desktop">
                      <span class="mini-card-number"> 42 </span>
                      <span class="mini-card-description"
                        >Przepracowane godziny</span
                      >
                    </div>
                    <div fxLayout="column" class="mini-card-desktop">
                      <span class="mini-card-number"> 50 PLN / h </span>
                      <span class="mini-card-description"
                        >Stawka godzinowa</span
                      >
                    </div> -->
                </div>
                <!-- <div fxLayout="row" class="statistics-second-row">

                    <div fxLayout="column" class="mini-card-desktop">
                      <span class="mini-card-number"></span>
                      <span class="mini-card-description"></span>
                    </div>
                  </div> -->
              </div>
              <div fxLayout="row" class="paginator-wrapper">
                <div fxFlex="60" fxLayoutAlign="start center">
                  <span class="finished-offers"> Zakończone zlecenia </span>
                </div>
                <div
                  fxFlex="40"
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="paginator"
                >
                  <!-- <mat-paginator [length]="length" [pageSize]="pageSize">
                    </mat-paginator> -->
                </div>
              </div>

              <app-offer-list-horizontal
                class="horizontal-item-desktop"
                (onOffersLoaded)="offersLoaded(offers)"
                queryMethod="getEndedOffers"
              ></app-offer-list-horizontal>

              <!-- <div fxLayout="row" class="paginator-wrapper">
                  <div fxFlex="60" fxLayoutAlign="start center">
                    <span class="finished-offers"> Rekomendacje </span>
                  </div>
                  <div
                    fxFlex="40"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="filter"
                  >
                    <span class="select-label">Sortuj:</span>
                    <select [(ngModel)]="selected" (change)="changed()">
                      <option
                        *ngFor="let filter of filtersList | async"
                        [value]="filter.value"
                      >
                        {{ filter.label }}
                      </option>
                    </select>
                  </div>
                </div> -->
              <!-- <div fxLayout="column" class="recommendation-wrapper">
                  <div fxLayout="row">
                    <span class="event-title">
                      Pomoc przy przygotowaniu eventu Samsunga
                    </span>
                  </div>
                  <div fxLayout="row">
                    <div fxLayoutAlign="start center">
                      <app-avatar class="mini-avatar"></app-avatar>
                      <span class="event-name">Max Bruce</span>
                    </div>
                    <div
                      fxLayoutAlign="start center"
                      fxLayout="row"
                      class="middle-event-title"
                    >
                      <a class="location-icon"></a>
                      <span class="event-name">Kraków</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <a class="clock-icon"></a>
                      <span class="date-span">24 kwiecień 2020</span>
                    </div>
                  </div>
                  <div fxLayout="row" class="recommendation-content">
                    Jimmy jest bardzo kompetentnym i szybko działającym
                    pracownikiem. Podczas naszej owocnej współpracy nie pojawiły
                    się żadne problemy czy to komunikacyjne czy organizacyjne.
                    Widać profesjonalne podejście do wykonawanej pracy oraz
                    sumienność w realizacji zadań. Mogę jedynie polecić go, jako
                    bardzo rzetelnego pracownika.
                    <br /><br />
                    Mam nadzieję, że to nie ostatnia takie zlecenie!
                  </div>
                </div>
                <div fxLayout="column" class="recommendation-wrapper-2">
                  <div fxLayout="row">
                    <span class="event-title">
                      Pomoc przy przygotowaniu eventu Samsunga
                    </span>
                  </div>
                  <div fxLayout="row">
                    <div fxLayoutAlign="start center">
                      <app-avatar class="mini-avatar"></app-avatar>
                      <span class="event-name">Max Bruce</span>
                    </div>
                    <div
                      fxLayoutAlign="start center"
                      fxLayout="row"
                      class="middle-event-title"
                    >
                      <a class="location-icon"></a>
                      <span class="event-name">Kraków</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <a class="clock-icon"></a>
                      <span class="date-span">24 kwiecień 2020</span>
                    </div>
                  </div>
                  <div fxLayout="row" class="recommendation-content">
                    Jimmy jest bardzo kompetentnym i szybko działającym
                    pracownikiem. Podczas naszej owocnej współpracy nie pojawiły
                    się żadne problemy czy to komunikacyjne czy organizacyjne.
                    Widać profesjonalne podejście do wykonawanej pracy oraz
                    sumienność w realizacji zadań. Mogę jedynie polecić go, jako
                    bardzo rzetelnego pracownika.
                    <br /><br />
                    Mam nadzieję, że to nie ostatnia takie zlecenie!
                  </div>
                </div> -->
            </div>
          </mat-tab>
          <mat-tab label="Profil zleceniodawcy" class="customer-span">
            <!-- Dodaj offer-liste mine -->
            <div class="welcome-and-image-wrapper">
              <div class="welcome-and-image">
                <div fxLayout="column" class="welcome-container">
                  <div fxLayout="row">
                    <span
                      class="welcome-span"
                      *ngIf="profile?.contratorTitle"
                      >{{ profile?.contratorTitle }}</span
                    >
                    <span
                      class="welcome-span"
                      *ngIf="!profile?.contratorTitle"
                      >Opis</span
                    >
                  </div>
                  <div fxLayout="row">
                    <span
                      class="bio-content"
                      *ngIf="profile?.contractorDescription"
                    >
                      {{ profile?.contractorDescription }}
                    </span>
                    <a
                      matTooltip="Dodaj opis"
                      matTooltipClass="tooltip"
                      [routerLink]="['/app', 'edit-contractor-profile']"
                      class="bio-content-none"
                      *ngIf="mine && !profile?.contractorDescription"
                    >
                      Brak opisu
                    </a>
                    <span class="edit-icon-small" *ngIf="mine"></span>
                    <a
                      class="bio-content-none-friend"
                      *ngIf="!mine && !profile?.contractorDescription"
                    >
                      Brak opisu
                    </a>
                  </div>
                </div>
                <div
                  fxLayout="column"
                  class="image-container-desktop"
                  *ngIf="commonHttpService.getImagePath(profile?.contractorProfileMedia?.path)"
                >
                  <!-- <a class="image"
      [ngStyle]="{'background-image': 'url('+     this.offersService.getImagePath(profile?.profileMedia.path)  +')'}"></a> -->
                  <a
                    *ngIf="profile?.contractorProfileMedia?.path"
                    class="example-image"
                    [ngStyle]="{
                      'background-image':
                        'url(' +
                        commonHttpService.getImagePath(
                          profile?.contractorProfileMedia?.path
                        ) +
                        ')'
                    }"
                  ></a>
                </div>
              </div>
              <div class="statistics">
                <div fxLayout="row">
                  <div fxLayout="column" class="mini-card-desktop">
                    <span class="mini-card-number"> 0 </span>
                    <span class="mini-card-description">
                      Wystawione zlecenia
                    </span>
                  </div>
                  <div fxLayout="column" class="mini-card-desktop">
                    <span class="mini-card-number"> 0 PLN</span>
                    <span class="mini-card-description">Łącznie zapłacono</span>
                  </div>
                  <div fxLayout="column" class="mini-card-desktop">
                    <span class="mini-card-number"> 0 PLN </span>
                    <span class="mini-card-description"
                      >Średnia kwota zlecenia</span
                    >
                  </div>
                  <!-- <div fxLayout="column" class="mini-card-desktop">
                      <span class="mini-card-number"> 42 </span>
                      <span class="mini-card-description"
                        >Przepracowane godziny</span
                      >
                    </div>
                    <div fxLayout="column" class="mini-card-desktop">
                      <span class="mini-card-number"> 50 PLN / h </span>
                      <span class="mini-card-description"
                        >Stawka godzinowa</span
                      >
                    </div> -->
                </div>
                <!-- <div fxLayout="row" class="statistics-second-row">

                    <div fxLayout="column" class="mini-card-desktop">
                      <span class="mini-card-number"></span>
                      <span class="mini-card-description"></span>
                    </div>
                  </div> -->
              </div>
              <div fxLayout="row" class="paginator-wrapper">
                <div fxFlex="60" fxLayoutAlign="start center">
                  <span class="finished-offers"> Zrealizowane zlecenia </span>
                </div>
                <div
                  fxFlex="40"
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="paginator"
                >
                  <!-- <mat-paginator [length]="length" [pageSize]="pageSize">
                    </mat-paginator> -->
                </div>
              </div>

              <app-offer-list-horizontal
                class="horizontal-item-desktop"
                (onOffersLoaded)="offersLoaded(offers)"
                queryMethod="getEndedOffers"
              ></app-offer-list-horizontal>
              <!-- <div fxLayout="row" class="paginator-wrapper">
                  <div fxFlex="60" fxLayoutAlign="start center">
                    <span class="finished-offers"> Rekomendacje </span>
                  </div>
                  <div
                    fxFlex="40"
                    fxLayout="row"
                    fxLayoutAlign="end center"
                    class="filter"
                  >
                    <span class="select-label">Sortuj:</span>
                    <select [(ngModel)]="selected" (change)="changed()">
                      <option
                        *ngFor="let filter of filtersList | async"
                        [value]="filter.value"
                      >
                        {{ filter.label }}
                      </option>
                    </select>
                  </div>
                </div> -->
              <!-- <div fxLayout="column" class="recommendation-wrapper">
                  <div fxLayout="row">
                    <span class="event-title">
                      Pomoc przy przygotowaniu eventu Samsunga
                    </span>
                  </div>
                  <div fxLayout="row">
                    <div fxLayoutAlign="start center">
                      <app-avatar class="mini-avatar"></app-avatar>
                      <span class="event-name">Max Bruce</span>
                    </div>
                    <div
                      fxLayoutAlign="start center"
                      fxLayout="row"
                      class="middle-event-title"
                    >
                      <a class="location-icon"></a>
                      <span class="event-name">Kraków</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <a class="clock-icon"></a>
                      <span class="date-span">24 kwiecień 2020</span>
                    </div>
                  </div>
                  <div fxLayout="row" class="recommendation-content">
                    Jimmy jest bardzo kompetentnym i szybko działającym
                    pracownikiem. Podczas naszej owocnej współpracy nie pojawiły
                    się żadne problemy czy to komunikacyjne czy organizacyjne.
                    Widać profesjonalne podejście do wykonawanej pracy oraz
                    sumienność w realizacji zadań. Mogę jedynie polecić go, jako
                    bardzo rzetelnego pracownika.
                    <br /><br />
                    Mam nadzieję, że to nie ostatnia takie zlecenie!
                  </div>
                </div>
                <div fxLayout="column" class="recommendation-wrapper-2">
                  <div fxLayout="row">
                    <span class="event-title">
                      Pomoc przy przygotowaniu eventu Samsunga
                    </span>
                  </div>
                  <div fxLayout="row">
                    <div fxLayoutAlign="start center">
                      <app-avatar class="mini-avatar"></app-avatar>
                      <span class="event-name">Max Bruce</span>
                    </div>
                    <div
                      fxLayoutAlign="start center"
                      fxLayout="row"
                      class="middle-event-title"
                    >
                      <a class="location-icon"></a>
                      <span class="event-name">Kraków</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <a class="clock-icon"></a>
                      <span class="date-span">24 kwiecień 2020</span>
                    </div>
                  </div>
                  <div fxLayout="row" class="recommendation-content">
                    Jimmy jest bardzo kompetentnym i szybko działającym
                    pracownikiem. Podczas naszej owocnej współpracy nie pojawiły
                    się żadne problemy czy to komunikacyjne czy organizacyjne.
                    Widać profesjonalne podejście do wykonawanej pracy oraz
                    sumienność w realizacji zadań. Mogę jedynie polecić go, jako
                    bardzo rzetelnego pracownika.
                    <br /><br />
                    Mam nadzieję, że to nie ostatnia takie zlecenie!
                  </div>
                </div> -->
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div fxLayout="column" class="second-container">
        <div fxLayout="column">
          <div fxLayout="row" class="panel-wrapper">
            <div class="big-avatar-wrapper" fxLayoutAlign="start center">
              <!-- TODO: Zamiast dodawac klase "big" przekaz (i utworz) parametr size, i jesli przekazesz 'small' to bierzesz css z jednej klasy a jesli 'big' to z drugiej. W htmlu dodac jakis ngClass -->
              <replica-avatar
                *ngIf="!mine"
                [userId]="id"
                class="big-avatar"
              ></replica-avatar>
              <replica-avatar
                *ngIf="mine"
                class="big-avatar"
              ></replica-avatar>
            </div>
            <div
              fxLayout="column"
              class="name-and-location"
              fxLayoutAlign="center start"
            >
              <span class="name"
                >{{ userData?.firstname }} {{ userData?.lastname }}</span
              >

              <div fxLayout="row">
                <span class="date-span">{{ profile?.email }}</span>
              </div>
            </div>
            <div fxLayoutAlign="end start">
              <!-- <a class="more-icon"></a> -->
            </div>
          </div>
          <div fxLayout="row" class="buttons">
            <button
              mat-fab
              color="primary"
              class="chat-button"
              *ngIf="!mine && authService.me"
              mat-dialog-close
              [routerLink]="['/app', 'ext', 'messenger']"
              [queryParams]="{ user: userData?.id }"
              queryParamsHandling="merge"
              matTooltip="Napisz wiadomość"
              matTooltipClass="tooltip"
            >
              <a class="icon-mail" fxLayoutAlign="center center"></a>
            </button>
            <button
              mat-fab
              color="primary"
              class="phone-button"
              matTooltip="Zadzwoń"
              matTooltipClass="tooltip"
              *ngIf="profile?.phoneNumber"
            >
              <a
                class="icon-phone"
                [href]="getPhone(profile?.phoneNumber)"
                fxLayoutAlign="center center"
              ></a>
            </button>
            <app-button
              [color]="ButtonColors.PRIMARY"
              class="button-yellow"
              routerLink="/app/edit-contractor-profile"
              *ngIf="mine"
              tooltip="Edytuj profil"
              ><span>Edytuj dane</span></app-button
            >
            <!-- <app-button
                [color]="ButtonColors.PRIMARY"
                class="yellow-button"
                *ngIf="!mine && !friend"
              >
                <span
                  class="yellow-button-span"
                  (click)="profileFav(userData.profile?.id)"
                >
                  Dodaj do kontaktów
                </span>
              </app-button>
              <app-button
                [color]="ButtonColors.PRIMARY"
                class="yellow-button"
                *ngIf="!mine && friend"
              >
                <span
                  class="yellow-button-span"
                  (click)="profileFav(userData.profile?.id)"
                >
                  Usuń z kontaktów
                </span>
              </app-button> -->
          </div>
          <div fxLayout="column">
            <!-- <span
                *ngIf="profile?.welcomeTitle"
                class="profile-details-label-desktop"
              >
                Opis
              </span>
              <span class="profile-details-desktop">
                {{ profile?.welcomeTitle }}
              </span> -->
            <!-- <span
                *ngIf="profile?.welcomeDescription"
                class="profile-details-content-desktop"
              >
                {{ profile?.welcomeDescription }}
              </span> -->
            <span
              *ngIf="profile?.offersCategory"
              class="profile-details-desktop"
              >Kategorie zleceń</span
            >
            <span
              matTooltip="Kategorie"
              matTooltipClass="tooltip"
              *ngIf="profile?.offersCategory"
              class="profile-details-content-desktop"
            >
              {{ profile?.offersCategory.category }}
            </span>
            <!-- <span class="profile-details-description-label">
              Edukacja
            </span>
            <span class="profile-details-description-date">
              {{ education?.educationYears }}
            </span>
            <span class="profile-details-description-date-lebel">
              {{ education?.educationSchoolName }}
            </span>
            <span class="profile-details-description-education">
              {{ education?.educationSpecialization }}
            </span> -->
            <!-- <span class="profile-details-desktop">Pozostałe </span> -->
            <!-- <span class="profile-details-content-desktop">Wystąpienia publiczne</span> -->
            <!-- <span class="profile-details-label-desktop"> Kwalifikacje </span> -->

            <span
              class="profile-details-label-desktop-education"
              *ngIf="
                education?.educationYears ||
                education?.educationSchoolName ||
                education?.educationSpecialization
              "
            >
              Edukacja
            </span>
            <span
              matTooltip="Lata"
              matTooltipClass="tooltip"
              class="profile-details-description-date"
              *ngIf="education?.educationYears"
            >
              {{ education?.educationYears }}
            </span>
            <span
              matTooltip="Nazwa szkoły"
              matTooltipClass="tooltip"
              class="profile-details-description-date-lebel school-name"
              *ngIf="education?.educationSchool"
            >
              {{ education?.educationSchool }}
            </span>
            <span
              matTooltip="Specjalizacja"
              matTooltipClass="tooltip"
              class="profile-details-description-education"
              *ngIf="education?.educationSpecialization"
            >
              {{ education?.educationSpecialization }}
            </span>
            <span
              class="profile-details-label-desktop-education"
              *ngIf="
                profile?.facebook ||
                profile?.instagram ||
                profile?.twitter ||
                profile?.linkedin ||
                profile?.website
              "
            >
              Social media
            </span>
            <div
              fxLayoutAlign="start center"
              fxLayout="row"
              class="social-media-desktop"
            >
              <a
                matTooltip="Facebook"
                matTooltipClass="tooltip"
                [title]="profile?.facebook"
                class="icon-facebook"
                *ngIf="profile?.facebook"
                [href]="profile?.facebook"
                target="_blank"
              ></a>
              <a
                matTooltip="Instagram"
                matTooltipClass="tooltip"
                [title]="profile?.instagram"
                class="icon-instagram"
                *ngIf="profile?.instagram"
                [href]="profile?.instagram"
                target="_blank"
              ></a>
              <a
                matTooltip="Twitter"
                matTooltipClass="tooltip"
                [title]="profile?.twitter"
                class="icon-twitter"
                *ngIf="profile?.twitter"
                [href]="profile?.twitter"
                target="_blank"
              ></a>
              <a
                matTooltip="Linkedin"
                matTooltipClass="tooltip"
                [title]="profile?.linkedin"
                class="icon-linkedin"
                *ngIf="profile?.linkedin"
                [href]="profile?.linkedin"
                target="_blank"
              ></a>
              <span
                matTooltip="Strona internetowa"
                matTooltipClass="tooltip"
                [title]="profile?.website"
                class="material-icons website-icon"
                [href]="profile?.website"
                target="_blank"
                *ngIf="profile?.website"
                >public</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div class="profile-mobile-wrapper">
      <div fxLayout="column">
        <button
          fxLayoutAlign="center center"
          mat-icon-button
          (click)="back()"
          fxFlex
          class="back-button"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div fxLayout="row" class="avatar-panel">
          <div fxFlex="30" fxLayoutAlign="center center">
            <div class="avatar">
              <app-avatar [userId]="id || userData?.id"></app-avatar>
            </div>
          </div>

          <div fxLayout="column" class="name-container">
            <span class="name"
              >{{ userData?.firstname }} {{ userData?.lastname }}</span
            >
            <div fxLayout="row" *ngIf="profile?.city">
              <a class="location-icon"></a>
              <span class="location-span">{{ profile?.city }}</span>
            </div>
            <div fxLayout="row" *ngIf="!profile?.city">
              <mat-icon *ngIf="profile?.email" class="email-icon"
                >alternate_email</mat-icon
              >
              <span class="location-span">{{ profile?.email }}</span>
            </div>
          </div>
          <div>
            <!-- <a class="more-icon"></a> -->
          </div>
        </div>
        <div fxLayout="row" *ngIf="id" class="buttons-container">
          <button
            mat-fab
            color="primary"
            class="chat-button"
            *ngIf="!mine && authService.me"
            mat-dialog-close
            [routerLink]="['/app', 'ext', 'messenger']"
            [queryParams]="{ user: userData?.id }"
            queryParamsHandling="merge"
          >
            <a class="icon-mail" fxLayoutAlign="center center"></a>
          </button>
          <button
            mat-fab
            color="primary"
            class="phone-button"
            *ngIf="!mine && profile?.phoneNumber"
          >
            <a class="icon-phone" fxLayoutAlign="center center"></a>
          </button>
          <!-- <app-button
              [color]="ButtonColors.PRIMARY"
              class="yellow-button"
              *ngIf="!mine && !friend"
            >
              <span
                class="yellow-button-span"
                (click)="profileFav(userData.profile?.id)"
              >
                Dodaj do kontaktów
              </span>
            </app-button> -->
          <!-- <app-button
              [color]="ButtonColors.PRIMARY"
              class="yellow-button"
              *ngIf="!mine && friend"
            >
              <span
                class="yellow-button-span"
                (click)="profileFav(userData.profile?.id)"
              >
                Usuń z kontaktów
              </span>
            </app-button> -->
        </div>
        <div fxLayout="row" class="profiles">
          <mat-tab-group mat-align-tabs="start" disablePagination="true">
            <mat-tab label="Profil wykonawcy" class="contractor-span">
              <div
                fxLayout="row"
                *ngIf="profile?.profileMedia"
                fxLayoutAlign="center center"
                class="image-container"
              >
                <a
                  class="image"
                  [ngStyle]="{
                    'background-image':
                      'url(' +
                      commonHttpService.getImagePath(
                        profile?.profileMedia.path
                      ) +
                      ')'
                  }"
                ></a>
              </div>
              <div fxLayout="column" class="container">
                <div fxLayout="row" *ngIf="profile?.welcomeTitle">
                  <span class="welcome-span">{{
                    profile?.welcomeTitle
                  }}</span>
                </div>
                <div
                  fxLayout="row"
                  class="bio-content"
                  *ngIf="profile?.welcomeDescription"
                >
                  {{ profile?.welcomeDescription }}
                </div>
                <mat-accordion class="accordion-panel">
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title> Szczegóły profilu </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div fxLayout="column" class="first-panel">
                      <span
                        class="profile-details-label"
                        *ngIf="profile?.description"
                      >
                        Opis
                      </span>
                      <!-- TODO: no data to display -->
                      <!-- <span class="profile-details-description-label">
                        Sprawię, że prezentacja Twojego projektu będzie wyjątkowa!
                      </span> -->
                      <span
                        class="profile-details-description"
                        *ngIf="profile?.description"
                      >
                        {{ profile?.description }}
                      </span>
                      <span
                        *ngIf="profile?.offersCategory"
                        class="profile-details-label"
                      >
                        Kategorie zleceń
                      </span>
                      <span
                        matTooltip="Kategorie"
                        matTooltipClass="tooltip"
                        *ngIf="profile?.offersCategory"
                        class="profile-details-content-mobile"
                      >
                        {{ profile?.offersCategory.category }}
                      </span>
                      <!-- <span class="profile-details-description-label">
                          Pozostałe
                        </span> -->
                      <!-- <span class="profile-details-description">
                          Wystąpienia publiczne
                        </span> -->
                      <!-- <span class="profile-details-label">
                          Kwalifikacje
                        </span> -->
                      <!-- <div fxLayout="row" class="chips">
                          <app-chips></app-chips>
                        </div> -->
                      <span
                        class="profile-details-description-label"
                        *ngIf="
                          education?.educationYears ||
                          education?.educationSchoolName ||
                          education?.educationSpecialization
                        "
                      >
                        Edukacja
                      </span>
                      <span
                        matTooltip="Lata"
                        matTooltipClass="tooltip"
                        class="profile-details-description-date"
                        *ngIf="education?.educationYears"
                      >
                        {{ education?.educationYears }}
                      </span>
                      <span
                        matTooltip="Nazwa szkoły"
                        matTooltipClass="tooltip"
                        class="profile-details-description-date-lebel"
                        *ngIf="education?.educationSchoolName"
                      >
                        {{ education?.educationSchoolName }}
                      </span>
                      <span
                        matTooltip="Specjalizacja"
                        matTooltipClass="tooltip"
                        class="profile-details-description-education"
                        *ngIf="education?.educationSpecialization"
                      >
                        {{ education?.educationSpecialization }}
                      </span>
                      <span
                        class="profile-details-label"
                        *ngIf="
                          profile?.facebook ||
                          profile?.instagram ||
                          profile?.twitter ||
                          profile?.linkedin ||
                          profile?.website
                        "
                      >
                        Social media
                      </span>
                      <div fxLayout="row" class="socialmedia">
                        <a
                          matTooltip="Facebook"
                          matTooltipClass="tooltip"
                          [title]="profile?.facebook"
                          class="icon-facebook"
                          *ngIf="profile?.facebook"
                          [href]="profile?.facebook"
                          target="_blank"
                        ></a>
                        <a
                          matTooltip="Instagram"
                          matTooltipClass="tooltip"
                          [title]="profile?.instagram"
                          class="icon-instagram"
                          *ngIf="profile?.instagram"
                          [href]="profile?.instagram"
                          target="_blank"
                        ></a>
                        <a
                          matTooltip="Twitter"
                          matTooltipClass="tooltip"
                          [title]="profile?.twitter"
                          class="icon-twitter"
                          *ngIf="profile?.twitter"
                          [href]="profile?.twitter"
                          target="_blank"
                        ></a>
                        <a
                          matTooltip="Linkedin"
                          matTooltipClass="tooltip"
                          [title]="profile?.linkedin"
                          class="icon-linkedin"
                          *ngIf="profile?.linkedin"
                          [href]="profile?.linkedin"
                          target="_blank"
                        ></a>
                        <span
                          matTooltip="Strona internetowa"
                          matTooltipClass="tooltip"
                          [title]="profile?.website"
                          class="material-icons website-icon"
                          [href]="profile?.website"
                          target="_blank"
                          *ngIf="profile?.website"
                          >public</span
                        >
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title> Działalność </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column">
                      <div fxLayout="row" class="mini-cards">
                        <div class="mini-card-container" fxLayout="column">
                          <span class="mini-card-number">{{
                            profile?.endedOffers
                          }}</span>
                          <span class="mini-card-description"
                            >Wykonane zlecenia</span
                          >
                        </div>
                        <div class="mini-card-container" fxLayout="column">
                          <span class="mini-card-number"
                            >{{ profile?.earned }} PLN</span
                          >
                          <span class="mini-card-description"
                            >Zarobionych ze zleceń</span
                          >
                        </div>
                        <div class="mini-card-container" fxLayout="column">
                          <span class="mini-card-number"
                            >{{ profile?.averageSalary }} PLN</span
                          >
                          <span class="mini-card-description"
                            >Średnia kwota zlecenia</span
                          >
                        </div>
                      </div>
                      <span class="profile-details-label padding-left">
                        Zakończone zlecenia
                      </span>

                      <app-offer-list-horizontal
                        (onOffersLoaded)="offersLoaded(offers)"
                        queryMethod="getEndedOffers"
                      ></app-offer-list-horizontal>

                      <!-- <div fxLayout="row" class="recommendation">
                          <span
                            class="recommendation-label"
                            fxLayoutAlign="start center"
                            fxFlex="50"
                          >
                            Rekomendacje
                          </span>
                          <div
                            fxLayout="row"
                            fxLayoutAlign="end center"
                            fxFlex="50"
                          >
                            <span class="select-label">Sortuj:</span>
                            <select [(ngModel)]="selected" (change)="changed()">
                              <option
                                *ngFor="let filter of filtersList | async"
                                [value]="filter.value"
                              >
                                {{ filter.label }}
                              </option>
                            </select>
                          </div>
                        </div> -->

                      <!-- <div fxLayout="column">
                          <span class="single-recommendation-title">
                            Pomoc przy przygotowaniu eventu Samsunga
                          </span>
                          <div fxLayout="row" class="avatar-small-container">
                            <div class="avatar-small">
                              <app-avatar></app-avatar>
                            </div>
                            <span class="avatar-small-label"> Max Bruce </span>
                          </div>
                          <div fxLayout="row" class="date-container">
                            <a class="location-icon"></a>
                            <span class="date-span">Kraków</span>
                            <a class="clock-icon"></a>
                            <span class="date-span">24 kwiecień 2020</span>
                          </div>
                          <div fxLayout="row" class="recommendation-content">
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            <br /><br />
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                          </div>
                          <div class="divider"></div>
                        </div> -->
                      <div fxLayout="column">
                        <!-- <span class="single-recommendation-title">
                            Pomoc przy przygotowaniu eventu Samsunga
                          </span>
                          <div fxLayout="row" class="avatar-small-container">
                            <div class="avatar-small">
                              <app-avatar></app-avatar>
                            </div>
                            <span class="avatar-small-label"> Max Bruce </span>
                          </div>
                          <div fxLayout="row" class="date-container">
                            <a class="location-icon"></a>
                            <span class="date-span">Kraków</span>
                            <a class="clock-icon"></a>
                            <span class="date-span">24 kwiecień 2020</span>
                          </div>
                          <div fxLayout="row" class="recommendation-content">
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            <br /><br />
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                          </div>
                          <div class="divider"></div>
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <app-button
                              [color]="ButtonColors.SECONDARY"
                              class="button-blue"
                            >
                              <span class="button-span">Wyświetl więcej</span>
                            </app-button>
                          </div> -->
                        <div class="divider"></div>
                        <div fxLayout="row" class="avatar-panel">
                          <div fxFlex="30" fxFlex fxLayoutAlign="center center">
                            <div class="avatar">
                              <app-avatar [userId]="id"></app-avatar>
                            </div>
                          </div>

                          <div fxLayout="column" class="name-container">
                            <span class="name"
                              >{{ userData?.firstname }}
                              {{ userData?.lastname }}</span
                            >
                            <div fxLayout="row" *ngIf="profile?.city">
                              <a class="location-icon"></a>
                              <span class="location-span">{{
                                profile?.city
                              }}</span>
                            </div>
                            <div
                              fxLayout="row"
                              *ngIf="!profile?.city"
                            >
                              <mat-icon
                                *ngIf="profile?.email"
                                class="email-icon"
                                >alternate_email</mat-icon
                              >
                              <span class="location-span">{{
                                profile?.email
                              }}</span>
                            </div>
                          </div>
                          <div>
                            <!-- <a class="more-icon"></a> -->
                          </div>
                        </div>
                        <div
                          fxLayout="row"
                          class="buttons-container"
                          *ngIf="id"
                        >
                          <button
                            mat-fab
                            color="primary"
                            class="chat-button"
                            *ngIf="!mine && authService.me"
                            mat-dialog-close
                            [routerLink]="['/app', 'ext', 'messenger']"
                            [queryParams]="{ user: userData?.id }"
                            queryParamsHandling="merge"
                          >
                            <a
                              class="icon-mail"
                              fxLayoutAlign="center center"
                            ></a>
                          </button>
                          <button mat-fab color="primary" class="phone-button">
                            <a
                              class="icon-phone"
                              fxLayoutAlign="center center"
                            ></a>
                          </button>
                          <!-- <app-button
                              [color]="ButtonColors.PRIMARY"
                              class="yellow-button"
                              ><span class="yellow-button-span">
                                Dodaj do kontaktów
                              </span>
                            </app-button> -->
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Profil zleceniodawcy" class="customer-span">
              <div
                fxLayout="row"
                *ngIf="profile?.contractorProfileMedia?.path"
                fxLayoutAlign="center center"
                class="image-container"
              >
                <a
                  class="image"
                  [ngStyle]="{
                    'background-image':
                      'url(' +
                      commonHttpService.getImagePath(
                        profile?.contractorProfileMedia?.path
                      ) +
                      ')'
                  }"
                ></a>
              </div>
              <div fxLayout="column" class="container">
                <div fxLayout="row" *ngIf="profile?.contratorTitle">
                  <span class="welcome-span">{{
                    profile?.contratorTitle
                  }}</span>
                </div>
                <div
                  fxLayout="row"
                  class="bio-content"
                  *ngIf="profile?.contractorDescription"
                >
                  {{ profile?.contractorDescription }}
                </div>
                <mat-accordion class="accordion-panel">
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title> Szczegóły profilu </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div fxLayout="column" class="first-panel">
                      <span
                        class="profile-details-label"
                        *ngIf="profile?.description"
                      >
                        Opis
                      </span>
                      <!-- TODO: no data to display -->
                      <!-- <span class="profile-details-description-label">
                        Sprawię, że prezentacja Twojego projektu będzie wyjątkowa!
                      </span> -->
                      <span
                        class="profile-details-description"
                        *ngIf="profile?.description"
                      >
                        {{ profile?.description }}
                      </span>
                      <span
                        *ngIf="profile?.offersCategory"
                        class="profile-details-label"
                      >
                        Kategorie zleceń
                      </span>
                      <span
                        *ngIf="profile?.offersCategory"
                        class="profile-details-content-mobile"
                      >
                        {{ profile?.offersCategory.category }}
                      </span>
                      <!-- <span class="profile-details-description-label">
                          Pozostałe
                        </span> -->
                      <!-- <span class="profile-details-description">
                          Wystąpienia publiczne
                        </span> -->
                      <!-- <span class="profile-details-label">
                          Kwalifikacje
                        </span> -->
                      <!-- <div fxLayout="row" class="chips">
                          <app-chips></app-chips>
                        </div> -->

                      <span
                        class="profile-details-description-label"
                        *ngIf="
                          education?.educationYears ||
                          education?.educationSchoolName ||
                          education?.educationSpecialization
                        "
                      >
                        Edukacja
                      </span>
                      <span
                        class="profile-details-description-date"
                        *ngIf="education?.educationYears"
                      >
                        {{ education?.educationYears }}
                      </span>
                      <span
                        class="profile-details-description-date-lebel"
                        *ngIf="education?.educationSchoolName"
                      >
                        {{ education?.educationSchoolName }}
                      </span>
                      <span
                        class="profile-details-description-education"
                        *ngIf="education?.educationSpecialization"
                      >
                        {{ education?.educationSpecialization }}
                      </span>
                      <span
                        class="profile-details-label"
                        *ngIf="
                          profile?.facebook ||
                          profile?.instagram ||
                          profile?.twitter ||
                          profile?.linkedin ||
                          profile?.website
                        "
                      >
                        Social media
                      </span>
                      <div fxLayout="row" class="socialmedia">
                        <a
                          [title]="profile?.facebook"
                          class="icon-facebook"
                          *ngIf="profile?.facebook"
                          [href]="profile?.facebook"
                          target="_blank"
                        ></a>
                        <a
                          [title]="profile?.instagram"
                          class="icon-instagram"
                          *ngIf="profile?.instagram"
                          [href]="profile?.instagram"
                          target="_blank"
                        ></a>
                        <a
                          [title]="profile?.twitter"
                          class="icon-twitter"
                          *ngIf="profile?.twitter"
                          [href]="profile?.twitter"
                          target="_blank"
                        ></a>
                        <a
                          [title]="profile?.linkedin"
                          class="icon-linkedin"
                          *ngIf="profile?.linkedin"
                          [href]="profile?.linkedin"
                          target="_blank"
                        ></a>
                        <span
                          [title]="profile?.website"
                          class="material-icons website-icon"
                          [href]="profile?.website"
                          target="_blank"
                          *ngIf="profile?.website"
                          >public</span
                        >
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title> Działalność </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column">
                      <div fxLayout="row" class="mini-cards">
                        <div class="mini-card-container" fxLayout="column">
                          <span class="mini-card-number">0</span>
                          <span class="mini-card-description"
                            >Wystawione zlecenia</span
                          >
                        </div>
                        <div class="mini-card-container" fxLayout="column">
                          <span class="mini-card-number">0 PLN</span>
                          <span class="mini-card-description"
                            >Łącznie zapłacono</span
                          >
                        </div>
                        <div class="mini-card-container" fxLayout="column">
                          <span class="mini-card-number">0 PLN</span>
                          <span class="mini-card-description"
                            >Średnia kwota zlecenia</span
                          >
                        </div>
                      </div>
                      <span class="profile-details-label padding-left">
                        Zrealizowane zlecenia
                      </span>

                      <app-offer-list-horizontal
                        class="horizontal-item-mobile"
                        (onOffersLoaded)="offersLoaded(offers)"
                        queryMethod="getEndedOffers"
                      ></app-offer-list-horizontal>

                      <!-- <div fxLayout="row" class="recommendation">
                          <span
                            class="recommendation-label"
                            fxLayoutAlign="start center"
                            fxFlex="50"
                          >
                            Rekomendacje
                          </span>
                          <div
                            fxLayout="row"
                            fxLayoutAlign="end center"
                            fxFlex="50"
                          >
                            <span class="select-label">Sortuj:</span>
                            <select [(ngModel)]="selected" (change)="changed()">
                              <option
                                *ngFor="let filter of filtersList | async"
                                [value]="filter.value"
                              >
                                {{ filter.label }}
                              </option>
                            </select>
                          </div>
                        </div> -->

                      <!-- <div fxLayout="column">
                          <span class="single-recommendation-title">
                            Pomoc przy przygotowaniu eventu Samsunga
                          </span>
                          <div fxLayout="row" class="avatar-small-container">
                            <div class="avatar-small">
                              <app-avatar></app-avatar>
                            </div>
                            <span class="avatar-small-label"> Max Bruce </span>
                          </div>
                          <div fxLayout="row" class="date-container">
                            <a class="location-icon"></a>
                            <span class="date-span">Kraków</span>
                            <a class="clock-icon"></a>
                            <span class="date-span">24 kwiecień 2020</span>
                          </div>
                          <div fxLayout="row" class="recommendation-content">
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            <br /><br />
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                          </div>
                          <div class="divider"></div>
                        </div> -->
                      <div fxLayout="column">
                        <!-- <span class="single-recommendation-title">
                            Pomoc przy przygotowaniu eventu Samsunga
                          </span>
                          <div fxLayout="row" class="avatar-small-container">
                            <div class="avatar-small">
                              <app-avatar></app-avatar>
                            </div>
                            <span class="avatar-small-label"> Max Bruce </span>
                          </div>
                          <div fxLayout="row" class="date-container">
                            <a class="location-icon"></a>
                            <span class="date-span">Kraków</span>
                            <a class="clock-icon"></a>
                            <span class="date-span">24 kwiecień 2020</span>
                          </div>
                          <div fxLayout="row" class="recommendation-content">
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            <br /><br />
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                            Chcę dostawać wiadomości o ofertach dopasowanych dla
                            mnie oraz informacjach jak usprawnić mój profil.
                          </div>
                          <div class="divider"></div>
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <app-button
                              [color]="ButtonColors.SECONDARY"
                              class="button-blue"
                            >
                              <span class="button-span">Wyświetl więcej</span>
                            </app-button>
                          </div> -->
                        <div class="divider"></div>
                        <div fxLayout="row" class="avatar-panel">
                          <div fxFlex="30" fxFlex fxLayoutAlign="center center">
                            <div class="avatar">
                              <app-avatar [userId]="userData?.id"></app-avatar>
                            </div>
                          </div>

                          <div fxLayout="column" class="name-container">
                            <span class="name"
                              >{{ userData?.firstname }}
                              {{ userData?.lastname }}</span
                            >
                            <div fxLayout="row" *ngIf="profile?.city">
                              <a class="location-icon"></a>
                              <span class="location-span">{{
                                profile?.city
                              }}</span>
                            </div>
                            <div
                              fxLayout="row"
                              *ngIf="!profile?.city"
                            >
                              <mat-icon
                                *ngIf="profile?.email"
                                class="email-icon"
                                >alternate_email</mat-icon
                              >
                              <span class="location-span">{{
                                profile?.email
                              }}</span>
                            </div>
                          </div>
                          <div>
                            <!-- <a class="more-icon"></a> -->
                          </div>
                        </div>
                        <div
                          fxLayout="row"
                          class="buttons-container"
                          *ngIf="id"
                        >
                          <button
                            mat-fab
                            color="primary"
                            class="chat-button"
                            *ngIf="!mine"
                            mat-dialog-close
                            [routerLink]="['/app', 'ext', 'messenger']"
                            [queryParams]="{ user: userData?.id }"
                            queryParamsHandling="merge"
                          >
                            <a
                              class="icon-mail"
                              fxLayoutAlign="center center"
                            ></a>
                          </button>
                          <button mat-fab color="primary" class="phone-button">
                            <a
                              class="icon-phone"
                              fxLayoutAlign="center center"
                            ></a>
                          </button>
                          <!-- <app-button
                              [color]="ButtonColors.PRIMARY"
                              class="yellow-button"
                              ><span class="yellow-button-span">
                                Dodaj do kontaktów
                              </span>
                            </app-button> -->
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </rs-mobile>
</responsive-switcher>
