<responsive-switcher>
  <rs-desktop>
    <div fxLayout="row" class="container-yellow">
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
        <div fxLayout="row" class="span-container">
          <span class="newsletter-span">
            Chcesz otrzymywać oferty idealne dla Ciebie?
          </span>
        </div>

        <div fxLayout="row" class="input-wrapper-2">
          <div class="input-wrapper">
            <a fxLayoutAlign="center center" class="mail-icon"> </a>
            <input
              matTooltip="Podaj swój email"
              matTooltipClass="tooltip"
              class="input-container"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxFlex="100"
              placeholder="Podaj swój adres email"
            />
            <app-button
              tooltip="Zapisz mnie"
              [color]="ButtonColors.BLACK"
              class="newsletter-button"
            >
              <span class="button-span" [routerLink]="['/', 'sign-in']"
                >Zapisz mnie do newslettera</span
              >
            </app-button>
          </div>
        </div>
      </div>
      <div class="newsletter-image-container" fxLayoutAlign="center center">
        <img
          fxLayoutAlign="center center"
          class="newsletter-image"
          src="../../../assets/newsletter-image.svg"
        />
      </div>
    </div>
  </rs-desktop>
</responsive-switcher>
<responsive-switcher>
  <rs-mobile>
    <div fxLayout="row" class="container-yellow" fxLayoutAlign="center center">
      <div
        fxLayout="column"
        class="span-container"
        fxLayoutAlign="center center"
      >
        <span class="newsletter-span">
          Chcesz otrzymywać oferty idealne dla Ciebie?
        </span>
        <div class="input-wrapper">
          <a fxLayoutAlign="center center" class="mail-icon"> </a>
          <input
            class="input-container"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="100"
            placeholder="Podaj swój adres email"
          />
        </div>
        <app-button
          [color]="ButtonColors.BLACK"
          class="newsletter-button"
          [routerLink]="['/', 'sign-in']"
        >
          <span class="button-span">Zapisz mnie</span>
        </app-button>
      </div>
    </div>
  </rs-mobile>
</responsive-switcher>
