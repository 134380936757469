import { Component } from '@angular/core';
import { MaintenanceService } from '../../common-services/maintenance.service';

@Component({
  selector: 'app-footer-panel',
  templateUrl: './footer-panel.component.html',
  styleUrls: ['./footer-panel.component.scss']
})
export class FooterPanelComponent {
  maintenance = false;
  constructor(private maintenanceService: MaintenanceService) {

  }
  ngOnInit(): void {
    this.maintenance = this.maintenanceService.maintenance;
  }


}
