import {ButtonRoundComponent} from './button-round/button-round.component';
import {MatListModule} from '@angular/material/list';
import {SelectButtonComponent} from './select-button/select-button.component';
import {MatIconModule} from '@angular/material/icon';
import {LOCALE_ID, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AvatarComponent} from './avatar/avatar.component';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxFileDropModule} from 'ngx-file-drop';
import {ResponsiveSwitcherComponent} from './responsive-switcher/responsive-switcher.component';
import {ButtonComponent} from './button/button.component';
import {VersionInfoComponent} from './version-info/version-info.component';
import {SelectInputComponent} from './select-input/select-input.component';
import {SelectOptionsListComponent} from './select-options.list/select-options-list.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {GeoSelectComponent} from './geo-select/geo-select.component';
import {FormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AuthModule} from '@replica-frontend/auth';
import {LoadingViewComponent} from '../common-components/loading-view/loading-view.component';


@NgModule({
  declarations: [
    AvatarComponent,
    ResponsiveSwitcherComponent,
    ButtonComponent,
    SelectButtonComponent,
    ButtonRoundComponent,
    VersionInfoComponent,
    SelectInputComponent,
    SelectOptionsListComponent,
    GeoSelectComponent,
    LoadingViewComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    NgxFileDropModule,
    MatListModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatTooltipModule,
    AuthModule.forFeature()
  ],
  exports: [
    AvatarComponent,
    ResponsiveSwitcherComponent,
    ButtonComponent,
    SelectButtonComponent,
    ButtonRoundComponent,
    VersionInfoComponent,
    SelectInputComponent,
    SelectOptionsListComponent,
    GeoSelectComponent,
    LoadingViewComponent
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pl-PL'}
  ],
})
export class CommonComponentsModule {

}
