<responsive-switcher>
  <rs-desktop>
    <div fxLayout="row" fxFlex="100">
      <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="input-container input-container-closed"
      floatLabel="never"
      [ngClass]="{
        error: !!error
      }"
    >
      <div class="location-icon-wrapper" fxLayoutAlign="center center">
        <a class="location-icon"></a>
      </div>

      <input
        *ngIf="!value"
        (input)="searchCities($event)"
        fxFlex="90"
        placeholder="Wprowadz adres"
      />
      <div
        class="search-second-value"
        fxLayout="row"
        *ngIf="value"
        fxLayoutAlign="center center"
        
      >
        <div class="search-span-label" *ngIf="value">
          {{ value.label }}
        </div>
        <button
          class="close-button"
          *ngIf="value"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="writeValue(null)"
        >
          <mat-icon class="close-icon">close</mat-icon>
        </button>
        <div class="arrow-container">
          <a class="arrow"></a>
        </div>
      </div>
    </div>
    <div
      (click)="opened = !opened"
      class="select-container select-container-closed"
  
      [ngClass]="{
        error: !!error
      }"
    >
      <span class="select-bar">|</span>
      <div class="container-select" fxFlex="70" fxLayout="column">
        <div class="search-input-container" fxLayout="row">
          <div
            *ngIf="leftIcon"
            fxLayout="row"
            fxFlex="10"
            fxLayoutAlign="center center"
            class="icon-location-wrapper"
          >
            <a class="icon-location"></a>
          </div>
          <div fxLayout="row"></div>
          <div
            class="search-second-value"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="100"
          >
            <div
              class="search-span-label label-color-value"
              *ngIf="secondValue"
            >
              {{ secondValue.label }}
            </div>
            <div
              class="search-span-label label-color-placeholder"
              *ngIf="!secondValue"
            >
              {{ secondPlaceholder }}
            </div>
            <button
              class="close-button"
              *ngIf="secondValue"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="writeSecondValue(null)"
            >
              <mat-icon class="close-icon">close</mat-icon>
            </button>
            <div class="arrow-container">
              <a class="arrow"></a>
            </div>
          </div>
        </div>

        <div class="options" *ngIf="opened">
          <div class="options-inner-container">
            <div class="option-wrapper first-options">
              <div
                *ngFor="let option of options"
                class="option"
                (click)="writeValue(option)"
              >
                {{ option.label }}
              </div>
            </div>
            <div class="search-option-wrapper first-second-options">
              <div
                *ngFor="let option of mileages"
                (click)="writeSecondValue(option)"
                class="search-option"
              >
                {{ option.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      mat-button
      (click)="submit()"
      [disabled]="!value || !secondValue"
      class="search-button"
    >
      <a class="search-icon"></a>
    </button>
    </div>
  
  </rs-desktop>
  <rs-mobile>
    <div
      (click)="opened = !opened"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxFlex="50"
      class="input-container input-container-closed"
      floatLabel="never"
      [ngClass]="{
        error: !!error
      }"
    >
      <a fxFlex="20" fxLayoutAlign="center center" class="location-icon"></a>
      <input
        *ngIf="!value"
        (input)="searchCities($event)"
        fxFlex="80"
        placeholder="Wprowadz adres"
      />
      <div
        class="search-second-value"
        fxLayout="row"
        *ngIf="value"
        fxLayoutAlign="center center"
        fxFlex="70"
      >
        <div class="search-span-label label-color-value" *ngIf="value">
          {{ value.label }}
        </div>
        <button
          class="close-button"
          *ngIf="value"
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="writeValue(null)"
        >
          <mat-icon class="close-icon">close</mat-icon>
        </button>
        <div class="arrow-container">
          <a class="arrow"></a>
        </div>
      </div>
    </div>
    <div
      (click)="opened = !opened"
      class="select-container select-container-closed"
      fxFlex="50"
      [ngClass]="{
        error: !!error
      }"
    >
      <span class="select-bar">|</span>
      <div class="container-select" fxFlex="70" fxLayout="column">
        <div class="search-input-container" fxLayout="row">
          <div
            *ngIf="leftIcon"
            fxLayout="row"
            fxFlex="10"
            fxLayoutAlign="center center"
            class="icon-location-wrapper"
          >
            <a class="icon-location"></a>
          </div>
          <div fxLayout="row"></div>
          <div
            class="search-second-value"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="100"
          >
            <div
              class="search-span-label label-color-value"
              *ngIf="secondValue"
            >
              {{ secondValue.label }}
            </div>
            <div
              class="search-span-label label-color-placeholder"
              *ngIf="!secondValue"
            >
              {{ secondPlaceholder }}
            </div>
            <button
              class="close-button"
              *ngIf="secondValue"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="writeSecondValue(null)"
            >
              <mat-icon class="close-icon">close</mat-icon>
            </button>
            <div class="arrow-container">
              <a class="arrow"></a>
            </div>
          </div>
        </div>

        <div class="options" *ngIf="opened">
          <div class="options-inner-container">
            <div class="option-wrapper first-options">
              <div
                *ngFor="let option of options"
                class="option"
                (click)="writeValue(option)"
              >
                {{ option.label }}
              </div>
            </div>
            <div class="search-option-wrapper first-second-options">
              <div
                *ngFor="let option of mileages"
                (click)="writeSecondValue(option)"
                class="search-option"
              >
                {{ option.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      mat-button
      (click)="submit()"
      [disabled]="!value || !secondValue"
      class="search-button"
    >
      <a class="search-icon"></a>
    </button>
  </rs-mobile>
</responsive-switcher>
