import {Component} from '@angular/core';

@Component({
  templateUrl: './mobile-ext-wrapper.component.html',
  styleUrls: ['./mobile-ext-wrapper.component.scss']
})
export class MobileExtWrapperComponent {
  constructor() {}

}
