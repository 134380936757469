import { Subscription } from 'rxjs';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { MessageMeta } from './../../../modules/messenger-components/components/app-messages/app-messages.component';
import { IMessageComponent } from './../../../modules/messenger-components/interfaces/message-component.interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Buddy, MessageFromDb } from '../../../modules/messenger-services/messenger.service';
import { AppliesService, OffersApplies, OfferApplyStatus } from '../../../modules/sp-services/applies.service';
import { AppliesSyncService } from '../../../modules/sp-services';
import { AuthService } from '@replica-frontend/auth';

@Component({
  selector: 'app-offer-message',
  templateUrl: './offer-message.component.html',
  styleUrls: ['./offer-message.component.scss']
})
export class OfferMessageComponent implements OnInit, IMessageComponent, OnDestroy {
  ButtonColors = BUTTON_COLORS;
  message: MessageFromDb;
  meta: MessageMeta;
  buddy: Buddy;
  offerApply: OffersApplies;
  loading = true;
  debug = false;
  error: boolean;
  employee?: boolean = null;
  OfferApplyStatus = OfferApplyStatus;
  isLast: boolean;
  appliesSyncSub: Subscription;
  constructor(
    private authService: AuthService,
    private appliesSyncService: AppliesSyncService,
    private appliesService: AppliesService
  ) { }

  ngOnInit(): void {
    if (this.meta.data.offer) {
      // this.appliesService.singleApply(this.meta.data.applyId)
      //   .subscribe((offerApply) => {
      //     // this.offerApply = offerApply;
      //     this.updateApply(offerApply);
      //     this.employee = this.authService.me.id !== this.offerApply.offerid.userid.id;
      //     this.loading = false;
      //   });
      this.appliesSyncSub = this.appliesSyncService.getApplyById$(this.meta.data.applyId)
        .subscribe((offerApply) => {
          this.updateApply(offerApply);
          this.employee = this.authService.me.id !== offerApply.offerid.userid.id;
          this.loading = false;
        });
    } else {
      console.error('Bad message format:', this.meta.data);
    }
  }

  approove(): void {
    this.loading = true;
    this.appliesService.aproove(this.offerApply.id)
      .subscribe((offerApply: OffersApplies) => this.onApplyUpdate(offerApply));
  }

  finalize(): void {
    this.loading = true;
    if (this.employee) {
      this.appliesService.finalizeAsEmployee(this.offerApply.id)
        .subscribe((offerApply: OffersApplies) => this.onApplyUpdate(offerApply));
    } else {
      this.appliesService.finalizeAsEmployer(this.offerApply.id)
        .subscribe((offerApply: OffersApplies) => this.onApplyUpdate(offerApply));
    }
  }

  unfinalize(): void {
    alert('to be implemented');
  }

  resignAsEmployer(): void {
    alert('to be implemented');
  }

  aproovePayment(): void {
    this.appliesService.aproovePayment(this.offerApply.id)
      .subscribe((offerApply: OffersApplies) => this.onApplyUpdate(offerApply));
  }

  private onApplyUpdate(offerApply): void {
    this.loading = false;
    this.updateApply(offerApply);
  }

  updateApply(apply: OffersApplies): void {
    this.offerApply = apply;
  }

  ngOnDestroy(): void {
    if (this.appliesSyncSub) {
      this.appliesSyncSub.unsubscribe();
    }
  }
}
