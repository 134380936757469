import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DefaultFilters } from '../../common-services';
import { City } from '../../common-services/common-http.service';
import { MapboxFeature, MapboxService } from '../../sp-services/mapbox.service';
import { HierarchicalOption } from '../interfaces/hierarchical-option.interface';
import { SelectInputChangeEvent } from '../select-input/select-input.component';

@Component({
    selector: 'common-geo-select',
    templateUrl: './geo-select.component.html',
    styleUrls: ['./geo-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GeoSelectComponent),
            multi: true,
        },
    ],
})
export class GeoSelectComponent implements ControlValueAccessor, OnInit {
    value = -1;
    onChange: (_: any) => void;
    onTouched: () => void;
    disabled = false;
    @Input()
    small: boolean;
    @Input()
    error: string;
    loadSub: Subscription;
    currentLevel = 1;
    offset = 0;
    optionsSubject: BehaviorSubject<HierarchicalOption<MapboxFeature>[]> =
        new BehaviorSubject<HierarchicalOption<MapboxFeature>[]>([]);
    options$ = this.optionsSubject.asObservable();
    currentFilters: DefaultFilters<City> = {
        filterBy: {},
        limit: 0,
        offset: 0
    };
    displayBack = false;
    constructor(private mapboxService: MapboxService) { }
    ngOnInit(): void {

    }

    writeValue(value: any): void {
        if (!value) return;
        if (value === -1) {
            // remove the value
            this.value = null;
            if (this.onChange) {
                this.onChange(null);
            }

            return;
        }
        this.value = value;
        if (this.onChange) {
            this.onChange(this.value);
        }
        // check if already has that option in local array. If hasn't, ask server by id
        if (this.optionsSubject.getValue().length === 0) {
            // ask server
        } else {
            // look deeper, if not found, ask server
        }

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    valueChanged(value): void {
        this.writeValue(value);
    }

    loadChildren(params: SelectInputChangeEvent<City>): void {
        console.log(params);
        if (params.value.lazyChildren) {
            // set filters for province
            // this.currentFilters = { filterBy: { province: params.value.value }, limit: 20, offset: this.offset };
            // this.commonHttpService.getCities(this.currentFilters).subscribe((cities) => {
            //     // const idx = this.options.findIndex(x => x.value === params.value);
            //     // this.options[idx].children = cities.map(x => ({
            //     //     label: x.label,
            //     //     value: x.value,
            //     //     lazyChildren: false
            //     // }));
            //     // TODO: cleanup other children
            //     const options = this.optionsSubject.getValue();
            //     const idx = options.findIndex(x => x.value === params.value.value);
            //     options[idx].activeNode = true;
            //     options[idx].children = cities.map(x => ({
            //         label: x.label,
            //         value: x.value,
            //         lazyChildren: false,
            //         activeNode: false
            //     }));
            //     this.optionsSubject.next(options);
            //     this.currentLevel++;
            // });
        }
    }

    // for now only one lvl deep
    unloadChildren(level: number): void {
        this.currentFilters = {
            filterBy: {},
            limit: 0,
            offset: 0
        };
        const options = this.optionsSubject.getValue();

        if (level < 0) { return; }
        // it's not an object
        // tslint:disable-next-line: forin
        // for (const i in options) {
        //     if (options[i].children) {
        //         delete options[i].children;
        //     }
        //     options[i].activeNode = false;
        // }
        this.optionsSubject.next([]);
        this.ngOnInit();
        this.displayBack = false;
    }

    loadMoreCities(): void {
        if (this.currentLevel === 1) return;
        const options = this.optionsSubject.getValue();
        const idx = options.findIndex(x => x.children && x.children.length > 0);
        this.currentFilters.offset = options[idx].children.length;
        // this.commonHttpService.getCities(this.currentFilters)
        //     .subscribe((cities) => {
        //         //
        //         options[idx].children = options[idx].children.concat(cities.map(x => ({
        //             label: x.label,
        //             value: x.value,
        //             lazyChildren: false,
        //             activeNode: false
        //         })));
        //         this.optionsSubject.next(options);
        //     });
    }

    searchCities($event): void {
        if (this.loadSub) {
            this.loadSub.unsubscribe();
        }
        this.mapboxService.searchPlaces($event).subscribe((response) => {
            console.log(response);
            this.optionsSubject.next(response.features.map(feature => ({
                activeNode: false,
                label: feature.place_name,
                children: [],
                lazyChildren: false,
                value: feature,
            })))
        })
        // this.loadSub = this.commonHttpService.getCities(this.currentFilters).subscribe((cities) => {
        //     const options = cities.map(x => ({
        //         label: x.label,
        //         value: x.value,
        //         lazyChildren: false,
        //         activeNode: false
        //     }));
        //     this.optionsSubject.next(options);
        //     this.displayBack = true;
        // });
    }
}
