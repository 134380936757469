<responsive-switcher>
  <rs-desktop>
    <div [ngClass]="{'logged': authService.me, 'guest': !authService.me}">
      <app-static-page [desktop]="true">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">

          <span fxLayoutAlign="center center" class="label" fxFlex="100">
            Jak to działa?</span>

          <div fxLayout="row" fxFlex="40">
            <span class="top-button-left" matTooltip="Szukam zleceń"
            matTooltipClass="tooltip">
              Szukam zleceń
            </span>
            <span class="top-button-right" matTooltip="Szukam pracowników"
            matTooltipClass="tooltip">
              Szukam pracowników
            </span>
          </div>

          <div fxLayout="row" class="margin-8" fxLayoutAlign="center center">
            <div fxLayout="column" class="how-it-works-container" fxLayoutAlign="start center">
              <div fxLayoutAlign="center center">
                <a class="how-it-works-1"></a>
              </div>

              <span class="how-it-works-1-label">1. Załóż swój profil wykonawcy</span>
              <span class="how-it-works-content">
                Nie zapomnij po założeniu konta uzupełnić informacji na swoim profilu, pozwoli nam proponować Ci zlecenia
                stworzone dla Ciebie.
              </span>

            </div>

            <div fxLayout="column" class="how-it-works-container" fxLayoutAlign="start center">
              <div fxLayoutAlign="start center">
                <a class="how-it-works-2"></a>
              </div>

              <span class="how-it-works-1-label">2. Przeglądaj aktualne oferty</span>
              <span class="how-it-works-content">
                Poszukuj pożądanych zleceń samodzielnie lub czekaj na bezpośrednie propozycje od zleceniodawcy.
              </span>

            </div>

          </div>

          <div fxLayout="row" class="margin-8" fxLayoutAlign="center center">
            <div fxLayout="column" class="how-it-works-container" fxLayoutAlign="start center">
              <div fxLayoutAlign="center center">
                <a class="how-it-works-3"></a>
              </div>

              <span class="how-it-works-1-label">3. Nawiąż współpracę ze zleceniodawcą</span>
              <span class="how-it-works-content">
                Udało się znaleźć odpowiednie zlecenie? Nawiąż kontakt ze zleceniodawcą i umów się na realizację pracy.
              </span>
            </div>
            <div fxLayout="column" class="how-it-works-container" fxLayoutAlign="start center">
              <div fxLayoutAlign="center center">
                <a class="how-it-works-4"></a>
              </div>
              <span class="how-it-works-1-label">4. Po zleceniu poproś o rekomendację</span>
              <span class="how-it-works-content">Współpraca się udała? Nie zapomnij poprosić zleceniodawcę o rekomendację
                po udanym zleceniu, zapunktuje to w przyszłości!</span>
            </div>
          </div>
          <app-newsletter-panel class="newslatter"></app-newsletter-panel>
        </div>
      </app-static-page>
    </div>

  </rs-desktop>
  <rs-mobile>
    <app-static-page [desktop]="false">
    <div fxLayout="column" class="mobile-container">

      <div class="top-buttons inner-container">
        <mat-tab-group mat-align-tabs="start" disablePagination="true">
          <mat-tab label="Szukam zleceń" class="span-tab-active">
            <div fxLayout="column" class="how-it-works-container" fxLayoutAlign="center center">
              <div fxLayoutAlign="center center">
                <a class="how-it-works-1"></a>
              </div>

              <span class="how-it-works-1-label">1. Załóż swój profil wykonawcy</span>
              <span class="how-it-works-content">
                Nie zapomnij po założeniu konta uzupełnić informacji na swoim profilu, pozwoli nam proponować Ci
                zlecenia stworzone dla Ciebie.
              </span>

            </div>
            <div fxLayout="column" class="how-it-works-container" fxLayoutAlign="center center">
              <div fxLayoutAlign="center center">
                <a class="how-it-works-2"></a>
              </div>

              <span class="how-it-works-1-label">2. Przeglądaj aktualne oferty</span>
              <span class="how-it-works-content">
                Poszukuj pożądanych zleceń samodzielnie lub czekaj na bezpośrednie propozycje od zleceniodawcy.
              </span>

            </div>
            <div fxLayout="column" class="how-it-works-container" fxLayoutAlign="center center">
              <div fxLayoutAlign="center center">
                <a class="how-it-works-3"></a>
              </div>

              <span class="how-it-works-1-label">3. Nawiąż współpracę ze zleceniodawcą</span>
              <span class="how-it-works-content">
                Udało się znaleźć odpowiednie zlecenie? Nawiąż kontakt ze zleceniodawcą i umów się na realizację pracy.
              </span>

            </div>
            <div fxLayout="column" class="how-it-works-container" fxLayoutAlign="center center">
              <div fxLayoutAlign="center center">
                <a class="how-it-works-4"></a>
              </div>

              <span class="how-it-works-1-label">4. Po zleceniu poproś o rekomendację</span>
              <span class="how-it-works-content">
                Współpraca się udała? Nie zapomnij poprosić zleceniodawcę o rekomendację po udanym zleceniu, zapunktuje
                to w przyszłości!
              </span>
            </div>

          </mat-tab>
          <mat-tab label="Szukam pracowników" class="span-tab-active">

          </mat-tab>
        </mat-tab-group>

      </div>

    </div>
  </app-static-page>
  </rs-mobile>
</responsive-switcher>
