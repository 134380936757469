<responsive-switcher>
  <rs-desktop>
    <div class="container-main">
      <app-desktop-guest-wrapper>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="label-wrapper"
          >
            <span
              fxLayoutAlign="center center"
              class="create-label"
              fxFlex="100"
            >
              Utwórz silne hasło
            </span>
          </div>

          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="grey-container"
          >
            <p *ngIf="authService.socialError" style="color: red">
              {{ authService.socialError }}
            </p>

            <span class="label-in-container">
              Zostałeś zaproszony/a do założenia konta
            </span>
            <form
              fxLayout="column"
              [formGroup]="invitePasswordForm"
              (ngSubmit)="submit()"
            >
              <div fxLayout="column" class="inputs-container">
                <div fxLayout="row" class="name-wrapper">
                  <app-input
                    placeholder="Hasło"
                    [error]="invitePasswordForm.get('password').errors?.message"
                    formControlName="password"
                    type="password"
                    ngDefaultControl
                    class="name-input"
                  ></app-input>
                  <app-input
                    placeholder="Powtórz hasło"
                    [error]="invitePasswordForm.get('passwordRetype').errors?.message"
                    formControlName="passwordRetype"
                    ngDefaultControl
                    type="password"
                    class="surname-input"
                  ></app-input>
                </div>
                <div
                  *ngIf="submitError"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <p class="error">
                    {{ submitError }}
                  </p>
                </div>
                <div class="checkboxes">
                  <app-checkbox
                    formControlName="acceptRules"
                    [error]="invitePasswordForm.get('acceptRules').errors?.message"
                    ngDefaultControl
                    class="first-checkbox"
                    label="Zgadzam się z postanowieniami regulaminu"
                  ></app-checkbox>
                  <app-checkbox
                    formControlName="newsletter"
                    [error]="invitePasswordForm.get('newsletter').errors?.message"
                    ngDefaultControl
                    label="Chcę dostawać wiadomości o ofertach dopasowanych do mnie oraz informacach jak usprawnić mój profil"
                  ></app-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
                <div class="button-zaloguj">
                  <app-button
                    [color]="ButtonColors.PRIMARY"
                    tooltip="Załóż konto"
                    type="submit"
                  >
                    <span>Załóż konto</span>
                  </app-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </app-desktop-guest-wrapper>
    </div>
  </rs-desktop>
  <rs-mobile>
    <app-mobile-guest-wrapper>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center" class="label-wrapper">

          <span fxLayoutAlign="center center" class="create-label" fxFlex="100">
            Utwórz silne hasło
          </span>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="grey-container"
        >
          <span class="label-in-container">
            Zostałeś zaproszony/a do założenia konta
          </span>

          <form
            class="inputs-container-2"
            fxLayout="column"
            [formGroup]="invitePasswordForm"
            (submit)="submit()"
          >
            <div fxLayout="column" class="inputs-container">

              <app-input
                class="first-input"
                placeholder="Hasło"
                [error]="invitePasswordForm.get('password').errors?.message"
                formControlName="password"
                ngDefaultControl
                type="password"
              ></app-input>
              <app-input
                placeholder="Powtórz hasło"
                [error]="invitePasswordForm.get('passwordRetype').errors?.message"
                formControlName="passwordRetype"
                ngDefaultControl
                type="password"
              ></app-input>
            </div>

            <div class="checkboxes">
              <app-checkbox
                formControlName="acceptRules"
                [error]="invitePasswordForm.get('acceptRules').errors?.message"
                ngDefaultControl
                class="first-checkbox"
                label="Zgadzam się z postanowieniami regulaminu"
              ></app-checkbox>
              <app-checkbox
                formControlName="newsletter"
                [error]="invitePasswordForm.get('newsletter').errors?.message"
                ngDefaultControl
                label="Chcę dostawać wiadomości o ofertach dopasowanych do mnie oraz informacach jak usprawnić mój profil"
              ></app-checkbox>
            </div>

            <div
              *ngIf="submitError"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <p class="error">
                {{ submitError }}
              </p>
            </div>
            <div class="button-create-account-wrapper">
              <div class="button-zaloguj">
                <app-button
                  class="button-create-account"
                  buttonType="submit"
                  [color]="ButtonColors.PRIMARY"
                >
                  <span>Załóż konto</span>
                </app-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </app-mobile-guest-wrapper>
  </rs-mobile>
</responsive-switcher>
