import {Component, Input} from '@angular/core';
import { BUTTON_COLORS } from '../../modules/common-components';

@Component({
  selector: 'app-button-socialmedia',
  templateUrl: './button-socialmedia.component.html',
  styleUrls: ['./button-socialmedia.component.scss']
})
export class ButtonSocialmediaComponent {
  title = 'Szybkapraca';
  @Input()
  public color: BUTTON_COLORS = BUTTON_COLORS.PRIMARY;
  public ButtonColors = BUTTON_COLORS;
}
