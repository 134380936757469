import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultFilters } from '../../common-services';
import { OffersResponseDTO, OffersService } from '../../sp-services';

@Component({
  selector: 'app-offer-list-horizontal',
  templateUrl: './offer-list-horizontal.component.html',
  styleUrls: ['./offer-list-horizontal.component.scss']
})
export class OfferListHorizontalComponent implements OnInit {
  @Input()
  public queryMethod: string;
  @Input()
  public limit: number;
  @Input()
  public offset: number;
  @Output()
  onOffersLoaded = new EventEmitter<OffersResponseDTO[]>();
  @Input()
  filters: DefaultFilters<OffersResponseDTO> = {
    limit: 16,
    offset: 0,
    sortBy: { field: 'title', sort: 'ASC' },
    filterBy: {}
  };
  constructor(private offerService: OffersService) { }
  offersSubscription: Subscription;
  offers = [];

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.offersSubscription = this.offerService[this.queryMethod](this.filters)
    .subscribe(offers => {
      this.offers = offers;
      this.onOffersLoaded.next(offers);
    })
  }

  ngOnDestroy(): void {
    if (this.offersSubscription) {
      this.offersSubscription.unsubscribe();
    }
  }
}
