<responsive-switcher>
  <rs-desktop>
    <!-- <div fxLayout="column" fxLayoutAlign="stretch stretch"> -->
      <div class="topbar-filters">
        <app-topbar-filter
        [label]="titleLabel"
        (filtersChanged)="filtersChanged($event)"
        (sortChanged)="sortChanged($event)"
      >
      </app-topbar-filter>
      </div>

    <div class="list-scroll">
      <div
        fxFlex="30"
        class="list-container"
        fxLayout="column"
        [ngClass]="{ 'list-container-smaller': filtersOpened }"
      >
        <div class="offer-card" *ngFor="let offer of buffer">
          <app-offer-small-card-logged
            [offer]="offer"
          ></app-offer-small-card-logged>
        </div>
        <div
          *ngIf="!loading && buffer?.length === 0"
          class="no-messages"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span class="no-offers">Brak ofert</span>
        </div>
        <div
          *ngIf="loading"
          class="spinner"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-spinner mode="indeterminate"></mat-spinner>
        </div>
      </div>
      <div fxFlex="70" class="map-container">
        <mgl-map
        *ngIf="!isServer"
        [style]="'mapbox://styles/mapbox/outdoors-v12'"
        [zoom]="[0]"
        [center]="[-74.5, 40]"
        (load)="onMapLoaded($event)"
      ></mgl-map>
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div class="content-logged">
      <app-topbar-filter
        [label]="titleLabel"
        (filtersChanged)="filtersChanged($event)"
        (sortChanged)="sortChanged($event)"
      >
      </app-topbar-filter>
      <div
       
        class="list-container"
      >
      <mgl-map
      *ngIf="!isServer && buffer.length > 0"
      [style]="'mapbox://styles/mapbox/outdoors-v12'"
      [zoom]="[0]"
      [center]="[-74.5, 40]"
      (load)="onMapLoaded($event)"
    ></mgl-map>
        <!-- <div fxLayoutAlign="center center" fxLayout="column"> -->
        <app-offer-small-card-logged
          *ngFor="let offer of buffer; trackBy: trackFunction"
          [offer]="offer"
          class="event"
        ></app-offer-small-card-logged>
        <div
          *ngIf="loading"
          class="spinner"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-spinner mode="indeterminate"></mat-spinner>
        </div>

        <div
          *ngIf="!loading && buffer?.length === 0"
          class="no-messages"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span class="no-offers">Brak ofert</span>
        </div>

        <!-- </div> -->
      </div>
    </div>
  </rs-mobile>
</responsive-switcher>
