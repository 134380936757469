import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  searchSubject = new ReplaySubject<string>(1);
  search$ = this.searchSubject.asObservable();
  constructor() { }
  query(queryString: string): void {
    this.searchSubject.next(queryString);
  }
}
