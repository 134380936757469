import { DeviceDetectorService } from '../../modules/common-services/device-detector.service';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-logged-wrapper-mobile',
  templateUrl: './logged-wrapper-mobile.component.html',
  styleUrls: ['./logged-wrapper-mobile.component.scss']
})
export class LoggedWrapperMobileComponent implements OnInit {
  events: [];
  options = [
    {
      name: 'Oferty',
      icon: '/assets/icon-oferty.svg'
    },
    {
      name: 'Katalog',
      icon: '/assets/icon-katalog.svg'
    },
    {
      name: 'Obserwowane',
      icon: '/assets/icon-serce.svg'
    },
    {
      name: 'Profil',
      icon: '/assets/icon-user.svg'
    },
  ];
  constructor(private deviceDetectorService: DeviceDetectorService) {}
  ngOnInit(): void {
    if (!this.deviceDetectorService.isServer()) {
      window.scrollTo(0, 0);
    }
  }
}
