import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

export enum BUTTON_COLORS {
  PRIMARY,
  SECONDARY,
  GREY,
  BLACK,
  WHITE,
  GREEN,
  RED
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input()
  tooltip: string;
  title = 'Szybkapraca';
  @Input()
  public color: BUTTON_COLORS = BUTTON_COLORS.PRIMARY;
  @Input()
  image: string;
  @Input()
  buttonType: string;
  @Output()
  click = new EventEmitter();
  public ButtonColors = BUTTON_COLORS;
  @Input()
  error: string = null;

  ngOnInit() {
  }

  ngOnChanges() {
  }
}
