import { Component } from '@angular/core';
import {BUTTON_COLORS} from '../../modules/common-components/button/button.component';

@Component({
  selector: 'app-newsletter-panel',
  templateUrl: './newsletter-panel.component.html',
  styleUrls: ['./newsletter-panel.component.scss']
})
export class NewsletterPanelComponent {
  ButtonColors = BUTTON_COLORS;
}
