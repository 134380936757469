import {Component, ElementRef, forwardRef, Input, Renderer2} from '@angular/core';
import { CheckboxControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilsService } from '@src/app/modules/common-services/utils.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends CheckboxControlValueAccessor {
  title = 'Szybkapraca';
  @Input()
  public label: string;
  checked = false;
  onChange: (_: any) => void;
  onTouched: () => void;
  @Input()
  error: string = null;
  registerOnChange(fn: (checked: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  constructor(renderer: Renderer2, elementRef: ElementRef, private utilsService: UtilsService) {
    super(renderer, elementRef);
  }

  changeValue(value) {
    this.checked = !value;
    this.error = null;
    this.onChange(value);
  }
}
