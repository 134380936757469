<responsive-switcher>
  <rs-desktop>
    <div
      matRipple
      fxFlex="100"
      fxLayout="column"
      fxLayoutAlign="center"
      class="container"
    >
      <div class="image-container">
        <img
          class="image-event"
          [src]="
            offer?.thumbnail
              ? commonHttpService.getImagePath(offer.thumbnail.path)
              : '/assets/noimage.png'
          "
        />
        <!-- <button
                    class="download-button"
                    mat-flat-button
                >
                    <a class="download-icon"></a>
                </button>
                <button
                    class="favourite-button"
                    mat-flat-button
                >
                    <a class="heart-icon"></a>
                </button> -->
      </div>

      <span class="title">
        {{ offer?.title }}
      </span>
      <div fxLayout="row" class="date-container">
        <div class="location-wrapper">
          <a class="location-icon-small"></a>
        
          <span class="city">
         
            {{ offer?.mapboxPlace ? offer.mapboxPlace.text : 'no data' }}
          </span>
        </div>
        <div class="date-wrapper">
          <a class="clock-icon-small"></a>
          <span class="date">Dodane {{ offer?.created }}</span>
        </div>
      </div>
      <hr class="divider" />
      <div fxLayout="row" class="price-and-time">
        <span class="price" *ngIf="offer?.salary">{{ offer?.salary }} PLN</span>
        <span class="date margin" *ngIf="offer?.workTime"
          >Szacowany czas pracy : {{ offer?.workTime }}
          {{ offer?.rateType?.name }}</span
        >
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div class="container-wrapper" matRipple>
      <div
        fxFlex="100"
        fxLayout="column"
        fxLayoutAlign="center"
        class="container"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="image-container"
        >
          <img
            class="image-event"
            [src]="
              offer?.thumbnail?.path
                ? commonHttpService.getImagePath(offer.thumbnail.path)
                : '/assets/noimage.png'
            "
          />
          <!-- <button
                        class="download-button"
                        mat-flat-button
                    >
                        <a class="download-icon"></a>
                    </button>
                    <button
                        class="favourite-button"
                        mat-flat-button
                    >
                        <a class="heart-icon"></a>
                    </button> -->
        </div>

        <span fxFlex="100" fxLayout="row" class="title">
          {{ offer?.title }}
        </span>
        <div fxLayout="row" class="date-container">
          <a class="location-icon-small"></a>
          <span class="city">
         
            {{ offer?.mapboxPlace ? offer.mapboxPlace.text : 'no data' }}
          </span>
          <a class="clock-icon-small"></a>
          <span class="date">Dodano {{ offer?.created | date }}</span>
        </div>
        <hr class="divider" />
        <div fxLayout="row">
          <span class="price">{{ offer?.salary }} PLN</span>
          <span class="date margin">Szacowany czas pracy: 10 - 15 godzin</span>
        </div>
      </div>
    </div>
  </rs-mobile>
</responsive-switcher>
