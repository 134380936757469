import { AvatarCacheService } from './avatar-cache.service';
import { ExceptionTelemeteryErrorHandlerService } from './exception-telemetery/exception-telemetery-error-handler.service';
import { ExceptionTelemeteryService } from './exception-telemetery/exception-telemetery.service';
import { MaintenanceGuard } from './maintenance.guard';
import { MaintenanceService } from './maintenance.service';
import { SocketService } from './socket.service';
import { NgModule, ErrorHandler, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from '@replica-frontend/auth';
import { EnvironmentService } from './environment.service';
import { LoggedAuthGuard } from './logged-auth.guard';
import { DeviceDetectorService } from './device-detector.service';
import { UtilsService } from './utils.service';
import { CommonHttpService } from './common-http.service';
import { VersioningService } from './versioning.service';

export class CommonServiceConfig {
  appName: string;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ExceptionTelemeteryErrorHandlerService
    },
    ExceptionTelemeteryService,
    AuthService,
    EnvironmentService,
    LoggedAuthGuard,
    MaintenanceGuard,
    DeviceDetectorService,
    SocketService,
    UtilsService,
    MaintenanceService,
    AvatarCacheService,
    CommonHttpService,
    VersioningService
  ]
})
export class CommonServicesModule {
  static forRoot(config: CommonServiceConfig): ModuleWithProviders<CommonServicesModule> {
    return {
      ngModule: CommonServicesModule,
      providers: [
        {
          provide: CommonServiceConfig,
          useValue: config
        }
      ]
    };
  }
 }
