import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import mapboxgl, { LngLatLike, SymbolPaint } from 'mapbox-gl';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface MapboxFeature {
  centerLng: number;
  centerLat: number;
  bbox: number[];
  center: LngLatLike;
  id: string;
  type: 'Feature';
  place_type: Array<string>;
  place_name: string;
  text: string;
}
export interface MapboxResponse {
  type: 'FeatureCollection';
  query: Array<string>;
  features: Array<MapboxFeature>;
  attribution: string;
}

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  constructor(private httpClient: HttpClient) {}

  searchPlaces(searchStr: string, types = [
    'country',
    'region',
    'place',
    'district',
    'postcode',
    'neighborhood',
    'address',
  ]): Observable<MapboxResponse> {
    return this.httpClient.get<MapboxResponse>(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchStr}.json?${new URLSearchParams(
        {
          proximity: 'ip',
          types: types.join(','),
          access_token: environment.mapboxGlToken,
          limit: '5',
          language: 'pl-PL',
        }
      ).toString()}`
    );
  }

  static getClusterPaintConfig(): mapboxgl.CirclePaint {
    return {
      // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      'circle-color': '#0B0B0B',
      'circle-radius': ['step', ['get', 'point_count'], 24, 100, 30, 750, 40],
    };
  }

  static getSymbolConfig() {
    return {
      id: 'cluster-count',
      type: 'symbol',
      source: 'offers',
      layout: {
        'text-field': ['get', 'point_count'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 14,
      },
      paint: {
        "text-color": '#ffee00' 
      }
    }
  }


  static getUnclusteredSymbolConfig() {
    return {
      id: 'single-count',
      type: 'symbol',
      source: 'offers',
      layout: {
        'text-field': '1',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      filter: ['!', ['has', 'point_count']],
      paint: {
        "text-color": '#ffee00' 
      }
    }
  }

  static getUnclusteredPointConfig() {
    return {
      id: 'unclustered-point',
      type: 'circle',
      source: 'offers',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#0B0B0B',
        'circle-radius': 14,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#ffee00',
      },
    }
  }
}
