import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import { DeviceDetectorService } from '@replica-frontend/sdk';

export interface MenuObject {
  name: string;
  link: string;
  icon: string;
  iconBlue: string;
}

export enum IconModes {
  LINK, MAT_ICON
}
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  defaultDesktopMenu = [
    {
      name: 'Oferty',
      icon: '/assets/icon-oferty.svg',
      iconBlue: '/assets/icon-oferty-mobile-footer-active.svg',
      link: '/app/offers-for-employee'
    },
    {
      name: 'Katalog',
      icon: '/assets/icon-katalog.svg',
      iconBlue: '/assets/icon-katalog-active.svg',
      link: '/app/catalog'
    },
    {
      name: 'Obserwowane',
      iconBlue: '/assets/icon-serce.svg',
      icon: '/assets/icon-heart-grey.svg',
      link: '/app/favorites'
    },
    {
      name: 'Profil',
      icon: '/assets/icon-user.svg',
      iconBlue: '/assets/icon-person-active.svg',
      link: '/app/contractor-profile'
    },
  ];
  defaultMobileMenu = [
    {
      name: 'Oferty',
      icon: '/assets/icon-oferty.svg',
      iconBlue: '/assets/icon-oferty-mobile-footer-active.svg',
      link: '/app/offers-for-employee'
    },
    {
      name: 'Katalog',
      icon: '/assets/icon-katalog.svg',
      iconBlue: '/assets/icon-katalog-active.svg',
      link: '/app/catalog'
    },
    {
      name: 'Obserwowane',
      icon: '/assets/icon-serce-2.svg',
      iconBlue: '/assets/icon-serce.svg',
      link: '/app/favorites'
    },
    {
      name: 'Wiadomości',
      icon: '/assets/icon-chat-footer.svg',
      iconBlue: '/assets/icon-chat-active.svg',
      link: '/app/ext/messenger'
    },
    {
      name: 'Profil',
      icon: '/assets/icon-user.svg',
      iconBlue: '/assets/icon-person-active.svg',
      link: '/app/logged-options'
    },
  ];
  menuSubject: BehaviorSubject<Array<MenuObject>> = new BehaviorSubject<MenuObject[]>(this.deviceDetectorService.isDesktop() ? this.defaultDesktopMenu : this.defaultMobileMenu);
  menu$ = this.menuSubject.asObservable();
  iconModeSubject: BehaviorSubject<IconModes> = new BehaviorSubject<IconModes>(IconModes.LINK);
  iconMode$ = this.iconModeSubject.asObservable();
  constructor(private deviceDetectorService: DeviceDetectorService) { }
  setMenuObjects(menuArr: Array<MenuObject>): void {
    this.menuSubject.next(menuArr);
  }

  setDefaultMenu(): void {
    console.log('setDefaultMenu');
    this.setMenuObjects(this.deviceDetectorService.isDesktop() ? this.defaultDesktopMenu : this.defaultMobileMenu);
  }

  setIconMode(iconMode: IconModes): void {
    this.iconModeSubject.next(iconMode);
  }
}
