import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@replica-frontend/auth';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BUTTON_COLORS } from '@src/app/modules/common-components/button/button.component';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './new-password-form.component.html',
  styleUrls: ['./new-password-form.component.scss']
})
export class NewPasswordFormComponent implements OnInit, OnDestroy {
  ButtonColors = BUTTON_COLORS;
  newPasswordForm = this.formBuilder.group({
    password: ['', [Validators.required]],
    retypePassword: ['', [Validators.required]]
  });
  approved: boolean = null;
  differentPasswords = false;
  weakPassword = false;
  subscriptions = [];
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.newPasswordForm.valueChanges.subscribe(changes => {
      if (
        changes.retypePassword.length > 0 &&
        changes.retypePassword !== changes.password
      ) {
        this.differentPasswords = true;
      } else {
        this.differentPasswords = false;
      }
    });
  }

  submit(): void {
    if (this.weakPassword || this.differentPasswords) {
      return;
    }
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params: Params) => {
        this.authService
          .forgotPasswordVerify(
            params.token,
            this.newPasswordForm.value.password
          )
          .subscribe(
            result => {
              if (result) {
                this.approved = true;
              }
              this.matSnackBar.open('Password was changed', null, {
                duration: 3000
              });
              this.router.navigate(['/', 'sign-in']);
            },
            err => {
              this.approved = false;
            }
          );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
