import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from './confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { OffersResponseDTO, OffersService } from '../../modules/sp-services';
import { DefaultFilters } from '@replica-frontend/sdk';

@Component({
  templateUrl: './admin-offer-list.component.html',
  styleUrls: ['admin-offer-list.component.scss']
})
export class AdminOfferListComponent implements OnInit {
  dataSource = [];
  displayedColumns = ['slug', 'title', 'created', 'options'];

  filters: DefaultFilters<OffersResponseDTO> = {
    limit: 16,
    offset: 0,
    sortBy: { field: 'title', sort: 'ASC' },
    filterBy: {}
  };

  constructor(
    private offersService: OffersService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.offersService.getOffersAdmin(this.filters as any).subscribe(results => {
      this.dataSource = results;
    });
  }

  deleteDialog(id: string): void {
    const message = `Are you sure you want to do delete offer ${
      this.dataSource.find(x => x.id === id).title
    }?`;
    const dialogData = new ConfirmDialogModel('Confirm delete', message);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log('dialogResult', dialogResult);
      if (dialogResult) {
        this.offersService.delete(id).subscribe(() => {
          this.matSnackBar.open('Offer deleted', 'Dismiss', {
            duration: 3000
          });
          this.load();
        });
      }
    });
  }
}
