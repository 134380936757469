import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  OffersResponseDTO,
  OffersService,
  Profile,
  ProfileService,
} from '@src/app/modules/sp-services';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  templateUrl: './single-offer.component.html',
  styleUrls: ['./single-offer.component.scss'],
})
export class SingleOfferComponent implements OnInit {
  slug: string;
  public offer: OffersResponseDTO;
  public profile: Profile;
  loading = true;
  constructor(
    private offersService: OffersService,
    private profileService: ProfileService,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.slug = this.route.snapshot.params.slug;
    this.loadOfferAndProfile();
  }

  loadOfferAndProfile(): void {
    this.offersService
      .singleOffer(this.slug)
      .pipe(
        tap((offer) => {
          this.offer = offer;
        }),
        switchMap((offer: OffersResponseDTO) => {
          return this.profileService.getProfileByUserId(offer.userid.id);
        })
      )
      .subscribe((profile: Profile) => {
        this.profile = profile;
        this.loading = false;
      });
  }
}
