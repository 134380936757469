import { MessengerServicesConfig } from './messenger-services.module';
import { Injectable } from '@angular/core';

interface Component { }

type ComponentClass = any;

export interface CustomMessageType {
  metaType: string; // main descriptor in metatags
  sticky: boolean;
  component: ComponentClass;
}

@Injectable({
  providedIn: 'root'
})
export class CustomMessageRegistry {
  constructor(private messengerServicesConfig: MessengerServicesConfig) {
    messengerServicesConfig.customMessageTypes.forEach((messageType: CustomMessageType) => {
      this.register(messageType.metaType, messageType.component);
    });
  }
  registry = new Map<string, ComponentClass>();

  getTypeFor(name: string): ComponentClass {
    if (name.match('-')) {
      name = this.getComponentNameFromTemplate(name);
    }
    const componentClass: ComponentClass = this.registry.get(name);
    if (componentClass === null) {
      throw new Error(`${name} was not found in the COMPONENTREGISTRY.registry`);
    }
    return componentClass;
  }

  getComponentNameFromTemplate(metaName: string): string {
    const words: string[] = metaName.split('-');
    words.splice(0, 1); // remove the prefix
    for (let i = 0; i < words.length; i++) {
      const capitalized = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      words.splice(i, 1, capitalized);
    }
    const componentName = words.join('') + 'Component';
    return componentName;
  }

  private register(metaName: string, cls: ComponentClass): void {
    this.registry.set(metaName, cls);
  }

  // registerTemplateString(cls: ComponentClass): void {
  // 	let templateString = 'app-angular-component'; // how to get this from the ComponentClass reference?
  // 	this.registry.set(templateString, cls);
  // }

}
