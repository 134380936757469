import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@replica-frontend/auth';
import { OffersService } from '@src/app/modules/sp-services/offers.service';
import { SearchService } from '@src/app/modules/sp-services/search.service';
import { BUTTON_COLORS } from './../button-square/button-square.component';
@Component({
  selector: 'app-topbar-logged-desktop',
  templateUrl: './topbar-logged-desktop.component.html',
  styleUrls: ['./topbar-logged-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TopbarLoggedDesktopComponent implements OnInit {
  ButtonColors = BUTTON_COLORS;
  searchQuery = null;
  constructor(
    private router: Router,
    public authService: AuthService,
    public offersService: OffersService,
    private searchService: SearchService,
  ) { }

  ngOnInit(): void {
  }

  changed(): void {
    this.router.navigate(['/app/offers-for-employee']);
    this.searchService.query(this.searchQuery);
  }

}
