import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import {OffersService} from '@src/app/modules/sp-services';
import {OffersResponseDTO} from '@src/app/modules/sp-services/offers.service';
import { AuthService } from '@replica-frontend/auth';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})

export class CatalogComponent {
  offers$: Observable<Array<OffersResponseDTO>> = this.offersService.mine({
    limit: 10,
    offset: 0,
    filterBy: {}
  });

  constructor(public authService: AuthService, private offersService: OffersService) {

  }

}
