<responsive-switcher>
  <rs-desktop>
    <div class="menu-container">
      <div fxLayout="row" fxLayoutAlign="center center" class="first-content">
        <button
          mat-button
          routerLink="/"
          class="form-logo"
          matTooltip="Strona główna"
          matTooltipClass="tooltip"
        >
          <a class="logo"></a>
          <span>szybkapraca.pl</span>
        </button>
      </div>
      <mat-list>
        <mat-list-item
          *ngFor="let option of options$ | async"
          [routerLink]="option.link"
          routerLinkActive="active"
        >
          <div class="item-button" fxLayoutAlign="center center">
            <div class="item-button-inner">
              <mat-icon *ngIf="(iconMode$ | async) === IconModes.MAT_ICON">{{
                option.icon
              }}</mat-icon>
              <svg
                *ngIf="(iconMode$ | async) === IconModes.LINK"
                class="option-icon"
                style="background: url({{ option.icon }}) center no-repeat"
              ></svg>
              <span class="option-span">
                {{ option.name }}
              </span>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item class="logout-item" (click)="logout()">
          <div class="item-button-logout" fxLayoutAlign="center center">
            <div class="item-button-inner">
              <a class="logout-icon"></a>
              <span>Wyloguj się</span>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div class="menu-container">
      <mat-list>
        <!-- TODO: change this menu -->
        <mat-list-item (click)="itemClicked.next()" routerLink="/static/how-it-works">
          <div class="item-button" fxLayoutAlign="center center">
            <span class="option-span"> Jak to działa? </span>
          </div>
        </mat-list-item>
        <mat-list-item routerLink="/static/faq" (click)="itemClicked.next()">
          <div class="item-button" fxLayoutAlign="center center">
            <span class="option-span"> FAQ </span>
          </div>
        </mat-list-item>
        <mat-list-item routerLink="/static/regulations" (click)="itemClicked.next()">
          <div class="item-button" fxLayoutAlign="center center">
            <span class="option-span"> Regulamin </span>
          </div>
        </mat-list-item>
        <div class="options-button" *ngIf="!authService.me">
          <div fxLayout="row" fxLayoutAlign="center center">
            <app-button
              [color]="ButtonColors.PRIMARY"
              class="button-top"
              (click)="register()"
            >
              <span class="button-span">Załóż konto</span>
            </app-button>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center">
            <app-button
              [color]="ButtonColors.WHITE"
              class="button-bottom"
              (click)="login()"
            >
              <a class="person-icon"></a>
              <span>Zaloguj się</span>
            </app-button>
          </div>
        </div>
      </mat-list>
    </div>
  </rs-mobile>
</responsive-switcher>
