import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, tap, shareReplay } from 'rxjs/operators';
import { SocketService } from './../common-services/socket.service';
import { OffersApplies } from './applies.service';

@Injectable()
export class AppliesSyncService {
  NAMESPACE = 'applies-sync';
  private sync$: Observable<OffersApplies[]>;
  private update$: Observable<OffersApplies>;
  private remove$: Observable<number>;
  private add$: Observable<OffersApplies>;
  private offerApplies: OffersApplies[] = [];
  private subscriptions: Subscription[] = [];
  private userAppliesSubject = new BehaviorSubject<OffersApplies[]>(null);
  public userApplies$ = this.userAppliesSubject.asObservable();

  constructor(
    private socket: SocketService
  ) { }

  init() {
    this.sync$ = this.socket.listen<OffersApplies[]>(this.NAMESPACE, 'sync');
    this.update$ = this.socket.listen<OffersApplies>(this.NAMESPACE, 'update');
    this.remove$ = this.socket.listen<number>(this.NAMESPACE, 'remove');
    this.add$ = this.socket.listen<OffersApplies>(this.NAMESPACE, 'add');
    this.addEvents();
  }

  getMyAppliesForOffer$(id: string): Observable<OffersApplies> {
    const apply = this.offerApplies.find(x => x.offerid.id === id);
    const appliesSubject = new BehaviorSubject<OffersApplies>(apply)

    if (!apply) {
      this.sync$.pipe(filter(x => !!(x.find(y => y.offerid.id === id))))
        .subscribe((applies) => {
          appliesSubject.next(applies.find(x => x.offerid.id === id));
        })
      this.add$.pipe(filter(x => x.offerid.id === id)).subscribe((apply) => {
        appliesSubject.next(apply);
      })
    }
    this.update$.pipe(filter(x => x.offerid.id === id)).subscribe((apply) => {
      appliesSubject.next(apply);
    })
    return appliesSubject
      .asObservable().pipe(filter(x => !!x));
  }

  getApplyById$(id: number): Observable<OffersApplies> {
    const apply = this.offerApplies.find(x => x.id === id);
    const appliesSubject = new BehaviorSubject<OffersApplies>(apply)
    if (!apply) {
      this.sync$.pipe(filter(x => !!(x.find(y => y.id === id))))
      .subscribe((applies) => {
        appliesSubject.next(applies.find(y => y.id === id));
      })
      this.add$.pipe(filter(x => x.id === id)).subscribe((apply) => {
        appliesSubject.next(apply);
      })
    }
    this.update$.pipe(filter(x => x.id === id)).subscribe((apply) => {
      appliesSubject.next(apply);
    })
    return appliesSubject
      .asObservable().pipe(filter(x => !!x))
  }

  destroy() {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    })
  }

  private addEvents(): void {
    this.subscriptions.push(this.sync$.subscribe((offerApplies: OffersApplies[]) => {
      this.offerApplies = offerApplies;
      this.userAppliesSubject.next(this.offerApplies);
    }));
    this.subscriptions.push(this.add$.subscribe((offerApplies: OffersApplies) => {
      this.offerApplies.push(offerApplies);
      this.userAppliesSubject.next(this.offerApplies);
    }));
    this.subscriptions.push(this.remove$.subscribe((id: number) => {
      const idx = this.offerApplies.findIndex(x => x.id === id);
      this.offerApplies.splice(idx, 1);
      this.userAppliesSubject.next(this.offerApplies);
    }));
    this.subscriptions.push(this.update$.subscribe((offerApplies: OffersApplies) => {
      const idx = this.offerApplies.findIndex(x => x.id === offerApplies.id);
      this.userAppliesSubject.next(this.offerApplies);
      this.offerApplies[idx] = offerApplies;
    }));
  }
}
