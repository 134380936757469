import { ProfileService } from './profile.service';
import { AppliesSyncService } from './applies-sync.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffersService } from './offers.service';
import { UploadService } from './upload.service';
import { SearchService } from './search.service';
import { CommonServicesModule } from '@src/app/modules/common-services';
import { AppliesService } from './applies.service';
import {MenuService} from "@src/app/modules/sp-services/menu.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CommonServicesModule
  ],
  providers: [
    OffersService,
    UploadService,
    SearchService,
    AppliesService,
    AppliesSyncService,
    ProfileService,
    MenuService
  ]
})
export class SpServicesModule { }
