import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { SocketService, CONNECTION_STATES } from '../../modules/common-services/socket.service';
import { AuthEvents, AuthService } from '@replica-frontend/auth';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceDetectorService } from '../../modules/common-services';
import { AppliesSyncService } from '../../modules/sp-services';
import { MessengerService } from '../../modules/messenger-services/messenger.service';

@Component({
  selector: 'app-security-mobile-wrapper',
  templateUrl: './security-wrapper-mobile.component.html',
  styleUrls: ['./security-wrapper-mobile.component.scss']
})
export class SecurityWrapperMobileComponent implements OnInit, OnDestroy {
  snackBarRef: MatSnackBarRef<TextOnlySnackBar>;
  loading = true;
  logged: boolean;
  constructor(
    private appliesSyncService: AppliesSyncService,
    private router: Router,
    private authService: AuthService,
    private deviceDetectorService: DeviceDetectorService,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private messengerService: MessengerService
    ) {}
  ngOnInit(): void {
    if (this.deviceDetectorService.isServer()) {
      return;
    }
    if (this.authService.me) {
      this.loading = false;
      this.logged = true;
      this.initServices();
    } else {
      this.authService.authEvents$.subscribe((authEvent: AuthEvents) => {
        if (authEvent === AuthEvents.loading) {
          this.loading = true;
        }
        if (authEvent === AuthEvents.onLogged) {
          this.loading = false;
          this.logged = true;
          this.initServices();
        }
        if (authEvent === AuthEvents.onLogout) {
          this.logged = false;
        }
      });
    }

    // if (this.authService.hasMe === false) {
    //   this.logout();
    // }
    this.socketService.connection$.subscribe((connected: CONNECTION_STATES) => {
      if (connected === CONNECTION_STATES.RECONNECTING) {
        if (!this.snackBarRef) {
          this.snackBarRef = this.snackBar.open(
            'Utracono połączenie. Ponawianie...'
          );
        }
      } else if (connected === CONNECTION_STATES.ONLINE) {
        if (this.snackBarRef) {
          this.snackBarRef.dismiss();
        }
      } else if (connected === CONNECTION_STATES.ERROR) {
        this.snackBarRef = this.snackBar.open(
          'Wystąpił błąd połączenia',
          'Odśwież'
        );
        this.snackBarRef.onAction().subscribe(() => location.reload());
      }

    })
  }


  initServices(): void {
    this.appliesSyncService.init();
    this.messengerService.init();
  }

  logout(): void {
    this.router.navigateByUrl('/sign-in');
  }

  ngOnDestroy(): void {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

}
