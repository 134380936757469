import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {EnvironmentService} from '@src/app/modules/common-services/environment.service';

export interface ImageData {
  id: string;
  mimetype: string;
  path: string;
  type: string;
}

@Injectable()
export class UploadService {
  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) {}
  getImageUploadURL(): string {
    return `${this.environmentService.getEnv('apiUrl')}/upload/image`;
  }

  uploadOfferImage(image: File): Observable<ImageData> {
    const formData = new FormData();
    formData.append('image', image);
    return this.httpClient.post<ImageData>(
      `${this.environmentService.getEnv('apiUrl')}/offers/upload`,
      formData
    );
  }
  uploadProfileMediaImage(image: File): Observable<ImageData> {
    const formData = new FormData();
    formData.append('image', image);
    return this.httpClient.post<ImageData>(
      `${this.environmentService.getEnv('apiUrl')}/profile/profilemedia`,
      formData
    );
  }
  uploadContractorProfileMediaImage(image: File): Observable<ImageData> {
    const formData = new FormData();
    formData.append('image', image);
    return this.httpClient.post<ImageData>(
      `${this.environmentService.getEnv('apiUrl')}/profile/contractorprofilemedia`,
      formData
    );
  }
}
