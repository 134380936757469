<responsive-switcher>
  <rs-desktop>
    <div class="container-main">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="label-wrapper"
          >
            <button
              fxLayoutAlign="start center"
              (click)="back($event)"
              mat-icon-button
              fxFlex
              type="button"
            >
              <mat-icon>arrow_back</mat-icon>
            </button>
            <span
              fxLayoutAlign="center center"
              class="create-label"
              fxFlex="100"
            >
              Załóż konto zleceniodawcy
            </span>
          </div>

          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="grey-container"
          >
            <div
              fxFlex="60"
              fxLayout="row"
              fxLayoutAlign="center center"
              class="buttons"
            >
              <app-button-socialmedia
                type="button"
                [color]="ButtonColors.WHITE"
                class="facebook-button"
                fxFlex="50"
                (click)="loginSocial($event, 'facebook')"
                matTooltip="Połącz przez Facebooka"
                matTooltipClass="tooltip"
              >
                <div fxLayout="row">
                  <a class="facebook-icon-2"></a>
                  <span class="facebook-span">Połącz przez Facebooka</span>
                </div>
              </app-button-socialmedia>

              <app-button-socialmedia
                type="button"
                [color]="ButtonColors.WHITE"
                class="google-button"
                fxFlex="50"
                (click)="loginSocial($event, 'google')"
                matTooltip="Połącz przez Google"
                matTooltipClass="tooltip"
              >
                <div fxLayout="row">
                  <a class="google-icon-2"></a>
                  <span class="google-span">Połącz przez Google</span>
                </div>
              </app-button-socialmedia>
            </div>

            <span class="label-in-container">
              lub dołącz za pośrednictwem adresu email
            </span>
            <p *ngIf="authService.socialError" style="color: red">
              {{ authService.socialError }}
            </p>
            <form
              fxLayout="column"
              [formGroup]="registerForm"
              (ngSubmit)="submit()"
            >
              <div fxLayout="column" class="inputs-container">
                <div fxLayout="row" class="name-wrapper">
                  <app-input
                    placeholder="Imię"
                    formControlName="firstname"
                    [error]="registerForm.get('firstname').errors?.message"
                    ngDefaultControl
                    class="name-input"
                  ></app-input>
                  <app-input
                    placeholder="Nazwisko"
                    formControlName="lastname"
                    [error]="registerForm.get('lastname').errors?.message"
                    ngDefaultControl
                    class="surname-input"
                  ></app-input>
                </div>
                <app-input
                  class="first-input"
                  formControlName="email"
                  [error]="registerForm.get('email').errors?.message"
                  ngDefaultControl
                  placeholder="Adres e-mail"
                ></app-input>
                <div fxLayout="row" class="name-wrapper">
                  <app-input
                    placeholder="Hasło"
                    [error]="registerForm.get('password').errors?.message"
                    formControlName="password"
                    type="password"
                    ngDefaultControl
                    class="name-input"
                  ></app-input>
                  <app-input
                    placeholder="Powtórz hasło"
                    [error]="registerForm.get('passwordRetype').errors?.message"
                    formControlName="passwordRetype"
                    ngDefaultControl
                    type="password"
                    class="surname-input"
                  ></app-input>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="name-wrapper"
                >
                  <re-captcha (resolved)="resolved($event)"></re-captcha>
                </div>
                <div
                  *ngIf="captchaError"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <p class="error">Błąd walidacji captcha</p>
                </div>
                <div
                  *ngIf="submitError"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <p class="error">
                    {{ submitError }}
                  </p>
                </div>
                <div class="checkboxes">
                  <app-checkbox
                    formControlName="acceptRules"
                    [error]="registerForm.get('acceptRules').errors?.message"
                    ngDefaultControl
                    class="first-checkbox"
                    label="Zgadzam się z postanowieniami regulaminu"
                  ></app-checkbox>
                  <app-checkbox
                    formControlName="newsletter"
                    [error]="registerForm.get('newsletter').errors?.message"
                    ngDefaultControl
                    label="Chcę dostawać wiadomości o ofertach dopasowanych do mnie oraz informacach jak usprawnić mój profil"
                  ></app-checkbox>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
                <div class="button-zaloguj">
                  <app-button
                    [color]="ButtonColors.PRIMARY"
                    tooltip="Załóż konto"
                    type="submit"
                  >
                    <span>Załóż konto</span>
                  </app-button>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
  </rs-desktop>
  <rs-mobile>
      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center" class="label-wrapper">
          <button (click)="back($event)" mat-icon-button class="back-button">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span fxLayoutAlign="center center" class="create-label" fxFlex="100">
            Załóż konto zleceniodawcy
          </span>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="grey-container"
        >
          <app-button-socialmedia
            [color]="ButtonColors.WHITE"
            class="facebook-button"
            fxFlex="30"
            (click)="loginSocial($event, 'facebook')"
          >
            <div fxLayout="row">
              <a class="facebook-icon-2"></a>
              <span class="facebook-span">Połącz przez Facebooka</span>
            </div>
          </app-button-socialmedia>
          <app-button-socialmedia
            [color]="ButtonColors.WHITE"
            class="google-button"
            fxFlex="30"
            (click)="loginSocial($event, 'google')"
          >
            <div fxLayout="row">
              <a class="google-icon-2"></a>
              <span class="google-span">Połącz przez Google</span>
            </div>
          </app-button-socialmedia>
          <span class="label-in-container">
            lub dołącz za pośrednictwem adresu e-mail
          </span>
          <form
            class="inputs-container-2"
            fxLayout="column"
            [formGroup]="registerForm"
            (submit)="submit()"
          >
            <div fxLayout="column" class="inputs-container">
              <app-input
                class="first-input"
                placeholder="Imię"
                formControlName="firstname"
                [error]="registerForm.get('firstname').errors?.message"
                ngDefaultControl
              ></app-input>
              <app-input
                class="first-input surname-input"
                placeholder="Nazwisko"
                formControlName="lastname"
                [error]="registerForm.get('lastname').errors?.message"
                ngDefaultControl
              ></app-input>
              <app-input
                class="first-input"
                placeholder="Adres e-mail"
                [error]="registerForm.get('email').errors?.message"
                formControlName="email"
                ngDefaultControl
              ></app-input>
              <app-input
                class="first-input"
                placeholder="Hasło"
                [error]="registerForm.get('password').errors?.message"
                formControlName="password"
                ngDefaultControl
                type="password"
              ></app-input>
              <app-input
                placeholder="Powtórz hasło"
                [error]="registerForm.get('passwordRetype').errors?.message"
                formControlName="passwordRetype"
                ngDefaultControl
                type="password"
              ></app-input>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              class="catpcha-container-mobile"
            >
              <re-captcha (resolved)="resolved($event)"></re-captcha>
            </div>
            <div class="checkboxes">
              <app-checkbox
                formControlName="acceptRules"
                [error]="registerForm.get('acceptRules').errors?.message"
                ngDefaultControl
                class="first-checkbox"
                label="Zgadzam się z postanowieniami regulaminu"
              ></app-checkbox>
              <app-checkbox
                formControlName="newsletter"
                [error]="registerForm.get('newsletter').errors?.message"
                ngDefaultControl
                label="Chcę dostawać wiadomości o ofertach dopasowanych do mnie oraz informacach jak usprawnić mój profil"
              ></app-checkbox>
            </div>
            <div
              *ngIf="captchaError"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <p class="error">Błąd walidacji captcha</p>
            </div>
            <div
              *ngIf="submitError"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <p class="error">
                {{ submitError }}
              </p>
            </div>
            <div class="button-create-account-wrapper">
              <div class="button-zaloguj">
                <app-button
                  class="button-create-account"
                  buttonType="submit"
                  [color]="ButtonColors.PRIMARY"
                >
                  <span>Załóż konto</span>
                </app-button>
              </div>
            </div>
          </form>
        </div>
      </div>
  </rs-mobile>
</responsive-switcher>
