import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy, Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DefaultFilters, DeviceDetectorService } from '@src/app/modules/common-services';
import { Observable, Subscription } from 'rxjs';
import { Option } from '..';
import { HierarchicalOption } from '../interfaces/hierarchical-option.interface';
import { SelectOptionsListComponent } from '../select-options.list/select-options-list.component';

export interface SelectInputChangeEvent<T> {
  level: number;
  value: HierarchicalOption<T>;
}

@Component({
  selector: 'common-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  options$: Observable<Array<HierarchicalOption<any>>>;
  @Input()
  small = true;
  @Input()
  placeholder: string;
  @Input()
  required: boolean;
  @Input()
  displayBack: boolean;
  @Input()
  leftIcon = false;
  value: any;
  onChange: (_: any) => void;
  onTouched: () => void;
  opened = false;
  @Input()
  disabled = false;
  clickedInside = false;
  @Input()
  error: string = null;
  @Output()
  loadChildren = new EventEmitter<SelectInputChangeEvent<any>>();
  @Output()
  unloadChildren = new EventEmitter<number>(); // level
  @Output()
  loadMoreChildren = new EventEmitter<boolean>();
  @Output()
  search = new EventEmitter<string>()

  filters: DefaultFilters<any> = {
    limit: 10,
    offset: 0,
    filterBy: {}
  };

  data: Array<Option>;

  loading = false;

  searchString: string;

  currentLevel = 0;

  dialogRef: MatDialogRef<SelectOptionsListComponent>;
  subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    public deviceDetectorService: DeviceDetectorService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dialogRef) {
      this.bindChildInput();
    }

  }

  searchChanged($event): void {
    this.search.next($event.target.value);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.value === c2.value : c1 === c2;
  }

  writeValue(value: any): void {
    if (!value) return;
    if (value === -1) {
      this.value = -1;
      this.unloadChildren.next(this.currentLevel - 1);
      if (this.onChange) {
        this.onChange(-1);
      }
    } else if (value.lazyChildren) {
      // invoke loadChildren, don't write value
      this.currentLevel += 1;
      this.loadChildren.next({
        level: this.currentLevel,
        value
      });
    } else {
      if (this.value !== value) {
        if (this.dialogRef) {
          this.dialogRef.close();
        }
        this.value = value;
        this.error = null;
        if (this.onChange) {
          this.onChange(value.value);
          this.opened = false;

        }
      }
    }

  }

  clear(): void {
    this.searchString = '';
    this.filters = {
      limit: 10,
      offset: 0,
      filterBy: {}
    };
    this.writeValue(-1);
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  // TODO - select moze byc wybrany i wyszarzony. Wtedy nie reaguje na eventy
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  click(): void {
    if (!this.disabled) {
      if (this.deviceDetectorService.isDesktop()) {
        this.opened = true;
        this.clickedInside = true;
      } else {
        this.openDialog();
      }
    }
  }

  @HostListener('document:click', ['$event'])
  clickOut($event: any): void {
    if (!$event.target.className.includes('option') && !this.clickedInside && !this.disabled && this.opened) {
      this.opened = false;
      $event.stopPropagation();
      this.unloadChildren.next(1);
    }
    this.clickedInside = false;
    this.searchString = '';
  }

  getCurrentLabel(): string {
    // TODO
    return '';
  }

  onBack(): void {
    this.searchString = '';
    this.clear();
    this.unloadChildren.next(1);
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(SelectOptionsListComponent, {
      panelClass: 'small-dialog-style',
      position: {

        top: '80px'
      }
    });
    this.bindChildInput();

    this.subscriptions.push(
      this.dialogRef.componentInstance.onSelected
        .subscribe((value: HierarchicalOption<any>) => {
          this.writeValue(value);
        }));

    this.subscriptions.push(
      this.dialogRef.componentInstance.inputChange
        .subscribe((value) => {
          this.onInputChange(value);
        }));
    this.subscriptions.push(this.dialogRef.componentInstance.onBack.subscribe(() => this.onBack()));
    this.subscriptions.push(this.dialogRef.afterClosed().subscribe(() => {
      this.unloadChildren.next(1);
    }));
    this.subscriptions.push(this.dialogRef.componentInstance.scrolledDown
      .subscribe(() => {
      this.scrolledDown();
    }));
  }

  bindChildInput(): void {
    this.dialogRef.componentInstance.options$ = this.options$;
    this.dialogRef.componentInstance.displayBack = this.displayBack;
    this.dialogRef.componentInstance.searchString = this.searchString;
  }

  onInputChange(event): void {
    // add searchString to filters
    if (event) {
      this.filters.filterBy = { name: event };
    } else {
      delete this.filters.filterBy;
    }
    this.search.next(event);
  }

  scrolledDown(): void {
    this.loadMoreChildren.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
