<footer fxLayout="row">
  <a
    *ngFor="let option of options$ | async"
    [routerLink]="option.link"
    routerLinkActive="active"
    fxLayout="column"
    #rla="routerLinkActive"
    class="icon-wrapper"
    mat-button
  >
    <mat-icon *ngIf="!rla.isActive && (iconMode$ | async) === IconModes.MAT_ICON">{{
      option.icon
    }}</mat-icon>
    <mat-icon class="material-icon-active" *ngIf="rla.isActive && (iconMode$ | async) === IconModes.MAT_ICON">{{
      option.iconBlue
    }}</mat-icon>
    <svg
      *ngIf="!rla.isActive && (iconMode$ | async) === IconModes.LINK"
      class="icon"
      style="background: url({{ option.icon }}) center no-repeat"
    ></svg>
    <svg
    *ngIf="rla.isActive && (iconMode$ | async) === IconModes.LINK"
    class="icon"
    style="background: url({{ option.iconBlue }}) center no-repeat"
  ></svg>
    <span class="blue-label">
      {{ option.name }}
    </span>
  </a>
</footer>
