import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { BUTTON_COLORS } from '..';
import { Option } from '../interfaces/option.interface';
@Component({
  selector: 'app-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
})
export class SelectButtonComponent implements OnInit {
  ButtonColors = BUTTON_COLORS;
  @Input()
  options: Array<Option>;
  @Input()
  placeholder: string;
  @Input()
  required: boolean;
  @Input()
  leftIcon = false;
  value: any;
  opened = false;
  @Input()
  disabled = false;
  clickedInside = false;
  @Input()
  error: string = null;
  @Input()
  staticLabel: string = null;
  @Output()
  changed = new EventEmitter<Option>();

  selectedOption: Option;

  constructor() {

  }
  ngOnInit(): void {
    if (this.options && this.options.length > 0) {
    this.selectedOption = this.options[0];
    }
  }
  writeValue(event, value: any): void {
    event.stopPropagation();
    this.selectedOption =  this.options.find(option => option.value === value);
    this.error = null;
    this.changed.next(this.selectedOption);
    this.opened = false;
  }

  toggleOpened(event): void {
    event.stopPropagation();
    this.opened = !this.opened;
  }


  @HostListener('click')
  click(): void {
    if (!this.disabled) {
      this.opened = !this.opened;
    }
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  clickOut($event: Event): void {
    if (!this.clickedInside && !this.disabled && this.opened) {
      this.opened = false;
      $event.stopPropagation();
      $event.preventDefault();
    }
    this.clickedInside = false;
  }

}
