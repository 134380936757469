import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@replica-frontend/auth';
import { CommonHttpService } from "@src/app/modules/common-services";
import { OfferApplyStatus } from '@src/app/modules/sp-services/applies.service';
import { Subscription } from 'rxjs';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { OffersResponseDTO, OffersService } from '../../sp-services/offers.service';

@Component({
  selector: 'app-offer-small-card-logged',
  templateUrl: './offer-small-card-logged.component.html',
  styleUrls: ['./offer-small-card-logged.component.scss']
})
export class OfferSmallCardLoggedComponent implements OnInit {
  @Input()
  offer: OffersResponseDTO;
  applyMenu = false;
  liked = false;
  favorite = false;
  unliked = false;
  mine = true;
  likedButtonColor = '#FFFFFF';
  unlikedButtonColor = '#FFFFFF';
  favouriteButtonColor = '#FFFFFF';
  favouriteButtonColorActivate = '#1DA4D5';
  ButtonColors = BUTTON_COLORS;
  employeeAwaitingAproovalOptions = [
    {
      label: 'Zrezygnuj',
      value: 'resignAsEmployee'
    },
  ];

  employeeInProgressOptions = [
    {
      label: 'Potwierdź wykonanie',
      value: 'finalize'
    },
    {
      label: 'Zrezygnuj',
      value: 'resignAsEmployee'
    }
  ];

  employeeEmployerFinalizedOptions = [
    {
      label: 'Potwierdź płatność',
      value: 'aproovePayment'
    }
  ];

  mineOfferOptions =  [
    {
      label: 'Wyłącz',
      value: 'disableOffer'
    },
    {
      label: 'Edytuj',
      value: 'editOffer'
    },
    {
      label: 'Usuń',
      value: 'removeOffer'
    }
  ];

  @Output()
  changed = new EventEmitter<boolean>();

  appliesSyncSub: Subscription;
  OfferApplyStatus = OfferApplyStatus;
  dialogRef: any;
  constructor(
    public offersService: OffersService,
    private authService: AuthService,
    public dialog: MatDialog,
    public commonHttpService: CommonHttpService
    ) {
  }

  ngOnInit(): void {
    // TODO: change for array
    console.log('offer', this.offer);
    this.mine = this.offer && this.authService.me.id === (this.offer.userid && this.offer.userid.id);

    if (this.offer && this.offer.favorite) {
      this.favorite = true;
      this.changeFavouriteButton();
    }
    if (this.offer.LikedStatus === false) {
      this.liked = true;
      this.changeLikedButton();
    } else if (this.offer.LikedStatus) {
      this.unliked = true;
      this.changeUnlikedButton();
    }
  }

  changeLikedButton(): void {
    this.likedButtonColor = '#00D715';
  }

  changeLikedButtonBack(): void {
    this.likedButtonColor = '#FFFFFF';
  }

  changeUnlikedButton(): void {
    this.unlikedButtonColor = '#FF2E00';
  }

  changeUnlikedButtonBack(): void {
    this.unlikedButtonColor = '#FFFFFF';
  }

  changeFavouriteButton(): void {
    this.favouriteButtonColor = '#1DA4D5';
  }

  changeFavouriteButtonBack(): void {
    this.favouriteButtonColor = '#FFFFFF';
  }

  isLiked($event): void {
    $event.stopPropagation();
    this.liked = true;
    if (this.unliked) {
      this.unliked = false;
      this.changeUnlikedButtonBack();
    }
    this.changeLikedButton();
    this.offersService.like(this.offer).subscribe(() => this.changed.emit(true));
  }

  notLiked($event): void {
    $event.stopPropagation();
    this.liked = false;
    if (this.unliked) {
      this.unliked = false;
      this.changeLikedButtonBack();
    }
    this.changeLikedButtonBack();
    this.offersService.dislike(this.offer).subscribe(() => {
      this.changed.emit(true);
    });
  }

  isFavourite($event): void {
    this.favorite = true;
    $event.stopPropagation();
    this.changeFavouriteButton();
    this.changed.emit(true);
    this.offersService.addToFavorites(this.offer).subscribe(() => {

      this.changed.emit(true);
    });
  }

  notFavorite($event): void {
    this.favorite = false;
    $event.stopPropagation();
    this.changeFavouriteButtonBack();

    this.offersService.removeFromFavorites(this.offer).subscribe(() => {
      this.changed.emit(true);
    });
  }

  isUnliked($event): void {
    this.unliked = true;
    if (this.liked) {
      this.liked = false;
      this.changeLikedButtonBack();
    }
    $event.stopPropagation();
    this.changeUnlikedButton();
    this.offersService.dislike(this.offer).subscribe(() => {
      this.changed.emit(true);
    });
  }

  notUnliked($event) {
    this.unliked = false;
    if (this.liked) {
      this.liked = false;
      this.changeLikedButtonBack();
    }
    $event.stopPropagation();
    this.changeUnlikedButtonBack();
    this.offersService.dislike(this.offer).subscribe(() => {
      this.changed.emit(true);
    });
  }

}
