<div class="topbar-logged-mobile">
  <mat-toolbar class="mobile-topbar-wrapper">
    <div class="mobile-topbar-logo-container">
      <button mat-button routerLink="/" class="form-logo">
        <a class="logo"></a>
      </button>
    </div>
    <div class="middle-container">
      <div *ngIf="opened" (click)="toggleOpen($event)">
        <div class="label-mobile-wrapper">
          <span class="label-mobile">{{ label }}</span>
          <a class="arrowup-icon"></a>
        </div>

        <div class="menu-user">
          <button
            routerLink="/app/offers-for-employee"
            (click)="changeLabel(options[0])"
            mat-button
            class="button-option-menu"
          >
            <span class="option-button-label"> {{ options[0].label }} </span>
          </button>
          <button
            routerLink="/app/offers-for-contractor"
            (click)="changeLabel(options[1])"
            mat-button
            class="button-option-menu-bottom"
          >
            <span class="option-button-label"> {{ options[1].label }} </span>
          </button>
        </div>
      </div>

      <div
        *ngIf="!opened"
        class="label-mobile-wrapper-second"
        (click)="toggleOpen($event)"
      >
        <span class="label-mobile">{{ label }}</span>
        <a *ngIf="optionsVisible" class="arrowdown-icon"></a>
      </div>
    </div>

    <div class="mobile-add-container">
      <button mat-mini-fab [routerLink]="['/app', 'create-offer']">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</div>
