<footer fxLayout="row">
  <div fxLayout="row" fxFlex="40">
    <span class="span1" [routerLink]="['/app']">
      © 2020 szybkapraca.pl | Wszelkie prawa zastrzeżone
    </span>
  </div>
  <div fxLayout="row" fxFlex="60" fxLayoutAlign="end center">
    <button mat-button  [routerLink]="['/app', 'static', 'how-it-works']">
      <span
        class="button-span"

        >Jak to działa?</span
      >
    </button>
    <!-- <button mat-button [routerLink]="['/', 'app', 'static', 'how-it-works']">
      <span class="button-span">O nas</span>
    </button> -->
    <!-- <button mat-button [routerLink]="['/', 'app', 'static', 'faq']">
      <span class="button-span">Pomoc</span>
    </button> -->
    <button mat-button routerLink="/app/static/faq">
      <span class="button-span"
        >FAQ</span
      >
    </button>
    <button mat-button routerLink="/app/static/regulations">
      <span class="button-span"
        >Regulamin</span
      >
    </button>
    <button mat-button class="last" [routerLink]="['/app', 'static', 'regulations']">
      <span class="button-span">Polityka prywatności</span>
    </button>
  </div>
</footer>
