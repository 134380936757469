import { Router } from '@angular/router';
import { MaintenanceService } from './modules/common-services/maintenance.service';
import {Component, OnInit} from '@angular/core';
import { MessengerService } from './modules/messenger-services/messenger.service';
import { AuthEvents, AuthService } from '@replica-frontend/auth';
import { MapboxService } from './modules/sp-services/mapbox.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Szybkapraca';
  firstTime = true;
  constructor(
    private authService: AuthService,
    private maintenanceService: MaintenanceService,
    private router: Router,
    private mapboxService: MapboxService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    if (this.maintenanceService.maintenance) {
      this.router.navigate(['/', 'maintenance']);
    }
    this.authService.init();
    this.authService.authEvents$.subscribe((event: AuthEvents)  => {
      if (event === AuthEvents.onLogged) {
        // this.router.navigate(['/', 'app', 'offers-for-employee']);
      }
    });
  }
}
