import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthEvents, AuthService, UserData } from '@replica-frontend/auth';
import { Subscription } from 'rxjs/internal/Subscription';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { CommonHttpService } from '../../common-services';
import {
  AppliesSyncService,
  OffersResponseDTO,
  OffersService,
  Profile
} from '../../sp-services';
import {
  AppliesService,
  OfferApplyStatus,
  OffersApplies
} from '../../sp-services/applies.service';

@Component({
  selector: 'sp-full-offer',
  templateUrl: './full-offer.component.html',
  styleUrls: ['./full-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullOfferComponent implements OnInit {
  ButtonColors = BUTTON_COLORS;
  slug: string;
  mine = false;
  apply = false;
  liked = false;
  favorite = false;
  unliked = false;
  logged = false;
  education;
  @Input()
  offer: OffersResponseDTO;
  OfferApplyStatus = OfferApplyStatus;
  @Input()
  profile: Profile = null;
  applySyncSub: Subscription;
  constructor(
    private snackBar: MatSnackBar,
    private location: Location,
    public offersService: OffersService,
    public authService: AuthService,
    private appliesSyncService: AppliesSyncService,
    public commonHttpService: CommonHttpService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private appliesService: AppliesService
  ) {}

  ngOnInit(): void {
    if (this.profile) {
      this.education = JSON.parse(this.profile.education);
    }
    this.authService.authEvents$.subscribe((authEvent: AuthEvents) => {
      if (authEvent === AuthEvents.onLogged) {
        this.logged = true;
        this.applySyncSub = this.appliesSyncService
          .getMyAppliesForOffer$(this.offer.id)
          .subscribe((updatedApplies) => {
            this.offer.offerApplies[0] = updatedApplies;
            this.changeDetectorRef.detectChanges();
          });
        this.mine =
          this.authService.me.id ===
          (this.offer.userid && this.offer.userid.id);
        if (this.offer && this.offer.favorite) {
          this.favorite = true;
        }
        if (this.offer.LikedStatus === false) {
          this.liked = true;
        } else if (this.offer.LikedStatus) {
          this.unliked = true;
        }
        this.changeDetectorRef.detectChanges();
      }
      if (authEvent === AuthEvents.onLogout) {
        this.logged = false;

        this.mine = false;
        this.changeDetectorRef.detectChanges();
        if (this.applySyncSub) {
          this.applySyncSub.unsubscribe();
        }
      }
    });
  }

  toggleLike($event): void {
    $event.stopPropagation();
    this.liked = !this.liked;
    if (this.liked) {
      this.offersService.like(this.offer).subscribe(
        () => {
          this.snackBar.open('Polubiono oferte', 'Zamknij');
          this.unliked = false;
        },
        (error) => {
          this.snackBar.open('Wystapil blad', 'Zamknij');
          this.liked = !this.liked;
        }
      );
    } else {
      this.offersService.like(this.offer).subscribe(
        () => {
          this.unliked = false;
        },
        (error) => {
          this.liked = !this.liked;
        }
      );
    }
  }

  toggleFavorites($event): void {
    $event.stopPropagation();
    this.favorite = !this.favorite;
    if (this.favorite) {
      this.offersService.addToFavorites(this.offer).subscribe(
        () => {},
        (error) => {
          this.favorite = !this.favorite;
        }
      );
    } else {
      this.offersService.removeFromFavorites(this.offer).subscribe(
        () => {
          this.favorite = false;
        },
        (error) => (this.favorite = !this.favorite)
      );
    }
  }


  applyChanged(): void {}

  toggleDislike($event): void {
    $event.stopPropagation();
    this.unliked = !this.unliked;
    if (this.liked) {
      this.offersService.dislike(this.offer).subscribe(
        () => {
          this.liked = false;
        },
        (error) => {
          this.unliked = !this.unliked;
        }
      );
    } else {
      this.offersService.dislike(this.offer).subscribe(
        () => {
          this.liked = false;
        },
        (error) => (this.unliked = !this.unliked)
      );
    }
  }

  newApply() {
    if (this.authService.me) {
      this.appliesService.apply(this.offer).subscribe((offerApply) => {
        // TODO: send from server
        this.ngOnInit();
      });
    } else {
      this.router.navigate(['/sign-in'], { queryParams: { returnTo: this.router.url }});
    }
  }

  getUserListForOfferAppliesByStatus(
    offerApplies: OffersApplies[],
    offerStatus: string
  ): UserData[] {
    return offerApplies
      .filter((offerApply) => offerApply.status === offerStatus)
      .map((offerApply) => offerApply.userid);
  }

  back(): void {
    this.location.back();
  }
}
