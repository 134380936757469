<responsive-switcher>
  <rs-desktop>
    <div class="container-main">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100">
          <span
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="100"
            class="create-label"
            >Załóż konto</span
          >
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="buttons-wrapper"
          >
            <div fxLayout="row" fxLayoutAlign="center center">
              <app-button-square

                fxLayoutAlign="center center"
                [color]="ButtonColors.GREY"
                class="left-button"
                [routerLink]="['/create-contractor-account']"
              >
                <div
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  class="in-button"
                >
                  <div fxLayout="row" class="person-icon"></div>
                  <div>
                    <span class="button-span"> Szukam zleceń </span>
                  </div>
                </div>
              </app-button-square>

              <app-button-square
                fxLayoutAlign="center center"
                [color]="ButtonColors.GREY"
                class="right-button"
                [routerLink]="['/create-employee-account']"

              >
                <div fxLayout="column" fxLayoutAlign="center center">
                  <div fxLayout="row" class="person-icon-2"></div>
                  <div>
                    <span class="button-span"> Szukam wykonawcy </span>
                  </div>
                </div>
              </app-button-square>
            </div>
            <div
              fxLayout="row"
              class="blue-button"
              fxFlex="60"
              fxLayoutAlign="center center"
              class="blue-button"
            >
              <app-button
                [color]="ButtonColors.SECONDARY"
                fxLayout="column"
                fxLayoutAlign="center center"
                class="button-blue"
              >
                <span class="button-label" fxFlex="100">
                  Po założeniu konta możesz korzystać z funkcjonalności zarówno
                  zleceniodawcy, jak i wykonawcy!
                </span>
              </app-button>
            </div>
          </div>

          <div
            fxLayout="row"
            fxFlex="40"
            fxLayoutAlign="center center"
            class="q-container"
          >
            <span class="konto">Masz już konto?</span>
            <span class="zaloguj" routerLink="/sign-in" matTooltip="Zaloguj się"
            matTooltipClass="tooltip">Zaloguj się</span>
          </div>
        </div>
    </div>
  </rs-desktop>

  <rs-mobile>
      <div fxLayout="column" fxLayoutAlign="center" fxFlex="100">
        <span
          fxLayout="row"
          fxLayoutAlign="center center"
          fxFlex="100"
          class="create-label"
          >Załóż konto</span
        >
        <div fxLayout="row" fxLayoutAlign="center center">
          <app-button-square
            fxLayoutAlign="center center"
            [color]="ButtonColors.GREY"
            class="left-button"
            [routerLink]="['/create-contractor-account']"
          >
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="in-button"
            >
              <div fxLayout="row" class="person-icon"></div>
              <div>
                <span class="button-span"> Szukam zleceń </span>
              </div>
            </div>
          </app-button-square>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <app-button-square
            fxLayoutAlign="center center"
            [color]="ButtonColors.GREY"
            class="right-button"
            [routerLink]="['/create-employee-account']"
          >
            <div fxLayout="column" fxLayoutAlign="center center">
              <div fxLayout="row" class="person-icon-2"></div>
              <div>
                <span class="button-span"> Szukam wykonawcy </span>
              </div>
            </div>
          </app-button-square>
        </div>
        <div fxLayout="row" fxFlex="90" fxLayoutAlign="center center">
          <app-button
            [color]="ButtonColors.SECONDARY"
            fxLayout="column"
            fxLayoutAlign="center center"
            class="button-blue"
          >
            <span class="button-label" fxFlex="90">
              Po założeniu konta możesz korzystać z funkcjonalności zarówno
              zleceniodawcy, jak i wykonawcy!
            </span>
          </app-button>
        </div>
        <div
          fxLayout="row"
          fxFlex="40"
          fxLayoutAlign="center center"
          class="q-container"
        >
          <span class="konto">Masz już konto?</span>
          <span class="zaloguj" [routerLink]="['/sign-in']">Zaloguj się</span>
        </div>
      </div>
  </rs-mobile>
</responsive-switcher>
