import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DeviceDetectorService } from '../../common-services/device-detector.service';
import { HierarchicalOption } from '../interfaces/hierarchical-option.interface';
import { Option } from '../interfaces/option.interface';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  UP_ARROW = 38,
  LEFT_ARROW = 37,
  DOWN_ARROW = 40
}

interface SmHierarchicalOption {
  label: string;
  value: any;
  hasChildren: boolean;
}

@Component({
  selector: 'common-select-options-list',
  templateUrl: './select-options-list.component.html',
  styleUrls: ['./select-options-list.component.scss']
})
export class SelectOptionsListComponent implements AfterViewInit, OnDestroy {
  @Input()
  leftIcon = true;
  @Input()
  loading: boolean;
  @Input()
  placeholder: string;
  @Input()
  small = true;

  @Input()
  searchString: string;
  currentLevel = 1;

  hierarchicalOptions: Array<HierarchicalOption<any>>;
  options: SmHierarchicalOption[][] = [];
  @Input()
  options$: Observable<Array<HierarchicalOption<any>>>;
  @Output()
  onSelected = new EventEmitter<HierarchicalOption<any>>();
  @Output()
  onBack = new EventEmitter<number>();
  @Output()
  inputChange = new EventEmitter<string>();
  @Output()
  scrolledDown = new EventEmitter<boolean>();
  subscriptions = [];
  @ViewChild('sliderContainer')
  sliderContainer: ElementRef;
  singleSlideWidth: number;
  @Input()
  displayBack: boolean;
  containerWidth: number;
  selectedIdx = -1;
  constructor(public deviceDetectorService: DeviceDetectorService) { }
  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.options$.subscribe((options) => {
        this.hierarchicalOptions = options;
        this.options = this.getFlatOptions(options);
        this.currentLevel = this.options.length;
        this.recountContainerSize();
      })
    );
  }

  recountContainerSize(): void {
    const rect = this.sliderContainer.nativeElement.getBoundingClientRect();
    this.singleSlideWidth = rect.width;
    this.containerWidth = this.singleSlideWidth * this.currentLevel;
  }

  selected(value: Option): void {
    if (this.currentLevel < 2) {
      this.onSelected.next(this.hierarchicalOptions.find(x => x.value === value.value));
    } else {
      this.onSelected.next(this.hierarchicalOptions.find(x => x.children && x.children.length > 0).children.find(x => x.value === value.value));
    }
  }

  goUp(): void {
    this.currentLevel--;
    if (this.currentLevel < 0) {
      this.currentLevel = 1;
    }
    this.onBack.next(-1);
  }

  scroll($event): void {
    if ($event.target.scrollHeight - $event.target.scrollTop === 300) {

      this.scrolledDown.next(true);
    }
  }

  /**
   * for now only 2 levels are allowed
   * @param options
   */
  private getFlatOptions(options: HierarchicalOption<any>[]): SmHierarchicalOption[][] {
    // TODO: after finding children - push to next index.
    const optionsArr: SmHierarchicalOption[][] = [];
    options.forEach((x, i) => {
      if (!optionsArr[0]) {

        optionsArr[0] = [];
      }
      optionsArr[0].push({
        label: x.label,
        value: x.value,
        hasChildren: x.lazyChildren
      });
      // display it's children
      if (x.activeNode) {
        optionsArr[1] = x.children.map((x) => ({ label: x.label, value: x.value, hasChildren: x.lazyChildren }));
      }
    });

    return optionsArr;
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {

    if (event.keyCode === KEY_CODE.DOWN_ARROW) {
      // move selection down
      if (this.selectedIdx < this.options[this.currentLevel - 1].length) {
        this.selectedIdx++;
        event.preventDefault();

      }
    }

    if (event.keyCode === KEY_CODE.UP_ARROW) {
      // move selection up
      if (this.selectedIdx > 0) {
        this.selectedIdx--;
        event.preventDefault();

      }
    }

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      // move selection up
      this.selected(this.options[this.currentLevel - 1][this.selectedIdx]);
      this.selectedIdx = 0;
      event.preventDefault();

    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      // move selection up
      this.goUp();
      this.selectedIdx = 0;
      event.preventDefault();

    }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
