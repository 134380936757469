<responsive-switcher>
  <rs-desktop>
    <!-- TODO: add offer id -->
    <div
      [routerLink]="['/', 'offer', offer?.slug]"
      fxLayout="column"
      fxLayoutAlign="center"
      class="wrapper"
    >
      <div class="image-container">
        <img
          class="image-event"
          [src]="
            offer?.thumbnail?.path
              ? commonHttpService.getImagePath(offer.thumbnail.path)
              : '../../../assets/noimage.png'
          "
        />
      </div>
      <div fxLayout="row" class="date-container">
        <a class="location-icon-small"></a>
        <span class="city">
          {{ offer?.mapboxPlace?.text ? offer?.mapboxPlace.text : 'no data' }}
        </span>
        <a class="clock-icon-small"></a>
        <span class="date">
          {{ offer?.created | date: "M/d/yy, h:mm" }}
        </span>
      </div>
      <div fxFlex="100" fxLayout="row">
        <span class="title">{{ offer?.title }}</span>
      </div>

      <div fxLayout="row" class="margin-2">
        <div class="worktime-desktop-wrapper">
          <span *ngIf="offer?.workTime" class="date margin">Czas pracy:</span>
          <span *ngIf="offer?.workTime" class="date"
            >{{ offer?.workTime }} {{ offer?.rateType?.name }}</span
          >
        </div>
        <div fxLayoutAlign="end center" fxFlex="40" *ngIf="offer?.salary">
          <div fxLayoutAlign="start center">
            <span class="price">{{ offer?.salary }} PLN</span>
          </div>
        </div>
      </div>
      <div fxLayout="row" class="margin-3">
        <div
          fxLayoutAlign="start center"
          fxLayout="row"
          class="first-bottom-icons"
        >
          <div fxLayout="row" *ngIf="!mine">
            <button
              matTooltip="Dodaj do ulubionych"
              matTooltipClass="tooltip"
              mat-mini-fab
              *ngIf="!favorite"
              (click)="isFavourite($event)"
              [style.background-color]="favouriteButtonColor"
            >
              <a class="heart-icon"></a>
            </button>
            <button
              matTooltip="Usuń z ulubionych"
              matTooltipClass="tooltip"
              mat-mini-fab
              *ngIf="favorite"
              (click)="notFavorite($event)"
              [style.background-color]="favouriteButtonColor"
            >
              <a class="heart-icon-active"></a>
            </button>
            <button
              matTooltip="Polub"
              matTooltipClass="tooltip"
              mat-mini-fab
              class="button-middle"
              *ngIf="!liked"
              (click)="isLiked($event)"
              [style.background-color]="likedButtonColor"
            >
              <a class="like-icon"></a>
            </button>
            <button
              matTooltip="Nie lubię"
              matTooltipClass="tooltip"
              mat-mini-fab
              class="button-middle"
              *ngIf="liked"
              (click)="notLiked($event)"
              [style.background-color]="likedButtonColor"
            >
              <a class="like-icon-active"></a>
            </button>
            <button
              matTooltip="Nie lubię"
              matTooltipClass="tooltip"
              mat-mini-fab
              *ngIf="!unliked"
              (click)="isUnliked($event)"
              [style.background-color]="unlikedButtonColor"
            >
              <a class="unlike-icon"></a>
            </button>
            <button
              matTooltip="Usuń z nielubianych"
              matTooltipClass="tooltip"
              mat-mini-fab
              *ngIf="unliked"
              (click)="notUnliked($event)"
              [style.background-color]="unlikedButtonColor"
            >
              <a class="unlike-icon-active"></a>
            </button>
          </div>
        </div>
        <div class="apply-desktop-button">
          <sp-apply-button
            *ngIf="!offer?.scrappedFrom"
            [offer]="offer"
          ></sp-apply-button>
        </div>
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div
      [routerLink]="['/', 'offer', offer?.slug]"
      fxFlex="100"
      fxLayout="column"
      fxLayoutAlign="center"
      class="wrapper"
    >
      <div
        *ngIf="offer?.scrappedFrom"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="top-container"
      >
        <div fxLayout="row" fxFlex="40" class="facebook-wrapper">
          <a class="globe-icon"></a>
          <span class="facebook-span">Facebook.com</span>
        </div>
        <div
          fxLayout="row"
          *ngIf="offer?.isScrapped"
          fxFlex="60"
          fxLayoutAlign="end center"
        >
          <span class="promotion-span">Ogłoszenie promowane</span>
        </div>
      </div>
      <div class="image-container">
        <img
          class="image-event"
          [src]="
            offer?.thumbnail?.path
              ? commonHttpService.getImagePath(offer.thumbnail.path)
              : '../../../assets/noimage.png'
          "
        />
      </div>
      <div fxLayout="row" class="date-container">
        <a class="location-icon-small"></a>
        <span class="city">
          {{ offer?.mapboxPlace?.text ? offer?.mapboxPlace.text : 'no data' }}
        </span>
        <a class="clock-icon-small"></a>
        <span class="date">
          {{ offer?.created | date: "D/m/yy, h:mm" }}
        </span>
      </div>
      <div fxLayout="row">
        <span class="title">{{ offer?.title }}</span>
      </div>

      <div fxLayout="row" class="margin-2">
        <div fxLayout="row" fxFlex="60" *ngIf="offer?.workTime">
          <span class="date margin">Czas pracy:</span>
          <span class="date"
            >{{ offer?.workTime }} {{ offer?.rateType?.name }}</span
          >
        </div>
        <div fxLayoutAlign="end center" fxFlex="40" *ngIf="offer?.salary">
          <div fxLayoutAlign="start center">
            <span class="price">{{ offer?.salary }} PLN</span>
          </div>
        </div>
      </div>
      <div fxLayout="row" class="margin-3">
        <div fxLayoutAlign="start center" fxLayout="row" fxFlex="50">
          <div fxLayout="row" *ngIf="!mine">
            <button
              mat-mini-fab
              *ngIf="!favorite"
              (click)="isFavourite($event)"
              [style.background-color]="favouriteButtonColor"
            >
              <a class="heart-icon"></a>
            </button>
            <button
              mat-mini-fab
              *ngIf="favorite"
              (click)="notFavorite($event)"
              [style.background-color]="favouriteButtonColor"
            >
              <a class="heart-icon-active"></a>
            </button>
            <button
              mat-mini-fab
              class="button-middle"
              *ngIf="!liked"
              (click)="isLiked($event)"
              [style.background-color]="likedButtonColor"
            >
              <a class="like-icon"></a>
            </button>
            <button
              mat-mini-fab
              class="button-middle"
              *ngIf="liked"
              (click)="notLiked($event)"
              [style.background-color]="likedButtonColor"
            >
              <a class="like-icon-active"></a>
            </button>
            <button
              mat-mini-fab
              *ngIf="!unliked"
              (click)="isUnliked($event)"
              [style.background-color]="unlikedButtonColor"
            >
              <a class="unlike-icon"></a>
            </button>
            <button
              mat-mini-fab
              *ngIf="unliked"
              (click)="notUnliked($event)"
              [style.background-color]="unlikedButtonColor"
            >
              <a class="unlike-icon-active"></a>
            </button>
          </div>
        </div>
        <sp-apply-button
          *ngIf="!offer?.scrappedFrom"
          class="apply-button"
          [offer]="offer"
        ></sp-apply-button>
        <!-- <sp-apply-button class="apply-button" [offer]="offer"></sp-apply-button> -->
      </div>
    </div>
  </rs-mobile>
</responsive-switcher>
