import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '@replica-frontend/auth';
import {
  ServerSideValidationService,
  ValidationHandler,
  ValidationErrorMessage,
} from '@replica-frontend/sdk';

@Component({
  selector: 'app-create-contractor-account',
  templateUrl: './create-contractor-account.component.html',
  styleUrls: ['./create-contractor-account.component.scss'],
})
export class CreateContractorAccountComponent
  implements OnInit, OnDestroy, ValidationHandler
{
  ButtonColors = BUTTON_COLORS;
  title = 'Szybkapraca';
  captchaSolved = false;
  captchaToken: string;
  captchaError = false;
  submitError = '';
  registerForm = this.formBuilder.group({
    firstname: ['', [Validators.required, Validators.maxLength(80)]],
    lastname: ['', [Validators.required, Validators.maxLength(80)]],
    email: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(80)],
    ],
    password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
        ),
      ],
    ],
    passwordRetype: ['', Validators.required],
    acceptRules: [false, Validators.required],
    newsletter: [false],
  });
  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private location: Location,
    private validationService: ServerSideValidationService
  ) {}
  onFieldErrors(errors: ValidationErrorMessage): void {
    // onFieldErrors(errors: ValidationError[]): void {
    errors.message.forEach((error) => {
      this.registerForm.controls[error.field].setErrors({
        message: error.message,
      });
    });
  }
  ngOnInit(): void {
    this.validationService.registerValidationHandler('register', this);
  }
  ngOnDestroy(): void {
    this.validationService.removeValidationHandler('register');
  }

  submit(): void {
    // TODO: check password retype
    if (!this.captchaToken) {
      this.captchaError = true;
      return;
    }
    this.authService
      .register({
        ...this.registerForm.value,
        ...{ captchaToken: this.captchaToken },
      })
      .subscribe(
        () => {
          this.router.navigate(['create-account-successful']);
        },
        (err) => this.handleError(err)
      );
  }

  loginSocial($event, platform: string): void {
    this.authService.loginSocial($event, platform).subscribe(() => {
      this.router.navigate(['/app', 'offers-for-contractor']);
    });
  }

  resolved(captchaResponse: string): void {
    this.captchaError = false;
    this.captchaToken = captchaResponse;
  }

  handleError(err): void {
    this.submitError = err.message.statusCode === 422 ? 'Wystąpiły błędy formularza' : 'Coś poszło nie tak';
  }

  back($event): void {
    this.location.back();
    $event.stopPropagation();
  }
}
