import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-button-add-picture',
  templateUrl: './button-add-picture.component.html',
  styleUrls: ['./button-add-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonAddPictureComponent {
  @Input()
  public height: string;
  @Input()
  public imagePath: string | ArrayBuffer;
  public hasBaseDropZoneOver = false;
  public message: string;
  public isFileAllowed = false;
  public allowedFiles = ['.jpg', '.jpeg', '.png'];
  public fileName: string;
  public regex = /(?:\.([^.]+))?$/;

  @Input()
  error: string = null;
  // public uploader = new FileUploader({url: this.uploadService.getImageUploadURL()});
  public files: NgxFileDropEntry[] = [];
  // @ts-ignore
  @Output()
  loaded = new EventEmitter<File>();
  constructor() { }

  public dropped(files: NgxFileDropEntry[]): void {
    this.files = files;
    let extension = this.regex.exec(this.fileName);
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.error = "Niewłaściwy format pliku. Obsługujemy JPEG i PNG"
            return;
          }


          // Here you can access the real file
          const reader = new FileReader();
          reader.onload = () => {
            this.imagePath = reader.result;
            this.loaded.emit(file);
          };
          reader.readAsDataURL(file);
          return this.isFileAllowed;
        });
      }


    }

  }
  // TODO:
  public fileOver(event) {
    console.log('not implemented', event);
  }
  // TODO:
  public fileLeave(event) {
    console.log('not implemented', event);
  }

  invokeSelector($event, openFileSelected) {
    $event.preventDefault();
    $event.stopPropagation();
    this.error = null;
    openFileSelected($event);
  }
}
