<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="slug">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Slug</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{element.slug}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="title">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="created">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Created</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{element.created | date: 'fullDate' }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="options">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Option </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element;">
      <div class="button-row">
        <button class="btn-first" mat-stroked-button color="primary" [routerLink]="['/','app', 'edit-offer', element.slug]">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-stroked-button color="accent" (click)="deleteDialog(element.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-cell>
  </ng-container>


  <!--  <ng-container matColumnDef="category">-->
  <!--    <mat-header-cell mat-header-cell *matHeaderCellDef> Title </mat-header-cell>-->
  <!--    <mat-cell mat-cell *matCellDef="let element"> {{element}} </mat-cell>-->
  <!--  </ng-container>-->

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>
<div class="noresults" *ngIf="dataSource.length === 0"><span>No records found</span></div>
