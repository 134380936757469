<responsive-switcher>
  <rs-desktop>
      <div fxLayout="column" class="content-logged">
        <app-offer-list
          [titleLabel]="
            method === 'getOffers'
              ? 'Zlecenia polecane dla Ciebie'
              : 'Twoje zlecenia'
          "
          [queryMethod]="method"
          [filters]="filters"
        ></app-offer-list>
      </div>
  </rs-desktop>
  <rs-mobile>
      <div class="content-logged">
        <app-offer-list
          [titleLabel]="
            method === 'getOffers'
              ? 'Zlecenia polecane dla Ciebie'
              : 'Twoje zlecenia'
          "
          [queryMethod]="method"
          [filters]="filters"
        ></app-offer-list>
      </div>
  </rs-mobile>
</responsive-switcher>
