<responsive-switcher>
  <rs-desktop>
    <div class="container">
      <div fxLayout="row" class="wrapper">
        <div fxFlex="65" class="offer-content" fxLayout="column">
          <button
            mat-icon-button
            (click)="back()"
            class="back-button"
            matTooltip="Cofnij"
            matTooltipClass="tooltip"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span class="typo-title offer-title">
            {{ offer?.title }}
          </span>
          <div fxLayout="row">
            <div fxLayout="row" fxFlex="90" fxLayoutAlign="start center">
              <div
                fxFlex="17"
                class="location"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <div class="location-icon-small"></div>
                <span class="city">
                  {{
                    offer?.mapboxPlace?.context ? offer?.mapboxPlace.context : offer?.city.name
                  }}</span
                >
              </div>
              <div
                fxFlex="33"
                class="date"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <div class="clock-icon-small"></div>
                <span>Dodano</span>
                <span>{{ offer?.created | date: "M/d/yy o h:mm" }}</span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxFlex="50"
                class="applied-wrapper"
              >
                <a class="user-icon-small"></a>
                <span class="applied">
                  Aplikowało {{ offer?.offerApplies.length }} wykonawców
                </span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxFlex="10">
              <div class="location" fxLayout="row" fxLayoutAlign="start center">
                <div class="eye-icon"></div>
                <span>{{ offer?.view }}</span>
              </div>
              <div class="date" fxLayout="row" fxLayoutAlign="start center">
                <div class="heart-icon"></div>
                <span>{{ offer?.likesCount }}</span>
              </div>
            </div>
          </div>
          <hr />
          <div fxLayoutAlign="center center" class="image">
            <img
              class="image-event"
              [src]="
                offer?.thumbnail
                  ? commonHttpService.getImagePath(offer?.thumbnail.path)
                  : '/assets/noimage.png'
              "
            />
          </div>

          <div
            class="options-buttons"
            fxLayoutAlign="start center"
            fxLayout="row"
          >
            <div
              class="salary typo-title"
              fxLayoutAlign="start center"
              fxLayout="row"
              fxFlex="30"
            >
              <span>{{ offer?.salary ? offer.salary + " PLN" : "" }}</span>
            </div>
            <div
              *ngIf="logged"
              class="buttons-wrapper"
              fxFlex="40"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <app-button
                (click)="toggleLike($event)"
                [color]="ButtonColors.GREY"
              >
                <div class="like-icon-button"></div>
              </app-button>
              <app-button
                (click)="toggleDislike($event)"
                [color]="ButtonColors.GREY"
              >
                <div class="unlike-icon-button"></div>
              </app-button>
            </div>
            <div
              *ngIf="logged"
              class="buttons-wrapper"
              fxFlex="30"
              fxLayout="row"
              fxLayoutAlign="end center"
            >
              <app-button
                (click)="toggleFavorites($event)"
                [color]="ButtonColors.GREY"
              >
                <div class="heart-icon-button"></div>
              </app-button>
              <app-button *ngIf="logged" [color]="ButtonColors.GREY">
                <div class="share-icon-button"></div>
              </app-button>
              <app-button *ngIf="logged" [color]="ButtonColors.GREY">
                <div class="report-icon-button"></div>
              </app-button>
            </div>
          </div>
          <div class="work-time" fxLayout="row" fxLayoutAlign="start center">
            <span>Szacowany czas pracy: </span
            ><span>{{ offer?.workTime }}</span>
          </div>
          <hr />
          <div class="description typo-description">
            <span *ngIf="offer && offer.description.length > 0">
              {{ offer?.description }}
            </span>
            <span *ngIf="offer && offer.description.length === 0">
              <i>Brak opisu</i>
            </span>
          </div>
        </div>
        <div fxFlex="35" class="profile-panel">
          <div fxLayout="row">
            <app-avatar class="avatar" [userId]="offer.userid.id"></app-avatar>
            <div
              fxLayout="column"
              class="profile-name"
              fxLayoutAlign="center start"
            >
              <span class="typo-title"
                >{{ offer.userid.firstname }} {{ offer.userid.lastname }}</span
              >
              <a [routerLink]="['/', 'contractor-profile', offer.userid.id]">zobacz profil</a>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="profile-buttons"
          >
            <sp-apply-button
              class="apply-button"
              *ngIf="!offer?.scrappedFrom"
              [offer]="offer"
            ></sp-apply-button>
            <app-button
              class="profile-button"
              fxFlex="20"
              *ngIf="logged"
              [color]="ButtonColors.GREY"
            >
              <div class="chat-icon-button"></div>
            </app-button>
            <app-button fxFlex="20" *ngIf="logged" [color]="ButtonColors.GREY">
              <div class="phone-icon-button"></div>
            </app-button>
          </div>
          <div fxLayout="column" class="first-panel">
            <span class="profile-details-label" *ngIf="profile?.description">
              Opis
            </span>
            <!-- TODO: no data to display -->
            <!-- <span class="profile-details-description-label">
                        Sprawię, że prezentacja Twojego projektu będzie wyjątkowa!
                      </span> -->
            <span
              class="profile-details-description"
              *ngIf="profile?.description"
            >
              {{ profile?.description }}
            </span>
            <span *ngIf="profile?.offersCategory" class="typo-title">
              Kategorie zleceń
            </span>
            <span
              matTooltip="Kategorie"
              class="typo-subheader"
              matTooltipClass="tooltip"
              *ngIf="profile?.offersCategory"
              class="profile-details-content-mobile"
            >
              {{ profile?.offersCategory.category }}
            </span>
            <!-- <span class="profile-details-description-label">
                          Pozostałe
                        </span> -->
            <!-- <span class="profile-details-description">
                          Wystąpienia publiczne
                        </span> -->
            <!-- <span class="profile-details-label">
                          Kwalifikacje
                        </span> -->
            <!-- <div fxLayout="row" class="chips">
                          <app-chips></app-chips>
                        </div> -->
            <span
              class="typo-title"
              *ngIf="
                education?.educationYears ||
                education?.educationSchoolName ||
                education?.educationSpecialization
              "
            >
              Edukacja
            </span>
            <span
              matTooltip="Lata"
              matTooltipClass="tooltip"
              class="profile-details-description-date"
              *ngIf="education?.educationYears"
            >
              {{ education?.educationYears }}
            </span>
            <span
              matTooltip="Nazwa szkoły"
              matTooltipClass="tooltip"
              class="profile-details-description-date-lebel"
              *ngIf="education?.educationSchoolName"
            >
              {{ education?.educationSchoolName }}
            </span>
            <span
              matTooltip="Specjalizacja"
              matTooltipClass="tooltip"
              class="profile-details-description-education"
              *ngIf="education?.educationSpecialization"
            >
              {{ education?.educationSpecialization }}
            </span>
            <span
              class="profile-details-label"
              *ngIf="
                profile?.facebook ||
                profile?.instagram ||
                profile?.twitter ||
                profile?.linkedin ||
                profile?.website
              "
            >
              Social media
            </span>
            <div fxLayout="row" class="socialmedia">
              <a
                matTooltip="Facebook"
                matTooltipClass="tooltip"
                [title]="profile?.facebook"
                class="icon-facebook"
                *ngIf="profile?.facebook"
                [href]="profile?.facebook"
                target="_blank"
              ></a>
              <a
                matTooltip="Instagram"
                matTooltipClass="tooltip"
                [title]="profile?.instagram"
                class="icon-instagram"
                *ngIf="profile?.instagram"
                [href]="profile?.instagram"
                target="_blank"
              ></a>
              <a
                matTooltip="Twitter"
                matTooltipClass="tooltip"
                [title]="profile?.twitter"
                class="icon-twitter"
                *ngIf="profile?.twitter"
                [href]="profile?.twitter"
                target="_blank"
              ></a>
              <a
                matTooltip="Linkedin"
                matTooltipClass="tooltip"
                [title]="profile?.linkedin"
                class="icon-linkedin"
                *ngIf="profile?.linkedin"
                [href]="profile?.linkedin"
                target="_blank"
              ></a>
              <span
                matTooltip="Strona internetowa"
                matTooltipClass="tooltip"
                [title]="profile?.website"
                class="material-icons website-icon"
                [href]="profile?.website"
                target="_blank"
                *ngIf="profile?.website"
                >public</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </rs-desktop>
  <rs-mobile>
    <div class="container-first">
      <div class="container" *ngIf="offer">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxLayout="row" fxFlex>
            <button
              fxLayoutAlign="center center"
              mat-icon-button
              (click)="back()"
              fxFlex
              class="back-button"
            >
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center">
            <div fxLayout="row" class="views" fxLayoutAlign="end center">
              <a class="eye-icon"></a>
              <span class="views-and-likes">{{ offer?.view }}</span>
            </div>
            <div fxLayout="row" class="likes" fxLayoutAlign="end center">
              <a class="heart-icon"></a>
              <span class="views-and-likes">{{ offer?.likesCount }}</span>
            </div>
          </div>
        </div>
        <div fxLayout="row" class="fab-buttons" *ngIf="logged">
          <div
            fxLayout="row"
            class="buttons-first-group"
            fxLayoutAlign="start center"
            fxFlex="60"
          >
            <div *ngIf="!mine" fxLayout="row">
              <button
                (click)="isFavorite($event)"
                *ngIf="!favorite"
                [style.background-color]="favouriteButtonColor"
                mat-mini-fab
                class="button-margin"
              >
                <a class="heart-icon-button"></a>
              </button>
              <button
                (click)="notFavorite($event)"
                *ngIf="favorite"
                [style.background-color]="favouriteButtonColor"
                mat-mini-fab
                class="button-margin"
              >
                <a class="heart-icon-active"></a>
              </button>
            </div>

            <button mat-mini-fab class="button-margin" *ngIf="logged">
              <a class="share-icon-button"></a>
            </button>
            <button mat-mini-fab *ngIf="logged">
              <a class="report-icon-button"></a>
            </button>
          </div>
          <div
            fxLayout="row"
            class="buttons-second-group"
            fxLayoutAlign="end center"
            fxFlex="40"
            *ngIf="!mine"
          >
            <button
              (click)="isLiked($event)"
              [style.background-color]="likedButtonColor"
              mat-mini-fab
              class="button-margin"
              *ngIf="!liked"
            >
              <a class="like-icon-button"></a>
            </button>
            <button
              (click)="notLiked($event)"
              [style.background-color]="likedButtonColor"
              mat-mini-fab
              class="button-margin"
              *ngIf="liked"
            >
              <a class="like-icon-active"></a>
            </button>
            <button
              (click)="isUnliked($event)"
              [style.background-color]="unlikedButtonColor"
              mat-mini-fab
              *ngIf="!unliked"
              class="button-margin-3"
            >
              <a class="unlike-icon-button"></a>
            </button>
            <button
              (click)="notUnliked($event)"
              [style.background-color]="unlikedButtonColor"
              mat-mini-fab
              *ngIf="unliked"
              class="button-margin-3"
            >
              <a class="unlike-icon-active"></a>
            </button>
          </div>
        </div>
        <div class="image-container-wrapper">
          <div class="image-container">
            <img
              class="image-event"
              [src]="
                offer?.thumbnail
                  ? commonHttpService.getImagePath(offer.thumbnail.path)
                  : '/assets/noimage.png'
              "
            />
          </div>
        </div>
        <div class="event-name-wrapper">
          <span class="event-name">{{ offer?.title }}</span>
        </div>
        <div fxLayout="row" class="date-container" fxLayoutAlign="start center">
          <a class="location-icon-small"></a>
          <span class="city">
            {{ offer?.mapboxPlace?.context ? offer?.mapboxPlace.context : offer?.cityid?.name }}
          </span>
          <a class="clock-icon-small"></a>
          <span class="date">
            {{ offer?.created | date: "M/d/yy o h:mm" }}
          </span>
        </div>
        <div fxLayout="row">
          <mat-divider class="divider"></mat-divider>
        </div>
        <div fxLayout="row">
          <span class="price">{{ offer?.salary }} PLN</span>
        </div>
        <div fxLayout="row">
          <span class="time">
            Szacowany czas pracy: {{ offer?.workTime }} godzin
          </span>
        </div>
        <div fxLayout="row">
          <mat-divider class="divider"></mat-divider>
        </div>
        <div fxLayout="column">
          <span>Opis</span>
          <span class="content">{{ offer?.description }}</span>
        </div>

        <div fxLayout="column" *ngIf="mine && offer.offerApplies.length > 0">
          <span class="header">Aplikanci:</span>
          <mat-tab-group mat-stretch-tabs disablePagination="true">
            <mat-tab label="Oczekują">
              <app-user-list
                [userList]="
                  getUserListForOfferAppliesByStatus(
                    offer.offerApplies,
                    OfferApplyStatus.AwaitingAprooval
                  )
                "
              ></app-user-list>
            </mat-tab>
            <mat-tab label="Aktywni">
              <app-user-list
                [userList]="
                  getUserListForOfferAppliesByStatus(
                    offer.offerApplies,
                    OfferApplyStatus.InProgress
                  )
                "
              ></app-user-list>
            </mat-tab>
            <mat-tab label="Nieopł.">
              <app-user-list
                [userList]="
                  getUserListForOfferAppliesByStatus(
                    offer.offerApplies,
                    OfferApplyStatus.EmployeeFinalized
                  )
                "
              ></app-user-list>
            </mat-tab>
            <mat-tab label="Zakończ.">
              <app-user-list
                [userList]="
                  getUserListForOfferAppliesByStatus(
                    offer.offerApplies,
                    OfferApplyStatus.Finalized
                  )
                "
              ></app-user-list>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div fxLayout="row">
          <mat-divider class="divider"></mat-divider>
        </div>
        <!-- <div fxLayout="row" fxAlignCenter="center center"> -->
        <sp-apply-button [offer]="offer" [mine]="mine"></sp-apply-button>
        <!-- </div> -->
        <div fxLayout="row">
          <mat-divider class="divider"></mat-divider>
        </div>
        <div fxLayout="row" *ngIf="offer?.userid">
          <div fxLayout="row" fxLayoutAlign="center start">
            <app-avatar class="avatar" [userId]="offer?.userid.id"></app-avatar>
          </div>
          <div fxLayout="column" fxFlex="100" fxLayoutAlign="center start">
            <span class="avatar-name">
              {{ offer?.userid.firstname }}
              {{ offer?.userid.lastname }}
            </span>
            <span
              class="see-profile"
              [routerLink]="['/', 'contractor-profile', offer?.userid.id]"
            >
              Zobacz profil
            </span>
          </div>
          <div fxLayout="row" fxFlex fxLayoutAlign="center end">
            <button
              mat-mini-fab
              *ngIf="!mine"
              class="bottom-buttons"
              [routerLink]="[
                '/app',
                'ext',
                'messenger',
                'conversations',
                'user',
                offer.userid.id
              ]"
            >
              <a class="chat-icon-button"></a>
            </button>
            <button mat-mini-fab>
              <a class="phone-icon-button"></a>
            </button>
          </div>
        </div>
        <div
          *ngIf="offer?.userid"
          fxLayout="row"
          fxLayoutAlign="center center"
        ></div>
      </div>
    </div>
  </rs-mobile>
</responsive-switcher>
