import { MatTooltipModule } from '@angular/material/tooltip';
import { SortingSelectComponent } from './sorting-select/sorting-select.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MenuLoggedComponent } from '../sp-components/menu-logged/menu-logged.component';
import { MobileGuestWrapperComponent } from '../sp-components/mobile-guest-wrapper/mobile-guest-wrapper.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FooterPanelComponent } from '../sp-components/footer-panel/footer-panel.component';
import { GuestTopbarComponent } from './guest-topbar/guest-topbar.component';
import { DesktopGuestWrapperComponent } from '../sp-components/desktop-guest-wrapper/desktop-guest-wrapper.component';
import { CommonComponentsModule } from './../common-components/common-components.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { CommonServicesModule } from './../common-services/common-services.module';
import { SpServicesModule } from './../sp-services/sp-services.module';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { UserListComponent } from './user-list/user-list.component';
import { InputMobileComponent } from './input-mobile/input-mobile.component';
import { FormsModule } from '@angular/forms';
import { OfferListHorizontalComponent } from './offer-list-horizontal/offer-list-horizontal.component';
import { OfferSmallCardLoggedComponent } from './offer-small-card-logged/offer-small-card-logged.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ApplyButtonComponent } from './apply-button/apply-button.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SpVersionComponent } from './sp-version/sp-version.component';
import { FullOfferComponent } from './full-offer/full-offer.component';
import { TagComponent } from './tag/tag.component';

@NgModule({
  declarations: [
    UserListComponent,
    InputMobileComponent,
    OfferListHorizontalComponent,
    OfferSmallCardLoggedComponent,
    ApplyButtonComponent,
    MaintenanceComponent,
    DesktopGuestWrapperComponent,
    GuestTopbarComponent,
    FooterPanelComponent,
    MobileGuestWrapperComponent,
    MenuLoggedComponent,
    SpVersionComponent,
    SortingSelectComponent,
    FullOfferComponent,
    TagComponent,
  ],
  imports: [
    CommonModule,
    CommonServicesModule.forRoot({
      appName: 'Szybkapraca',
    }),
    CommonComponentsModule,
    RouterModule,
    SpServicesModule,
    ScrollingModule,
    MatListModule,
    FlexLayoutModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    UserListComponent,
    InputMobileComponent,
    OfferSmallCardLoggedComponent,
    OfferListHorizontalComponent,
    ApplyButtonComponent,
    DesktopGuestWrapperComponent,
    GuestTopbarComponent,
    FooterPanelComponent,
    MobileGuestWrapperComponent,
    MenuLoggedComponent,
    SpVersionComponent,
    SortingSelectComponent,
    FullOfferComponent,
    TagComponent
  ],
})
export class SpComponentsModule {}
