import {AfterViewInit, Component, Injector, OnDestroy} from '@angular/core';
import {MenuService} from '../../modules/sp-services';
import {AdminUxOptionsService} from '@replica-frontend/admin';
import {IconModes} from '../../modules/sp-services/menu.service';
import { ModulesConfigService } from '@replica-frontend/sdk';

@Component({
  templateUrl: './admin-wrapper.component.html',
})
export class AdminWrapperComponent implements AfterViewInit, OnDestroy {
  private _adminUxOptionsService: AdminUxOptionsService;
  constructor(
    private menuService: MenuService,
    private injector: Injector,
    private modulesConfigService: ModulesConfigService
  ) {
  }

  ngOnDestroy(): void {
    // revert menu
    this.menuService.setDefaultMenu();
    this.menuService.setIconMode(IconModes.LINK);
  }

  get adminUxOptionsService(): AdminUxOptionsService {
    if (!this._adminUxOptionsService) {
      this._adminUxOptionsService = this.injector.get(AdminUxOptionsService);
    }
    return this._adminUxOptionsService;
  }

  ngAfterViewInit(): void {
    this.menuService.setIconMode(IconModes.MAT_ICON);
    const menu = this.adminUxOptionsService.getMenuItems();
    console.log(menu);
    let menuObjects = [];
      menuObjects = menuObjects.concat(this.adminUxOptionsService.getMenuItems().map((item) => ({
        icon: item.icon,
        iconBlue: item.icon,
        link: '/app/admin/' + item.link,
        name: item.label
      })))
    menuObjects = menuObjects.concat(this.modulesConfigService
      .getConfigForModule('admin')
      .externalModules.map((externalModule) => {
        return {
          link: '/app/admin/ext/' + externalModule.entryPath,
          name: externalModule.label,
          icon: externalModule.icon,
          iconBlue: externalModule.icon
        }
      }));

      this.menuService.setMenuObjects(menuObjects);
      
  }
}
