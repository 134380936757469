import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserData } from '@replica-frontend/auth';
import { Files } from '@replica-frontend/media';
import { ServerSideValidationService } from "@replica-frontend/sdk";
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay, take } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/modules/common-services/environment.service';
import { DefaultFilters } from '../common-services';
import { OffersApplies } from './applies.service';
import { MapboxFeature } from './mapbox.service';

export interface Skills {
  id: number;
  name: string;
}

export interface OffersRequestDTO {
  id: string;
  salary: number;
  slug: string;
  title: string;
  description: string;
  workTime: string;
  archived: boolean;
  view: number;
  created: Date;
  expireAt: Date;
  likesCount: number;
  dislikesCount: number;
  isScrapped: boolean;
  jobId?: number;
  skills: Skills[];
  userid: UserData;
  mapboxPlace: MapboxFeature;
  category: number;
  days: number;
  rateType: number;
  type: number;
  favorite: boolean;
  LikedStatus?: boolean;
  wasApplied: boolean;
  thumbnail: Files;
  originalPosted: Date;
  scrapperRecruiterImage: string;
  scrapperRecruiterName: string;
  scrapperAddress: string;
  scrappedFrom: string;
}

// export interface Education {
//   id: string;
//   schoolName: string;
//   city: City;
//   startYear: number;
//   endYear: number;
//   degreeCourse: string;
// }

export interface OfferCategory {
  id: number
  category: string
};



export interface OffersResponseDTO {
  id: string;
  salary: number;
  slug: string;
  title: string;
  description: string;
  workTime: string;
  archived: boolean;
  view: number;
  created: Date;
  expireAt: Date;
  likesCount: number;
  dislikesCount: number;
  isScrapped: boolean;
  jobId?: number;
  skills: Skills[];
  userid: UserData;
  city: {
    id: number,
    name: string
  };
  category: OfferCategory;
  days: {
    id: number,
    name: string
  };
  rateType: {
    id: number
    name: string
  };
  type: {
    id: number
    name: string
  };
  favorite: boolean;
  LikedStatus?: boolean;
  offerApplies: OffersApplies[];
  thumbnail: Files;
  originalPosted: Date;
  scrapperRecruiterImage: string;
  scrapperRecruiterName: string;
  scrapperAddress: string;
  scrappedFrom: string;
  mapboxPlace: MapboxFeature
}
export interface CreateOfferDto {
  city: number;
  days: number;
  type: number;
  rateType: number;
  category: null;
  salary: number;
  title: string;
  description: string;
  workTime: number;
  skills?: Skills[];
}

export interface UsersFavorites {
  id: number;
  userid: UserData;
  offerid: OffersResponseDTO;
}

export interface Categories {
  id: number;
  category: string;
}

export interface Other {
  id: number;
  name: string;
}

@Injectable()
export class OffersService {
  getCategories$ = this.httpClient.get<Array<Categories>>(`${this.environmentService.getEnv('apiUrl')}/offers/category`)
    .pipe(shareReplay(1));
  getRateTypes$ = this.httpClient.get<Array<Other>>(`${this.environmentService.getEnv('apiUrl')}/offers/ratetypes`)
    .pipe(shareReplay(1));
  getOfferTypes$ = this.httpClient.get<Array<Other>>(`${this.environmentService.getEnv('apiUrl')}/offers/offerstypes`)
    .pipe(shareReplay(1));
  getOffersDays$ = this.httpClient.get<Array<Other>>(`${this.environmentService.getEnv('apiUrl')}/offers/offersdays`)
    .pipe(shareReplay(1));

  constructor(
    private httpClient: HttpClient,
    private validationService: ServerSideValidationService,
    private environmentService: EnvironmentService
  ) {
  }

  getOffers(filters: DefaultFilters<OffersRequestDTO>): Observable<Array<OffersResponseDTO>> {
    return this.httpClient.post<Array<OffersResponseDTO>>(`${this.environmentService.getEnv('apiUrl')}/offers`, filters);
  }

  getOffersAdmin(filters: DefaultFilters<OffersRequestDTO>): Observable<Array<OffersResponseDTO>> {
    return this.httpClient.post<Array<OffersResponseDTO>>(`${this.environmentService.getEnv('apiUrl')}/offers/admin-search`, filters);
  }

  mine(filters: DefaultFilters<OffersRequestDTO>): Observable<Array<OffersResponseDTO>> {
    return this.httpClient.post<Array<OffersResponseDTO>>(`${this.environmentService.getEnv('apiUrl')}/offers/mine`, filters).pipe(take(1));
  }

  getFavorites(filters: DefaultFilters<OffersRequestDTO>): Observable<Array<OffersResponseDTO>> {
    return this.httpClient.post<Array<OffersResponseDTO>>(`${this.environmentService.getEnv('apiUrl')}/offers/favorites`, filters)
      .pipe(take(1));
  }

  getEndedOffers(filters: DefaultFilters<OffersRequestDTO>): Observable<Array<OffersResponseDTO>> {
    return this.httpClient.get<Array<OffersResponseDTO>>(`${this.environmentService.getEnv('apiUrl')}/offers/ended`)
      .pipe(take(1));
  }

  updateOffer(id: string, data: CreateOfferDto): Observable<OffersResponseDTO> {
    return this.httpClient.put<OffersResponseDTO>(`${this.environmentService.getEnv('apiUrl')}/offers/${id}`, data);
  }

  createOffer(data: CreateOfferDto): Observable<CreateOfferDto> {
    return this.httpClient
      .put<CreateOfferDto>(`${this.environmentService.getEnv('apiUrl')}/offers`, data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 422) {
            this.validationService.handleErrorResponse('create-offer', error);
          }
          return throwError(error);
        }),
      );
  }

  getSkillsByName(namePart: string): Observable<Other[]> {
    return this.httpClient.get<Array<Other>>(`${this.environmentService.getEnv('apiUrl')}/skills/${namePart}`);
  }

  addSkill(skill: string): Observable<Other[]> {
    return this.httpClient.put<Array<Other>>(`${this.environmentService.getEnv('apiUrl')}/skills`, skill);
  }

  getLike(filters: DefaultFilters<OffersResponseDTO>): Observable<OffersResponseDTO[]> {
    return this.httpClient.post<OffersResponseDTO[]>(`${this.environmentService.getEnv('apiUrl')}/offers/like`, filters);
  }

  like(offer: OffersResponseDTO): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.environmentService.getEnv('apiUrl')}/offers/like`, { id: offer.id });
  }

  getDislike(filters: DefaultFilters<OffersResponseDTO>): Observable<OffersResponseDTO[]> {
    return this.httpClient.post<OffersResponseDTO[]>(`${this.environmentService.getEnv('apiUrl')}/offers/dislike`, filters).pipe(take(1));
  }

  dislike(offer: OffersResponseDTO): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.environmentService.getEnv('apiUrl')}/offers/dislike`, { id: offer.id });
  }
  addToFavorites(offer: OffersResponseDTO): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.environmentService.getEnv('apiUrl')}/offers/favorite`, { id: offer.id });
  }

  removeFromFavorites(offer: OffersResponseDTO): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.environmentService.getEnv('apiUrl')}/offers/favorite/${offer.id}`);
  }

  singleOffer(slug: string): Observable<OffersResponseDTO> {
    return this.httpClient.get<OffersResponseDTO>(`${this.environmentService.getEnv('apiUrl')}/offers/single/${slug}`);
  }

  singleApply(id: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.environmentService.getEnv('apiUrl')}/offers/apply/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${this.environmentService.getEnv('apiUrl')}/offers/${id}`)
  }
}
