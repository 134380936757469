import {Component} from '@angular/core';
import { MaintenanceService } from '../../common-services/maintenance.service';
@Component({
  selector: 'app-mobile-guest-wrapper',
  templateUrl: './mobile-guest-wrapper.component.html',
  styleUrls: ['./mobile-guest-wrapper.component.scss']
})

export class MobileGuestWrapperComponent {
  maintenance = false;
  opened: boolean;
  // TODO: Make input and interface
  options = [
    {
      name: 'Jak to działa?',
      link: '/static/how-it-works',
    },
    // {
    //   name: 'O nas'
    // },
    // {
    //   name: 'Pomoc'
    // },
    {
      name: 'FAQ',
      link: '/static/faq'
    },
    {
      name: 'Regulamin',
      link: '/static/regulations'
    },
    // {
    //   name: 'Polityka prywatności',
    //   link: '/static/faq'
    // },
  ];
  constructor(private maintenanceService: MaintenanceService) {

  }
  ngOnInit(): void {
    this.maintenance = this.maintenanceService.maintenance;
  }


}
