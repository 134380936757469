import { OffersResponseDTO, OffersService } from '../../modules/sp-services/offers.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonHttpService } from '../../modules/common-services';

@Component({
  selector: 'app-offer-small-card',
  templateUrl: './offer-small-card.component.html',
  styleUrls: ['./offer-small-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferSmallCardComponent implements OnInit {
  @Input()
  offer: OffersResponseDTO;
  constructor(
    public offersService: OffersService,
    public commonHttpService: CommonHttpService
  ){}
  ngOnInit(): void {
  }
}
