import {Component, ElementRef, forwardRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {  NG_VALUE_ACCESSOR, SelectControlValueAccessor} from '@angular/forms';
import {Option} from '../../modules/common-components';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent extends SelectControlValueAccessor implements OnInit {
  @Input()
  options: Array<Option> = [];
  @Input()
  placeholder: string;
  @Input()
  required: boolean;
  @Input()
  leftIcon = false;
  value: any;
  onChange: (_: any) => void;
  onTouched: () => void;
  opened = false;
  @Input()
  disabled = false;
  clickedInside = false;
  @Input()
  error: string = null;

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef);
  }
  ngOnInit(): void {
    if (!this.options) return;
    this.options.forEach((option) => {
      if (!option.value || !option.label) {
        console.warn(`Incorrect options for app-select with placeholder ${this.placeholder}. They should have only value and label fields.`)
      }
    })
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.value === c2.value : c1 === c2;
  }

  writeValue(value: any): void {
    this.value = value;
    this.error = null;
    this.onChange(value);
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  // TODO - select moze byc wybrany i wyszarzony. Wtedy nie reaguje na eventy
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostListener('click')
  click(): void {
    if (!this.disabled) {
      this.opened = !this.opened;
    }
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event'])
  clickOut($event: Event): void {
    if (!this.clickedInside && !this.disabled && this.opened) {
      this.opened = false;
      $event.stopPropagation();
    }
    this.clickedInside = false;
  }

  getCurrentLabel() {
    const option = this.options.find(option => option.value === this.value);

    if (option) {
      return option.label;
    } else {
      return null;
    }
  }

}
