import {Component, Input} from '@angular/core';

export enum BUTTON_COLORS {
  PRIMARY,
  SECONDARY,
  GREY,
  BLACK,
  WHITE
}

@Component({
  selector: 'app-button-square',
  templateUrl: './button-square.component.html',
  styleUrls: ['./button-square.component.scss']
})
export class ButtonSquareComponent {
  @Input()
  public color: BUTTON_COLORS = BUTTON_COLORS.PRIMARY;
  public ButtonColors = BUTTON_COLORS;
}
