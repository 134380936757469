<responsive-switcher>
  <rs-desktop>
    <div class="home-container">
      <app-topbar-filter
        (filtersOpened)="setOpenedFilter($event)"
        [label]="titleLabel"
        (filtersChanged)="filtersChanged($event)"
        (sortChanged)="sortChanged($event)"
      ></app-topbar-filter>
      <!-- TODO: width should be constant -->
      <div class="map-container">
        <!-- mapbox -->
        <mgl-map
          *ngIf="!isServer && offers.length !== 0"
          [style]="'mapbox://styles/mapbox/outdoors-v12'"
          [zoom]="[0]"
          [center]="[-74.5, 40]"
          (load)="onMapLoaded($event)"
        ></mgl-map>
      </div>

      <div class="list-container" *ngIf="offers.length !== 0">
 
        <div
          fxLayout="row"
          fxLayoutAlign="start"
          fxLayoutGap="30px"
          class="cards-wrapper"
        >
          <app-offer-small-card
            [routerLink]="['../', 'offer', offer.slug]"
            class="offer-small-card"
            [offer]="offer"
            *ngFor="let offer of offers"
          ></app-offer-small-card>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="offers.length === 0" class="no-offers">Brak ofert</span>
        <app-button
          *ngIf="offers.length > 0 && offers.length !== 0"
          tooltip="Zobacz więcej"
          [color]="ButtonColors.PRIMARY"
          class="button-more"
          (click)="getMore()"
          [style.display]="cannotBeMore ? 'none' : 'flex'"
        >
          <span class="button-span">Zobacz więcej</span>
        </app-button>
      </div>
      <app-newsletter-panel></app-newsletter-panel>
    </div>
  </rs-desktop>

  <rs-mobile>
    <div fxLayout="column">
      <div class="container-grey-wrapper">
        <app-topbar-filter></app-topbar-filter>
        <div
          fxLayout="column"
          class="container-grey"
          fxLayoutAlign="center center"
          *ngIf="offers.length !== 0"
        >
          <mgl-map
            *ngIf="!isServer"
            [style]="'mapbox://styles/mapbox/outdoors-v12'"
            [zoom]="[0]"
            [center]="[-74.5, 40]"
            (load)="onMapLoaded($event)"
          ></mgl-map>
        </div>
      </div>

      <span *ngIf="offers.length === 0" class="no-offers">Brak ofert</span>
      <div
        fxLayout="row"
        [fxLayoutAlign]="
          offers.length === 1 ? 'center center' : 'space-between center'
        "
        fxLayoutGap="10px"
        fxFlex="100"
        [ngClass]="{
          'event-list-container': offers.length === 1,
          'events-list-container': offers.length > 1
        }"
        class="event-list-container"
      >
        <app-offer-small-card
          [offer]="offer"
          *ngFor="let offer of offers"
          [routerLink]="['/', 'offer', offer.slug]"
        ></app-offer-small-card>
      </div>
      <div fxLayout="row" fxLayoutAlign="center">
        <app-button
          *ngIf="offers.length > 0 && offers.length !== 0"
          [color]="ButtonColors.SECONDARY"
          class="button-more"
          (click)="getMore()"
          [style.display]="cannotBeMore ? 'none' : 'flex'"
        >
          <span class="button-span">Pokaż więcej zleceń</span>
        </app-button>
      </div>
    </div>
    <div class="newsletter-mobile">
      <app-newsletter-panel></app-newsletter-panel>
    </div>
  </rs-mobile>
</responsive-switcher>
