import { Component } from '@angular/core';

@Component({
  selector: 'app-desktop-guest-wrapper',
  templateUrl: './desktop-guest-wrapper.component.html',
  styleUrls: ['./desktop-guest-wrapper.component.scss']
})
export class DesktopGuestWrapperComponent {

}
