<div class="options-desktop" [ngClass]="{'small-select' : small, 'big-select' : !small}" (scroll)="scroll($event)">
  <div fxLayout="column">
    <div
      fxLayout="row"
      class="input-container"
      *ngIf="!deviceDetectorService.isDesktop()"
    >
      <div *ngIf="leftIcon" class="icon-location-wrapper">
        <div class="icon-location-container">
          <a *ngIf="!loading" class="icon-location"></a>
        </div>
      </div>
      <mat-progress-spinner *ngIf="loading"></mat-progress-spinner>
      <div *ngIf="!leftIcon" fxLayout="row"></div>
      <div class="label-input-container-icon" fxLayoutAlign="start center">
        <input
          class="span-label label-color-placeholder"
          placeholder="Wybierz miasto"
          (input)="inputChange.next($event.target.value)"
          [(ngModel)]="searchString"
        />

        <div class="arrow-container">
          <a class="arrow"></a>
        </div>
      </div>
    </div>
    <!-- set width manually -->
    <div
      #sliderContainer
      class="slider-container"
      [style.width.px]="singleSlideWidth || 'inherit'"
    >
      <div
        [style.width.px]="containerWidth"
        class="slider"
        [ngStyle]="{
          transform:
            'translateX(' + -((currentLevel - 1) * singleSlideWidth) + 'px)'
        }"
      >
        <div
          [style.width.px]="singleSlideWidth"
          fxLayout="column"
          *ngFor="let slide of options"
          class="options"
          style="background-color: #f4f6fb"
        >
          <!-- if option is first -->

          <div class="option" (click)="goUp()" *ngIf="currentLevel >= 2 || displayBack">
            <a class="arrow-left option-back"></a>
          </div>
          <div
            *ngFor="let option of slide; let i = index"
            class="option"
            [ngClass]="{'selected': i === selectedIdx}"
            (click)="selected(option)"
          >
            <span class="option-label">
              {{ option.label }}
            </span>
            <a *ngIf="option.hasChildren" class="arrow-right"></a>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
