<span class="error-message" *ngIf="error">
  {{ error }}
</span>

<button
  [matTooltip]="tooltip"
  matTooltipClass="tooltip"
  mat-button
  [type]="buttonType"
  [ngClass]="{
    'bg-primary': color === ButtonColors.PRIMARY,
    'bg-secondary': color === ButtonColors.SECONDARY,
    'bg-grey': color === ButtonColors.GREY,
    'bg-black': color === ButtonColors.BLACK,
    'bg-white': color === ButtonColors.WHITE,
    'bg-red': color === ButtonColors.RED,
    'bg-green': color === ButtonColors.GREEN,
    error: error
  }"
>
  <ng-content></ng-content>
</button>
