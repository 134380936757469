import { MatSnackBar } from '@angular/material/snack-bar';
import { OffersResponseDTO } from './../../sp-services/offers.service';
import { AppliesService, OfferApplyStatus, OffersApplies } from './../../sp-services/applies.service';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppliesSyncService } from '../../sp-services';
import { Option } from '../../common-components';
import { Router } from '@angular/router';
import { AuthService } from '@replica-frontend/auth';

@Component({
  selector: 'sp-apply-button',
  templateUrl: './apply-button.component.html',
  styleUrls: ['./apply-button.component.scss'],
})
export class ApplyButtonComponent implements OnInit, OnDestroy {
  employeeAwaitingAproovalOptions = [
    {
      label: 'Zrezygnuj',
      value: 'resignAsEmployee'
    },
  ];

  employeeInProgressOptions = [
    {
      label: 'Potwierdź wykonanie',
      value: 'finalize'
    },
    {
      label: 'Zrezygnuj',
      value: 'resignAsEmployee'
    }
  ];

  employeeEmployerFinalizedOptions = [
    {
      label: 'Potwierdź płatność',
      value: 'aproovePayment'
    }
  ];

  mineOfferOptions =  [
    // {
    //   label: 'Wyłącz',
    //   value: 'disableOffer'
    // },
    {
      label: 'Edytuj',
      value: 'editOffer'
    },
    // {
    //   label: 'Usuń',
    //   value: 'removeOffer'
    // }
  ];
  mine: boolean = false;

  appliesSyncSub: Subscription;

  @Input()
  offer: OffersResponseDTO;
  apply: OffersApplies;

  @Output()
  changed = new EventEmitter<string>();

  OfferApplyStatus = OfferApplyStatus;

  constructor(
    private appliesService: AppliesService,
    private appliesSyncService: AppliesSyncService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.offer.userid && this.authService.me.id) {
      this.mine = this.authService.me ? this.offer.userid.id === this.authService.me.id : false;
    }
    if (this.authService.me) {
      this.appliesSyncSub = this.appliesSyncService.getMyAppliesForOffer$(this.offer.id).subscribe((offerApply) => {
        this.offer.offerApplies = [];
        this.offer.offerApplies[0] = offerApply;
        this.apply = offerApply;
      });
    }
    
  }

  newApply($event): void {
    // this.apply = true;
    $event.stopPropagation();
    if (this.authService.me) {
      this.appliesService.apply(this.offer).subscribe((offerApply) => {
        this.changed.emit('newApply');
        // TODO: send from server
        this.apply = offerApply;
      });
    } else {
      this.router.navigate(['/sign-in'], { queryParams: { returnTo: this.router.url }});
    }

  }

  unApply($event): void {
    $event.stopPropagation();
    this.appliesService.apply(this.offer).subscribe(() => {
      this.changed.emit('unApply');
    });
  }

  finalize(): void {
    this.appliesService.finalizeAsEmployee(this.apply.id).subscribe((apply: OffersApplies) => {
      this.apply = apply;
    });
  }

  employeeAwaitingAproovalClicked(option: Option): void {
    // value
    switch(option.value) {
      case 'resignAsEmployee':
        this.resignAsEmployee()
      break;
    }
  }

  employeeInProgressClicked(option: Option): void {
    // value
    switch(option.value) {
      case 'finalize':
        this.finalize();
        break;
      case 'resignAsEmployee':
        this.resignAsEmployee()
        break;
    }
  }

  employeeEmployerFinalizedClicked(option: Option): void {
    // value
    switch(option.value) {
      case 'finalize':
        this.finalize();
        break;
      case 'resignAsEmployee':
        this.resignAsEmployee();
        break;
    }
  }

  mineOfferOptionsClicked(option: Option): void {
    switch(option.value) {
      case 'editOffer':
        this.changed.next('editOffer');
        this.router.navigate(['/', 'app', 'edit-offer', this.offer.slug]);
        break;
    }
  }

  aproovePayment($event): void {
    $event.stopPropagation();
    this.appliesService.aproovePayment(this.apply.id).subscribe((offerApply: OffersApplies) => {
      this.changed.next('aproovePayment');
      this.apply = offerApply;
    });
  }

  private resignAsEmployee(): void {
    this.appliesService.resignAsEmployee(this.apply).subscribe((offerAplies) => {
      this.apply = offerAplies;
    })
  }

  ngOnDestroy(): void {
    if (this.appliesSyncSub) {
      this.appliesSyncSub.unsubscribe();
    }
  }

}
