import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface SzybkaPracaEnv {
  production: boolean;
  apiUrl: string;
  wsUrl: string;
  wsPort: number;
  debug: boolean;
}
@Injectable()
export class EnvironmentService {
  constructor() {
    if (environment.debug) {
      console.log(environment);
    }
  }

  getEnv(envName: string): SzybkaPracaEnv {
    return environment[envName];
  }
}
