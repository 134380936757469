import {Component, OnInit} from '@angular/core';
import { VersioningService } from '../../common-services/versioning.service';

@Component({
    selector: 'common-version-info',
    templateUrl: './version-info.component.html',
    styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent implements OnInit {
    version = this.versioningService.currentApplicationVersion;
    appName = this.versioningService.appName;
    deps = [];
    Array = Array;
    constructor(
        public versioningService: VersioningService,

    ) {
        console.log('version', this.version);
    }

  ngOnInit(): void {
      this.deps = this.versioningService.getPackages();
      console.log(this.deps);

  }
}
