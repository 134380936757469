<common-select-input
  [small]="small"
  [error]="error"
  [leftIcon]="true"
  class="first-select"
  placeholder="Wybierz miasto"
  [options$]="options$"
  [(ngModel)]="value"
  (ngModelChange)="valueChanged($event)"
  (loadChildren)="loadChildren($event)"
  (loadMoreChildren)="loadMoreCities()"
  (unloadChildren)="unloadChildren($event)"
  (search)="searchCities($event)"
  [displayBack]="displayBack"
>
</common-select-input>
