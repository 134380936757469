import {Injectable} from '@angular/core';
import {CommonServiceConfig} from './common-services.module';
import {EnvironmentService} from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class VersioningService {
  currentApplicationVersion = this.environmentService.getEnv('appVersion');
  appName = this.commonComponentsConfig.appName;

  constructor(
    private environmentService: EnvironmentService,
    private commonComponentsConfig: CommonServiceConfig
  ) {
  }

  getPackages(): Array<any> {
    const { dependencies } = require('../../../../package.json');
    return dependencies;
  }
}
