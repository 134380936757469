import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OffersService } from '../../../../app/modules/sp-services/offers.service';
import {DeviceDetectorService} from '../../../../app/modules/common-services';
import { AuthService } from '@replica-frontend/auth';


@Component({
  selector: 'app-logged-options',
  templateUrl: './logged-options.component.html',
  styleUrls: ['./logged-options.component.scss']
})

export class LoggedOptionsComponent {
  constructor(
    private router: Router,
    private location: Location,
    public authService: AuthService,
    public offersService: OffersService,
    private deviceDetectorService: DeviceDetectorService
  ) {

  }

  isIos = false;

  ngOnInit(): void {
    this.isIos = this.deviceDetectorService.iOS();
}
  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  back(): void {
    this.location.back();
  }
}
