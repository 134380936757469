import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService, UserData } from '@replica-frontend/auth';
import {
  ServerSideValidationService, ValidationErrorMessage,
  ValidationHandler
} from '@replica-frontend/sdk';
import { CommonHttpService, DeviceDetectorService } from '@src/app/modules/common-services';
import { DefaultFilters } from '@src/app/modules/common-services/default-filters.interface';
import { OffersService, UploadService } from '@src/app/modules/sp-services';
import {
  OffersRequestDTO,
  OffersResponseDTO
} from '@src/app/modules/sp-services/offers.service';
import { combineLatest, Observable, of, zip } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Option } from '../../../modules/common-components';
import { BUTTON_COLORS } from '../../../modules/common-components/button/button.component';
import { Profile, ProfileService } from './../../../modules/sp-services';

@Component({
  selector: 'app-edit-contractor-profile',
  templateUrl: './edit-contractor-profile.component.html',
  styleUrls: ['./edit-contractor-profile.component.scss'],
})
export class EditContractorProfileComponent
  implements OnInit, OnDestroy, ValidationHandler {
  ButtonColors = BUTTON_COLORS;
  filters = new FormControl();
  offers: Array<OffersResponseDTO> = [];
  filtersList: Observable<any> = of([
    { label: 'Data dodania', value: 'created' },
    { label: 'Nazwa', value: 'title' },
    { label: 'Miejscowość', value: 'city' },
  ]);

  getEndedOffers = this.offersService.getEndedOffers;
  panelOpenState = false;
  length = 32;
  pageSize = 2;
  pageSizeOptions: number[] = [2, 4, 6, 8];
  selected = null;
  public activeField: string[] = [];
  profileForm = this.formBuilder.group({
    phoneNumber: [null, Validators.required],
    email: [null, Validators.required],
    avatar: [null],
    profileMedia: [null],
    contractorProfileMedia: [null],
    welcomeTitle: [null],
    contratorTitle: [null],
    welcomeDescription: [null],
    contractorDescription: [null],
    description: [null],
    facebook: [null],
    instagram: [null, Validators.required],
    twitter: [null, Validators.required],
    linkedin: [null, Validators.required],
    website: [null],
    offersCategory: [null],
    firstname: [null],
    lastname: [null],
    educationSchoolName: [''],
    educationYears: [''],
    educationSpecialization: [''],
  });
  private tasks = [];
  welcomeEnabled = true;
  statsEnabled = true;
  finishedEnabled = true;
  recommendationsEnabled = true;
  public wasChanged = false;
  public user = this.authService.me;
  public profile: Profile = null;
  public emailActive = false;
  public phoneActive = false;
  public descriptionActive = false;
  public facebookActive = false;
  public instagramActive = false;
  public twitterAcitve = false;
  public linkedinActive = false;
  loading = true;
  avatar: File = null;
  profileMedia: File = null;
  contractorProfileMedia: File = null;
  offers$: Observable<Array<OffersResponseDTO>> = this.offersService.getOffers({
    limit: 10,
    offset: 0,
    filterBy: {}
  });
  categories$ = this.offersService.getCategories$.pipe(
    map((categories) => {
      return categories.map((category) => ({
        label: category.category,
        value: category.id,
      }));
    })
  );
  categories = [];

  categoriesSub = this.categories$.subscribe((categories) => {
    this.categories = categories;
  });

  subscriptions = [];
  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
    private location: Location,
    private validationService: ServerSideValidationService,
    public commonHttpService: CommonHttpService,
    private uploadService: UploadService,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private deviceDetectorService: DeviceDetectorService,
    private offersService: OffersService

  ) { }

  ngOnInit(): void {
    combineLatest([this.profileService.getProfileByUserId(this.user.id), this.authService.me$.pipe(filter(x => !!x))])
      .subscribe(([profile, user]: [Profile, UserData]) => {
        this.profile = profile;
        this.user = user;
        this.profileForm.get('email').setValue(this.user.email);
        this.profileForm.get('phoneNumber').setValue(profile.phoneNumber);
        this.profileForm
          .get('welcomeTitle')
          .setValue(profile.welcomeTitle);
        this.profileForm
          .get('contratorTitle')
          .setValue(profile.contratorTitle);
        this.profileForm
          .get('welcomeDescription')
          .setValue(profile.welcomeDescription);
        this.profileForm
          .get('contractorDescription')
          .setValue(profile.contractorDescription);
        this.profileForm.get('description').setValue(profile.description);
        this.profileForm.get('facebook').setValue(profile.facebook);
        this.profileForm.get('instagram').setValue(profile.instagram);
        this.profileForm.get('twitter').setValue(profile.twitter);
        this.profileForm.get('linkedin').setValue(profile.linkedin);
        this.profileForm.get('firstname').setValue(this.user.firstname);
        this.profileForm.get('lastname').setValue(this.user.lastname);
        if (profile.education) {
          this.profileForm
            .get('educationYears')
            .setValue(JSON.parse(profile.education).educationYears);
          this.profileForm
            .get('educationSchoolName')
            .setValue(JSON.parse(profile.education).educationSchool);
          this.profileForm
            .get('educationSpecialization')
            .setValue(
              JSON.parse(profile.education).educationSpecialization
            );
        }
        this.profileForm
          .get('offersCategory')
          .setValue(profile.offersCategory?.id);
        this.profileForm
          .get('profileMedia')
          .setValue(profile.profileMedia?.id);
        this.profileForm
          .get('contractorProfileMedia')
          .setValue(profile.contractorProfileMedia?.id);

        this.profileForm.get('website')
          .setValue(profile.website);
        this.profileForm.valueChanges.subscribe((change) => {
          // TODO: compare for real changes
          this.wasChanged = true;
        });

        this.loading = false;
        this.validationService.registerValidationHandler('profile', this);
      }, (err) => {
        debugger;
        console.error(err);
        this.snackBar.open('Nie można uzyskać profilu', 'Ok', { duration: 3000 });
      });

  }

  ngOnDestroy(): void {
    this.validationService.removeValidationHandler('profile');
  }

  onFieldErrors(errors: ValidationErrorMessage): void {
    this.changeDetectorRef.detectChanges();

    errors.message.forEach((error) => {
      // ugly hack but didn't work other way
      setTimeout(() => {
        this.profileForm.controls[error.field].setErrors({
          message: error.message,
        }, { emitEvent: true });
      });
      this.setActiveField(error.field);
    });

    this.scrollToError();
  }

  scrollToError(): void {
    this.changeDetectorRef.detectChanges();
    const mobileSelector = 'form .ng-invalid';
    const desktopSelector = 'form:nth-child(1) form .ng-invalid'; // second form
    let firstElementWithError = null;
    if (this.deviceDetectorService.isDesktop()) {
      firstElementWithError = document.querySelector(desktopSelector);
    } else {
      firstElementWithError = document.querySelector(mobileSelector);
    }
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }

  setActiveField(activeField: string): void {
    this.activeField.push(activeField);
    // add esc and outclick events
  }

  onEnter(event): void {
    event.preventDefault();
    event.stopPropagation();
    // TODO: prevent submit
    // TODO: save value
    this.wasChanged = true;

    // value is in form, just disable the input
    this.activeField = [];
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ): void {
    this.activeField = [];
  }

  cleanForm(formValues: Profile): Profile {
    const formKeys = Object.keys(formValues);
    if (formKeys.length) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < formKeys.length; i++) {
        if (!formValues[formKeys[i]]) {
          delete formValues[formKeys[i]];
        }
      }
    }
    return formValues;
  }

  submit(): void {
    if (this.avatar) {
      const avatarUpload = this.authService.uploadAvatarImage(this.avatar);
      this.tasks.push(avatarUpload);
    }
    if (this.profileMedia) {
      const profileMediaUpload = this.uploadService.uploadProfileMediaImage(
        this.profileMedia
      );
      this.tasks.push(profileMediaUpload);
    }
    if (this.contractorProfileMedia) {
      const contractorProfileMediaUpload = this.uploadService.uploadContractorProfileMediaImage(
        this.contractorProfileMedia
      );
      this.tasks.push(contractorProfileMediaUpload);
    }
    // this.tasks.push(profileSubmit);
    if (this.avatar || this.profileMedia || this.contractorProfileMedia) {
      const combine = zip(...this.tasks);
      combine.subscribe((data) => {
        data.map((image: any) => {
          // TODO: was image; ImageData but breaks types. Investigate what happened
          if (image.type === 'avatar') {
            this.profileForm.controls.avatar.setValue(image.id);
          } else if (image.type === 'profile-media') {
            this.profileForm.controls.profileMedia.setValue(image.id);
          } else if (image.type === 'contractor-profile-media') {
            this.profileForm.controls.contractorProfileMedia.setValue(image.id);
          }
        });
        this.profileService
          .editProfile(this.cleanForm(this.profileForm.value))
          .subscribe((userData) => {
            this.router.navigate(['/app', 'contractor-profile']);
          }, () => this.onError());
      });
    } else {
      const toSend = this.profileForm.value;

      toSend['education'] = JSON.stringify({
        educationSchool: this.profileForm.value.educationSchoolName,
        educationYears: this.profileForm.value.educationYears,
        educationSpecialization: this.profileForm.value.educationSpecialization,
      });
      delete toSend.educationSchoolName;
      delete toSend.educationYears;
      delete toSend.educationSpecialization;

      this.profileService
        .editProfile(this.cleanForm(toSend))
        .subscribe(() => this.router.navigate(['/app', 'contractor-profile']), () => this.onError());
    }
  }

  onError(): void {
    this.snackBar.open('Nie udalo sie zapisać danych', null, {
      duration: 3000,
    });
  }

  avatarImageLoaded($event: File): void {
    this.avatar = $event;
    this.wasChanged = true;
  }

  welcomeImageLoaded($event: File): void {
    this.profileMedia = $event;
    this.wasChanged = true;
  }

  welcomeContractorImageLoaded($event: File): void {
    this.contractorProfileMedia = $event;
    this.wasChanged = true;
  }

  load(filters: DefaultFilters<OffersRequestDTO>): void {
    this.offersService.getOffers(filters).subscribe((offers) => {
      this.offers = offers;
    });
  }

  back(): void {
    this.location.back();
  }

  onAvatarLoaded($event: File): void {
    this.avatar = $event;
    this.wasChanged = true;
  }

  onSidenavChanged(): void {
    this.wasChanged = true;
  }

  findCategoryById(value: number): Option {
    if (this.categories.length === 0) return null;
    const result = this.categories.find((x) => x.value == value);
    return result ? result.label : null;
  }

  getImageURL(path): string {
    return this.commonHttpService.getImagePath(path);
  }
}
